import styled from 'styled-components';

interface Props {
  display: string;
}

export const ContainerMenu = styled.aside<Props>`
  width: 260px;
  height: 100vh;
  background: #2b1a48;
  display: ${({ display }) => display};
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  img {
    width: 30px;
    height: 30px;
    align-self: flex-end;
    margin-top: 17px;
    margin-right: 20px;
  }
  a {
    width: 197px;
    height: 23px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 40px;
    color: #fff;
    text-align: left;
    margin-left: 20px;
  }
  @media (min-width: 1091px) {
    display: none;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 80vh;
  width: 50vw;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.span`
  color: #2b1a48;
  font-weight: 700;
  font-size: 1.5rem;
  display: inline-block;
  max-width: 415px;
  margin-bottom: 1rem;
`;

export const MessageText = styled.span`
  color: #4c4d4d;
  font-size: 1rem;
  font-weight: 400;

  display: inline-block;
  max-width: 375px;
  text-align: start;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  > button {
    margin: 0;
    width: 236px;
    height: 47px;
  }
`;

export const ImageContainer = styled.div``;

import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Address } from 'model/address';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/RegisterForm';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import AddressSVG from '../../../images/address.svg';
import { FormContainer } from '../styles';
import { ContainerInputsAddress } from './InputsAddress';

export const FormAddress = () => {
  const { onClickNext } = useRegisterForm();
  const { states, stateID, cities, getStates, getCities } = useSelectLists();
  const { t } = useTranslation();

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
  });

  useEffect(() => {
    getStates({ size: 7 });
  }, []);

  useEffect(() => {
    if (stateID != null && !Number.isNaN(stateID)) {
      getCities(stateID, { size: 7 });
    }
  }, [stateID]);

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const state = states.find(st => st.name === res.state);
          const city = cities.find(cit => cit.name === res.city);

          if (onClickNext) {
            const address: Address = {
              zipcode: res.zipcode,
              street: res.street,
              number: res.number,
              complement: res.complement,
              district: res.district,
              state,
              city,
            };
            onClickNext({ address } as Client);
          }
        }}
      >
        <ContainerInputsAddress />
      </ReactHookFormProvider>
      <img src={AddressSVG} />
    </FormContainer>
  );
};

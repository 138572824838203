import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import CustomSteper from 'components/custom-steper/custom-steper';
import Status from 'components/status/status';
import StyledButton from 'components/StyledButton';
import { t } from 'i18next';
import { CreditAnalysisStatus } from 'model/enums/credit-analysis-status';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthUtils from 'shared/util/auth-utils';
import {
  ButtonsContainer,
  CaptionBox,
  CaptionContainer,
  CaptionTitle,
  ColorConnector,
  Container,
  InputDivisor,
  StepContainer,
  Title,
} from './styles';

interface AboutFinancingProps {
  creditAnalysisStatus?: CreditAnalysisStatus;
  registrationStatus: RegistrationStatus;
  setShowAnalyseFinancingModal: (value: boolean) => void;
  isPartner: boolean;
  financingStatus: string;
}

export enum ModalType {
  ANALYSE_REGISTRATION = 'ANALYSE_REGISTRATION',
  ANALYSE_FINANCING = 'ANALYSE_FINANCING',
  APPOINT_CONSULTANT = 'APPOINT_CONSULTANT',
}

const AboutFinancing: React.FC<AboutFinancingProps> = props => {
  // tslint:disable-next-line:no-shadowed-variable
  const { t } = useTranslation();

  const handleVerifyActiveStep = () => {
    if (props.creditAnalysisStatus === CreditAnalysisStatus.APPROVED) {
      return 1;
    }
    if (props.financingStatus === 'FINISHED') {
      return 4;
    } else if (props.creditAnalysisStatus === CreditAnalysisStatus.ACCEPTED) {
      return 2;
    }
    return 0;
  };

  const handleAnalyseFinancing = () => {
    props.setShowAnalyseFinancingModal(true);
  };

  return (
    <Container>
      <Title>{t('aboutFinancing.title')}</Title>
      <CaptionContainer>
        <CaptionTitle>{t('aboutFinancing.status')}</CaptionTitle>
        <CaptionBox>
          <Status title={props.creditAnalysisStatus} />
        </CaptionBox>
      </CaptionContainer>
      <StepContainer>
        <CustomSteper activeStep={handleVerifyActiveStep()} />
      </StepContainer>
      {!props.isPartner && !AuthUtils.isInternal() && props.financingStatus !== CreditAnalysisStatus.ACCEPTED && (
        <ButtonsContainer>
          <InputDivisor />
          <StyledButton
            primary
            disabled={props.registrationStatus !== RegistrationStatus.APPROVED ? true : false}
            onClick={handleAnalyseFinancing}
            text={t('aboutFinancing.analyseFinancing')}
          />
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default AboutFinancing;

import { action } from 'typesafe-actions';
import { GlobalModalActionTypes } from './types';

export const openPaidDiseaseModal = () => action(GlobalModalActionTypes.OPEN_PAID_DISEASE_MODAL);
export const closePaidDiseaseModal = () => action(GlobalModalActionTypes.CLOSE_PAID_DISEASE_MODAL);

export const openImageModal = (imageSrc: string) => action(GlobalModalActionTypes.OPEN_PAID_IMAGE_MODAL, imageSrc);
export const closeImageModal = () => action(GlobalModalActionTypes.CLOSE_PAID_IMAGE_MODAL);

export const openFeedbackModal = () => action(GlobalModalActionTypes.OPEN_FEEDBACK_MODAL);
export const closeFeedbackModal = () => action(GlobalModalActionTypes.CLOSE_FEEDBACK_MODAL);

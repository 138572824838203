import { BackButton, ButtonsContainer, FormInputsContainer } from 'components/RegistrationForms/styles';
import { SearchSelectInput } from 'components/SearchSelectInput';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/RegisterForm';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsIncome = () => {
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const { isFirstForm, isLastForm, onClickBack, initialClientData } = useRegisterForm();
  const { occupations, occupationsTotalPages, getOccupations } = useSelectLists();

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  return (
    <FormInputsContainer>
      <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
        <SearchSelectInput
          name="occupation"
          placeholder={t('register.placeholder.occupation')}
          options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
          defaultValue={initialClientData?.occupation?.name}
          handleChange={values => {
            setOccupationName(values.name);
            setPage(0);
            getOccupations({ name: values.name, size: 7 });
          }}
          handleOnScroll={() => {
            if (page < occupationsTotalPages - 1) {
              setPage(page + 1);
            }
          }}
          defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
        />
      </TextInput>

      <TextInput
        name="income"
        label={t('register.inputs.income')}
        isRequired
        placeholder={t('register.placeholder.income')}
        mask={Mask.CURRENCY}
        defaultValue={initialClientData?.income?.toFixed(2)}
      />

      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack} type="button">
            <span>Voltar</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? 'Cadastrar' : 'Avançar'} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};

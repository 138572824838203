import { HeaderWithLogout } from 'components/HeaderWithLogout';
import StyledButton from 'components/StyledButton';
import { ButtonContainer, Container, ImageContainer, Message, TextContainer, Title } from './styles';
import Bike from '../../../images/bike.svg';
import Footer from 'features/footer/footer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import registerApi from 'api/register-api';
import { useEffect, useState } from 'react';
import { Client } from 'model/client';
import registerService from 'services/register-service';

export const StatusApprove = () => {
  const [clientCourse, setClientCourse] = useState<Client | undefined>(undefined);
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    handleToFinancingStarted();
  };

  const handleToFinancingStarted = async () => {
    setClientCourse(await registerService.toFinancingStarted());
  };

  useEffect(() => {
    if (clientCourse?.courses != null) {
      history.replace(`/client/dashboard/financing/process?id=${clientCourse?.courses[0]?.id}`);
    }
  }, [clientCourse]);

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextContainer>
          <Title>{t('documentStatus.approved.title')}</Title>
          <Message>{t('documentStatus.approved.message')}</Message>
          <ButtonContainer>
            <StyledButton primary text={t('documentStatus.approved.buttonText')} onClick={handleOnClick} />
          </ButtonContainer>
        </TextContainer>
        <ImageContainer>
          <img src={Bike} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

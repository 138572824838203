import authenticationApi from 'api/authentication-api';
import { AxiosResponse } from 'axios';
import { AUTHORITIES } from 'config/constants';
import jwt_decode from 'jwt-decode';
import { Authentication } from 'model/authentication';
import { User } from 'model/user';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import AuthUtils from 'shared/util/auth-utils';
import {
  getEmailError,
  getEmailSuccess,
  getSessionError,
  getSessionRequest,
  getSessionSuccess,
  loginAdminRequest,
  loginError,
  loginRequest,
  loginSuccess,
} from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(authenticationApi.login, action.payload);

    if (result.status !== 200 && result.data.token != null) {
      const errorMessage: string = result.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    }

    const jwt: string = result.data.token!;
    const decodeJwt: any = jwt_decode(jwt);

    if (decodeJwt.auth.includes(AUTHORITIES.CLIENT)) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(loginError(err.stack));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleAdminLogin(action: ReturnType<typeof loginAdminRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(authenticationApi.adminLogin, action.payload);

    if (result.status !== 200 && result.data.token != null) {
      const errorMessage: string = result.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    }

    const jwt: string = result.data.token!;
    const decodeJwt: any = jwt_decode(jwt);

    if (
      decodeJwt.auth.includes(AUTHORITIES.ADMIN) ||
      decodeJwt.auth.includes(AUTHORITIES.INTERNAL) ||
      decodeJwt.auth.includes(AUTHORITIES.INTERNAL_ADMIN) ||
      decodeJwt.auth.includes(AUTHORITIES.PARTNER)
    ) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(loginError(err.stack));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleGetSession() {
  try {
    const result: AxiosResponse<User> = yield call(authenticationApi.getSession);

    if (result.status === 200 && result.data != null) {
      yield put(getSessionSuccess(result.data));
    } else {
      yield put(getSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
  }
}

function* handleGetEmailValidation() {
  try {
    const result: AxiosResponse<User> = yield call(authenticationApi.getSession);

    if (result.status === 200 && result.data != null) {
      yield put(getEmailSuccess(result.data?.emailVerified ?? false));
    } else {
      yield put(getEmailError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getEmailError(err.stack));
    } else {
      yield put(getEmailError('An unknown error occured.'));
    }
  }
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchAdminLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_ADMIN_REQUEST, handleAdminLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_SESSION_REQUEST, handleGetSession);
}

function* watchGetEmailRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_EMAIL_REQUEST, handleGetEmailValidation);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchAdminLoginRequest), fork(watchGetSessionRequest), fork(watchGetEmailRequest)]);
}

export default authenticationSaga;

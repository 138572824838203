import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 80vh;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > span {
    color: #80008c;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  span + span {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;

    display: inline-block;
    margin-top: 24px;
    max-width: 476px;
    text-align: left;
  }
`;

import { Graph } from 'model/graph';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dashboardService from 'services/dashboard-service';
import GraphIcon from '../../images/graph-icon.svg';
import { Bar, Container, DataItem, Title, Value, ValueContainer } from './styles';

interface IDynamicGraphInfos {
  page: string;
}

const DynamicGraphInfos: React.FC<IDynamicGraphInfos> = props => {
  const { t } = useTranslation();

  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    const callDashboardService = async () => {
      const value = await dashboardService.getGraphData(props.page);
      setGraphData(value ?? []);
    };

    callDashboardService();
  }, []);
  console.log(graphData);
  return (
    <Container>
      {Object.keys(graphData).map((item, i) => (
        <React.Fragment key={i}>
          <DataItem>
            <Title>{t(`global.graphStatus.${item}`)}</Title>
            <ValueContainer>
              <img src={GraphIcon} />
              <Value>{graphData[`${item}`] !== 0 ? graphData[`${item}`] : '-'}</Value>
            </ValueContainer>
          </DataItem>
          {i < Object.keys(graphData).length - 1 && <Bar />}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default DynamicGraphInfos;

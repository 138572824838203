import Status from 'components/status/status';
import { useTranslation } from 'react-i18next';
import { Caption, Container, Content } from './StyledComponents';

const TooltipModal = ({ captions, tableName }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>Legenda Status</h1>
      {captions.map((caption, index) => (
        <Caption key={index}>
          <Status title={caption.propName} />
          <div style={{ marginTop: '5px' }}>
            <span>{t(`global.tooltipCaption.${tableName}.${caption.title}`)}</span>
          </div>
        </Caption>
      ))}
    </Container>
  );
};

export default TooltipModal;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #faf9f9;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  padding-left: 26px;
  img {
    cursor: pointer;
  }
`;

export const TitleHeader = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-left: 11px;
`;

export const ContainerNameDocument = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 35px;
`;

export const TitleName = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const Separator = styled.div`
  width: 4px;
  height: 4px;
  background: #80008c;
  border: none;
  border-radius: 50%;
  margin: 10px;
`;

export const TitleDocumentNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.541667px;
  color: #000000;
`;

export const ContainerContract = styled.div`
  width: 100%;
  padding-right: 25px;
  margin-bottom: 200px;
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerTitles } from './styles';
import ListGuarantor from '../ListGuarantor';
import { FooterStyled } from 'components/FooterStyled';

export const GuarantorList = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ContainerTitles>
        <h1>{t('dashboardClient.guarantor.textTitle')}</h1>
        <span>{t('dashboardClient.guarantor.textSubTitle')}</span>
      </ContainerTitles>
      <ListGuarantor allGuarantorsToshow={true} />
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

import { Course } from 'model/courses';

export const unMaskedCourseFieldsValues = (fields: Course, dateAlreadyUnmasked?: boolean) => {
  const newValues = { ...fields };

  newValues.courseValue = Number(newValues.courseValue?.toString().replace(/\D/g, ''));

  if (newValues.financing?.approvedValue) {
    newValues.financing.approvedValue = Number(newValues.financing?.approvedValue?.toString().replace(/\D/g, ''));
  }
  if (newValues.financing?.choosedValue) {
    newValues.financing.choosedValue = Number(newValues.financing?.choosedValue?.toString().replace(/\D/g, ''));
  }

  return newValues;
};

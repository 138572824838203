import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  min-width: 23.4rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    margin-bottom: 0.438rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #333333;
  }

  span + span {
    margin-left: 0.125rem;
    color: #f0654a;
  }
`;

import { truncate } from 'lodash';
import { Client } from 'model/client';
import { CourseFinancing } from 'model/financing';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import ClipBlack from '../../../../../../images/clipBlack.svg';
import { BreakLine, ContainerAttachment, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';
import NoDataPage from './no-data-page';

interface ClientDataProps {
  paymentContent: CourseFinancing;
}

const ClientPaymentData: React.FC<ClientDataProps> = props => {
  const { t } = useTranslation();
  const { paymentContent } = props;

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.approvedValue')}</ContentLabel>
        <ContentValue>{StringUtils.currencyMask(paymentContent?.totalValue?.toFixed(2)) ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.financingValue')}</ContentLabel>
        <ContentValue>{StringUtils.currencyMask(paymentContent?.totalValue?.toFixed(2)) ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.deadline')}</ContentLabel>
        <ContentValue>{t('clientData.details.deadlineValue')}</ContentValue>
      </ContentContainer>
      <BreakLine />
      <ContentContainer>
        <ContentLabel>{t('clientData.details.fees')}</ContentLabel>
        <ContentValue>{paymentContent?.monthlyTax ? `${paymentContent?.monthlyTax}% a.m.` : 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.cet')}</ContentLabel>
        <ContentValue>{paymentContent?.annualTax ? `${paymentContent?.annualTax}% a.a.` : 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.income')}</ContentLabel>
        <ContentValue>{StringUtils.currencyMask(paymentContent?.downPaymentValue?.toFixed(2)) ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <BreakLine />
      <ContentContainer>
        <ContentLabel>{t('clientData.details.installments')}</ContentLabel>
        <ContentValue>
          {paymentContent?.installmentsTotal
            ? `${paymentContent?.installmentsTotal}x ${StringUtils.currencyMask(paymentContent?.installmentValue?.toFixed(2))}`
            : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.dueDate')}</ContentLabel>
        <ContentValue>
          {paymentContent?.firstDueDate ? StringUtils.dateFormatMask(paymentContent?.firstDueDate?.toString()) : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer />
    </DataContainer>
  );
};

export default ClientPaymentData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContainerText,
  ContainerProgressBar,
  Circule,
  Bar,
  TextBar,
  ActualCircule,
  CompletedCircule,
  CompletedBar,
} from './styles';
import Correct from '../../images/correct.svg';

interface Props {
  ActualStep: 'NEW' | 'CREDIT_ANALYSIS' | 'ADJUST' | 'ADJUSTED' | 'APPROVED' | 'ACCEPTED' | 'REJECTED' | 'CONTRACT' | 'FINISHED';
  steps: string[];
}

export const ProgressBarClient = ({ steps, ActualStep }: Props) => {
  const { t } = useTranslation();
  const step = {
    NEW: 1,
    CREDIT_ANALYSIS: 2,
    ADJUST: 2,
    ADJUSTED: 2,
    APPROVED: 2,
    REJECTED: 2,
    ACCEPTED: 3,
    CONTRACT: 3,
    FINISHED: 4,
  };
  return (
    <Container>
      <ContainerProgressBar>
        {steps.map((element, index) => {
          return (
            <>
              {step[ActualStep] === index ? (
                <ActualCircule>
                  <TextBar>{t(element)}</TextBar>
                </ActualCircule>
              ) : step[ActualStep] > index ? (
                <CompletedCircule>
                  <img src={Correct} alt="" />
                  <TextBar>{t(element)}</TextBar>
                </CompletedCircule>
              ) : (
                <Circule>
                  <TextBar>{t(element)}</TextBar>
                </Circule>
              )}
              {index !== steps.length - 1 && index >= step[ActualStep] && <Bar />}
              {index !== steps.length - 1 && index < step[ActualStep] && step[ActualStep] !== 0 && <CompletedBar />}
            </>
          );
        })}
      </ContainerProgressBar>
    </Container>
  );
};

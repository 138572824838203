import React, { ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { IRootState } from 'reducer';
import { compose } from 'redux';
import AccessDenied from 'features/access-denied';
import AuthUtils from 'shared/util/auth-utils';

interface IPrivateRouteProps extends StateProps, RouteComponentProps {
  path: string;
  exact?: boolean;
  accessLevel?: AccessLevel[];
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
  isPreLoading: boolean;
}

export enum AccessLevel {
  ADMIN = 'ROLE_ADMIN',
  INTERNAL = 'ROLE_INTERNAL',
  INTERNAL_ADMIN = 'ROLE_INTERNAL_ADMIN',
  PARTNER = 'ROLE_PARTNER',
  CLIENT = 'ROLE_CLIENT',
}

class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
  constructor(props) {
    super(props);
    this.state = {
      isPreLoading: true,
    };
  }

  componentDidMount() {
    this.isPreLoadingTimeout();
  }

  isPreLoadingTimeout = () => {
    setTimeout(() => this.isPreLoading(false), 50);
  };

  isPreLoading = (isPreLoading: boolean) => {
    this.setState({
      isPreLoading,
    });
  };

  checkIfAuthoritiesIncludes = () => {
    const { account, accessLevel } = this.props;
    return accessLevel ? account?.authorities?.filter(auth => accessLevel.filter(access => access == auth) != null) != null : true;
  };

  renderNonAuthenticatedRoute = () => {
    const { history } = this.props;
    const redirectProps = { pathname: '/', state: { from: history.location.pathname } };
    return <Redirect to={redirectProps} />;
  };

  renderAuthenticatedRoute = () => {
    const { isPreLoading } = this.state;
    const { exact, path, component } = this.props;
    if (isPreLoading) return;
    return (
      <Fragment>
        {this.checkIfAuthoritiesIncludes() ? <Route exact={exact} path={path} component={component} /> : <AccessDenied />}
      </Fragment>
    );
  };

  render() {
    return <Fragment>{AuthUtils.isAuthenticated() ? this.renderAuthenticatedRoute() : this.renderNonAuthenticatedRoute()}</Fragment>;
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps))(withRouter(PrivateRoute));

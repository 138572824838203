import styled from 'styled-components';

export const ContainerEverthing = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ContainerFirstSignUp = styled.div`
  width: 100%;
  min-height: 620px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(103.89% 243.44% at 7.78% 0%, #a90fcf 15.25%, #ef6d63 75.55%, #ffd954 94.51%);
`;

export const ContainerContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  @media (max-width: 1179px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 569px) {
    align-items: center;
    div + form {
      span {
        width: 280px;
      }
    }
  }
`;

export const ContainerContentApp = styled.div`
  max-width: 1176px;
  min-width: 1176px;
  display: flex;
  position: relative;
  @media (max-width: 1175px) {
    max-width: 320px;
    min-width: 320px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    div + img {
      position: absolute;
      width: 189px !important;
      height: 280px !important;
      left: 38px;
      top: 200px;
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    div + img {
      position: absolute;
      width: 128px !important;
      height: 155px !important;
      left: 199px;
      top: 253px;
      margin-left: 0 !important;
    }
  }
  @media (max-width: 356px) {
    flex-direction: column;
    align-items: center;
    div + img {
      position: absolute;
      width: 128px !important;
      height: 155px !important;
      left: 199px;
      top: 253px;
      margin-left: 0 !important;
    }
  }
`;

export const ContainerTextApresentation = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 90px;
  position: relative;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    width: 609px;
    height: 195px;
    color: #ffffff;
    margin-bottom: 33px;
    text-align: left;
  }
  span + span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    color: #eeeeee;
    width: 489px;
    height: 145px;
    position: static;
  }
  img {
    position: absolute;
    left: 150px;
    top: 125px;
    width: 396px;
  }

  img + img {
    position: absolute;
    left: 585px !important;
    top: 122px;
    width: 50px;
  }
  img + img + img {
    position: absolute;
    left: 510px !important;
    top: 340px;
    width: 150px;
  }
  @media (max-width: 1179px) {
    margin-right: 0;
    margin-left: 130px;
    margin-top: 32px;
    img {
      left: 151px;
      top: 121px;
    }
    img + img {
      top: 122px;
    }
    img + img + img {
      transform: rotate(90deg) scaleY(-1);
      left: 441px !important;
    }
  }
  @media (max-width: 810px) {
    span {
      font-size: 40px;
      width: 502px;
    }
    margin-left: 26px;
    img {
      width: 322px;
      left: 120px;
    }
    img + img {
      left: 461px !important;
      top: 126px;
    }
    img + img + img {
      left: 441px !important;
      top: 344px;
      width: 111px;
    }
  }
  @media (max-width: 590px) {
    img + img + img {
      left: 380px !important;
      top: 389px;
      width: 111px !important;
    }
  }
  @media (max-width: 569px) {
    margin-left: 0px !important;
    span {
      font-size: 32px;
      width: 290px;
      height: 200px;
      line-height: 40.32px;
      margin-left: 20px;
    }
    img {
      width: 253px;
      left: 23px;
      top: 119px;
    }
    img + img {
      left: 164px !important;
      width: 37px;
      top: 155px;
    }
    img + img + img {
      left: 258px !important;
      top: 420px;
      width: 68px !important;
    }
    span + span {
      font-size: 16px;
      width: 298px;
      height: 206px;
    }
  }
  @media (max-width: 350px) {
    margin-left: 0px !important;
    img + img {
      left: 165px;
    }
  }
`;

export const ContainerInfoApp = styled.div`
  width: 100%;
  height: 528px;
  display: flex;
  justify-content: center;
`;

export const ContainerTextApp = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 155px;
  text-align: left;
  position: relative;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-bottom: 18px;
    span {
      color: #80008c;
    }
  }
  span + span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    color: #4c4d4d;
    width: 376px;
    height: 116px;
  }
  img {
    position: absolute;
    top: 175px;
    left: 400px;
  }
  img + img {
    top: 45px;
    left: 222px;
  }
  @media (max-width: 1175px) {
    margin-right: 0;
    span + span {
      width: 320px;
      height: 138px;
    }
    span + img {
      transform: scaleY(-1);
      width: 140px;
      top: 235px;
      left: 68px;
    }
    img + img {
      transform: scaleY(1);
    }
  }
`;

export const ContainerRedirectToApp = styled.div`
  display: flex;
  margin-top: 61px;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    position: static;
    margin-right: 20px;
  }
  @media (max-width: 1175px) {
    flex-direction: column;
    width: 180px;
    img {
      margin-bottom: 20px;
    }
  }
`;

export const SpanStyledError = styled.span`
  font-weight: 300;
  color: #ff0045;
`;

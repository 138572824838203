import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0px 10px 10px #f1f1f1;
`;

export const LogoImageContainer = styled.div`
  height: 3.35rem;
  margin: 0.75rem 0px;
`;

export const LogoImage = styled.img`
  height: inherit;
  width: inherit;
`;

export const BackButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 1rem;
    color: #80008c;
    margin-left: 0.5rem;
  }
`;

export const GhostSeparator = styled.div`
  width: 1rem;
`;

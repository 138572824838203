import { Client } from 'model/client';
import { GuarantorProps } from 'model/guarantor';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface GuarantorGeneralDataProps {
  guarantorData: GuarantorProps;
}

const GuarantorAddressData: React.FC<GuarantorGeneralDataProps> = props => {
  const { t } = useTranslation();
  const { guarantorData } = props;

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.address1')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.street ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.number')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.number ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.address2')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.complement ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <BreakLine />
      <ContentContainer>
        <ContentLabel>{t('clientData.address.district')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.district ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.city')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.city?.name ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.state')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.city?.state?.name ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.address.cep')}</ContentLabel>
        <ContentValue>{guarantorData?.address?.zipcode ?? 'Não informado'}</ContentValue>
      </ContentContainer>
    </DataContainer>
  );
};

export default GuarantorAddressData;

import AccessDenied from 'features/access-denied';
import { AccessBlocked } from 'features/AccessBlocked';
import { RegistrationStepsStatus } from 'model/enums/registration-status';
import React, { ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { IRootState } from 'reducer';
import { compose } from 'redux';
import AuthUtils from 'shared/util/auth-utils';
import { AccessLevel } from './private-routes';

interface IPrivateRouteProps extends StateProps, RouteComponentProps {
  path: string;
  exact?: boolean;
  stepLevel?: StepLevelType;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
  isPreLoading: boolean;
}

interface StepLevelValidation {
  [key: string]: boolean;
}

type StepLevelType = 'REGISTRATION' | 'REGISTRATION_STATUS' | 'FINANCING_STARTED' | 'CONTRACT' | 'PAYMENT';

export enum StepLevelStatus {
  REGISTRATION = 'REGISTRATION',
  REGISTRATION_STATUS = 'REGISTRATION_STATUS',
  FINANCING_STARTED = 'FINANCING_STARTED',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
}

class ClientPrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
  constructor(props) {
    super(props);
    this.state = {
      isPreLoading: true,
    };
  }

  componentDidMount() {
    this.isPreLoadingTimeout();
  }

  isPreLoadingTimeout = () => {
    setTimeout(() => this.isPreLoading(false), 50);
  };

  isPreLoading = (isPreLoading: boolean) => {
    this.setState({
      isPreLoading,
    });
  };

  checkIfAuthoritiesIncludes = () => {
    const { account } = this.props;
    return account?.authorities?.includes(AccessLevel.CLIENT) ?? false;
  };

  checkLevelValidation = (): boolean => {
    const { stepLevel } = this.props;

    const registrationStepsStatus: RegistrationStepsStatus | undefined = this.props.account?.client?.registrationStepsStatus;
    console.log({ top: registrationStepsStatus });
    switch (stepLevel) {
      case StepLevelStatus.REGISTRATION:
        return this.checkIfAuthoritiesIncludes() && (registrationStepsStatus !== RegistrationStepsStatus.FINANCING_STARTED ?? false);
      case StepLevelStatus.REGISTRATION_STATUS:
        const registrationStepStatus: RegistrationStepsStatus[] = [
          RegistrationStepsStatus.REGISTRATION_ADJUST,
          RegistrationStepsStatus.REGISTRATION_REJECTED,
          RegistrationStepsStatus.REGISTRATION_APPROVED,
          RegistrationStepsStatus.FINANCING_STARTED,
        ];
        return (
          this.checkIfAuthoritiesIncludes() &&
          ((registrationStepsStatus && registrationStepStatus.includes(registrationStepsStatus)) ?? false)
        );
      case StepLevelStatus.FINANCING_STARTED:
        const status: RegistrationStepsStatus[] = [
          RegistrationStepsStatus.FINANCING_STARTED,
          RegistrationStepsStatus.REGISTRATION_APPROVED,
        ];
        return this.checkIfAuthoritiesIncludes() && ((registrationStepsStatus && status.includes(registrationStepsStatus)) ?? false);
      default:
        return this.checkIfAuthoritiesIncludes();
    }
  };

  renderNonAuthenticatedRoute = () => {
    const { history } = this.props;
    const redirectProps = { pathname: '/', state: { from: history.location.pathname } };
    return <Redirect to={redirectProps} />;
  };

  renderAuthenticatedRoute = () => {
    const { isPreLoading } = this.state;
    const { exact, path, component, stepLevel } = this.props;
    if (isPreLoading) return;
    return (
      <Fragment>{this.checkLevelValidation() ? <Route exact={exact} path={path} component={component} /> : <AccessDenied />}</Fragment>
    );
  };

  render() {
    return <Fragment>{AuthUtils.isAuthenticated() ? this.renderAuthenticatedRoute() : this.renderNonAuthenticatedRoute()}</Fragment>;
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps))(withRouter(ClientPrivateRoute));

import InputText from 'components/InputText';
import StyledButton from 'components/StyledButton';
import LogoFdi from 'images/logo-fdi-escrita.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { getSessionRequest, loginAdminRequest, logoutRequest } from 'reducer/authentication/actions';
import { compose } from 'redux';
import AuthUtils from 'shared/util/auth-utils';
import * as yup from 'yup';
import ReactHookFormProvider, { FieldsError } from '../../../components/ReactHookFormProvider';
import {
  ButtonContainer,
  Container,
  FormContainer,
  ImgContainer,
  InputContainer,
  ItemLink,
  LoginContainer,
  SubTextTitle,
  TextTitle,
} from './styles';

interface ILoginAdminProps extends StateProps, DispatchProps, RouteComponentProps, WithTranslation {
  // TODO
}

interface ILogin {
  username: string;
  password: string;
}

const LoginAdmin = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const [hasError, setHasError] = useState(false);

  const schema = yup.object({
    username: yup.string().email().required(t('global.errorMessage.required')),
    password: yup.string().required(t('global.errorMessage.required')).min(4, t('global.errorMessage.password')),
  });

  const fieldsError: FieldsError[] = [
    { name: 'username', errorMessage: t('global.errorMessage.login') },
    { name: 'password', errorMessage: t('global.errorMessage.login') },
  ];

  useEffect(() => {
    if (isAuthenticated && account?.id != null) {
      redirectToPath();
    }

    handleSetError();
  }, [account, loginStatus]);

  const redirectToPath = () => {
    const redirectPath = props.location.state?.['from'] || AuthUtils.getMainPath();
    props.history.replace(redirectPath);
  };

  const onHandleSubmit = (data: ILogin) => {
    props.adminLogin(data);
  };

  const handleSetError = () => {
    if (loginStatus === HttpRequestStatus.ERROR) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <LoginContainer>
      <Container>
        <ImgContainer>
          <img src={LogoFdi} />
        </ImgContainer>
        <TextTitle>{t('login.admin.title')}</TextTitle>
        <SubTextTitle>{t('login.admin.subtitle')}</SubTextTitle>
        <FormContainer>
          <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} hasError={hasError} fieldsWithError={fieldsError}>
            <InputContainer>
              <InputText name="username" label="" placeholder={t('login.admin.form.usernamePlaceholder')} />
            </InputContainer>
            <InputContainer>
              <Link to={'/forgot-password'}>
                <ItemLink>{t('login.form.forgotPassword')}</ItemLink>
              </Link>
              <InputText inputType="password" name="password" label="" placeholder={t('login.admin.form.passwordPlaceholder')} />
            </InputContainer>
            <ButtonContainer>
              <StyledButton primary type="submit" text={t('global.button.adminLogin')} />
            </ButtonContainer>
          </ReactHookFormProvider>
        </FormContainer>
      </Container>
    </LoginContainer>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginStatus: authentication.loginStatus,
  isAuthenticated: authentication.isAuthenticated,
  getSessionStatus: authentication.getSessionStatus,
});

const mapDispatchToProps = {
  adminLogin: loginAdminRequest,
  logout: logoutRequest,
  getSession: getSessionRequest,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps, mapDispatchToProps))(LoginAdmin);

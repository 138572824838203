import React, { useEffect, useState } from 'react';
import { ProgressBarClient } from 'components/ProgressBarClient';
import { useTranslation } from 'react-i18next';
import { Container, ContainerTitleAndBar, ContainerTitleButton, TitleText } from './styles';
import WaitRegisterGuarantor from './RegisterGuarantorStep';
import WarningContent from 'components/WarningContentFinancing';
import WaitApproval from '../../images/seatedWoman.svg';
import Rejected from '../../images/ErrorWoman.svg';
import WaitContract from '../../images/womanContract.svg';
import ContractView from '../../images/womanContractView.svg';
import womanPayment from '../../images/womanPayment.svg';
import Adjust from '../../images/womanAdjust.svg';
import Info from '../../images/infoPurple.svg';
import ButtonComponent from 'components/Button';
import { CreditAnalysisStatus } from 'model/enums/credit-analysis-status';
import { useHistory } from 'react-router-dom';
import CardFinancing from 'components/CardFinancing';
import { useFinancing } from 'provider/Financing';
import StringUtils from 'shared/util/string-utils';
import SimulatorStep from './SimulatorStep';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { RegistrationStepsStatus } from 'model/enums/registration-status';
import { getSessionRequest } from 'reducer/authentication/actions';
import courseService from 'services/course.service';
import { CourseFinancing, DownPaymentStatus } from 'model/financing';
import { FooterStyled } from 'components/FooterStyled';

type CreditStatus = 'NEW' | 'CREDIT_ANALYSIS' | 'ADJUST' | 'ADJUSTED' | 'APPROVED' | 'ACCEPTED' | 'REJECTED' | 'CONTRACT' | 'FINISHED';
interface StepElements {
  NEW: JSX.Element;
  CREDIT_ANALYSIS: JSX.Element;
  ADJUSTED: JSX.Element;
  ADJUST: JSX.Element;
  APPROVED: JSX.Element;
  ACCEPTED: JSX.Element;
  CONTRACT: JSX.Element;
  REJECTED: JSX.Element;
  FINISHED: JSX.Element;
}

const Financing = () => {
  const { t } = useTranslation();
  const accountStatus = useSelector((state: IRootState) => state.authentication.account?.client?.registrationStepsStatus);
  const idClient = useSelector((state: IRootState) => state.authentication.account?.client?.id);
  const dispatch = useDispatch();
  const [showInfos, setShowInfos] = useState<boolean>(false);
  const [statusCourse, setStatusCourse] = useState<CreditStatus>();
  const [fineshedTitle, setFineshedTitle] = useState<string>('dashboardClient.financing.finishedFinancingEntry');
  const [fineshedDescription, setFineshedDescription] = useState<string>();
  const [financing, setFinancing] = useState<CourseFinancing>();
  const [financingWait, setFinancingWait] = useState<boolean>(false);
  const history = useHistory();
  const courseId = parseInt(history.location.search.split('=')[1]);
  const { getCourse, course, linkedGuarantors, getFinancing, getContracts, contract } = useFinancing();
  const steps = [
    'dashboardClient.financing.progressBar.register',
    'dashboardClient.financing.progressBar.guarantor',
    'dashboardClient.financing.progressBar.approval',
    'dashboardClient.financing.progressBar.signature',
    'dashboardClient.financing.progressBar.payment',
  ];

  const StepsElements: StepElements = {
    NEW: <WaitRegisterGuarantor guarantorRegistred={linkedGuarantors.length > 0} setStatusCourse={setStatusCourse} />,
    CREDIT_ANALYSIS: (
      <WarningContent
        img={WaitApproval}
        subtitle="dashboardClient.financing.waitToApproval"
        description="dashboardClient.financing.descriptionWaitToApproval"
      />
    ),
    ADJUSTED: (
      <WarningContent
        img={WaitApproval}
        subtitle="dashboardClient.financing.waitToApproval"
        description="dashboardClient.financing.descriptionWaitToApproval"
      />
    ),
    ADJUST: (
      <WarningContent
        img={Adjust}
        subtitle="dashboardClient.financing.adjustFinancing"
        description="dashboardClient.financing.adjustFinancing"
        containerDescription
        message={course?.consultantComment ? course.consultantComment : ''}
        titleButton="dashboardClient.financing.buttons.AdjustFinancing"
        onClick={() => history.push('/client/dashboard/guarantor')}
      />
    ),
    APPROVED: <SimulatorStep />,
    ACCEPTED: (
      <WarningContent
        img={WaitContract}
        subtitle="dashboardClient.financing.waitContract"
        description="dashboardClient.financing.descriptionWaitContract"
      />
    ),
    CONTRACT: (
      <WarningContent
        img={ContractView}
        subtitle="dashboardClient.financing.waitToSignature"
        description="dashboardClient.financing.descriptionWaitToSignature"
        titleButton="dashboardClient.financing.buttons.contract"
        onClick={() => history.push(`/client/dashboard/contracts?id=${courseId}`)}
      />
    ),
    REJECTED: (
      <WarningContent
        img={Rejected}
        subtitle="dashboardClient.financing.rejectedFinancing"
        description="dashboardClient.financing.descriptionWaitToApproval"
        containerDescription
        message="O nome do cadastro não é o mesmo do documento de identificação; enviar foto do documento de identificação não borrada; enviar comprovante de residência mais atual."
      />
    ),
    FINISHED: (
      <WarningContent
        img={financingWait ? WaitContract : womanPayment}
        subtitle={fineshedTitle}
        description={fineshedDescription ?? ''}
        titleButton={financingWait ? undefined : 'dashboardClient.financing.buttons.payment'}
        onClick={financingWait ? undefined : () => history.push(`/client/dashboard/payment?id=${courseId}`)}
      />
    ),
  };

  useEffect(() => {
    getCourse(courseId);
  }, []);

  useEffect(() => {
    if (accountStatus === RegistrationStepsStatus.REGISTRATION_APPROVED) {
      dispatch(getSessionRequest());
    }
  }, [accountStatus]);

  useEffect(() => {
    if (course?.creditAnalysisStatus === CreditAnalysisStatus.ACCEPTED) {
      getContracts(idClient ?? 0);
    } else if (course?.creditAnalysisStatus) {
      setStatusCourse(course.creditAnalysisStatus);
    }
  }, [course]);

  useEffect(() => {
    if (statusCourse === CreditAnalysisStatus.APPROVED) {
      getFinancing(courseId);
    }
  }, [statusCourse]);

  const getFinancings = async () => {
    const content = await courseService.getFinancingsByClientId(idClient);
    setFinancing(content.find(element => element.course?.id === Number(courseId)));
  };

  useEffect(() => {
    const contractoShow = contract?.find(element => element.course?.id === Number(courseId));
    if (contractoShow?.status === 'IN_PROGRESS') {
      setStatusCourse('CONTRACT');
    } else if (contractoShow?.status === 'FINISHED') {
      setStatusCourse('FINISHED');
      getFinancings();
    } else if (contractoShow?.status === 'NEW') {
      setStatusCourse(CreditAnalysisStatus.ACCEPTED);
    }
  }, [contract]);

  useEffect(() => {
    if (financing && financing?.downPaymentInstallment?.bankSlip?.status !== 'CREATED') {
      setFineshedTitle('dashboardClient.financing.waitToFinancingPayments');
      setFineshedDescription(
        `${t('dashboardClient.financing.waitToFinancingPaymentsDescriptionFirst')}${StringUtils.moneyMaskPtbr(
          financing?.downPaymentTotalValue
        )}${t('dashboardClient.financing.waitToFinancingPaymentsDescriptionSecond')}`
      );
      setFinancingWait(true);
    }
    if (financing?.downPaymentStatus === DownPaymentStatus.PENDING && financing?.downPaymentInstallment?.bankSlip?.status === 'CREATED') {
      setFineshedTitle('dashboardClient.financing.finishedFinancingEntry');
      setFineshedDescription(
        `${t('dashboardClient.financing.descriptionFinancingEntryFirst')}${StringUtils.moneyMaskPtbr(financing.downPaymentTotalValue)}${t(
          'dashboardClient.financing.descriptionFinancingEntrySecond'
        )}`
      );
      setFinancingWait(false);
    } else if (financing?.downPaymentStatus === DownPaymentStatus.PAID && financing?.installments?.[0].bankSlip?.status === 'PROCESSING') {
      setFineshedTitle('dashboardClient.financing.finishedFinancingPayments');
      setFineshedDescription('dashboardClient.financing.finishedFinancingPaymentsDescription');
      setFinancingWait(false);
    } else if (financing?.downPaymentStatus === DownPaymentStatus.PAID && financing?.downPaymentInstallment?.bankSlip?.status === 'PAID') {
      setFineshedTitle('dashboardClient.financing.finishedFinancingPayments');
      setFineshedDescription('dashboardClient.financing.finishedFinancingPaymentsDescription');
      setFinancingWait(false);
    } else if (
      financing?.downPaymentStatus === DownPaymentStatus.PAID &&
      financing?.downPaymentInstallment?.bankSlip?.status === 'CREATED'
    ) {
      setFineshedTitle('dashboardClient.financing.finishedFinancingPayments');
      setFineshedDescription('dashboardClient.financing.finishedFinancingPaymentsDescription');
      setFinancingWait(false);
    }
  }, [financing]);

  return (
    <Container>
      <ContainerTitleAndBar>
        <ContainerTitleButton>
          <TitleText>{t('dashboardClient.financing.textTitle')}</TitleText>
          <div>
            <img
              src={Info}
              style={{ marginTop: '28px', marginLeft: '16px' }}
              onMouseEnter={() => setShowInfos(true)}
              onMouseLeave={() => setShowInfos(false)}
            />
            {showInfos && (
              <CardFinancing
                data={{
                  id: course?.id ?? 0,
                  duration: course?.duration ?? '',
                  language: course?.language?.name ?? '',
                  objective: course?.objective ?? '',
                  startDate: StringUtils.dateFormatMask(course?.startDate ?? ''),
                }}
              />
            )}
          </div>
          <ButtonComponent
            width="148px"
            height="30px"
            background="#80008C"
            border="none"
            borderRadius="8px"
            color="white"
            text="ver financiamentos"
            margin="28px 0px 0px 430px"
            weight="600"
            size="14px"
            onClick={() => history.push('/client/dashboard/financing/')}
          />
        </ContainerTitleButton>
        <ProgressBarClient steps={steps} ActualStep={statusCourse ? statusCourse : CreditAnalysisStatus.NEW} />
      </ContainerTitleAndBar>
      {statusCourse && StepsElements[statusCourse]}
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

export default Financing;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #3e3e3e;
  margin-bottom: 10px;
`;
export const ContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 8px;
  width: 730px;
  height: 151px;
  background: #f8f8f8;
  border-radius: 4px;
`;

import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerTitles } from './styles';
import GuarantorSvg from '../../images/guarantorSvg.svg';
import ButtonComponent from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useFinancing } from 'provider/Financing';
import Loading from 'components/loading/loading';
import { MoonLoader } from 'react-spinners';
import { FooterStyled } from 'components/FooterStyled';

const override: CSSProperties = {
  margin: 'calc(50vh)',
};

export const GuarantorRegister = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const { guarantors, getGuarantors } = useFinancing();

  useEffect(() => {
    if (guarantors.length === 0) {
      getGuarantors();
      setLoad(true);
    } else {
      history.push('/client/dashboard/guarantor/list');
    }
  }, []);

  useEffect(() => {
    if (guarantors.length === 0 && load) {
      setLoad(false);
    }
  }, [guarantors]);

  useEffect(() => {
    if (guarantors.length > 0) {
      history.push('/client/dashboard/guarantor/list');
    }
  }, [guarantors]);

  return (
    <Container>
      {load ? (
        <MoonLoader size={60} color="#80008C" />
      ) : (
        <>
          <ContainerTitles>
            <h1>{t('dashboardClient.guarantor.textTitle')}</h1>
            <span>{t('dashboardClient.guarantor.textSubTitle')}</span>
          </ContainerTitles>
          <img src={GuarantorSvg} alt={t('dashboardClient.guarantor.textTitle')} />
          <ButtonComponent
            width="276px"
            height="44px"
            background="#2B1A48"
            border="none"
            borderRadius="8px"
            color="white"
            margin="40px 0"
            text="dashboardClient.guarantor.button.newGuarantor"
            weight="400"
            size="24px"
            onClick={() => history.push('/client/register/guarantor')}
          />
          <FooterStyled>
            <span>{t('footer.texts.copyright')}</span>
          </FooterStyled>
        </>
      )}
    </Container>
  );
};

import LogoFdi from 'images/logo-fdi-escrita.svg';
import { Container } from './StyledComponents';

const FooterLoginAdmin = () => {
  return (
    <Container>
      <span>© FinanciamentoDeIntercâmbio. Todos os direitos reservados.</span>
    </Container>
  );
};

export default FooterLoginAdmin;

import backgroundImage from 'images/background.png';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 51px;
  max-height: 601px;
  border-bottom: 1px solid #d8d8d8;
  position: fixed;
`;
export const ImgContainer = styled.div`
  img {
    width: 100px;
    height: 34px;
  }
  margin-left: 25px;
`;

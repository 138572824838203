import registerApi from 'api/register-api';
import { ContainerEditData, Schema } from 'components/ContainerEditData';
import { HeaderWithLogout } from 'components/HeaderWithLogout';
import { parse } from 'date-fns';
import Footer from 'features/footer/footer';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/RegisterForm';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import registerService from 'services/register-service';
import { unMaskedFieldsValues } from 'shared/util/register-utils';
import * as yup from 'yup';
import { Container, Title } from './styles';

export const RegistrationEditScreen = () => {
  const [personalDatas, setPersonalDatas] = useState<Client>({});
  const [addressOccupationIds, setAddressOccupationIds] = useState<AddressOccupationIds>();
  const { files } = useRegisterForm();
  const history = useHistory();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')).email(t('global.errorMessage.email')),
    phone: yup.string().required(t('global.errorMessage.required')),
    cpf: yup.string().required(t('global.errorMessage.required')),
    income: yup.string().required(t('global.errorMessage.required')),
    office: yup.string().required(t('global.errorMessage.required')),
    birth: yup.string().required(t('global.errorMessage.required')),
    zipcode: yup.string().required(t('global.errorMessage.required')),
    address: yup.string().required(t('global.errorMessage.required')),
    district: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string().required(t('global.errorMessage.required')),
    rg: yup.string().required(t('global.errorMessage.required')),
    attachmentIdentification: yup
      .mixed()
      .transform(value => personalDatas?.attachments?.filter(it => it.type === AttachmentType.IDENTIFICATION_CARD)[0] ?? value[0])
      .required(t('global.errorMessage.required')),
    attachmentIncome: yup
      .mixed()
      .transform(value => personalDatas?.attachments?.filter(it => it.type === AttachmentType.PROOF_INCOME)[0] ?? value[0])
      .required(t('global.errorMessage.required')),
    attachmentAddress: yup
      .mixed()
      .transform(value => personalDatas?.attachments?.filter(it => it.type === AttachmentType.PROOF_RESIDENCE)[0] ?? value[0])
      .required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = res => {
    sendNewClientPersonalDatas(res);
  };

  useEffect(() => {
    getClientPersonalDatas();
  }, []);

  const getClientPersonalDatas = async () => {
    const res = await registerService.getClientData();
    setPersonalDatas(res);
  };

  const sendNewClientPersonalDatas = async (data: Schema) => {
    let attachments = personalDatas.attachments;

    if (files.length > 0) {
      files.map(file => {
        const attIndex = attachments?.findIndex(att => att.type === file.type);

        if (attIndex != null && attIndex >= 0) {
          attachments?.splice(attIndex, 1, file);
        }
      });
    }

    const clientBirth = data.birth.split('/').reverse().join('-');

    const clientDatas: Client = {
      name: data.name ?? personalDatas.name,
      email: data.email ?? personalDatas.email,
      phone: data.phone ?? personalDatas.phone,
      cpf: data.cpf ?? personalDatas.cpf,
      income: parseInt(data.income.split('R$ ')[1].replace('.', '')) ?? personalDatas.income,
      occupation: {
        id: addressOccupationIds?.occupationId ?? personalDatas.occupation?.id ?? 0,
      },
      rg: data.rg ?? personalDatas.rg,
      birth: parse(clientBirth, 'yyyy-MM-dd', new Date()) ?? personalDatas.birth,
      address: {
        zipcode: data.zipcode ?? personalDatas.address?.zipcode,
        street: data.address ?? personalDatas.address?.street,
        district: data.district ?? personalDatas.address?.district,
        number: data.number ?? personalDatas.address?.number,
        complement: data.complement ?? personalDatas.address?.complement,
        city: {
          id: addressOccupationIds?.cityId ?? personalDatas.address?.city?.id ?? 0,
        },
      },
      attachments,
    };

    const unMaskedDatas = unMaskedFieldsValues(clientDatas, true);

    try {
      await registerApi.sendNewClientPersonalDatas(unMaskedDatas);
      history.replace('/client/register-documents/attachments/wait');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <Title>{t('clientRegisterEdit.title')}</Title>
        <ContainerEditData
          schema={schema}
          editData={personalDatas}
          onSubmit={onHandleSubmit}
          setAddressOccupationIds={setAddressOccupationIds}
          addressOccupationIds={addressOccupationIds}
        />
      </Container>
      <Footer />
    </>
  );
};

import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: 20px 100px;
  width: 576px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    width: 124px;
    height: 36px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }

  h4 {
    height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #505050;
  }
`;

export const FormContainer = styled.div``;
export const ButtonContainer = styled.div`
  padding: 25px;
  display: flex;
  justify-content: space-between;
`;

export const modalStyles: ReactModal.Styles = {
  content: {
    width: '825px',
    height: '550px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

import { HeaderWithLogout } from 'components/HeaderWithLogout';
import StyledButton from 'components/StyledButton';
import { AdminMessageContainer, ButtonContainer, Container, ImageContainer, Message, TextContainer, Title } from './styles';
import WomanSitting from '../../../images/woman-sitting.svg';
import Footer from 'features/footer/footer';
import { useTranslation } from 'react-i18next';

interface Props {
  admMessage?: string;
}

export const StatusError = ({ admMessage }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextContainer>
          <Title>{t('documentStatus.error.title')}</Title>
          <Message>{t('documentStatus.error.message')}</Message>
          {admMessage && (
            <AdminMessageContainer>
              <span>{admMessage}</span>
            </AdminMessageContainer>
          )}
        </TextContainer>
        <ImageContainer>
          <img src={WomanSitting} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

import { AxiosResponse } from 'axios';
import { GuarantorProps } from 'model/guarantor';
import { Score } from 'model/score';
import { api } from './api';

type Params = string;

export const guarantor = () => {
  const createGuarantor = (guarantor: GuarantorProps): Promise<AxiosResponse<GuarantorProps>> => {
    return api.post<GuarantorProps>('/guarantors', guarantor);
  };

  const checkGuarantorScore = (id: number): Promise<AxiosResponse<Score>> => {
    return api.post<Score>('/credit-score/guarantor-analysis/check', { id });
  };

  const editGuarantor = (guarantor: GuarantorProps, id: number): Promise<AxiosResponse<GuarantorProps>> => {
    return api.patch<GuarantorProps>(`/guarantors/${id}`, guarantor);
  };

  const getGuarantor = (): Promise<AxiosResponse<GuarantorProps[]>> => {
    return api.get<GuarantorProps[]>('/guarantors');
  };

  const getGuarantorId = (id: number): Promise<AxiosResponse<GuarantorProps>> => {
    return api.get<GuarantorProps>(`/guarantors/${id}`);
  };

  const deleteGuarantorId = (id: number): Promise<AxiosResponse<void>> => {
    return api.delete<void>(`/guarantors/${id}`);
  };

  const getGuarantorIdUnlinkedToCourse = (id: number): Promise<AxiosResponse<GuarantorProps[]>> => {
    return api.get<GuarantorProps[]>(`guarantors?courseId=${id}`);
  };

  const coursesToLinkGuarantor = (id: number, params: Params): Promise<AxiosResponse<void>> => {
    return api.patch<void>(`/guarantors/${id}/courses?${params}`);
  };

  return {
    createGuarantor,
    getGuarantor,
    editGuarantor,
    getGuarantorId,
    getGuarantorIdUnlinkedToCourse,
    deleteGuarantorId,
    coursesToLinkGuarantor,
    checkGuarantorScore,
  };
};

export default guarantor();

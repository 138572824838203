import styled from 'styled-components';

export const ContainerRequestApproval = styled.form`
  width: 480px;
  min-height: 440px;
  background: #f1e0fd;
  box-shadow: 0px 15px 40px rgba(27, 25, 25, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  span {
    width: 351px;
    height: 30px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    color: #80008c;
    margin-top: 41px;
    margin-left: 37px;
  }
  span + span {
    width: 373px;
    height: 78px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160.02%;
    color: #4c4d4d;
    margin-top: 16px;
    text-align: left;
  }
  span + span + span {
    height: 27px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80008c;
    margin-top: 14px;
    margin-left: 38px;
    align-self: flex-start;
    span {
      margin-left: 0;
      font-weight: 700;
      color: #f0654a;
    }
  }
  span + span + div + span {
    height: 27px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80008c;
    margin-top: 14px;
    margin-left: 38px;
    align-self: flex-start;
    span {
      margin-left: 0;
      font-weight: 700;
      color: #f0654a;
    }
  }
  button {
    justify-self: center;
  }
  @media (max-width: 1178px) {
    margin-bottom: 20px;
  }
  @media (max-width: 589px) {
    width: 320px;
    margin-left: 20px !important;
    margin-bottom: 20px;
    span {
      margin-left: 20px;
      font-size: 18px;
    }
    span + span {
      width: 274px;
    }
    input {
      width: 280px !important;
      margin-left: 20px !important;
    }
    span + span + span {
      margin-left: 20px;
    }
    span + span + div + span {
      margin-left: 25px;
    }
    button {
      min-width: 280px;
      max-width: 280px;
      margin-left: 20px;
    }
  }
  @media (max-width: 350px) {
    margin-left: 0px !important;
  }
`;

export const InputStyled = styled.input`
  width: 476px;
  min-height: 50px;
  background: #ffffff;
  border: 1px solid #d8bdfd;
  border-radius: 8px;
  color: #80008c;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 16px;

  ::placeholder {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d5bee5;
  }

  :focus {
    border: 1px solid #80008c;
  }
`;

export const ContainerInputAndText = styled.div`
  width: 194px;
  display: flex;
  flex-direction: column;
  span {
    width: 170px;
    margin-left: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80008c;
    margin-top: 14px;
    align-self: flex-start;
    text-align: left;
    span {
      margin-left: 0;
      font-weight: 700;
      color: #f0654a;
    }
  }
  @media (max-width: 589px) {
    width: 136px;
    input {
      width: 130px !important;
      margin-left: 0 !important;
    }
  }
`;

export const ContainerInputs = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  @media (max-width: 589px) {
    width: 88%;
  }
`;

export const ContainerImgAndText = styled.div`
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ContainerAlltext = styled.div`
  display: flex;
  flex-direction: column;
  span {
    width: 180px;
    height: 102px;
  }
  span + span {
    width: 204px;
    height: 96px;
  }
`;

export const ContainerCheckBoxText = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  span {
    width: 42px;
    height: 42px;
    margin: 0;
    color: #80008c !important;
  }
  p {
    margin: 0;
    color: #80008c;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  p + a {
    margin-left: 4px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

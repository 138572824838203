import styled from 'styled-components';

export const Container = styled.div`
  min-height: 80vh;
  width: 80vw;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  & > span {
    text-align: left;
    color: #2b1a48;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 35px;
    margin-top: 48px;
  }
`;

export const ContainerTextAndArrow = styled.div`
  display: flex;
  img {
    margin-right: 16px;
  }
  margin-bottom: 19px;
`;

export const List = styled.ul`
  list-style-type: disc;
  text-align: left;
  margin-top: 16px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;

export const ImageContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  > button {
    margin: 0;
  }
`;

import guarantor from 'api/guarantor-api';
import registerApi from 'api/register-api';
import _ from 'lodash';
import { Client } from 'model/client';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import courseService from 'services/course.service';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';

interface RegisterData {
  steps: string[];
  actualStep: number;
  initialClientData: Client | null;
  isOpenEndModal: boolean;
  registerStatus: string;
  onClickNext: (newData: Client, isGuarantorRegister?: boolean) => void;
  onClickBack: () => void;
  isFirstForm: () => boolean;
  isLastForm: () => boolean;
  registerSteps: (steps: string[]) => void;
  getClientData: () => void;
  validateMajority: (date: Date) => boolean;
  handleSetEndModal: () => void;
  resetStates: () => void;
  seInitialClientData: (Register: Client) => void;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
  setGuarantorsFiles: (file: Attachment[]) => void;
  guarantorsFiles: Attachment[];
}

interface RegisterProps {
  children: ReactNode;
}

const RegisterFormContext = createContext<RegisterData>({} as RegisterData);

export const RegisterFormProvider = ({ children }: RegisterProps) => {
  const history = useHistory();
  const [steps, setSteps] = useState<string[]>([]);
  const [files, setFiles] = useState<Attachment[]>([]);
  const [guarantorsFiles, setGuarantorsFiles] = useState<Attachment[]>([]);
  const [actualStep, setActualStep] = useState(0);
  const [initialClientData, seInitialClientData] = useState<Client | null>(null);
  const [isOpenEndModal, setIsOpenEndModal] = useState(false);
  const [registerStatus, setRegisterStatus] = useState('');

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  useEffect(() => {
    if (registerStatus !== '') {
      setIsOpenEndModal(true);
    }
  }, [registerStatus]);

  const query = useQuery();

  const onClickNext = (newData: Client | GuarantorProps, isGuarantorRegister?: boolean) => {
    if (actualStep < steps.length - 1) {
      setActualStep(actualStep + 1);
      seInitialClientData({ ...initialClientData, ...newData });
    }

    if (actualStep === steps.length - 1) {
      seInitialClientData({ ...initialClientData, ...newData });

      const registerData: Client = unMaskedFieldsValues({ ...initialClientData, ...newData });

      registerData.income = unMaskedCurrency(registerData.income!.toString());
      registerData.income = Number(registerData.income) / 100;
      register(registerData, isGuarantorRegister);
    }
  };

  const onClickBack = () => {
    setActualStep(actualStep - 1);
  };

  const isFirstForm = () => actualStep === 0;

  const isLastForm = () => actualStep === steps.length - 1;

  const registerSteps = (steps: string[]) => {
    setSteps(steps);
  };

  const register = async (registerData: GuarantorProps, isGuarantorRegister?: boolean) => {
    if (isGuarantorRegister) {
      try {
        const res = await guarantor.createGuarantor(registerData);
        setRegisterStatus(res.status.toString());
        if (res.data.id) {
          const { courseId } = history?.location.state as any;
          const arrayId = [
            {
              id: res.data.id,
            },
          ];
          await courseService.linkedGuarantor(courseId, arrayId);
        }
      } catch (error: any) {
        setRegisterStatus(error.response.status.toString());
      }
    } else {
      try {
        const res = await registerApi.registerNewUser(registerData);
        setRegisterStatus(res.status.toString());
      } catch (error: any) {
        setRegisterStatus(error.response.status.toString());
      }
    }
  };

  const getClientData = () => {
    const registrationKey = query.get('registrationKey');

    if (registrationKey != null) {
      registerApi.getClientRegistration(registrationKey).then(registration => {
        seInitialClientData(registration.data);
      });
    }
  };

  const validateMajority = (date: Date): boolean => {
    const today = new Date();

    const majorityYear = today.getFullYear() - 18;

    if (date.getFullYear() <= majorityYear) {
      return true;
    }

    return false;
  };

  const handleSetEndModal = () => {
    setIsOpenEndModal(!isOpenEndModal);
  };

  const resetStates = () => {
    setActualStep(0);
    seInitialClientData(null);
    setRegisterStatus('');
  };

  return (
    <RegisterFormContext.Provider
      value={{
        steps,
        actualStep,
        onClickNext,
        onClickBack,
        isFirstForm,
        isLastForm,
        initialClientData,
        registerSteps,
        getClientData,
        validateMajority,
        isOpenEndModal,
        handleSetEndModal,
        resetStates,
        registerStatus,
        files,
        setFiles,
        guarantorsFiles,
        setGuarantorsFiles,
        seInitialClientData,
      }}
    >
      {children}
    </RegisterFormContext.Provider>
  );
};

export const useRegisterForm = () => useContext(RegisterFormContext);

import guarantor from 'api/guarantor-api';
import registerApi from 'api/register-api';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clientProfileService from 'services/client-profile-service';
import courseService from 'services/course.service';
import { unMaskedFieldsValues } from 'shared/util/register-utils';

interface RegisterData {
  getClientData: (clientId: number, courseId: number | undefined) => void;
  getClientDataWithoutCourse: (clientId: number) => void;
  getCourseData: (clientId: number, courseId: number) => void;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
  setGuarantorsFiles: (file: Attachment[]) => void;
  guarantorsFiles: Attachment[];
  personalData: Client;
  financingData: Course;
}

interface RegisterProps {
  children: ReactNode;
}

const EditFormContext = createContext<RegisterData>({} as RegisterData);

export const EditFormProvider = ({ children }: RegisterProps) => {
  const history = useHistory();
  const [personalData, setPersonalData] = useState<Client>({});
  const [files, setFiles] = useState<Attachment[]>([]);
  const [financingData, setFinancingData] = useState<Course>({});
  const [guarantorsFiles, setGuarantorsFiles] = useState<Attachment[]>([]);

  const getClientData = async (clientId, courseId) => {
    const content = await clientProfileService.getClientSummary(clientId, courseId);
    setPersonalData(content);
    setFiles(content.attachments ?? []);
  };

  const getClientDataWithoutCourse = async clientId => {
    const content = await clientProfileService.getClientSummaryWithoutCourse(clientId);
    setPersonalData(content);
    setFiles(content.attachments ?? []);
  };

  const getCourseData = async (clientId, courseId) => {
    const content = await courseService.getCoursesByClientId(Number(clientId));
    if (content) {
      setFinancingData(content.find(item => item.id === courseId) ?? {});
    }
  };
  console.log({ files });
  return (
    <EditFormContext.Provider
      value={{
        getClientData,
        getCourseData,
        files,
        setFiles,
        guarantorsFiles,
        setGuarantorsFiles,
        personalData,
        financingData,
        getClientDataWithoutCourse,
      }}
    >
      {children}
    </EditFormContext.Provider>
  );
};

export const useEditForm = () => useContext(EditFormContext);

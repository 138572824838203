import SimulatorForm from 'components/Simulator/SimulatorForm';
import SimulatorInfo from 'components/Simulator/SimulatorInfo';
import WarningContent from 'components/WarningContentFinancing';
import { useFinancing } from 'provider/Financing';
import React from 'react';
import StringUtils from 'shared/util/string-utils';
import { Container, ContainerSimulator } from './styles';

export default function SimulatorStep() {
  const { financing } = useFinancing();
  const approvedValueFormatted = StringUtils.moneyMask(String(financing.approvedValue));
  return (
    <Container>
      <WarningContent subtitle="dashboardClient.financing.approvedFinancing" description="dashboardClient.financing.selectInstallments" />
      <ContainerSimulator>
        <SimulatorInfo />
        <SimulatorForm />
      </ContainerSimulator>
    </Container>
  );
}

import { FormAddress } from 'components/RegistrationForms/Address';
import { FormCourse } from 'components/RegistrationForms/Course';
import { FormIncome } from 'components/RegistrationForms/Income';
import { FormPassword } from 'components/RegistrationForms/Password';
import { FormPersonalData } from 'components/RegistrationForms/PersonalData';
import Footer from 'features/footer/footer';
import { RegisterFormProvider } from 'provider/RegisterForm';
import { SelectListsProvider } from 'provider/SelectLists';
import { useTranslation } from 'react-i18next';
import { RegisterHeader } from '../../components/HeaderRegister';
import { ProgressBar } from '../../components/ProgressBar';
import { RegisterForm, StepTitles } from '../../components/RegisterForm';
import Airplane from '../../images/airplane.svg';

interface Modal {
  img: string;
  title: string;
  subtitle?: string;
  buttonTitle: string;
  route: string;
}

export const Register = () => {
  const { t } = useTranslation();
  const arrayForms: JSX.Element[] = [<FormPersonalData />, <FormAddress />, <FormCourse />, <FormIncome />, <FormPassword />];
  const stepTitles: StepTitles[] = [
    { title: t('register.titles.personalData') },
    { title: t('register.titles.address') },
    { title: t('register.titles.course'), subtitle: t('register.subtitles.course') },
    { title: t('register.titles.income'), subtitle: t('register.subtitles.income') },
    { title: t('register.titles.password') },
  ];

  const stepBarTitles: string[] = [
    t('register.progressBar.personalData'),
    t('register.progressBar.address'),
    t('register.progressBar.course'),
    t('register.progressBar.income'),
    t('register.progressBar.password'),
  ];

  const modalInfos: Modal = {
    img: Airplane,
    title: t('register.modal.sucessTitle'),
    subtitle: t('register.modal.sucessMessage'),
    buttonTitle: t('register.modal.buttonText'),
    route: '/login',
  };

  return (
    <RegisterFormProvider>
      <SelectListsProvider>
        <RegisterHeader />
        <ProgressBar steps={stepBarTitles} />
        <RegisterForm formsToRender={arrayForms} stepTitles={stepTitles} modalInfos={modalInfos} />
        <Footer />
      </SelectListsProvider>
    </RegisterFormProvider>
  );
};

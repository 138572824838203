import { AxiosResponse } from 'axios';
import { IFinishRecoveryPasswordData } from 'features/change-password/change-password';
import { IRecoveryEmail } from 'features/forgot-password/forgot-password';
import { Authentication } from 'model/authentication';
import { FirstSignUp } from 'model/first-signup';
import { User } from 'model/user';
import { api } from './api';

export const authenticate = () => {
  const login = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate/client', authentication);
  };

  const adminLogin = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate', authentication);
  };

  const getSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/account');
  };

  const createUser = (user: User): Promise<AxiosResponse<User>> => {
    return api.post<User>('/users/register', user);
  };

  const activateAccountToken = (activationKey: string): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/activate/${activationKey}`);
  };

  const requestPasswordReset = (email: IRecoveryEmail): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/reset-password/init`, email);
  };

  const finishPasswordReset = (passwordData: IFinishRecoveryPasswordData): Promise<AxiosResponse<void>> => {
    return api.post<void>('/account/reset-password/finish', passwordData);
  };

  const checkIfEmailExists = (email: string, checkIfIsAvailable: boolean): Promise<AxiosResponse<void>> => {
    return api.get<void>(`users/check-email/${email}/${checkIfIsAvailable}`);
  };

  const checkIfResetKeyIsValid = (key: string): Promise<AxiosResponse<number>> => {
    return api.get<number>(`users/check-reset-key/${key}`);
  };

  const checkIfActivationKeyIsValid = (key: string): Promise<AxiosResponse<string>> => {
    return api.get<string>(`users/check-activation-key/${key}`);
  };

  const checkCreditScoreClient = (): Promise<AxiosResponse<FirstSignUp>> => {
    return api.post<FirstSignUp>(`/credit-score/client/check`);
  };

  return {
    login,
    adminLogin,
    getSession,
    createUser,
    checkIfEmailExists,
    finishPasswordReset,
    requestPasswordReset,
    activateAccountToken,
    checkIfResetKeyIsValid,
    checkIfActivationKeyIsValid,
    checkCreditScoreClient,
  };
};

export default authenticate();

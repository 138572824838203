import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from '../../../../../../images/vector.svg';

interface NoDataProps {
  tab: string;
}
const NoDataContainer = styled.div`
  width: 100%;
  height: 150px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.666667px;
  color: #505050;
`;

const NoDataPage: React.FC<NoDataProps> = props => {
  const { t } = useTranslation();

  return (
    <NoDataContainer>
      <Title>{t('global.errorMessage.nothingHere')}</Title>
      <img src={Icon} />
      <Description>{t(`global.errorMessage.nothingHere${props.tab}Message`)}</Description>
    </NoDataContainer>
  );
};

export default NoDataPage;

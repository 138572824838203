import ButtonComponent from 'components/Button';
import { FormAttachmentsData } from 'components/form-attachments-data';
import { FormFinancingData } from 'components/form-financing-data';
import { FormGeneralData } from 'components/form-general-data';
import { FormGuarantorData } from 'components/form-guarantors-data';
import { FormPersonalData } from 'components/formPersonalData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledTabs from 'components/styled-tabs/styled-tabs';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { GuarantorProps } from 'model/guarantor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface Schema {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  income: string;
  office: string;
  birth: string;
  zipcode: string;
  address: string;
  district: string;
  number: string;
  city: string;
  state: string;
  complement: string;
  rg: string;
  password?: string;
  attachmentIdentification: File;
  attachmentIncome: File;
  attachmentAddress: File;
}

interface Props {
  schema: any;
  onSubmit: (data: Schema) => void;
  editData?: Course;
  setLanguageId: (id: number) => void;
  setAddressOccupationIds: (ids: AddressOccupationIds) => void;
  addressOccupationIds?: AddressOccupationIds;
}

export const ContainerAdminEditClientFinancing = ({
  schema,
  onSubmit,
  editData,
  setLanguageId,
  setAddressOccupationIds,
  addressOccupationIds,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <StyledTabs
        editPage
        tabs={[
          {
            title: t('global.tabs.financing'),
            content: (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
                <FormFinancingData financingData={editData} setLanguageId={setLanguageId} />
              </div>
            ),
          },
          {
            title: t('global.tabs.guarantor'),
            content: (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
                <FormGuarantorData edit guarantorsData={editData?.guarantors} />
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

import { BackButton, ButtonsContainer, FormInputsContainer } from 'components/RegistrationForms/styles';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/RegisterForm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsPersonalData = () => {
  const { t } = useTranslation();
  const { getClientData, initialClientData, isFirstForm, isLastForm, onClickBack } = useRegisterForm();

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <FormInputsContainer>
      <TextInput
        name="name"
        label={t('register.inputs.name')}
        isRequired
        placeholder={t('register.placeholder.name')}
        defaultValue={initialClientData?.name}
      />

      <TextInput
        name="birth"
        label={t('register.inputs.birthDate')}
        isRequired
        placeholder={t('register.placeholder.birthDate')}
        maxLength={10}
        mask={Mask.BIRTHDAY}
        defaultValue={initialClientData?.birth?.toLocaleDateString()}
      />

      <TextInput
        name="phone"
        label={t('register.inputs.phone')}
        isRequired
        placeholder={t('register.placeholder.phone')}
        mask={Mask.PHONE}
        maxLength={16}
        defaultValue={initialClientData?.phone}
      />

      <TextInput
        name="email"
        label={t('register.inputs.email')}
        isRequired
        placeholder={t('register.placeholder.email')}
        defaultValue={initialClientData?.email}
      />

      <TextInput
        name="cpf"
        label={t('register.inputs.cpf')}
        isRequired
        placeholder={t('register.placeholder.cpf')}
        mask={Mask.CPF}
        maxLength={14}
        defaultValue={initialClientData?.cpf}
      />

      <TextInput
        name="rg"
        label={t('register.inputs.rg')}
        isRequired
        placeholder={t('register.placeholder.rg')}
        defaultValue={initialClientData?.rg}
      />

      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack} type="button">
            <span>{t('global.button.backStep')}</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};

import styled from 'styled-components';

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  margin-bottom: 32px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const ContainerInputs = styled.div`
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

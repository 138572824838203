import styled from 'styled-components';

export const MainImage = styled.img`
  width: 352px;
  height: 231px;
  margin-top: 50px;
  align-self: center;
`;

export const SubtitleText = styled.span`
  height: 40px;
  margin-top: 32px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #2b1a48;
  text-align: left;
  align-self: center;
`;

export const Description = styled.span`
  width: 420px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  margin-top: 8px;
  align-self: center;
`;

export const ContainerDescription = styled.div`
  display: flex;
  align-items: center;
  width: 487px;
  height: 112px;
  background: #f9f3ff;
  border-radius: 8px;
  text-align: left;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

import styled from 'styled-components';

export const ContainerItems = styled.div`
  height: 68px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  /* padding: 0 100px 0 230px; */
  padding-left: 200px;
  padding-right: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: white;

  z-index: 10;
`;
export const TitleHeader = styled.div`
  font-size: 20px;
  color: black;
  font-weight: 500;
`;
export const UserHeader = styled.div`
  font-size: 14px;
  color: blue;
  display: flex;
  align-items: center;

  i {
    color: black;
  }

  .icon-notification {
    font-size: 22px;
    margin-right: 38px;
    cursor: pointer;
  }

  .icon-lupa {
    font-size: 18px;
    margin-right: 28px;
    cursor: pointer;
  }
`;
export const UserName = styled.button`
  position: relative;

  border: none;
  background: transparent;
  font-size: 14px;
  color: black;
  display: flex;
  align-items: center;

  .icon-perfil {
    font-size: 22px;
    margin-right: 6px;
  }

  div.imgBox {
    margin-left: 7px;
  }
`;
export const NotificationsContainer = styled.div`
  position: absolute;
  min-width: 452px;
  top: 58px;
  right: 235px;
`;

import { Reducer } from 'redux';
import { AdminSearchActionTypes, AdminSearchState } from './types';

export const initialState: AdminSearchState = { searchValue: '' };

const reducer: Reducer<AdminSearchState> = (state = initialState, action) => {
  switch (action.type) {
    case AdminSearchActionTypes.SAVE_VALUE: {
      return { ...state, searchValue: action.payload };
    }

    case AdminSearchActionTypes.DELETE_VALUE: {
      return { ...state, searchValue: '' };
    }

    default: {
      return state;
    }
  }
};

export { reducer as adminSearchReducer };

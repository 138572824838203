import { Client } from 'model/client';

export const unMaskedFieldsValues = (fields: Client, dateAlreadyUnmasked?: boolean) => {
  const newValues = { ...fields };

  newValues.cpf = newValues.cpf?.replace(/\W/g, '');
  newValues.rg = newValues.rg?.replace(/\W/g, '');
  newValues.phone = newValues.phone?.replace(/\W/g, '');

  if (newValues.birth && !dateAlreadyUnmasked) {
    newValues.birth = new Date(newValues.birth);
  }

  if (newValues.address?.zipcode) {
    newValues.address.zipcode = newValues.address?.zipcode?.replace(/\W/g, '');
  }

  return newValues;
};

export const unMaskedCurrency = (value: string) => {
  return Number(value?.toString().replace(/\D/g, ''));
};

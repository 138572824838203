import ReactModal from 'react-modal';
import styled from 'styled-components';

export const LabelLine = styled.div`
  padding: 8px 0;
  line-height: 16px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;

  i {
    cursor: pointer;
    color: #f8f8f8;
  }

  &:last-child {
    border-bottom: none;
  }

  img {
    cursor: pointer;
  }
`;

export const LabelLineItems = styled.div`
  display: flex;
  flex: 1 1 100%;

  &:last-child ${LabelLine} {
    border-bottom: none;
  }

  ${LabelLine} + ${LabelLine} {
    border-left: 1px solid #e3e3e3;
  }

  &:not(:last-child) ${LabelLine}:last-child {
    border-bottom: 1px solid #e3e3e3;
  }
`;

export const LabelContainer = styled.div`
  background-color: #f8f8f8;
`;

export const LabelTitle = styled.div`
  font-size: 12px;
  color: #7b7b7b;

  &.label-title-my-profile {
    color: #7b7b7b;
  }
`;
export const LabelAnswer = styled.div`
  font-size: 14px;
  color: #3e3e3e;
  padding-top: 2px;
`;

export const LabelLineSecond = styled(LabelLine)`
  flex-direction: column;
  align-items: flex-start;
`;

export const LabelLineItemsSecond = styled(LabelLineItems)`
  ${LabelLine} + ${LabelLine} {
    border-left: 1px solid transparent;
  }
`;
export const ModalTitleSecond = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  padding-bottom: 10px;
`;

export const modalStyles: ReactModal.Styles = {
  content: {
    width: '825px',
    height: '550px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { EditPasswordParams } from 'model/edit-password';
import { User } from 'model/user';
import { api } from './api';

export const customerApi = () => {
  const save = (user: User): Promise<AxiosResponse<User>> => {
    return api.put<User>('/account/update', user);
  };

  const activateCustomer = (key: string): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/activate/${key}`);
  };

  const getCustomerInfo = (): Promise<AxiosResponse<Client>> => {
    return api.get<Client>(`/clients/`);
  };

  const editPassword = (params: EditPasswordParams): Promise<AxiosResponse<any>> => {
    return api.put<any>('/account/change-password', params);
  };

  const resendEmail = (): Promise<AxiosResponse<any>> => {
    return api.post<any>('/clients/verify-email');
  };

  const editCustomer = (obj: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>('/clients', obj);
  };

  return {
    save,
    activateCustomer,
    editPassword,
    getCustomerInfo,
    editCustomer,
    resendEmail,
  };
};

export default customerApi();

import backgroundImage from 'images/background.png';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 117px);
  width: 100%;
  margin-top: 80px;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const ImgContainer = styled.div`
  img {
    width: 576px;
    height: 407px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 676px;
`;
export const TextTitle = styled.span`
  width: 676px;
  height: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #ff9f1c;
`;

export const FormContainer = styled.div`
  width: 375px;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
`;
export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ItemLink = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.666667px;
  color: #333333;
`;

export const FooterStyled = styled.footer`
  width: 100vw;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
  }
`;

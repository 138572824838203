import styled from 'styled-components/macro';

export const ContainerTextAndArrow = styled.div`
  display: flex;
  img {
    margin-right: 16px;
  }
  margin-bottom: 19px;
`;

export const List = styled.ul`
  list-style-type: disc;
  text-align: left;
  margin-top: 16px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;

import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';

import StyledButton from 'components/StyledButton';
import { CloseButton, CloseContainer, ButtonContainer, ImageContainer, MessageText, Title } from './styles';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Button';
import { MoonLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { useEffect, useState } from 'react';
import { getEmailRequest } from 'reducer/authentication/actions';
import Check from 'images/modalCheckGreen.svg';
import Error from 'images/error.svg';
import { useFinancing } from 'provider/Financing';

interface SimpleModalProps {
  isOpen: boolean;
  titleText: string;
  onCloseModal: () => void;
  confirmSimulation: () => void;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '420px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const WaitValidation = ({ isOpen, onCloseModal, titleText, confirmSimulation }: SimpleModalProps) => {
  const { t } = useTranslation();
  const emailValidation = useSelector((state: IRootState) => state.authentication.emailValidation);
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState<boolean>();
  const [load, setLoad] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(titleText);
  const { mockFinancing, confirmSimulateFinancing, financing } = useFinancing();

  useEffect(() => {
    if (isOpen) {
      dispatch(getEmailRequest());
    }
  }, [isOpen]);

  useEffect(() => {
    setIsVerified(emailValidation);
    if (emailValidation) {
      setTitle('global.button.errorMessage.emailValid');
    } else {
      setTitle('global.button.errorMessage.emailInvalid');
    }
  }, [emailValidation]);

  const handleConfirmSimulation = async () => {
    setLoad(true);
    await confirmSimulateFinancing(financing.id, mockFinancing);
    confirmSimulation();
    setLoad(false);
    onCloseModal();
  };

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <ImageContainer>
        {isVerified === undefined && <MoonLoader size={60} color="#80008C" />}
        {isVerified === false && <img src={Error} />}
        {isVerified === true && <img src={Check} />}
      </ImageContainer>
      <Title>{t(title)}</Title>
      <ButtonContainer>
        <ButtonComponent
          text={'OK'}
          width="123px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="#fff"
          size="18px"
          weight="600"
          load={load}
          onClick={isVerified ? handleConfirmSimulation : onCloseModal}
        />
      </ButtonContainer>
    </Modal>
  );
};

export default WaitValidation;

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerItems = styled.div`
  height: 68px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 0 100px 0 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: white;

  z-index: 10;
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
`;

export const ArrowMenu = styled.div`
  font-size: 8px;
  margin-left: auto;
  margin-right: 3px;
  color: #fff;
`;

export const MenuIcon = styled.i`
  font-size: 16px;
  text-align: center;
  flex: 0 0 39px;
  margin-right: 8px;
`;

export const MenuLinkBackground = styled(NavLink)`
  color: #e6d0f5;
  font-size: 16px;
  align-items: center;
  display: flex;
  line-height: 18px;
  padding: 8px 0;
  margin: 0 16px 0 8px;
  &.active {
    font-weight: 500;
    color: white;

    & div {
      color: white;
    }

    ${MenuIcon} {
      color: white;
    }
  }
`;

export const MenuSubLink = styled(NavLink)`
  display: none;
  .active > & {
    display: block;
    margin-left: 43px;
    margin-right: 14px;
    color: #e6d0f5;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  &.active {
    color: white;
    font-weight: 500;
  }
`;

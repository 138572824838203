import ClipBlack from 'images/clipBlack.svg';
import { truncate } from 'lodash';
import { Client } from 'model/client';
import { GuarantorProps } from 'model/guarantor';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContainerAttachment, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface GuarantorAttachmentsDataProps {
  guarantorData: GuarantorProps;
}

enum GuarantorAttachmentsTypes {
  PROOF_RESIDENCE = 'PROOF_RESIDENCE',
  IDENTIFICATION_CARD = 'IDENTIFICATION_CARD',
  PROOF_INCOME = 'PROOF_INCOME',
}

const GuarantorAttachmentsData: React.FC<GuarantorAttachmentsDataProps> = props => {
  const { t } = useTranslation();
  const { guarantorData } = props;

  return guarantorData?.attachments ? (
    <DataContainer>
      {guarantorData?.attachments?.map((item, i) => {
        const typeVerification = () => {
          switch (item.type) {
            case GuarantorAttachmentsTypes.PROOF_INCOME:
              return t('clientAttachments.proofIncome');
              break;
            case GuarantorAttachmentsTypes.PROOF_RESIDENCE:
              return t('clientAttachments.proofResidence');
              break;
            case GuarantorAttachmentsTypes.IDENTIFICATION_CARD:
              return t('clientAttachments.rg');
              break;
            default:
              break;
          }
        };
        return (
          <>
            <ContentContainer key={i}>
              <ContentLabel>{typeVerification()}</ContentLabel>
              <ContainerAttachment>
                <img src={ClipBlack} />
                <a href={item.presignedUrl} target="_blank">
                  {item.fileName && truncate(item.fileName, { length: 75 })}
                </a>
              </ContainerAttachment>
            </ContentContainer>
            {i < 2 && <BreakLine />}
          </>
        );
      })}
    </DataContainer>
  ) : (
    <></>
  );
};

export default GuarantorAttachmentsData;

import courseApi from 'api/course-api';
import guarantorApi from 'api/guarantor-api';
import ButtonComponent from 'components/Button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { SearchSelectInput } from 'components/SearchSelectInput';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import HttpStatus from 'model/enums/http-status';
import { GuarantorProps, Linkedguarantors } from 'model/guarantor';
import { useFinancing } from 'provider/Financing';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import courseService from 'services/course.service';
import * as yup from 'yup';
import Trash from '../../../images/deleteTrash.svg';
import { CloseButton, CloseContainer, ConfirmContainer, ContainerFinancing, ContainerFormInput, Title, TitleSignUp } from './styles';

interface SimpleModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}
interface Ids {
  id: number;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '560px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const LinkedGuarantorModel = ({ isOpen, onCloseModal }: SimpleModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const schema = yup.object({
    guarantor: yup.string().required(t('global.errorMessage.required')),
  });
  const [guarantorSelected, setGuarantorSelected] = useState<GuarantorProps[]>([]);
  const [guarantors, setGuarantors] = useState<GuarantorProps[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const courseId = parseInt(history.location.search.split('=')[1]);
  const { getCourse, getLinkedGuarantors } = useFinancing();

  const requestGetGuarantors = async () => {
    const res = await guarantorApi.getGuarantorIdUnlinkedToCourse(courseId);
    setGuarantors(res.data);
  };

  useEffect(() => {
    if (isOpen) {
      requestGetGuarantors();
    }
  }, [isOpen]);

  const removeGuarantorSelected = (index: number) => {
    let guarantors = guarantorSelected;
    const newGuarantors = guarantors.filter((element, indexGuarantor) => indexGuarantor !== index);
    setGuarantorSelected(newGuarantors);
  };

  const getId = () => {
    let arrayIds: Ids[] = [];
    guarantorSelected.forEach(element => {
      if (element.id !== undefined) {
        arrayIds.push({ id: element.id });
      }
    });
    return arrayIds;
  };

  const handleAddGuarantor = async () => {
    setLoad(true);
    if (guarantorSelected) {
      const arrayIds = getId();
      const res = await courseService.linkedGuarantor(courseId, arrayIds);
      if (res.id) {
        setLoad(false);
        setGuarantorSelected([]);
        getLinkedGuarantors(courseId);
        requestGetGuarantors();
        getCourse(courseId);
        onCloseModal();
      }
    }
  };
  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('dashboardClient.guarantor.modal.title')}</Title>
      <ContainerFormInput>
        <div style={{ width: '375px' }}>
          <ReactHookFormProvider schema={schema} onSubmit={() => {}}>
            <TextInput name="guarantor" label={t('dashboardClient.financing.modal.label')} hasCustomInput>
              <SearchSelectInput
                name="guarantor"
                placeholder={t('dashboardClient.financing.modal.placeholder')}
                options={guarantors?.map(guarantor => ({ label: guarantor.name ?? '', value: guarantor?.id?.toString() ?? '' }))}
                defaultOptions={guarantors?.map(guarantor => ({ label: guarantor.name ?? '', value: guarantor?.id?.toString() ?? '' }))}
                handleChange={value => {
                  if (guarantors && value.value && guarantorSelected.length <= 3) {
                    setGuarantorSelected([...guarantorSelected, guarantors.find(guarantor => guarantor.id?.toString() === value.value)!]);
                  }
                }}
              />
            </TextInput>
          </ReactHookFormProvider>
        </div>
        <TitleSignUp
          onClick={() =>
            history.push({
              pathname: '/client/register/guarantor',
              state: { courseId },
            })
          }
        >
          {t('dashboardClient.guarantor.newGuarantor')}
        </TitleSignUp>
      </ContainerFormInput>
      {guarantorSelected.map((element, index) => {
        return (
          <ContainerFinancing key={index}>
            <span>{element.name}</span>
            <img src={Trash} onClick={() => removeGuarantorSelected(index)} />
          </ContainerFinancing>
        );
      })}
      <ConfirmContainer>
        <ButtonComponent
          width="123px"
          height="47px"
          background="transparent"
          border="1px solid #80008C"
          borderRadius="8px"
          color="#80008C"
          text="global.button.cancel"
          weight="600"
          size="18px"
          onClick={onCloseModal}
        />
        <ButtonComponent
          width="123px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          margin="0 0 0 8px"
          color="white"
          text="global.button.add"
          weight="600"
          size="18px"
          onClick={() => handleAddGuarantor()}
          load={load}
        />
      </ConfirmContainer>
    </Modal>
  );
};

export default LinkedGuarantorModel;

import React from 'react';
import { Card, ContainerAllInfosToShowBenefits, ContainerCard, ContainerInfoBenefits } from './styles';
import PuppetLanguage from '../../images/puppetLanguage.svg';
import PuppetWork from '../../images/puppetWork.svg';
import PuppetMeetPlaces from '../../images/puppetMeetPlaces.svg';
import PuppetCulture from '../../images/puppetCulture.svg';
import { useTranslation } from 'react-i18next';

export const InfoBenefits = () => {
  const { t } = useTranslation();
  return (
    <ContainerInfoBenefits>
      <ContainerAllInfosToShowBenefits>
        <span>
          {t('LandingPage.textBenefits.firstText')} <span>{t('LandingPage.textBenefits.secondText')}</span>{' '}
        </span>
        <ContainerCard>
          <Card>
            <img src={PuppetLanguage} />
            <span>{t('LandingPage.textBenefits.cardTextLanguage')}</span>
          </Card>
          <Card>
            <img src={PuppetWork} />
            <span style={{ marginTop: '48px !important' }}>{t('LandingPage.textBenefits.cardTextWork')}</span>
          </Card>
          <Card>
            <img src={PuppetMeetPlaces} />
            <span>{t('LandingPage.textBenefits.cardTextMeetPlaces')}</span>
          </Card>
          <Card>
            <img src={PuppetCulture} />
            <span>{t('LandingPage.textBenefits.cardTextCulture')}</span>
          </Card>
        </ContainerCard>
      </ContainerAllInfosToShowBenefits>
    </ContainerInfoBenefits>
  );
};

import styled from 'styled-components';

export const ContainerBeApartner = styled.div`
  width: 100%;
  min-height: 749px;
  background: linear-gradient(78.59deg, rgba(255, 248, 222, 0.8) 0%, rgba(255, 219, 243, 0.8) 52.47%, rgba(239, 236, 255, 0.8) 100%);
  display: flex;
  justify-content: center;
`;

export const ContainerRequestBePartner = styled.div`
  width: 578px;
  min-height: 590px;
  background: #fff;
  box-shadow: 0px 15px 40px rgba(27, 25, 25, 0.1);
  border-radius: 30px;
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #80008c;
      margin-top: 14px;
      margin-bottom: 8px;
      align-self: flex-start;
      span {
        margin-left: 0;
        font-weight: 700;
        color: #f0654a;
      }
    }
    label > div {
      background: #fff !important;
    }
    label > div + div {
      height: 20px !important;
    }
    span + label + span + label + span + label + span + label {
      width: 476px;
      height: 125px;
      margin-bottom: 12px;
      div {
        width: 476px;
        height: 125px;
        textarea {
          width: 476px;
          height: 100px;
          margin-top: 10px;
          background: #ffffff;
          resize: none;
          border: none;
          color: #80008c;
          ::placeholder {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #80008c;
          }
        }
      }
    }
    input {
      color: #80008c;
      background: #fff;
      ::placeholder {
        color: #80008c;
      }
    }
  }
  @media (max-width: 1091px) {
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    width: 480px;
    label {
      width: 385px !important;
      div {
        width: 385px !important;
      }
    }
    input,
    textarea {
      width: 385px !important;
    }
  }
  @media (max-width: 490px) {
    width: 320px;
    label {
      width: 280px !important;
      div {
        width: 280px !important;
      }
    }
    input,
    textarea {
      width: 280px !important;
    }
  }
  button {
    margin-top: 10px;
  }
`;

export const InputStyled = styled.input`
  width: 476px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #d8bdfd;
  border-radius: 8px;
  color: #80008c;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 16px;

  ::placeholder {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d5bee5;
  }

  :focus {
    border: 1px solid #80008c;
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 476px;
  height: 125px;
  background: #ffffff;
  border: 1px solid #d8bdfd;
  border-radius: 8px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #80008c;
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 15px 0 0 16px;
  resize: none;

  ::placeholder {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d5bee5;
  }

  :focus {
    border: 1px solid #80008c;
  }
`;

export const ContainerContentBeApartner = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 1091px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerPartnerImgs = styled.div`
  display: grid;
  margin-top: 61px;
  grid-template-columns: 140px 140px 140px;
  grid-template-rows: auto auto auto;
  justify-items: center;
  align-items: flex-end;
  gap: 20px;
  img {
    position: static !important;
    margin-right: 20px;
  }
  @media (max-width: 490px) {
    width: 320px !important;
    display: grid;
    grid-template-columns: 90px 90px 90px;
    grid-template-rows: auto auto auto;
    justify-items: center;
    align-items: flex-end;
    gap: 20px;
    img {
      width: 90px !important;
      margin: 0px !important;
    }
    img + img + img + img + img {
      width: 43px !important;
    }
    img + img + img + img + img + img {
      width: 90px !important;
    }
  }
`;

export const ContainerTextBePartner = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 155px;
  text-align: left;
  position: relative;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-bottom: 18px;
    span {
      color: #80008c;
    }
  }
  span + span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    color: #4c4d4d;
    width: 376px;
    height: 116px;
  }
  img {
    position: absolute;
    top: 175px;
    left: 400px;
  }
  img + img {
    top: 45px;
    left: 222px;
  }
  @media (max-width: 1231px) {
    margin-right: 20px;
  }
  @media (max-width: 1091px) {
    margin-right: 0;
  }
  @media (max-width: 490px) {
    img {
      left: 193px !important;
      width: 32px;
    }
    span {
      font-size: 24px;
      line-height: 30px;
    }
    span + span {
      width: 320px !important;
      font-size: 16px;
      line-height: 160.02%;
    }
  }
`;

export const ContainerForm = styled.div`
  width: 476px;
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Container,
  ContainerButton,
  ContainerContent,
  ContainerData,
  ContainerFinancing,
  Row,
  SubtitleText,
  TitleText,
} from './styles';
import ArrowLeft from '../../images/circuleWIthArrowLeft.svg';
import ButtonComponent from 'components/Button';
import ContainerDocument from 'components/ContainerDocument';
import { useHistory } from 'react-router-dom';
import { Client } from 'model/client';
import StringUtils from 'shared/util/string-utils';
import { GuarantorProps } from 'model/guarantor';
import ModalLinkFinancing from 'components/Modais/ModalLinkFinancing';
import Trash from '../../images/deleteTrash.svg';
import guarantorApi from 'api/guarantor-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import customerApi from 'api/customer-api';
import { useFinancing } from 'provider/Financing';
import courseService from 'services/course.service';
import { FooterStyled } from 'components/FooterStyled';
interface Props {
  title?: string;
  buttonBack?: boolean;
  guarantor?: GuarantorProps;
  getGuarantor?: (id: number) => void;
  setInitialState?: () => void;
  getGuarantorLinked?: (id: number) => void;
  client?: boolean;
}

const InfosToShow = ({ title, buttonBack, client = false }: Props) => {
  const history = useHistory();
  const guarantorId = parseInt(history.location.search.split('=')[1]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [personalData, setPersonalData] = useState<Client>();
  const { getLinkedGuarantors, getGuarantor, guarantor } = useFinancing();
  const docs = {
    IDENTIFICATION_CARD: 'documents.identification',
    PROOF_INCOME: 'documents.ProofOfIncome',
    PROOF_RESIDENCE: 'documents.proofOfAddress',
  };
  const schema = yup.object({});
  const { t } = useTranslation();

  useEffect(() => {
    const request = async () => {
      const res = await customerApi.getCustomerInfo();
      setPersonalData(res.data);
    };

    if (!client) {
      getGuarantor(guarantorId);
    } else if (client) {
      request();
    }
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUnlinkedGuarantor = async (courseId: number) => {
    await courseService.unlinkGuarantor(courseId, guarantorId);
    getLinkedGuarantors(courseId);
    getGuarantor(guarantorId);
  };

  const onHandleSubmit = () => {
    return;
  };

  const handleEditData = () => {
    if (client) {
      history.push(`/client/dashboard/personalData/edit`);
    } else {
      history.push(`/client/dashboard/guarantor/edit?id=${guarantorId}`);
    }
  };

  return (
    <Container>
      <ContainerButton>
        {buttonBack && <img src={ArrowLeft} alt={t('global.button.backStep')} onClick={() => history.goBack()} />}
        <TitleText>{t(title!)}</TitleText>
        <ButtonComponent
          width="64px"
          height="30px"
          background="transparent"
          border="1px solid #80008C"
          borderRadius="4px"
          color="#80008C"
          margin="0 0 0 520px"
          text="global.button.edit"
          weight="600"
          size="14px"
          onClick={() => handleEditData()}
        />
      </ContainerButton>
      <ContainerContent>
        <SubtitleText>{t('dashboardClient.guarantor.personalData')}</SubtitleText>
        <Row>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.name')}:</span>
              <span>{personalData ? personalData.name : guarantor && guarantor.name}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.cpf')}:</span>
              <span>{personalData ? StringUtils.cpfMask(personalData.cpf) : guarantor && StringUtils.cpfMask(guarantor.cpf)}</span>
            </ContainerData>
          </Col>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.email')}:</span>
              <span>{personalData ? personalData.email : guarantor && guarantor.email}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.office')}:</span>
              <span>{personalData ? personalData.occupation?.name : guarantor && guarantor.occupation?.name}</span>
            </ContainerData>
          </Col>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.phone')}:</span>
              <span>{personalData ? StringUtils.phoneMask(personalData.phone) : guarantor && StringUtils.phoneMask(guarantor.phone)}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.income')}:</span>
              <span>
                {personalData
                  ? StringUtils.currencyMask(personalData.income?.toFixed(2))
                  : StringUtils.currencyMask(guarantor && guarantor?.income?.toFixed(2))}
              </span>
            </ContainerData>
          </Col>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.rg')}:</span>
              <span>{personalData ? personalData.rg : guarantor && guarantor.rg}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.birthDate')}:</span>
              <span>
                {personalData
                  ? personalData.birth?.toString().split('-').reverse().join('/')
                  : guarantor && guarantor.birth?.toString().split('-').reverse().join('/')}
              </span>
            </ContainerData>
          </Col>
        </Row>
        <SubtitleText>{t('dashboardClient.guarantor.address')}</SubtitleText>
        <Row>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.address')}:</span>
              <span>{personalData ? personalData.address?.street : guarantor && guarantor.address?.street}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.district')}:</span>
              <span>{personalData ? personalData.address?.district : guarantor && guarantor.address?.district}</span>
            </ContainerData>
          </Col>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.number')}:</span>
              <span>{personalData ? personalData.address?.number : guarantor && guarantor.address?.number}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.city')}:</span>
              <span>{personalData ? personalData.address?.city?.name : guarantor && guarantor.address?.city?.name}</span>
            </ContainerData>
          </Col>
          <Col>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.complement')}:</span>
              <span>{personalData ? personalData.address?.complement : guarantor && guarantor.address?.complement}</span>
            </ContainerData>
            <ContainerData>
              <span>{t('dashboardClient.guarantor.guarantorData.state')}:</span>
              <span>{personalData ? personalData.address?.city?.state?.name : guarantor && guarantor.address?.city?.state?.name}</span>
            </ContainerData>
          </Col>
          <ContainerData>
            <span>{t('dashboardClient.guarantor.guarantorData.zipCode')}:</span>
            <span>{personalData ? personalData.address?.zipcode : guarantor && guarantor.address?.zipcode}</span>
          </ContainerData>
        </Row>
        <SubtitleText style={{ marginTop: '26px' }}>{t('dashboardClient.guarantor.documents')}</SubtitleText>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          {!client &&
            guarantor?.attachments?.map(element => {
              return (
                <ContainerDocument
                  titleDocument={docs[element.type]}
                  title={element.fileName}
                  docUrl={StringUtils.getFileOrUrl(element)}
                  name={docs[element.type]}
                  nameInput={docs[element.type]}
                />
              );
            })}
          {client &&
            personalData?.attachments?.map(element => {
              return (
                <ContainerDocument
                  titleDocument={docs[element.type]}
                  title={element.fileName}
                  docUrl={StringUtils.getFileOrUrl(element)}
                  name={docs[element.type]}
                  nameInput={docs[element.type]}
                />
              );
            })}
        </ReactHookFormProvider>
        {!client && (
          <>
            <SubtitleText style={{ marginTop: '24px', marginBottom: 0 }}>{t('dashboardClient.guarantor.financing')}</SubtitleText>
            {guarantor?.courses &&
              guarantor?.courses.map((element, index) => {
                return (
                  <ContainerFinancing key={index}>
                    {t('dashboardClient.guarantor.cardFinancing.title')} {StringUtils.dateFormatMask(element.startDate ?? '')}
                    <img src={Trash} onClick={() => element.id && handleUnlinkedGuarantor(element.id)} />
                  </ContainerFinancing>
                );
              })}
            <ButtonComponent
              width="172px"
              height="30px"
              background="#80008C"
              border="none"
              borderRadius="4px"
              color="white"
              margin="0"
              text="dashboardClient.guarantor.button.linkFunding"
              weight="600"
              size="14px"
              onClick={() => setOpenModal(true)}
            />
          </>
        )}
      </ContainerContent>
      <ModalLinkFinancing isOpen={openModal} onCloseModal={handleCloseModal} />
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

export default InfosToShow;

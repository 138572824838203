import backgroundImage from 'images/background.png';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #fff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 477px;
  width: 477px;
  height: 601px;
  max-height: 601px;
`;
export const ImgContainer = styled.div`
  img {
    width: 167px;
    height: 57px;
  }
  margin-top: 1.5rem;
`;
export const TextTitle = styled.span`
  width: 430px;
  height: 36px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #000000;
`;
export const SubTextTitle = styled.span`
  width: 204px;
  height: 35px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.666667px;
  color: #505050;
`;

export const FormContainer = styled.div`
  width: 375px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;
export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ItemLink = styled.div`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #2b1a48;
`;

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import i18n from 'config/i18n';
import AuthUtils from 'shared/util/auth-utils';
import toastUtils from 'shared/util/toast-utils';
const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);
  // tslint:disable-next-line: no-shadowed-variable
  instance.interceptors.request.use(async config => {
    const token = AuthUtils.getToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });

  instance.interceptors.response.use(
    response => {
      const newToken = response.headers['x-new-token'];
      if (newToken != null && newToken !== '') {
        AuthUtils.setToken(newToken);
      }
      return response;
    },
    error => {
      const errorResponse = error.response?.data;
      if (errorResponse != null) {
        switch (errorResponse.status) {
          case 400:
            if (i18n.exists(`error.${errorResponse.code}`)) {
              toastUtils.emitErrorToast(i18n.t(`error.${errorResponse.code}`));
            } else {
              toastUtils.emitErrorToast(i18n.t('error.default'));
            }
            break;
          case 500:
            toastUtils.emitErrorToast(i18n.t('error.internal.error'));
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export const api = createApiInstance();
export default {
  api,
};

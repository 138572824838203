import guarantorApi from 'api/guarantor-api';
import { ContainerEditData, Schema } from 'components/ContainerEditData';
import { SimpleModal } from 'components/SimpleModal';
import { parse } from 'date-fns';
import { AddressOccupationIds } from 'model/address';
import { AttachmentType } from 'model/enums/attachment-types';
import HttpStatus from 'model/enums/http-status';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { useFinancing } from 'provider/Financing';
import { useRegisterForm } from 'provider/RegisterForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import ArrowLeft from '../../images/arrowLeft.svg';
import Check from '../../images/modalCheckGreen.svg';
import { Container, ContainerBackStep, ContainerButtons, ContainerContent, ContainerInputs, SubtitleText, TitleText } from './styles';

const GuarantorEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const guarantorId = parseInt(history.location.search.split('=')[1]);
  const { files, setFiles } = useRegisterForm();
  const [addressOccupationIds, setAddressOccupationIds] = useState<AddressOccupationIds>();
  const [openModal, setOpenModal] = useState(false);
  const { getGuarantor, guarantor } = useFinancing();

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required(),
    cpf: yup.string().required(),
    income: yup.string().required(),
    office: yup.string().required(),
    birth: yup.string().required(),
    zipcode: yup.string().required(),
    address: yup.string().required(),
    district: yup.string().required(),
    number: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    complement: yup.string().required(),
    rg: yup.string().required(),
    attachmentIdentification: yup
      .mixed()
      .transform(value => guarantor?.attachments?.filter(it => it.type === AttachmentType.IDENTIFICATION_CARD)[0] ?? value[0])
      .required(),
    attachmentIncome: yup
      .mixed()
      .transform(value => guarantor?.attachments?.filter(it => it.type === AttachmentType.PROOF_INCOME)[0] ?? value[0])
      .required(),
    attachmentAddress: yup
      .mixed()
      .transform(value => guarantor?.attachments?.filter(it => it.type === AttachmentType.PROOF_RESIDENCE)[0] ?? value[0])
      .required(),
  });

  useEffect(() => {
    if (!guarantor) {
      getGuarantor(guarantorId);
    }
  }, []);

  useEffect(() => {
    if (guarantor?.attachments) {
      setFiles(guarantor.attachments);
    }
  }, [guarantor]);

  const removeAndAddFile = (attachments: Attachment[]) => {
    if (files.length > 0) {
      files.map(file => {
        const attIndex = attachments?.findIndex(att => att.type === file.type);

        if (attIndex !== undefined && attIndex >= 0) {
          attachments = attachments?.filter((att, index) => index !== attIndex);
          attachments?.push(file);
        }

        if (attIndex === -1) {
          attachments?.push(file);
        }
      });
    }
    return attachments;
  };

  const refactorDate = (value: string) => {
    const dateFormatted = parse(StringUtils.returnDateFormatMask(value), 'yyyy-MM-dd', new Date());
    return dateFormatted;
  };

  const onSubmitGuarantorEdit = async (data: Schema) => {
    const attachments = removeAndAddFile(guarantor?.attachments ?? []);

    let { address, birth, zipcode, complement, city, email, district, income, name, number, office, phone, state, rg } = data;

    const guarantorEdit: GuarantorProps = {
      id: guarantorId,
      attachments,
      address: {
        city: {
          id: addressOccupationIds?.cityId ?? guarantor?.address?.city?.id ?? 0,
        },
        complement,
        district,
        number,
        street: address,
        zipcode,
      },
      birth: refactorDate(birth),
      email,
      income: unMaskedCurrency(income),
      name,
      occupation: {
        id: addressOccupationIds?.occupationId ?? guarantor?.occupation?.id ?? 0,
      },
      phone,
      rg,
    };

    const unMaskedDatas = unMaskedFieldsValues(guarantorEdit, true);
    unMaskedDatas.income = Number(unMaskedDatas.income) / 100;

    const res = await guarantorApi.editGuarantor(unMaskedDatas, guarantorId);
    if (res.status === HttpStatus.OK) {
      setOpenModal(true);
    }
  };

  return (
    <Container>
      <ContainerBackStep>
        <span>
          <img src={ArrowLeft} alt={t('global.button.backStep')} /> {t('global.button.backStep')}
        </span>
      </ContainerBackStep>
      <ContainerContent>
        <TitleText>{t('dashboardClient.guarantor.guarantorData.title')}</TitleText>
        <ContainerEditData
          schema={schema}
          editData={guarantor}
          onSubmit={onSubmitGuarantorEdit}
          setAddressOccupationIds={setAddressOccupationIds}
          addressOccupationIds={addressOccupationIds}
        />
      </ContainerContent>
      <SimpleModal
        isOpen={openModal}
        onCloseModal={() => setOpenModal(false)}
        imageSrc={Check}
        titleText={'dashboardClient.financing.modal.guarantorEdit'}
        messageText=""
        confirmText={'OK'}
        handleConfirm={() => history.goBack()}
      />
    </Container>
  );
};

export default GuarantorEdit;

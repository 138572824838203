import ButtonComponent from 'components/Button';
import React, { useState } from 'react';
import { ContainerInfos, ContainerLink, HeaderStyled } from './styles';
import Logo from '../../images/LogoHeader.svg';
import { useTranslation } from 'react-i18next';
import Menu from '../../images/menuRight.svg';
import SideMenu from 'components/sideMenu';
import { useHistory } from 'react-router-dom';

interface Props {
  shadow?: boolean;
}

export const Header = ({ shadow }: Props) => {
  const { t } = useTranslation();
  const [displayOpenMenu, setDisplayOpenMenu] = useState<string>('none');
  const history = useHistory();

  const handleMenu = () => {
    setDisplayOpenMenu(displayOpenMenu === 'none' ? 'flex' : 'none');
  };

  return (
    <HeaderStyled shadow={shadow ? '0px 4px 7px rgba(128, 0, 140, 0.1)' : ''}>
      <ContainerInfos>
        <img src={Logo} alt="Logo" />
        <ContainerLink>
          <a href="/#approval">{t('LandingPage.link1')}</a>
          <a href="/#howItWorks">{t('LandingPage.link2')}</a>
          <a href="/#app">{t('LandingPage.link3')}</a>
          <a href="/#bePartner">{t('LandingPage.link4')}</a>
          <a href="/#doubt">{t('LandingPage.link5')}</a>
        </ContainerLink>
        <ButtonComponent
          width="97px"
          height="41px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="#fff"
          text="global.button.login"
          onClick={() => history.push('/login')}
        />
        <img src={Menu} alt="menu" onClick={() => handleMenu()} />
      </ContainerInfos>
      <SideMenu display={displayOpenMenu} closeMenu={() => handleMenu()} onClickInButton={() => history.push('/login')} />
    </HeaderStyled>
  );
};

import ButtonComponent from 'components/Button';
import { FormConsultantData } from 'components/form-consultant-data';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { User } from 'model/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import teamService from 'services/team-service';
import { consultantsAuthorities, internalStatus } from 'shared/util/team-utils';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer } from './styles';

export const AdminEditConsultant = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const [personalData, setPersonalData] = useState<User>({});

  const userId = Number(props.match.params.id);

  useEffect(() => {
    getConsultantData();
  }, [userId]);

  const getConsultantData = async () => {
    const content = await teamService.getUserById(userId);
    setPersonalData(content);
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')).email(t('global.errorMessage.email')),
    phone: yup.string().required(t('global.errorMessage.required')),
    cpf: yup.string().required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = async data => {
    const userData: User = {
      id: userId,
      name: data.name,
      companyName: data.companyName,
      email: data.email,
      phone: data.phone.replace(/\W/g, ''),
      cpf: data.cpf.replace(/\W/g, ''),
      cnpj: data.cnpj.replace(/\W/g, ''),
      internalStatus: internalStatus().find(status => status.label === data.internalStatus)?.value,
      authority: consultantsAuthorities().find(authority => authority.label === data.authority)?.value,
    };

    const res = await await teamService.updateConsultantData(userData, userId);

    if (res) {
      history.replace(`/admin/team/consultants`);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('team.editUser.header')}</StyledEditHeader>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <Container>
            <div className="container-data">
              <span className="title">{t('team.editUser.title')}</span>
              <FormConsultantData personalData={personalData} />
            </div>
          </Container>
          <Footer>
            <ContainerButtons>
              <ButtonComponent
                width="117px"
                height="44px"
                background="transparent"
                border="none"
                borderRadius="none"
                color="#fff"
                text="global.button.cancel"
                weight="400"
                size="15px"
                onClick={() => history.goBack()}
              />
              <ButtonComponent
                width="150px"
                height="44px"
                background="#80008C"
                border="none"
                borderRadius="6px"
                color="#fff"
                text="global.button.save"
                weight="400"
                size="15px"
                type="submit"
              />
            </ContainerButtons>
          </Footer>
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

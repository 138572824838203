import { Internal } from 'model/internal';
import { useTranslation } from 'react-i18next';
import AuthUtils from 'shared/util/auth-utils';
import { ChangeButton, Container, Header, Name, Title } from './StyledComponents';

interface PartnerContainerProps {
  partnersList: Internal[];
  handleLinkPartner: () => void;
  setShowLinkPartnerModal: (value) => void;
  isPartner: boolean;
}

const PartnerContainer: React.FC<PartnerContainerProps> = props => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{t('registrationStatus.partner')}</Title>
        {!props.isPartner && !AuthUtils.isInternal() && (
          <ChangeButton onClick={() => props.setShowLinkPartnerModal(true)}>{t('global.button.change')}</ChangeButton>
        )}
      </Header>
      {props.partnersList.map(item => (
        <>
          <Name key={item.id}>{item.name}</Name>
          <br />
        </>
      ))}
    </Container>
  );
};

export default PartnerContainer;

import ContainerDocument from 'components/ContainerDocument';
import { InputAndText } from 'components/InputAndTitle';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { TextInput } from 'components/TextInput';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { GuarantorProps } from 'model/guarantor';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { ContainerInputs, SubtitleText } from './styles';

export interface InfoIds {
  cityId: number;
  occupationId: number;
}
interface Props {
  personalData?: GuarantorProps | Client;
  setInfoIds?: (ids: InfoIds) => void;
  infoIds?: InfoIds;
  isClient?: boolean;
}

export const FormPersonalData = ({ personalData, setInfoIds, infoIds, isClient = false }: Props) => {
  const { t } = useTranslation();
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [resetCities, setResetCities] = useState(false);
  const {
    states,
    cities,
    stateID,
    handleSetStateID,
    getStates,
    getCities,
    statesTotalPages,
    citiesTotalPages,
    occupations,
    occupationsTotalPages,
    getOccupations,
  } = useSelectLists();

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7 }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage]);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  useEffect(() => {
    setResetCities(true);
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 });
    }
  }, [stateID]);

  return (
    <>
      <SubtitleText>{t('dashboardClient.guarantor.personalData')}</SubtitleText>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.name"
          name="name"
          placeholder="dashboardClient.guarantor.form.placeholder.name"
          value={personalData && personalData.name}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.email"
          name="email"
          placeholder="dashboardClient.guarantor.form.placeholder.email"
          value={personalData && personalData.email}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.phone"
          name="phone"
          placeholder="dashboardClient.guarantor.form.placeholder.phone"
          value={personalData && personalData.phone}
          mask="PHONE"
          max={15}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.cpf"
          name="cpf"
          placeholder="dashboardClient.guarantor.form.placeholder.cpf"
          value={personalData && personalData.cpf}
          mask="CPF"
          disabled
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.income"
          name="income"
          placeholder="dashboardClient.guarantor.form.placeholder.income"
          value={personalData && personalData.income?.toFixed(2)}
          mask="CURRENCY"
        />
        <TextInput name="office" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="office"
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
            defaultValue={personalData?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              const occupation = occupations.find(op => op.name === values.name);
              if (occupation != null) {
                setInfoIds && setInfoIds({ ...infoIds!, occupationId: occupation.id });
              }
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs style={{ marginBottom: '48px' }}>
        <InputAndText
          title="dashboardClient.guarantor.form.rg"
          name="rg"
          placeholder="dashboardClient.guarantor.form.placeholder.rg"
          value={personalData && personalData.rg}
          disabled
        />
        <InputAndText
          title="dashboardClient.guarantor.form.birthDate"
          name="birth"
          placeholder="dashboardClient.guarantor.form.placeholder.birthDate"
          value={personalData && personalData?.birth?.toString().split('-').reverse().join('/')}
          mask="BIRTHDAY"
        />
      </ContainerInputs>
      <SubtitleText>{t('dashboardClient.guarantor.address')}</SubtitleText>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.zipCode"
          name="zipcode"
          placeholder="dashboardClient.guarantor.form.placeholder.zipCode"
          value={personalData && personalData.address?.zipcode}
          mask="CEP"
        />
        <InputAndText
          title="dashboardClient.guarantor.form.address"
          name="address"
          placeholder="dashboardClient.guarantor.form.placeholder.address"
          value={personalData && personalData.address?.street}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.district"
          name="district"
          placeholder="dashboardClient.guarantor.form.placeholder.district"
          value={personalData && personalData.address?.district}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.number"
          name="number"
          placeholder="dashboardClient.guarantor.form.placeholder.number"
          value={personalData && personalData.address?.number}
        />
      </ContainerInputs>
      <ContainerInputs>
        <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
          <SearchSelectInput
            name="state"
            placeholder={t('register.placeholder.state')}
            options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultValue={personalData?.address?.city?.state?.name}
            handleChange={values => {
              const state = states.find(st => st.name === values.name);
              if (state != null) {
                handleSetStateID(state.id);
              }
              setStateName(values.name);
              setStatePage(0);
              setCityPage(0);
              getStates({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (statePage < statesTotalPages - 1) {
                setStatePage(statePage + 1);
              }
            }}
          />
        </TextInput>
        <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
          <SearchSelectInput
            name="city"
            placeholder={t('register.placeholder.city')}
            defaultValue={personalData?.address?.city?.name}
            options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            handleChange={values => {
              setResetCities(false);
              setCityName(values.name);
              const city = cities.find(ct => ct.name === values.name);
              if (city != null) {
                setInfoIds && setInfoIds({ ...infoIds!, cityId: city.id });
              }
              setCityPage(0);
              if (stateID) {
                getCities(stateID, { name: values.name, size: 7 });
              }
            }}
            handleOnScroll={() => {
              if (cityPage < citiesTotalPages - 1) {
                setCityPage(cityPage + 1);
              }
            }}
            resetValue={resetCities}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs style={{ marginBottom: '48px' }}>
        <InputAndText
          title="dashboardClient.guarantor.form.complement"
          name="complement"
          placeholder="dashboardClient.guarantor.form.placeholder.complement"
          value={personalData && personalData.address?.complement}
        />
        <div />
      </ContainerInputs>
      {isClient && (
        <>
          <SubtitleText style={{ marginBottom: '24px', marginRight: '866px' }}>
            {t('dashboardClient.personalData.form.changePassword')}
          </SubtitleText>
          <ContainerInputs style={{ marginBottom: '24px' }}>
            <InputAndText
              title="dashboardClient.personalData.form.password"
              name="password"
              placeholder="dashboardClient.personalData.form.password"
              type="password"
            />
            <InputAndText
              title="dashboardClient.personalData.form.confirmPassword"
              name="confirmPassword"
              placeholder="dashboardClient.personalData.form.confirmPassword"
              type="password"
            />
          </ContainerInputs>
        </>
      )}
      <SubtitleText style={{ marginBottom: '24px' }}>{t('dashboardClient.guarantor.documents')}</SubtitleText>
      {personalData?.attachments && (
        <>
          <div style={{ marginTop: '24px' }}>
            <ContainerDocument
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={
                personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName
              }
              docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
          </div>
          <ContainerDocument
            titleDocument="documents.ProofOfIncome"
            editDocument
            nameInput="doc2"
            name="attachmentIncome"
            type={AttachmentType.PROOF_INCOME}
            title={personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
            docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.PROOF_INCOME))}
          />
          <ContainerDocument
            titleDocument="documents.proofOfAddress"
            editDocument
            nameInput="doc3"
            name="attachmentAddress"
            type={AttachmentType.PROOF_RESIDENCE}
            title={personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
            docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
          />
        </>
      )}
    </>
  );
};

import styled from 'styled-components';

interface Props {
  admin?: boolean;
}

export const Container = styled.div`
  margin-top: 49px;
  margin-left: 34px;
`;

export const ContainerInfos = styled.div<Props>`
  width: ${({ admin }) => (admin ? '100%' : '936px')};
  height: 106px;
  background: #f9f3ff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerNameStatus = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
`;

export const NameContract = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
`;

export const ContractDate = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7f7f7f;
  margin-left: 29px;
`;

export const ContainerStatus = styled.div`
  width: 93px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d90980;
  border-radius: 10px;
  margin-left: 76px;
`;

export const Status = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const ContainerDownload = styled.a`
  display: flex;
  align-items: center;
  margin-left: 29px;
  margin-top: 8px;
  cursor: pointer;
  img {
    margin-right: 7px;
  }
`;

export const DownloadTitle = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #80008c;
`;

export const TitleSignatory = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2b1a48;
  margin-top: 32px;
`;

export const ContainerSignatoryTitle = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '268px 160px 170px 254px 248px' : '280px 182px 226px 248px')};
  grid-template-rows: 29px;
  border: 1px solid #d8d8d8;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  margin-top: 8px;
`;

export const ContainerTitleDetail = styled.div`
  height: 100%;
  border-left: 1px solid #d8d8d8;
`;

export const TitleDetailSignatory = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  margin-left: 20px;
`;

export const RowInformationSignatory = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '268px 160px 170px 254px 248px' : '280px 182px 226px 248px')};
  grid-template-rows: 40px;
  border: 1px solid #d8d8d8;
  border-top: none;
  align-items: center;
`;

export const ContainerRowInformationData = styled.div`
  height: 100%;
  border-left: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
`;

export const TitleDataSignatory = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

export const BallSignature = styled.div`
  width: 6px;
  height: 6px;
  background: #979797;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 3px;
`;

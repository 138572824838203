import Check from '@mui/icons-material/Check';
import { Conector, Container, CustomStep, StepContainer, StepLabel } from './StyledComponents';

interface CustomSteperProps {
  activeStep: number;
}

const CustomSteper: React.FC<CustomSteperProps> = props => {
  const steps = [{ label: 'Financiamento' }, { label: 'Contrato' }, { label: 'Pagamento' }, { label: 'Finalizado' }];
  return (
    <Container>
      {steps.map((item, i) => (
        <>
          <StepContainer key={i}>
            {/* {i === props.activeStep && (
          )} */}
            <CustomStep active={props.activeStep === i}>
              {props.activeStep > i ? (
                <div className="Complete-StepIcon-circle">
                  <Check className="StepIcon-completedIcon" />
                </div>
              ) : (
                <div className="StepIcon-circle" />
              )}
            </CustomStep>
            <StepLabel active={props.activeStep === i}>{item.label}</StepLabel>
          </StepContainer>
          {i < 3 && <Conector />}
        </>
      ))}
    </Container>
  );
};

export default CustomSteper;

import styled from 'styled-components';

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 9.68rem;
    height: 9.81rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 14px 0px;
`;

export const MessageText = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const ConfirmContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

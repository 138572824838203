import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { color } from '../shared/util/styled-components-utils';

interface StyledPaginationProps {
  page: {
    page: number;
    totalPages: number;
  };
  handleChangePage?: (page: number) => void;
}

const PaginationItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  div {
    font-size: 0.875rem;
    color: ${color`gray6`};
  }
`;

const PageEdit = styled.input`
  width: 46px;
  height: 26px;
  border-radius: 6px;
  border: 1px solid ${color`gray7`};
  font-size: 0.75rem;
  color: black;
  text-align: center;
`;

const PreviusButton = styled.a`
  width: 30px;
  height: 30px;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-color: #80008c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  cursor: pointer;

  &:hover,
  :active {
    background-color: white;
    border-color: #80008c;
  }

  i {
    transform: rotate(180deg);
    color: #80008c;
  }
`;
const NextButton = styled.a`
  width: 30px;
  height: 30px;
  background-color: white;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-color: #80008c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  :active {
    background-color: white;
    border-color: #80008c;
  }

  i {
    color: #80008c;
  }
`;

const StyledPagination: React.FC<StyledPaginationProps> = ({ page, children, handleChangePage, ...props }) => {
  const [pageValue, setPageValue] = useState<string>(page.page.toString());

  const handleUpdatePage = (value: number) => {
    handleChangePage && handleChangePage(Number(value));
  };

  function handleType(evt) {
    const { value } = evt.currentTarget;

    if (value === '') {
      return setPageValue('');
    }
    if (value.match(/\D/gi)) {
      return;
    }
    if (value <= 0) {
      return setPageValue('1');
    }
    if (value > page.totalPages) {
      return setPageValue(page.totalPages.toString());
    }

    setPageValue(evt.currentTarget.value.toString());
  }

  const onHandlePrevPage = () => {
    if (Number(pageValue) >= 2) {
      const newValue = Number(pageValue) - 1;
      setPageValue(newValue.toString());
      handleUpdatePage(newValue);
    }
  };

  const onHandleNextPage = () => {
    if (Number(pageValue) < page.totalPages) {
      const newValue = Number(pageValue) + 1;
      setPageValue(newValue.toString());
      handleUpdatePage(newValue);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleUpdatePage(Number(pageValue));
  };

  return (
    <PaginationItems {...props}>
      <div style={{ marginRight: '14px' }}>Página</div>
      <div>
        <form onSubmit={handleSubmit}>
          <PageEdit type="text" value={pageValue} onChange={handleType} />
        </form>
      </div>
      <div style={{ marginRight: '16px', marginLeft: '12px' }}>
        {'de '}
        {page.totalPages}
      </div>
      <div>
        <PreviusButton className="page-link" tabIndex={-1} onClick={onHandlePrevPage}>
          <i className="icon-arrow-red" />
        </PreviusButton>
      </div>
      <div>
        <NextButton className="page-link" onClick={onHandleNextPage}>
          <i className="icon-arrow-red" />
        </NextButton>
      </div>
    </PaginationItems>
  );
};

export default StyledPagination;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 30px;

  .container-data {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
    }

    .sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.666667px;
      color: #505050;
    }
  }
`;

export const Title = styled.span`
  display: block;
  width: 100%;
  text-align: left;
  color: #2b1a48;
  font-size: 32px;
  font-weight: 700;

  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  margin-right: 150px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  background-color: #2b1a48;
  position: fixed;
  bottom: 0;
`;

import financing from 'api/financing';
import ContainerDocument from 'components/ContainerDocument';
import { InputAndText } from 'components/InputAndTitle';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { TextInput } from 'components/TextInput';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { AttachmentType } from 'model/enums/attachment-types';
import { GuarantorProps } from 'model/guarantor';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { ContainerInputs, SubtitleText } from './styles';

export interface InfoIds {
  cityId: number;
  occupationId: number;
}
interface Props {
  financingData?: Course;
  setInfoIds?: (ids: InfoIds) => void;
  setLanguageId: (id: number) => void;
  infoIds?: InfoIds;
  isClient?: boolean;
}

export const FormFinancingData = ({ financingData, setInfoIds, infoIds, isClient = false, setLanguageId }: Props) => {
  const { t } = useTranslation();
  const [languageName, setLanguageName] = useState('');
  const [page, setPage] = useState(0);
  const { languages, languagesTotalPages, getLanguages } = useSelectLists();

  const objectives = [
    { label: t('clientFinancing.exchangeData.study'), value: 'STUDY' },
    { label: t('clientFinancing.exchangeData.workStudy'), value: 'WORK_STUDY' },
  ];

  const durations = [
    { label: t('clientFinancing.exchangeData.oneToThreeMonths'), value: 'ONE_TO_THREE_MONTHS' },
    { label: t('clientFinancing.exchangeData.threeToSixMonths'), value: 'THREE_TO_SIX_MONTHS' },
    { label: t('clientFinancing.exchangeData.sixToTwelveMonths'), value: 'SIX_TO_TWELVE_MONTHS' },
    { label: t('clientFinancing.exchangeData.longerThanTwelveMonths'), value: 'LONGER_THAN_TWELVE_MONTHS' },
  ];

  useEffect(() => {
    getLanguages({ name: languageName, page, size: 7 }, true);
  }, [languagesTotalPages]);

  return (
    <div>
      <SubtitleText>{t('financing.titles.exchangeData')}</SubtitleText>
      <ContainerInputs>
        <TextInput name="objective" label={t('financing.inputs.objective')} isRequired hasCustomInput>
          <SearchSelectInput
            name="objective"
            placeholder={t('financing.placeholder.objective')}
            options={objectives.map(obj => ({ label: obj?.label ?? '', value: obj.value }))}
            defaultValue={objectives.find(obj => financingData?.objective === obj.value)?.label}
            defaultOptions={objectives.map(obj => ({ label: obj?.label ?? '', value: obj.value }))}
          />
        </TextInput>
        <TextInput name="language" label={t('financing.inputs.language')} isRequired hasCustomInput>
          <SearchSelectInput
            name="language"
            placeholder={t('financing.placeholder.language')}
            options={languages.map(lang => ({ label: lang?.name ?? '', value: lang.id.toString() }))}
            defaultValue={financingData?.language?.name}
            handleChange={values => {
              setLanguageName(values.name);
              const language = languages.find(op => op.name === values.name);
              if (language != null) {
                setLanguageId && setLanguageId(language?.id);
              }
              setPage(0);
              getLanguages({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < languagesTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={languages.map(lang => ({ label: lang?.name ?? '', value: lang.id.toString() }))}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="financing.inputs.courseValue"
          name="courseValue"
          placeholder="financing.placeholder.courseValue"
          value={financingData && financingData.courseValue?.toFixed(2)}
          mask="CURRENCY"
        />
        <TextInput name="duration" label={t('financing.inputs.duration')} isRequired hasCustomInput>
          <SearchSelectInput
            name="duration"
            placeholder={t('financing.placeholder.duration')}
            options={durations.map(dur => ({ label: dur?.label ?? '', value: dur.value }))}
            defaultValue={durations.find(dur => dur.value === financingData?.duration)?.label}
            defaultOptions={durations.map(dur => ({ label: dur?.label ?? '', value: dur.value }))}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs style={{ marginBottom: '48px' }}>
        <InputAndText
          title="financing.inputs.startDate"
          name="startDate"
          placeholder="financing.placeholder.startDate"
          value={financingData && StringUtils.dateFormatMask(financingData.startDate ?? '')}
          mask="BIRTHDAY"
        />
        <div />
      </ContainerInputs>
      <SubtitleText>{t('financing.titles.creditData')}</SubtitleText>
      <ContainerInputs>
        <div>
          <InputAndText
            title="financing.inputs.approvedCredit"
            name="approvedValue"
            placeholder="financing.placeholder.approvedCredit"
            disabled
            value={financingData && financingData.financing?.approvedValue?.toFixed(2)}
            mask="CURRENCY"
            required={false}
          />
          <span style={{ fontSize: '12px', opacity: '0.8' }}>{t('global.fields.autoField')}</span>
        </div>
        <InputAndText
          title="financing.inputs.downPaymentValue"
          name="downPaymentValue"
          placeholder="financing.placeholder.downPaymentValue"
          value={financingData && financingData.financing?.downPaymentValue?.toFixed(2)}
          mask="CURRENCY"
          required={false}
        />
      </ContainerInputs>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import check from '../images/svg/checkBlock.svg';
import noCheck from '../images/svg/noCheckBlock.svg';
import drop from '../images/tiny-expand.svg';
import { color, font } from '../shared/util/styled-components-utils';

export interface FormValues {
  filterSelect: string | null;
  saveFilter: 'on' | 'off';
}

export interface DropdownFilterProps extends WithTranslation {
  labels: string[];
  placeholder: string;
  onCancelFiltration?: () => void;
  onApplyFiltration?: (e) => void;
}

export interface ButtonStyleProps {
  apply?: boolean;
}

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const InputContainer = styled.div`
  width: 100%;

  background: ${color`secondary`};
  border: 1px solid ${color`formSecond`};
  border-radius: 4px;

  padding: 0px 18px;
`;

export const FormContainer = styled.div`
  position: absolute;
  width: 100%;
  background: ${color`secondary`};
  border: 1px solid ${color`formSecond`};
  padding: 16px 18px;
  z-index: 2;
  border-radius: 0 0 4px 4px;
`;

interface FiltersContainerProps {
  label: string[];
}

export const FiltersContainer = styled.div<FiltersContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  label:last-child {
    border-top: 1px solid ${color`formSecond`};
    padding-top: 5px;
  }

  label:nth-child(${props => props.label.length}) {
    margin-bottom: 10px;
  }
`;

export const SaveFilter = styled.input``;

export const Label = styled.label`
  width: 100%;
  cursor: pointer;

  input {
    display: none;
  }

  span {
    margin-left: 8px;
    font-size: 13px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;

  margin-top: 10px;
`;

export const Button = styled.button<ButtonStyleProps>`
  border: none;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;

  color: #ccc;
  background: transparent;

  width: 80px;

  transition: filter 0.2s;

  :hover {
    filter: brightness(0.9);
  }

  ${props =>
    props.apply
      ? css`
          color: #80008c;
        `
      : ''}
`;

const OpenButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;

  span {
    color: #ccc;
    font-weight: lighter;
    font-size: 0.8125rem;
  }
`;

const DropdownFilter: React.FC<DropdownFilterProps> = props => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selecteds, setSelecteds] = useState<FormValues>({
    filterSelect: null,
    saveFilter: 'off',
  });

  const onHandleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    props.onApplyFiltration && props.onApplyFiltration(selecteds);
    setOpenDropdown(false);
  };

  const { filterSelect, saveFilter } = selecteds;

  const handleChange = (index: number) => {
    const output = index.toString();

    if (output === filterSelect) {
      setSelecteds({ ...selecteds, filterSelect: 'off' });
      return;
    }
    setSelecteds({ ...selecteds, filterSelect: output });
  };

  useEffect(() => {
    const filterSaved = localStorage.getItem(props.labels.toString());
    if (filterSaved) {
      setSelecteds({
        filterSelect: filterSaved,
        saveFilter: 'on',
      });
    }
  }, []);

  useEffect(() => {
    if (selecteds.saveFilter === 'on') {
      localStorage.setItem(props.labels.toString(), selecteds.filterSelect ?? '');
    }
    if (selecteds.saveFilter === 'off') {
      localStorage.removeItem(props.labels.toString());
    }
  }, [selecteds]);

  return (
    <Container>
      <InputContainer>
        <OpenButton onClick={() => setOpenDropdown(!openDropdown)}>
          <span>{props.placeholder}</span>
          <div>
            <img src={drop} alt="dropdown icon" />
          </div>
        </OpenButton>
      </InputContainer>
      {openDropdown && (
        <FormContainer>
          <form onSubmit={onHandleSubmit}>
            <FiltersContainer label={props.labels}>
              {props.labels.map((label, index) => {
                return (
                  <Label key={index}>
                    <img src={filterSelect === index.toString() ? check : noCheck} alt="check" />
                    <input name="filterSelect" type="radio" value={index} onClick={() => handleChange(index)} />
                    <span>{label}</span>
                  </Label>
                );
              })}

              <Label>
                <img src={saveFilter === 'on' ? check : noCheck} alt="check" />

                <input
                  name="saveFilter"
                  type="checkbox"
                  value={selecteds.saveFilter}
                  onChange={() => setSelecteds({ ...selecteds, saveFilter: saveFilter === 'off' ? 'on' : 'off' })}
                />
                <span>{props.t('global.fields.saveFilters')}</span>
              </Label>
            </FiltersContainer>

            <ButtonContainer>
              <Button type="button" onClick={() => setOpenDropdown(false)}>
                <span>{props.t('global.button.cancel2')}</span>
              </Button>
              <Button type="submit" apply>
                <span>{props.t('global.button.save')}</span>
              </Button>
            </ButtonContainer>
          </form>
        </FormContainer>
      )}
    </Container>
  );
};

export default withTranslation()(DropdownFilter);

import { FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import AboutFinancing from 'components/about-financing/about-financing';
import AdminHeader from 'components/admin-header/admin-header';
import AnalyseFinancingModal from 'components/analyse-financing-modal/analyse-financing-modal';
import AnalyseRegistrationModal from 'components/analyse-registration-modal/analyse-registration-modal';
import DropdownFilter from 'components/DropdownFilter';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import GuarantorsTabs from 'components/guarantors-tabs/guarantors-tabs';
import LinkPartnerModal from 'components/link-partner-modal/link-partner-modal';
import RegisterStatus from 'components/register-status/register-status';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledTabs from 'components/styled-tabs/styled-tabs';
import SubStyledTabs from 'components/sub-styled-tabs/sub-styled-tabs';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { CreditAnalysisStatus } from 'model/enums/credit-analysis-status';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import clientProfileService from 'services/client-profile-service';
import courseService from 'services/course.service';
import StringUtils from 'shared/util/string-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import ClientContract from './client-contract';
import ClientAddressData from './client-data/address-data';
import ClientAttachmentsData from './client-data/attachments-data';
import ClientContractsData from './client-data/contracts-data';
import ClientCreditData from './client-data/credit-data';
import ClientExchangeData from './client-data/exchange-data';
import ClientGeneralData from './client-data/general-data';
import DownPaymentData from './client-data/down-payment-data';
import NoDataPage from './client-data/no-data-page';
import ClientPaymentData from './client-data/payment-data';
import ClientRegistrationData from './client-data/registration-data';
import { Container, Option, Select } from './styles';
import { CourseFinancing, Financing } from 'model/financing';
import InstallmentsPaymentData from './client-data/installments-payment-data';
import { isEmpty } from 'lodash';
import { SearchSelectInput } from 'components/SearchSelectInput';
import CustomDrodown from 'components/custom-dropdown';
import { useQuery } from 'hook/route';
import { useClientProfileIds } from 'provider/client-profile';
import AuthUtils from 'shared/util/auth-utils';
import CalculateScoreModal from 'components/calculate-score-modal/calculate-score-modal';
import { Score } from 'model/score';
import guarantorService from 'services/guarantor.service';
import { useHistory } from 'react-router-dom';

interface ClientsProfileProps extends RouteComponentProps<{ id?: string; courseId?: string }> {}

const ClientProfile: React.FC<ClientsProfileProps> = props => {
  const { t } = useTranslation();
  const { selectedCourseId, setSelectedCourseId } = useClientProfileIds();
  const clientId = props.match.params.id!;
  const courseId = props.match.params.courseId ?? undefined;

  useEffect(() => {
    if (localStorage.getItem('clientId') !== clientId) {
      localStorage.removeItem('activeTab');
      localStorage.removeItem('activeCourse');
      localStorage.removeItem('activeGuarantor');
    }
    localStorage.setItem('clientId', clientId);
  }, []);

  const [activeTab, setActiveTabQuery] = useState<number>(Number(localStorage.getItem('activeTab')) ?? 0);
  const [activeCourse, setActiveCourse] = useState<number>(Number(localStorage.getItem('activeCourse')) ?? 0);
  const [activeGuarantor, setActiveGuarantor] = useState<number>(Number(localStorage.getItem('activeGuarantor')) ?? 0);

  const [clientContent, setClientContent] = useState<Client>();
  const [courses, setCourses] = useState<Course[]>();
  const [financings, setFinancings] = useState<CourseFinancing>();
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [scoreData, setScoreData] = useState<Score>();
  const [newScoreData, setNewScoreData] = useState<Score>();
  const [showAnalyseRegistrationModal, setShowAnalyseRegistrationModal] = useState<boolean>(false);
  const [showAnalyseFinancingModal, setShowAnalyseFinancingModal] = useState<boolean>(false);
  const [showLinkPartnerModal, setShowLinkPartnerModal] = useState<boolean>(false);
  const [showAboutFinancing, setShowAboutFinancing] = useState<boolean>(false);
  const [showCalculateScoreModal, setShowCalculateScoreModal] = useState<boolean>(false);
  const [showRecalculateScoreModal, setShowRecalculateScoreModal] = useState<boolean>(false);

  useEffect(() => {
    const prepare = async () => {
      await getClientCourses();
    };

    prepare();
  }, []);

  const getClientCourses = async () => {
    const content = await courseService.getCoursesByClientId(Number(clientId));
    setCourses(content ?? []);
  };

  const getFinancings = async () => {
    const content = await courseService.getFinancingsById(Number(selectedCourse?.financing?.id));
    setFinancings(content ?? {});
  };

  const getClientContent = async existCourseId => {
    if (existCourseId) {
      const content = await clientProfileService.getClientSummary(Number(clientId), selectedCourse!.id!);
      setClientContent(content ?? []);
      getFinancings();
    } else {
      const content = await clientProfileService.getClientSummaryWithoutCourse(Number(clientId));
      setClientContent(content ?? []);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getClientContent(selectedCourse);
    } else {
      getClientContent(selectedCourse);
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (courses) {
      if (selectedCourse == null) {
        if (courseId) {
          setSelectedCourse(courses.find(course => course.id === Number(courseId)));
        } else {
          setSelectedCourse(courses[activeCourse]);
        }
      }
    }
  }, [courses]);

  useEffect(() => {
    localStorage.setItem('activeTab', `${activeTab}`);
    localStorage.setItem('activeCourse', `${activeCourse}`);
    localStorage.setItem('activeGuarantor', `${activeGuarantor}`);
  }, [activeTab, activeCourse, activeGuarantor]);

  const handleSelectCourse = () => {
    let newOptions: any = [];

    courses &&
      courses.map((item, i) => {
        newOptions = [...newOptions, { label: `Financiamento ${i + 1}`, value: item.id?.toString() }];
      });

    return newOptions;
  };

  const handleSelectChange = value => {
    setSelectedCourse(courses?.find(it => it.id === Number(value)));
    setActiveCourse(courses?.findIndex(it => it.id === Number(value)) ?? 0);
    window.history.pushState('', '', `/admin/dashboard/register/${clientId}/course/${courses?.find(it => it.id === Number(value))?.id}`);
  };

  const handleOpenScoreModal = async (id: number) => {
    const content = await guarantorService.GuarantorCheckScore(id);
    if (content) {
      setScoreData(content);
      setShowCalculateScoreModal(true);
    }
  };

  const handleOpenRecalculateScoreModal = async (id: number) => {
    const content = await clientProfileService.clientRecalculateScore(id);
    if (content) {
      setScoreData(content ?? {});
      setShowCalculateScoreModal(true);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AdminHeader />
      </div>
      <div style={{ width: '100%' }}>
        <StyledAdminHeader>{t('menu.dashboard.profile')}</StyledAdminHeader>
        <MinHeightScreeningContainer>
          {showAnalyseRegistrationModal && (
            <AnalyseRegistrationModal onCloseModal={() => setShowAnalyseRegistrationModal(false)} clientId={clientContent?.id!} />
          )}
          {showAnalyseFinancingModal && (
            <AnalyseFinancingModal
              onCloseModal={() => setShowAnalyseFinancingModal(false)}
              clientId={clientContent?.id!}
              courseId={clientContent?.course?.id!}
              hasCourseValue={clientContent?.course?.courseValue ? true : false}
            />
          )}
          {showLinkPartnerModal && (
            <LinkPartnerModal
              partners={clientContent?.internals ?? undefined}
              onCloseModal={() => setShowLinkPartnerModal(false)}
              clientId={clientContent?.id!}
            />
          )}
          {showCalculateScoreModal && scoreData && (
            <CalculateScoreModal
              scoreData={scoreData}
              onCloseModal={() => {
                setShowCalculateScoreModal(false);
                history.go(0);
              }}
            />
          )}
          <div style={{ display: 'flex' }}>
            <Container>
              <div className="container-header">
                <div className="about">
                  <span className="name">{clientContent?.name}</span>
                  <div className="separator" />
                  <span className="documentNumber">{StringUtils.cpfMask(clientContent?.cpf)}</span>
                </div>
                <CustomDrodown
                  options={handleSelectCourse()}
                  handleClickCourse={value => handleSelectChange(value)}
                  selectedCourse={selectedCourse?.id?.toString() ?? ''}
                />
              </div>
              <StyledTabs
                editClientRoute={`/admin/dashboard/register/edit/${clientId}`}
                editCourseRoute={`/admin/dashboard/financing/edit/${clientId}/course/${selectedCourse?.id}`}
                isPartner={AuthUtils.isPartner()}
                activeTabQuery={activeTab}
                setActiveTabQuery={setActiveTabQuery}
                tabs={[
                  {
                    title: t('global.tabs.data'),
                    content: (
                      <div>
                        <SubStyledTabs
                          tabs={[
                            {
                              title: t('global.subTabs.generalData'),
                              content: (
                                <ClientGeneralData clientContent={clientContent!} handleOpenScoreModal={handleOpenRecalculateScoreModal} />
                              ),
                            },
                            {
                              title: t('global.subTabs.address'),
                              content: <ClientAddressData clientContent={clientContent!} />,
                            },
                            {
                              title: t('global.subTabs.registrationData'),
                              content: <ClientRegistrationData clientContent={clientContent!} />,
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    title: t('global.tabs.attachments'),
                    content: (
                      <div>
                        <SubStyledTabs
                          tabs={
                            clientContent?.attachments?.length! > 0
                              ? [
                                  {
                                    title: t('global.subTabs.client'),
                                    content: <ClientAttachmentsData clientContent={clientContent!} />,
                                  },
                                ]
                              : [
                                  {
                                    title: '',
                                    content: <NoDataPage tab="Attachments" />,
                                  },
                                ]
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    title: t('global.tabs.financing'),
                    content: (
                      <div>
                        <SubStyledTabs
                          tabs={
                            clientContent?.course != null
                              ? [
                                  {
                                    title: t('global.subTabs.exchangeData'),
                                    content: <ClientExchangeData exchangeData={selectedCourse!} />,
                                  },
                                  {
                                    title: t('global.subTabs.creditData'),
                                    content: <ClientCreditData clientContent={clientContent!} selectedCourse={selectedCourse!} />,
                                  },
                                  {
                                    title: '',
                                    content: (
                                      <GuarantorsTabs
                                        handleOpenScoreModal={handleOpenScoreModal}
                                        guarantors={selectedCourse?.guarantors!}
                                        setActiveGuarantor={setActiveGuarantor}
                                        activeGuarantor={activeGuarantor}
                                      />
                                    ),
                                  },
                                ]
                              : [
                                  {
                                    title: '',
                                    content: <NoDataPage tab="Financing" />,
                                  },
                                ]
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    title: t('global.tabs.contracts'),
                    content: (
                      <div>
                        <SubStyledTabs
                          tabs={
                            clientContent?.course?.creditAnalysisStatus === CreditAnalysisStatus.ACCEPTED
                              ? [
                                  {
                                    title: '',
                                    content: <ClientContract course={selectedCourse} client={clientContent} />,
                                  },
                                ]
                              : [
                                  {
                                    title: '',
                                    content: <NoDataPage tab="Contract" />,
                                  },
                                ]
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    title: t('global.tabs.payment'),
                    content: (
                      <div>
                        <SubStyledTabs
                          tabs={
                            clientContent?.course?.financing?.installments?.length !== 0
                              ? [
                                  {
                                    title: t('global.subTabs.details'),
                                    content: <ClientPaymentData paymentContent={clientContent?.course?.financing!} />,
                                  },
                                  {
                                    title: t('global.subTabs.income'),
                                    content: <DownPaymentData paymentContent={financings?.downPaymentInstallment!} />,
                                  },
                                  {
                                    title: t('global.subTabs.installments'),
                                    content: (
                                      <InstallmentsPaymentData
                                        showContent={financings?.downPaymentInstallment?.bankSlip?.status === 'PAID'}
                                        paymentContent={clientContent?.course?.financing?.installments!}
                                      />
                                    ),
                                  },
                                ]
                              : [
                                  {
                                    title: '',
                                    content: <NoDataPage tab="Payment" />,
                                  },
                                ]
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                setShowAboutFinancing={setShowAboutFinancing}
              />
            </Container>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <RegisterStatus
                registrationStatus={clientContent?.registrationStatus!}
                setShowAnalyseRegistrationModal={setShowAnalyseRegistrationModal}
                setShowLinkPartnerModal={setShowLinkPartnerModal}
                internals={clientContent?.internals!}
                isPartner={AuthUtils.isPartner()}
              />
              {showAboutFinancing && (
                <AboutFinancing
                  registrationStatus={clientContent?.registrationStatus!}
                  creditAnalysisStatus={
                    selectedCourse != null
                      ? clientContent?.course?.creditAnalysisStatus!
                      : clientContent?.courses?.find(course => course.id === selectedCourse)?.creditAnalysisStatus!
                  }
                  setShowAnalyseFinancingModal={setShowAnalyseFinancingModal}
                  isPartner={AuthUtils.isPartner()}
                  financingStatus={clientContent?.course?.financing?.status!}
                />
              )}
            </div>
          </div>
          <FooterLoginAdmin />
        </MinHeightScreeningContainer>
      </div>
    </div>
  );
};

export default ClientProfile;

import { AxiosResponse } from 'axios';
import { PageableResponse, Params } from 'model/pageable';
import { User } from 'model/user';
import { api } from './api';

export const register = () => {
  const createUser = (obj: User): Promise<AxiosResponse<User>> => {
    return api.post<User>('/internals/authority/internal', obj);
  };
  const createConsultant = (obj: User): Promise<AxiosResponse<User>> => {
    return api.post<User>('/internals/authority/partner', obj);
  };

  const deleteInternal = (id: number): Promise<AxiosResponse<User>> => {
    return api.delete<User>(`/internals/${id}`);
  };

  const getUsersTableData = (params: Params): Promise<AxiosResponse<PageableResponse<User>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/internals/authority/internal?${urlParams}`);
  };
  const getConsultantsTableData = (params: Params): Promise<AxiosResponse<PageableResponse<User>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/internals/authority/partner?${urlParams}`);
  };
  const getUserById = (id: number): Promise<AxiosResponse<User>> => {
    return api.get(`/internals/${id}`);
  };
  const updateUserData = (data: User, id: number): Promise<AxiosResponse<User>> => {
    return api.patch<User>(`/internals/${id}/authority/internal`, data);
  };
  const updateConsultantData = (data: User, id: number): Promise<AxiosResponse<User>> => {
    return api.patch<User>(`/internals/${id}/authority/partner`, data);
  };
  return {
    createUser,
    getUsersTableData,
    deleteInternal,
    getConsultantsTableData,
    getUserById,
    updateUserData,
    createConsultant,
    updateConsultantData,
  };
};

export default register();

import registerApi from 'api/register-api';
import ButtonComponent from 'components/Button';
import { ContainerAdminEditClient } from 'components/container-admin-edit-client';
import { Schema } from 'components/ContainerEditData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { parse } from 'date-fns';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/admin-edit-client-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import clientProfileService from 'services/client-profile-service';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer } from './styles';

export const AdminEditClient = props => {
  const { t } = useTranslation();
  const { files, personalData, getClientDataWithoutCourse, setFiles } = useEditForm();

  const history = useHistory();
  const { state } = useLocation<boolean>();

  const clientId = Number(props.match.params.id!);

  useEffect(() => {
    getClientDataWithoutCourse(clientId);
  }, [clientId]);

  const [addressOccupationIds, setAddressOccupationIds] = useState<AddressOccupationIds>();

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')).email(t('global.errorMessage.email')),
    phone: yup.string().required(t('global.errorMessage.required')),
    cpf: yup.string().required(t('global.errorMessage.required')),
    income: yup.string().required(t('global.errorMessage.required')),
    office: yup.string().required(t('global.errorMessage.required')),
    birth: yup.string().required(t('global.errorMessage.required')),
    zipcode: yup.string().required(t('global.errorMessage.required')),
    address: yup.string().required(t('global.errorMessage.required')),
    district: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string().required(t('global.errorMessage.required')),
    rg: yup.string().required(t('global.errorMessage.required')),
    password: yup.string(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('global.errorMessage.confirmPassword')),
    attachmentIdentification: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.IDENTIFICATION_CARD)[0] ?? '')
      .required(t('global.errorMessage.required')),
    attachmentIncome: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.PROOF_INCOME)[0] ?? ''),
    attachmentAddress: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.PROOF_RESIDENCE)[0] ?? '')
      .required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = res => {
    sendNewClientPersonalDatas(res);
  };

  const sendNewClientPersonalDatas = async (data: Schema) => {
    const attachments = personalData.attachments;

    if (files.length > 0) {
      files.forEach(file => {
        const attIndex = attachments?.findIndex(att => att.type === file.type);

        if (attIndex != null && attIndex >= 0) {
          attachments?.splice(attIndex, 1, file);
        } else {
          attachments?.push(file);
        }
      });
    }

    const clientBirth = data.birth.split('/').reverse().join('-');

    const clientDatas: Client = {
      id: personalData.id,
      name: data.name ?? personalData.name,
      email: data.email ?? personalData.email,
      phone: data.phone ?? personalData.phone,
      cpf: data.cpf ?? personalData.cpf,
      income: unMaskedCurrency(data.income) ?? personalData.income,
      occupation: {
        id: addressOccupationIds?.occupationId ?? personalData.occupation?.id ?? 0,
      },
      user: {
        id: personalData.user?.id,
        login: personalData?.user?.login,
      },
      rg: data.rg ?? personalData.rg,
      birth: parse(clientBirth, 'yyyy-MM-dd', new Date()) ?? personalData.birth,
      address: {
        zipcode: data.zipcode ?? personalData.address?.zipcode,
        street: data.address ?? personalData.address?.street,
        district: data.district ?? personalData.address?.district,
        number: data.number ?? personalData.address?.number,
        complement: data.complement ?? personalData.address?.complement,
        city: {
          id: addressOccupationIds?.cityId ?? personalData.address?.city?.id ?? 0,
        },
      },
      attachments,
    };

    const unMaskedDatas = unMaskedFieldsValues(clientDatas, true);

    unMaskedDatas.income = Number(unMaskedDatas.income) / 100;

    if (data.password) {
      unMaskedDatas.user = { ...unMaskedDatas.user, password: data.password };
    }

    const res = await await clientProfileService.updateClientData(unMaskedDatas, clientId);

    if (res) {
      history.replace(`/admin/dashboard/register/${clientId}`);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('clientRegisterEdit.title')}</StyledEditHeader>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <Container>
            <div className="container-header">
              <div className="about">
                <span className="name">{personalData?.name}</span>
                <div className="separator" />
                <span className="documentNumber">{StringUtils.cpfMask(personalData?.cpf)}</span>
              </div>
            </div>
            <ContainerAdminEditClient
              newClient={state}
              schema={schema}
              editData={personalData}
              onSubmit={onHandleSubmit}
              setAddressOccupationIds={setAddressOccupationIds}
              addressOccupationIds={addressOccupationIds}
              files={files}
              setFiles={setFiles}
            />
          </Container>

          <Footer>
            <ContainerButtons>
              <ButtonComponent
                width="117px"
                height="44px"
                background="transparent"
                border="none"
                borderRadius="none"
                color="#fff"
                text="global.button.cancel"
                weight="400"
                size="15px"
                onClick={() => history.goBack()}
              />
              <ButtonComponent
                width="150px"
                height="44px"
                background="#80008C"
                border="none"
                borderRadius="6px"
                color="#fff"
                text="global.button.save"
                weight="400"
                size="15px"
                type="submit"
              />
            </ContainerButtons>
          </Footer>
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

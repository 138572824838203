import Footer from 'features/footer/footer';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, Container, ImageContainer, MessageText, TextsContainer, Title } from './styles';
import WomanWithDocument from '../../images/woman-with-document.svg';
import StyledButton from 'components/StyledButton';
import { HeaderWithLogout } from 'components/HeaderWithLogout';
import { useHistory } from 'react-router-dom';

export const PreRegisterDocuments = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextsContainer>
          <Title>{t('preRegisterDocuments.title')}</Title>
          <MessageText>{t('preRegisterDocuments.message')}</MessageText>
          <ButtonContainer>
            <StyledButton
              primary
              text={t('preRegisterDocuments.buttonText')}
              onClick={() => history.replace(`${history.location.pathname}/attachments`)}
            />
          </ButtonContainer>
        </TextsContainer>
        <ImageContainer>
          <img src={WomanWithDocument} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

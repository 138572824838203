import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerMenu, ContainerText, ContainerTextAndBar } from './styles';
import Bar from '../../images/barMenuClient.svg';
import { useHistory } from 'react-router-dom';
import { useFinancing } from 'provider/Financing';
import { CreditAnalysisStatus } from 'model/enums/credit-analysis-status';

interface Props {
  Page?: string;
}

export const SideMenu = ({ Page }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname;
  const arrayMenu = [
    { title: 'dashboardClient.sideMenu.financing', url: '/client/dashboard/financing' },
    { title: 'dashboardClient.sideMenu.personalData', url: '/client/dashboard/personalData' },
    { title: 'dashboardClient.sideMenu.guarantor', url: '/client/dashboard/guarantor' },
  ];
  const showBar = [{ title: 'financing' }, { title: 'personalData' }, { title: 'guarantor' }, { title: 'contracts' }, { title: 'payment' }];

  const arrayMenuAfterContract = [
    { title: 'dashboardClient.sideMenu.contract', url: '/client/dashboard/contracts' },
    { title: 'dashboardClient.sideMenu.payment', url: '/client/dashboard/payment' },
  ];

  const handleClickMenu = (url: string) => {
    history.push(url);
  };

  return (
    <ContainerMenu>
      <ContainerText>
        {arrayMenu.map((element, index) => {
          return (
            <ContainerTextAndBar key={index}>
              <span onClick={() => handleClickMenu(element.url)}>{t(element.title)}</span>
              {showBar[index].title === path.split('/')[3] && <img src={Bar} />}
            </ContainerTextAndBar>
          );
        })}
        {arrayMenuAfterContract.map((element, index) => {
          return (
            <ContainerTextAndBar key={index}>
              <span onClick={() => handleClickMenu(element.url)}>{t(element.title)}</span>
              {showBar[index + 3].title === path.split('/')[3] && <img src={Bar} />}
            </ContainerTextAndBar>
          );
        })}
      </ContainerText>
    </ContainerMenu>
  );
};

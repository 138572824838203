import ButtonComponent from 'components/Button';
import ProgressBar from 'components/radius-progress-bar';
import Status from 'components/status/status';
import StyledTabs from 'components/styled-tabs/styled-tabs';
import NoDataPage from 'features/admin/dashboard/register/client/client-data/no-data-page';
import { useQuery } from 'hook/route';
import { Course } from 'model/courses';
import { CourseFinancing } from 'model/financing';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import courseService from 'services/course.service';
import StringUtils from 'shared/util/string-utils';
import {
  Container,
  ContainerDetails,
  ContainerDownload,
  ContainerInfos,
  ContainerNameStatus,
  ContainerStatus,
  ContractDate,
  DownloadTitle,
  ValuePayment,
  ContainerText,
  Title,
  Value,
} from './styles';

const PaymentSteps = () => {
  const { t } = useTranslation();
  const [financings, setFinancings] = useState<CourseFinancing>();
  const [courses, setCourses] = useState<Course[]>();
  const [isLoading, setIsLoading] = useState<string | boolean>('');
  const accountId = useSelector((state: IRootState) => state.authentication.account?.client?.id);
  const query = useQuery();
  const id = Number(query.get('id'));

  const getFinancings = async () => {
    const content = await courseService.getFinancingsByClientId(accountId);
    if (id) {
      setFinancings(content.find(financing => financing?.course?.id === id) ?? {});
    } else {
      setFinancings(content?.[0] ?? {});
    }
  };

  useEffect(() => {
    if (accountId) {
      getFinancings();
    }
  }, []);

  const handleDownloadBankSlip = async (installmentId: number) => {
    setIsLoading(installmentId.toString());

    if (financings?.id && installmentId) {
      const bankSlip = await courseService.getClientBankSlip(financings?.id, installmentId);

      if (bankSlip) {
        StringUtils.showDocument(bankSlip.base64, 'application/pdf');
        setIsLoading(false);
        return;
      }
    }
  };

  const handleDownloadDownPaymentBankSlip = async () => {
    setIsLoading(true);

    if (financings?.id) {
      const bankSlip = await courseService.getClientDownPaymentBankSlip(financings?.id);
      if (bankSlip) {
        StringUtils.showDocument(bankSlip.base64, 'application/pdf');
        setIsLoading(false);
        return;
      }
    }
  };

  const handleCalculatePaidValue = () => {
    let paidValue = 0;
    financings?.installments?.forEach(installment => {
      if (installment.bankSlip?.status === 'PAID') {
        paidValue += installment.installmentValue;
      }
    });
    return paidValue;
  };

  const handleCalculateRemainingValue = () => {
    return (financings?.totalValue && financings?.totalValue - handleCalculatePaidValue()) ?? 0;
  };

  const handleCalculatePaidPercentage = () => {
    if (financings?.totalValue) {
      return Math.round((handleCalculatePaidValue() * 100) / financings.totalValue);
    }
    return 0;
  };

  const handleFindNextDueDate = () => {
    return financings?.installments?.findIndex(installment => installment.bankSlip?.status !== 'PAID') ?? 'Não informado';
  };

  return !financings?.downPaymentInstallment?.hasOwnProperty('bankSlip') ? (
    <NoDataPage tab="Payment" />
  ) : (
    <Container>
      <StyledTabs
        editPage
        tabs={[
          {
            title: t('dashboardClient.payments.paymentInfo.opened'),
            content: (
              <div
                style={{
                  width: '936px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                {financings?.downPaymentInstallment?.hasOwnProperty('bankSlip') &&
                financings?.downPaymentInstallment?.bankSlip?.status !== 'CREATED' ? (
                  financings?.installments
                    ?.sort((a, b) => a.id - b.id)
                    .map((item, key) => {
                      if (item.bankSlip?.status !== 'PAID') {
                        return (
                          <ContainerInfos key={key}>
                            <ContainerDetails>
                              <ContainerNameStatus>
                                <ValuePayment>{`${StringUtils.currencyMask(item.installmentValue.toFixed(2))}`}</ValuePayment>
                                <ContainerStatus>
                                  <Status title={item.bankSlip?.status ?? 'CREATED'} />
                                </ContainerStatus>
                              </ContainerNameStatus>
                              <ContainerDownload>
                                <DownloadTitle>
                                  <span>{t('clientData.details.installment')}</span>
                                  {`${item.installmentNumber}/${financings.installmentsTotal}`}
                                </DownloadTitle>
                                <DownloadTitle>
                                  <span>{t('clientData.details.dueDateInstallment')}</span>
                                  {item?.dueDate && StringUtils.dateFormatMask(item?.dueDate.toString())}
                                </DownloadTitle>
                              </ContainerDownload>
                            </ContainerDetails>
                            <ButtonComponent
                              width="159px"
                              height="47px"
                              background="#80008C"
                              color="#fff"
                              size="18px"
                              weight="600"
                              borderRadius="8px"
                              border="none"
                              text="Baixar boleto"
                              load={isLoading === item?.id.toString()}
                              onClick={() => handleDownloadBankSlip(item?.id ?? 0)}
                            />
                          </ContainerInfos>
                        );
                      }
                    })
                ) : (
                  <ContainerInfos>
                    <ContainerDetails>
                      <ContainerNameStatus>
                        <ValuePayment>{`${StringUtils.currencyMask(
                          financings?.downPaymentInstallment?.installmentValue.toFixed(2)
                        )}`}</ValuePayment>
                        <ContainerStatus>
                          <Status title={financings?.downPaymentInstallment?.bankSlip?.status} />
                        </ContainerStatus>
                      </ContainerNameStatus>
                      <ContainerDownload>
                        <DownloadTitle>
                          <span>{t('clientData.details.installment')}</span>
                          {t('clientData.details.downPayment')}
                        </DownloadTitle>
                        <DownloadTitle>
                          <span>{t('clientData.details.dueDateInstallment')}</span>
                          {financings?.downPaymentInstallment &&
                            StringUtils.dateFormatMask(financings?.downPaymentInstallment?.dueDate.toString())}
                        </DownloadTitle>
                      </ContainerDownload>
                    </ContainerDetails>
                    <ButtonComponent
                      width="159px"
                      height="47px"
                      background="#80008C"
                      color="#fff"
                      size="18px"
                      weight="600"
                      borderRadius="8px"
                      border="none"
                      text="Baixar boleto"
                      load={isLoading === true}
                      onClick={() => handleDownloadDownPaymentBankSlip()}
                    />
                  </ContainerInfos>
                )}
              </div>
            ),
          },
          {
            title: t('dashboardClient.payments.paymentInfo.paid'),
            content: (
              <div
                style={{
                  width: '936px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                {financings?.downPaymentInstallment?.hasOwnProperty('bankSlip') &&
                  financings?.downPaymentInstallment?.bankSlip?.status !== 'CREATED' && (
                    <>
                      <ContainerInfos>
                        <ContainerDetails>
                          <ContainerNameStatus>
                            <ValuePayment>{`${StringUtils.currencyMask(
                              financings?.downPaymentInstallment?.installmentValue.toFixed(2)
                            )}`}</ValuePayment>
                            <ContainerStatus>
                              <Status title={financings?.downPaymentInstallment?.bankSlip.status} />
                            </ContainerStatus>
                          </ContainerNameStatus>
                          <ContainerDownload>
                            <DownloadTitle>
                              <span>{t('clientData.details.installment')}</span>
                              {t('clientData.details.downPayment')}
                            </DownloadTitle>
                            <DownloadTitle>
                              <span>{t('clientData.details.dueDateInstallment')}</span>
                              {financings?.downPaymentInstallment &&
                                StringUtils.dateFormatMask(financings?.downPaymentInstallment?.dueDate.toString())}
                            </DownloadTitle>
                          </ContainerDownload>
                        </ContainerDetails>
                      </ContainerInfos>
                      {financings?.installments
                        ?.sort((a, b) => a.id - b.id)
                        .map((item, key) => {
                          if (item.bankSlip?.status === 'PAID') {
                            return (
                              <ContainerInfos key={key}>
                                <ContainerDetails>
                                  <ContainerNameStatus>
                                    <ValuePayment>{`${StringUtils.currencyMask(item.installmentValue.toFixed(2))}`}</ValuePayment>
                                    <ContainerStatus>
                                      <Status title={item.bankSlip?.status} />
                                    </ContainerStatus>
                                  </ContainerNameStatus>
                                  <ContainerDownload>
                                    <DownloadTitle>
                                      <span>{t('clientData.details.installment')}</span>
                                      {`${item.installmentNumber}/${financings.installmentsTotal}`}
                                    </DownloadTitle>
                                    <DownloadTitle>
                                      <span>{t('clientData.details.dueDateInstallment')}</span>
                                      {item?.dueDate && StringUtils.dateFormatMask(item?.dueDate.toString())}
                                    </DownloadTitle>
                                  </ContainerDownload>
                                </ContainerDetails>
                              </ContainerInfos>
                            );
                          }
                        })}
                    </>
                  )}
              </div>
            ),
          },
          {
            title: t('dashboardClient.payments.paymentInfo.panorama'),
            content: (
              <div style={{ width: '936px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px' }}>
                <ProgressBar progress={handleCalculatePaidPercentage()} text={t('clientData.details.paidValue')} />
                <ContainerText>
                  <Title>
                    {t('clientData.details.financingValue')}
                    <Value>{financings?.totalValue ? StringUtils.currencyMask(financings?.totalValue?.toFixed(2)) : 'Não informado'}</Value>
                  </Title>
                  <Title>
                    {t('clientData.details.paidValue')}
                    <Value>{StringUtils.currencyMask(handleCalculatePaidValue().toFixed(2))}</Value>
                  </Title>
                  <Title>
                    {t('clientData.details.remainingValue')}
                    <Value>{StringUtils.currencyMask(handleCalculateRemainingValue().toFixed(2))}</Value>
                  </Title>
                  <Title>
                    {t('clientData.details.installments')}
                    <Value>{financings?.installmentsTotal ?? 'Não informado'}</Value>
                  </Title>
                  <Title>
                    {t('clientData.details.dueDate')}
                    <Value>
                      {financings?.firstDueDate
                        ? StringUtils.dateFormatMask(financings?.installments?.[handleFindNextDueDate()].dueDate?.toString())
                        : 'Não informado'}
                    </Value>
                  </Title>
                  <Title>
                    {t('clientData.details.fees')}
                    <Value>{`${financings?.monthlyTax} a.m.`}</Value>
                  </Title>
                  <Title>
                    {t('clientData.details.cet')}
                    <Value>{`${financings?.annualTax} a.a.`}</Value>
                  </Title>
                </ContainerText>
              </div>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default PaymentSteps;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 49px;
  margin-left: 34px;
`;

export const ContainerInfos = styled.div`
  width: 936px;
  height: 98px;
  background: #f9f3ff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerNameStatus = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
`;

export const ValuePayment = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2b1a48;
`;

export const ContractDate = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7f7f7f;
  margin-left: 29px;
`;

export const ContainerStatus = styled.div`
  width: 93px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  margin-left: 11px;
`;

export const Status = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const ContainerDownload = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const DownloadTitle = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;

  span {
    margin-right: 5px;
    color: #999999;
  }
`;

export const Title = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.666667px;
  color: #999999;
  margin-bottom: 24px;
`;

export const ContainerText = styled.div`
  width: 936px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const Value = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.666667px;
  color: #000;
  margin-left: 10px;
`;

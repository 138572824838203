import { AxiosResponse } from 'axios';
import { IFinishRecoveryPasswordData } from 'features/change-password/change-password';
import { IRecoveryEmail } from 'features/forgot-password/forgot-password';
import { Authentication } from 'model/authentication';
import { FirstSignUp } from 'model/first-signup';
import { User } from 'model/user';
import { api } from './api';

export const user = () => {
  const activateAccount = (key: string): Promise<AxiosResponse> => {
    return api.get(`/activate?key=${key}`);
  };
  const confirmEmail = (key: string): Promise<AxiosResponse> => {
    return api.get(`/confirm-email?key=${key}`);
  };
  const updateLogin = (userData: User): Promise<AxiosResponse<User>> => {
    return api.put<User>('/admin/users/edit-profiles', userData);
  };
  return {
    activateAccount,
    confirmEmail,
    updateLogin,
  };
};

export default user();

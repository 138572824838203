import StyledInputWrapper from 'components/StyledInputWrapper';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Container, Option, OptionContainer, SelectContent } from './styles';

export interface Options {
  label: string;
  value: string;
}

interface SelectInputProps {
  name: string;
  placeholder?: string;
  options: Options[];
  defaultValue?: Options;
  disabled?: boolean;
  errorMessage?: string;
  showErrorIcon?: boolean;
}

export const SelectInput = ({ name, options, placeholder, defaultValue, disabled, errorMessage, showErrorIcon }: SelectInputProps) => {
  const [optionSelected, setOptionSelected] = useState<Options | null>(defaultValue ? defaultValue : null);
  const [openOptions, setOpenOptions] = useState(false);

  const {
    register,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  useEffect(() => {
    setValue(name, optionSelected?.value);
  }, [optionSelected]);

  const handleGetValue = () => {
    const values = getValues();
    return Boolean(values[name]);
  };

  const hasDefaultValue = () => {
    if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
      return true;
    }
    return dirtyFields[name];
  };

  const hasOptionValue = () => {
    if (optionSelected != null) {
      return true;
    }

    return false;
  };

  const styledComponentsProps = {
    isTouched: false,
    isFocused: false,
    hasError: !disabled ? !!errors[name] || errorMessage != null || (handleGetValue() && !hasOptionValue()) : false,
    hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
    isValid: !disabled ? (!errors[name] && hasOptionValue()) || hasDefaultValue() : false,
  };

  return (
    <Container>
      <StyledInputWrapper {...styledComponentsProps} style={{ padding: 0 }}>
        <SelectContent type="button" onClick={() => setOpenOptions(!openOptions)} isOptionSelected={optionSelected?.label != null}>
          <input id={`#select-${name}`} {...register(name)} value={optionSelected?.label} />
          <span>{optionSelected == null ? placeholder : optionSelected.label}</span>
          {!styledComponentsProps.hasError && !styledComponentsProps.isValid && (openOptions ? <FiChevronUp /> : <FiChevronDown />)}

          {errorMessage && !styledComponentsProps.hasError && showErrorIcon !== true && (
            <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
          )}

          {styledComponentsProps.isValid && !styledComponentsProps.hasError && showErrorIcon !== true && !errorMessage && (
            <i className="icon-check" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
          )}

          {showErrorIcon === true && (
            <i className="icon-status-icon" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
          )}

          {styledComponentsProps.hasError && showErrorIcon !== true && (
            <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
          )}
        </SelectContent>
      </StyledInputWrapper>
      {openOptions && (
        <OptionContainer>
          {options?.map(option => (
            <Option
              key={option.value}
              type="button"
              onClick={() => {
                setOptionSelected(option);
                setOpenOptions(false);
              }}
            >
              <span>{option.label}</span>
            </Option>
          ))}
        </OptionContainer>
      )}
    </Container>
  );
};

import { DashboardClient } from 'features/client/dashboard';
import RegisterGuarantor from 'features/client/registerGuarantor';
import { Route, Switch } from 'react-router-dom';
import ClientPrivateRoutes, { StepLevelStatus } from '../client-private-routes';

const ClientRoutes = ({ match }) => {
  return (
    <Switch>
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/financing`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/financing/process`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/personalData`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/personalData/edit`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/documents`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/guarantor`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/guarantor/list`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/guarantor/info`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/guarantor/edit`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/register/guarantor`}
        component={RegisterGuarantor}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/contracts`}
        component={DashboardClient}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.FINANCING_STARTED}
        exact
        path={`${match.url}/dashboard/payment`}
        component={DashboardClient}
      />
    </Switch>
  );
};

export default ClientRoutes;

import styled from 'styled-components';

export const Card = styled.div`
  min-width: 276px;
  min-height: 314px;
  background: #f9f3ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 41px;
  }
  span {
    width: 211px;
    height: 58px;
    font-family: 'Outfit' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 160.02% !important;
    text-align: center !important;
    color: #80008c !important;
    margin-top: 25px !important;
    margin-bottom: 0 !important;
  }
  @media (max-width: 1175px) {
    span {
      align-self: center !important;
    }
  }
  @media (max-width: 600px) {
    min-width: 156px;
    max-width: 156px;
    max-height: 180px;
    min-height: 180px;
    align-items: center !important;
    img {
      width: 100px;
      margin-top: 20px;
    }
    span {
      width: 124px !important;
      height: 32px !important;
      font-weight: 600 !important;
      font-size: 12px !important;
      margin-top: 20px !important;
      align-self: center !important;
    }
  }
`;

export const ContainerAllInfosToShowBenefits = styled.div`
  max-width: 1176px;
  min-width: 1176px;
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-bottom: 18px;
    img {
      margin-top: 15px;
    }
    span {
      color: #80008c;
    }
  }
  @media (max-width: 1175px) {
    min-width: 592px;
    max-width: 592px;
    span {
      font-size: 30px;
      width: 206px;
      height: 60px;
      margin-top: 48px;
      font-size: 24px;
      line-height: 30px;
      align-self: flex-start;
      img {
        margin-top: -75px;
        margin-left: 205px;
        width: 42px;
      }
    }
    align-items: center;
  }
  @media (max-width: 600px) {
    min-width: 320px;
    max-width: 320px;
  }
`;

export const ContainerCard = styled.div`
  width: 100%;
  min-width: 1176px;
  display: flex;
  justify-content: space-between;
  div {
    span {
      margin-top: 54px !important;
    }
  }
  div + div {
    span {
      margin-top: 48px !important;
    }
  }
  div + div + div {
    span {
      margin-top: 28px !important;
    }
  }
  div + div + div + div {
    span {
      margin-top: 38px !important;
    }
  }
  @media (max-width: 1175px) {
    min-width: 592px;
    max-width: 592px;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    div {
      margin: 0 20px 20px 0;
    }
  }
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 156px 156px;
    grid-template-rows: 180px 180px;
    gap: 20px;
    min-width: 320px;
    max-width: 320px;
    margin-bottom: 20px;
    div {
      span {
        margin-top: 44px !important;
      }
    }
    div + div {
      span {
        margin-top: 20px !important;
      }
    }
    div + div + div {
      span {
        width: 140px;
        margin-top: 27px !important;
      }
    }
    div + div + div + div {
      span {
        width: 140px;
        margin-top: 20px !important;
      }
    }
  }
  @media (max-width: 333px) {
    gap: 8px;
    margin-bottom: 20px;
  }
`;

export const ContainerInfoBenefits = styled.div`
  width: 100%;
  min-height: 488px;
  display: flex;
  justify-content: center;
`;

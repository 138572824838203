import styled from 'styled-components';

export const TitleTextInput = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  text-align: left;
  margin-bottom: 7px;
  span {
    color: #f0654a;
  }
`;

export const ContainerInputs = styled.div`
  width: 976px;
  display: flex;
  margin-top: 23px;
`;

export const ContainerInputAndText = styled.div`
  width: 475px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

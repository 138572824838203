import dashboardApi from 'api/dashboard-api';
import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { Contract } from 'model/contract';
import HttpStatus from 'model/enums/http-status';
import { Financing } from 'model/financing';
import { Graph } from 'model/graph';
import { Pageable, PageableResponse } from 'model/pageable';
import { Payment } from 'model/payment';

export const DashboardService = () => {
  const getRegisterTableData = async (params): Promise<PageableResponse<Client>> => {
    try {
      const result: AxiosResponse<PageableResponse<Client>> = await dashboardApi.getRegisterTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getFinancingTableData = async (params): Promise<PageableResponse<Financing>> => {
    try {
      const result: AxiosResponse<PageableResponse<Financing>> = await dashboardApi.getFinancingTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getContractTableData = async (params): Promise<PageableResponse<Contract>> => {
    try {
      const result: AxiosResponse<PageableResponse<Contract>> = await dashboardApi.getContractTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getPaymentTableData = async (params): Promise<PageableResponse<Payment>> => {
    try {
      const result: AxiosResponse<PageableResponse<Payment>> = await dashboardApi.getPaymentTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getGraphData = async (page: string): Promise<Graph> => {
    try {
      const result: AxiosResponse<Graph> = await dashboardApi.getGraphData(page);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    getRegisterTableData,
    getFinancingTableData,
    getContractTableData,
    getPaymentTableData,
    getGraphData,
  };
};

export default DashboardService();

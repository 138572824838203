import registerApi from 'api/register-api';
import guarantor from 'api/guarantor-api';
import { AxiosResponse } from 'axios';
import { FirstSignUp } from 'model/first-signup';
import HttpStatus from 'model/enums/http-status';
import { AttachmentResponse } from 'model/files';
import { Client } from 'model/client';

export const RegisterService = () => {
  const initialRegistration = async (obj: FirstSignUp): Promise<FirstSignUp> => {
    try {
      const result: AxiosResponse<FirstSignUp> = await registerApi.initialRegistration(obj);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const checkIfEmailAlreadyRegistered = async (email: string): Promise<boolean> => {
    try {
      const result: AxiosResponse<boolean> = await registerApi.checkIfEmailAlreadyRegistered(email);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getDocuments = async (): Promise<AttachmentResponse> => {
    try {
      const result: AxiosResponse<AttachmentResponse> = await registerApi.getDocuments();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientData = async (): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await registerApi.getClientPersonalDatas();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const toFinancingStarted = async (): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await registerApi.toFinancingStarted();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    initialRegistration,
    checkIfEmailAlreadyRegistered,
    getDocuments,
    getClientData,
    toFinancingStarted,
  };
};

export default RegisterService();

import styled from 'styled-components';

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 62px;
  margin-bottom: 50px;
`;

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  margin-bottom: 32px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

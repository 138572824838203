import styled from 'styled-components';

export const Container = styled.div`
  width: 976px;
  min-height: 176px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const ContainerTitleAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #2b1a48;
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  min-height: 300px;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
`;

export const ContainerData = styled.div`
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-end;
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
  span {
    width: 262px;
    margin-bottom: 2px;
  }
  span + span {
    width: 169px;
  }
  span + span + span {
    width: 173px;
  }
  span + span + span + span {
    width: 284px;
  }
  img {
    margin-bottom: 5px;
    margin-right: 30px;
    cursor: pointer;
  }
  img + img {
    margin-right: 0;
  }
`;

export const TitleList = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2b1a48;
`;

export const DataListText = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.666667px;
  color: #333333;
`;

import styled from 'styled-components';

export const ContainerItems = styled.div`
  height: 50px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  position: fixed;
  background: white;

  z-index: 10;
`;

export const TitleHeader = styled.span`
  margin-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`;

import React from 'react';
import {
  FooterBox,
  FooterDownloadLink,
  FooterDownloadLinksBox,
  Footer,
  FooterSocialLinks,
  FooterSocialLinksBox,
  FooterLinks,
  FooterTitle,
  FooterLinkBox,
  FooterImageBox,
  FooterImg,
  FooterPlantTitle,
  FooterContent,
  ContainerCopyright,
} from './styles';

import facebook from '../../images/Facebook.svg';
import instagram from '../../images/Instagram.svg';
import twitter from '../../images/Twitter.svg';
import linkedin from '../../images/Linkedin.svg';
import youtube from '../../images/Youtube.svg';
import LogoFooter from '../../images/LogoFooter.svg';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Button';
import { useHistory } from 'react-router-dom';

interface LandingPageFooterProps {}

const LandingPageFooter: React.FC<LandingPageFooterProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Footer>
        <FooterContent>
          <FooterLinkBox style={{ marginLeft: '132px' }}>
            <img src={LogoFooter} style={{ width: '200px', height: '70px' }} />
            <FooterSocialLinksBox>
              <a href="https://pt-br.facebook.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={facebook} />
              </a>
              <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={instagram} />
              </a>
              <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={youtube} />
              </a>
              <a href="https://br.linkedin.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={linkedin} />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={twitter} />
              </a>
            </FooterSocialLinksBox>
          </FooterLinkBox>

          <FooterLinkBox theme={{ width: '302px', margin: '0px 88px 0px 206px' }}>
            <FooterTitle>{t('footer.title.contactUs')}</FooterTitle>
            <FooterLinks href="mailto: financiamentodeintercambio@seda.io">{t('footer.options.email')}</FooterLinks>
            <FooterLinks href="tel:(11) 4506-0336">{t('footer.options.phone')}</FooterLinks>
            <FooterSocialLinksBox>
              <a href="https://pt-br.facebook.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={facebook} />
              </a>
              <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={instagram} />
              </a>
              <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={youtube} />
              </a>
              <a href="https://br.linkedin.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={linkedin} />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                <FooterSocialLinks src={twitter} />
              </a>
            </FooterSocialLinksBox>
          </FooterLinkBox>

          <FooterLinkBox theme={{ width: '176px', margin: '10px 58px 0px 0px' }} style={{ height: '150px' }}>
            <FooterTitle style={{ marginBottom: '0' }}>{t('footer.title.requestFinancing')}</FooterTitle>
            <span>{t('footer.texts.requestFinancing')}</span>
            <a href="#approval">{t('footer.texts.button')}</a>
          </FooterLinkBox>
        </FooterContent>
        <ContainerCopyright>
          <span>{t('footer.texts.copyright')}</span>
        </ContainerCopyright>
      </Footer>
    </>
  );
};

export default LandingPageFooter;

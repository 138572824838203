import { Graph } from 'model/graph';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dashboardService from 'services/dashboard-service';
import GraphIcon from '../../images/graph-icon.svg';
import { Bar, Container, DataItem, Title, Value, ValueContainer } from './styles';

const GeneralGraphInfos = () => {
  const { t } = useTranslation();

  const [graphData, setGraphData] = useState<Graph>();

  useEffect(() => {
    const callDashboardService = async () => {
      const values = await dashboardService.getGraphData('general');
      setGraphData(values ?? []);
    };

    callDashboardService();
  }, []);

  const graphHeader = [
    { title: t('menu.dashboard.register'), propName: 'registrationStatus' },
    { title: t('menu.dashboard.financing'), propName: 'creditAnalysisStatus' },
    { title: t('menu.dashboard.contract'), propName: 'contractStatus' },
    { title: t('menu.dashboard.payment'), propName: 'financingStatus' },
  ];

  return (
    <Container>
      {graphHeader.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <DataItem key={i}>
              <Title>{item.title}</Title>
              <ValueContainer>
                <img src={GraphIcon} />
                <Value>{graphData && graphData[item.propName] !== 0 ? graphData[item.propName] : '-'}</Value>
              </ValueContainer>
            </DataItem>
            {i < 3 && <Bar />}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default GeneralGraphInfos;

import { useTranslation } from 'react-i18next';
import { StatusContainer } from './styles';

const Status = ({ title }) => {
  const { t } = useTranslation();
  const renderStatus = () => {
    switch (title) {
      case 'NEW':
        return (
          <StatusContainer backgroundColor="#FB8022" primaryTextColor>
            <span>{t('global.status.new')}</span>
          </StatusContainer>
        );
        break;
      case 'APPROVED':
        return (
          <StatusContainer backgroundColor="#2AB87A" primaryTextColor>
            <span>{t('global.status.approved')}</span>
          </StatusContainer>
        );
        break;
      case 'ACCEPTED':
        return (
          <StatusContainer backgroundColor="#FB8022" primaryTextColor>
            <span>{t('global.status.accepted')}</span>
          </StatusContainer>
        );
        break;
      case 'PROGRESS':
        return (
          <StatusContainer backgroundColor="#FFFFAA">
            <span>{t('global.status.progress')}</span>
          </StatusContainer>
        );
        break;
      case 'IN_PROGRESS':
        return (
          <StatusContainer backgroundColor="#FFFFAA">
            <span>{t('global.status.inProgress')}</span>
          </StatusContainer>
        );
        break;
      case 'REJECTED':
        return (
          <StatusContainer backgroundColor="#F0654A" primaryTextColor>
            <span>{t('global.status.rejected')}</span>
          </StatusContainer>
        );
        break;
      case 'CANCELLED':
        return (
          <StatusContainer backgroundColor="#F0654A" primaryTextColor>
            <span>{t('global.status.cancelled')}</span>
          </StatusContainer>
        );
        break;
      case 'CREDIT_ANALYSIS':
        return (
          <StatusContainer backgroundColor="#FFFFAA">
            <span>{t('global.status.creditAnalysis')}</span>
          </StatusContainer>
        );
        break;
      case 'PRE_REJECTED':
        return (
          <StatusContainer backgroundColor="#E8DFEE">
            <span>{t('global.status.preRejected')}</span>
          </StatusContainer>
        );
        break;
      case 'ADJUSTED':
        return (
          <StatusContainer backgroundColor="#E8DFEE">
            <span>{t('global.status.adjusted')}</span>
          </StatusContainer>
        );
        break;
      case 'ADJUST':
        return (
          <StatusContainer backgroundColor="#E8DFEE">
            <span>{t('global.status.adjust')}</span>
          </StatusContainer>
        );
        break;
      case 'VALIDATION':
        return (
          <StatusContainer backgroundColor="#FB8022" primaryTextColor>
            <span>{t('global.status.validation')}</span>
          </StatusContainer>
        );
        break;
      case 'UPDATED':
        return (
          <StatusContainer backgroundColor="#E8DFEE">
            <span>{t('global.status.updated')}</span>
          </StatusContainer>
        );
        break;
      case 'ACTIVE':
        return (
          <StatusContainer backgroundColor="transparent" active>
            <span>{t('global.status.active')}</span>
          </StatusContainer>
        );
        break;
      case 'INACTIVE':
        return (
          <StatusContainer backgroundColor="transparent" inactive>
            <span>{t('global.status.inactive')}</span>
          </StatusContainer>
        );
        break;
      case 'PAID':
        return (
          <StatusContainer backgroundColor="#2AB87A" primaryTextColor>
            <span>{t('global.status.paid')}</span>
          </StatusContainer>
        );
        break;
      case 'FINISHED':
        return (
          <StatusContainer backgroundColor="#2AB87A" primaryTextColor>
            <span>{t('global.status.finished')}</span>
          </StatusContainer>
        );
        break;
      case 'PENDING':
        return (
          <StatusContainer backgroundColor="#FFFFAA">
            <span>{t('global.status.pending')}</span>
          </StatusContainer>
        );
        break;
      case 'CREATED':
        return (
          <StatusContainer backgroundColor="#FFFFAA">
            <span>{t('global.status.pending')}</span>
          </StatusContainer>
        );
        break;
      case 'OVERDUE':
        return (
          <StatusContainer backgroundColor="#F0654A" primaryTextColor>
            <span>{t('global.status.overdue')}</span>
          </StatusContainer>
        );
        break;
      default:
        break;
    }
  };
  return <>{renderStatus()}</>;
};

export default Status;

import { Button, ButtonContainer } from 'components/guarantors-tabs/styles';
import { Client } from 'model/client';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface ClientGeneralDataProps {
  clientContent: Client;
  handleOpenScoreModal: (id) => void;
}

const ClientGeneralData: React.FC<ClientGeneralDataProps> = props => {
  const { t } = useTranslation();
  const { clientContent, handleOpenScoreModal } = props;

  return (
    <>
      <DataContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.name')}</ContentLabel>
          <ContentValue>{clientContent?.name ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.email')}</ContentLabel>
          <ContentValue>{clientContent?.email ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.phone')}</ContentLabel>
          <ContentValue>{StringUtils.phoneMask(clientContent?.phone) ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <BreakLine />
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.cpf')}</ContentLabel>
          <ContentValue>{StringUtils.cpfMask(clientContent?.cpf) ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.occupation')}</ContentLabel>
          <ContentValue>{clientContent?.occupation?.name ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.income')}</ContentLabel>
          <ContentValue>
            {clientContent?.income ? StringUtils.currencyMask(clientContent?.income?.toFixed(2)) : 'Não informado'}
          </ContentValue>
        </ContentContainer>
        <BreakLine />
        <ContentContainer>
          <ContentLabel>{t('clientFinancing.creditData.clientScore')}</ContentLabel>
          <ContentValue>{clientContent?.creditScore?.score ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.rg')}</ContentLabel>
          <ContentValue>{clientContent?.rg ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.birth')}</ContentLabel>
          <ContentValue>
            {clientContent?.birth ? StringUtils.dateFormatMask(clientContent?.birth?.toString()) : 'Não informado'}
          </ContentValue>
        </ContentContainer>
      </DataContainer>
      <ButtonContainer>
        <Button onClick={() => handleOpenScoreModal(clientContent.id)}>
          <span>{t('global.button.calculateScore')}</span>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ClientGeneralData;

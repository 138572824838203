import styled from 'styled-components';
interface ActiveProps {
  active?: boolean;
}
export const Container = styled.div`
  width: 125px;
  height: 148px;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomStep = styled.div<ActiveProps>`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: ${props => (props.active ? '#e6d0f5' : 'transparent')};

  .Complete-StepIcon-circle {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #80008c;
  }
  .StepIcon-completedIcon {
    color: #fff;
    font-size: 11px;
  }
  .StepIcon-circle {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => (props.active ? '#80008c' : '#E5E7E7')};
  }
`;

export const StepLabel = styled.span<ActiveProps>`
  color: ${props => (props.active ? '#80008C' : '#979797')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
`;

export const Conector = styled.div<ActiveProps>`
  width: 0px;
  height: 20px;
  border: 1px solid ${props => (props.active ? '#80008C' : '#cccccc')};
  margin-left: 10px;
`;

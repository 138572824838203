import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';

import StyledButton from 'components/StyledButton';
import { CloseButton, CloseContainer, ConfirmContainer, ImageContainer, MessageText, Title } from './styles';
import { useTranslation } from 'react-i18next';

interface SimpleModalProps {
  isOpen: boolean;
  imageSrc: string;
  titleText: string;
  messageText: string;
  confirmText: string;
  disabled?: boolean;
  onCloseModal: () => void;
  handleConfirm: () => void;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '420px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

export const SimpleModal = ({
  isOpen,
  onCloseModal,
  imageSrc,
  titleText,
  messageText,
  confirmText,
  handleConfirm,
  disabled,
}: SimpleModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <ImageContainer>
        <img src={imageSrc} />
      </ImageContainer>
      <Title>{t(titleText)}</Title>
      <MessageText>{messageText}</MessageText>
      <ConfirmContainer>
        <StyledButton text={confirmText} primary largeSize onClick={handleConfirm} />
      </ConfirmContainer>
    </Modal>
  );
};

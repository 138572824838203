import { truncate } from 'lodash';
import { Client } from 'model/client';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import ClipBlack from '../../../../../../images/clipBlack.svg';
import { BreakLine, ContainerAttachment, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';
import NoDataPage from './no-data-page';

interface ClientAttachmentsDataProps {
  clientContent: Client;
}

enum ClientAttachmentsTypes {
  PROOF_RESIDENCE = 'PROOF_RESIDENCE',
  IDENTIFICATION_CARD = 'IDENTIFICATION_CARD',
  PROOF_INCOME = 'PROOF_INCOME',
}

const ClientAttachmentsData: React.FC<ClientAttachmentsDataProps> = props => {
  const { t } = useTranslation();
  const { clientContent } = props;

  return clientContent.attachments ? (
    <DataContainer>
      {clientContent.attachments.map((item, i) => {
        const typeVerification = () => {
          switch (item.type) {
            case ClientAttachmentsTypes.PROOF_INCOME:
              return t('clientAttachments.proofIncome');
              break;
            case ClientAttachmentsTypes.PROOF_RESIDENCE:
              return t('clientAttachments.proofResidence');
              break;
            case ClientAttachmentsTypes.IDENTIFICATION_CARD:
              return t('clientAttachments.rg');
              break;
            default:
              break;
          }
        };
        return (
          <>
            <ContentContainer key={i}>
              <ContentLabel>{typeVerification()}</ContentLabel>
              <ContainerAttachment>
                <img src={ClipBlack} />
                <a href={item.presignedUrl} target="_blank">
                  {item.fileName && truncate(item.fileName, { length: 75 })}
                </a>
              </ContainerAttachment>
            </ContentContainer>
            {i < 2 && <BreakLine key={i} />}
          </>
        );
      })}
    </DataContainer>
  ) : (
    <NoDataPage tab="Attachments" />
  );
};

export default ClientAttachmentsData;

import { useTranslation } from 'react-i18next';
import { Container, TitleContainer } from './styles';
import { useRegisterForm } from 'provider/RegisterForm';
import Airplane from '../../images/airplane.svg';
import Error from '../../images/error.svg';
import { SimpleModal } from 'components/SimpleModal';
import { useHistory } from 'react-router-dom';
interface Modal {
  img: string;
  title: string;
  subtitle?: string;
  buttonTitle: string;
  route?: string;
  goBack?: () => void;
}
export interface StepTitles {
  title: string;
  subtitle?: string;
}
interface RegisterFormProps {
  formsToRender: JSX.Element[];
  modalInfos: Modal;
  stepTitles: StepTitles[];
}

export const RegisterForm = ({ formsToRender, stepTitles, modalInfos }: RegisterFormProps) => {
  const { actualStep, isOpenEndModal, handleSetEndModal, registerStatus, resetStates } = useRegisterForm();
  const history = useHistory();
  const { t } = useTranslation();

  const sucess = registerStatus === '201';

  return (
    <>
      <Container>
        <TitleContainer>
          {stepTitles[actualStep].title && <span>{stepTitles[actualStep].title}</span>}
          {stepTitles[actualStep]?.subtitle && <span>{stepTitles[actualStep].subtitle}</span>}
        </TitleContainer>

        <div>{formsToRender[actualStep]}</div>
      </Container>

      <SimpleModal
        isOpen={isOpenEndModal}
        onCloseModal={modalInfos.goBack ? modalInfos.goBack : handleSetEndModal}
        imageSrc={sucess ? modalInfos.img : Error}
        titleText={sucess ? modalInfos.title : t('register.modal.errorTitle')}
        messageText={sucess ? modalInfos?.subtitle ?? '' : t('register.modal.errorMessage')}
        confirmText={modalInfos.buttonTitle}
        handleConfirm={() => {
          if (sucess && modalInfos.route) {
            history.push(modalInfos.route);
          } else if (sucess && modalInfos.goBack) {
            modalInfos.goBack();
          }
          handleSetEndModal();
          resetStates();
        }}
      />
    </>
  );
};

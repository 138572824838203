import { Client } from 'model/client';
import { Course } from 'model/courses';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface ClientDataProps {
  exchangeData: Course;
}
const OBJECTIVE_OPTIONS = [
  { label: 'Estudar', value: 'STUDY' },
  { label: 'Estudar e Trabalhar', value: 'WORK_STUDY' },
];

const DURATION_OPTIONS = [
  { label: '1 a 3 meses', value: 'ONE_TO_THREE_MONTHS' },
  { label: '3 a 6 meses', value: 'THREE_TO_SIX_MONTHS' },
  { label: '6 a 12 meses', value: 'SIX_TO_TWELVE_MONTHS' },
  { label: 'Mais de 12 meses', value: 'LONGER_THAN_TWELVE_MONTHS' },
];

const ClientExchangeData: React.FC<ClientDataProps> = props => {
  const { t } = useTranslation();

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.exchangeData.objective')}</ContentLabel>
        <ContentValue>
          {props.exchangeData?.objective
            ? OBJECTIVE_OPTIONS.find(obj => obj.value === props.exchangeData.objective)?.label
            : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.exchangeData.language')}</ContentLabel>
        <ContentValue>{props.exchangeData?.language?.name ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.exchangeData.exchangeValue')}</ContentLabel>
        <ContentValue>
          {props.exchangeData?.courseValue ? StringUtils.currencyMask(props.exchangeData?.courseValue?.toFixed(2)) : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <BreakLine />
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.exchangeData.duration')}</ContentLabel>
        <ContentValue>
          {props.exchangeData?.duration ? DURATION_OPTIONS.find(obj => obj.value === props.exchangeData.duration)?.label : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.exchangeData.courseStart')}</ContentLabel>
        <ContentValue>
          {props.exchangeData?.startDate ? StringUtils.dateFormatMask(props.exchangeData?.startDate?.toString()) : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer />
    </DataContainer>
  );
};

export default ClientExchangeData;

import styled from 'styled-components';

export const ContainerFinancing = styled.div`
  width: 377px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 22px;
  span {
    margin-left: 16px;
  }
  img {
    width: 14px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const SelectedPartnersContainer = styled.div`
  width: 377px;
`;

import ButtonComponent from 'components/Button';
import { FormPersonalData } from 'components/formPersonalData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { GuarantorProps } from 'model/guarantor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContainerButtons, TitleText } from './styles';

export interface Schema {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  income: string;
  office: string;
  birth: string;
  zipcode: string;
  address: string;
  district: string;
  number: string;
  city: string;
  state: string;
  complement: string;
  rg: string;
  password?: string;
  attachmentIdentification: File;
  attachmentIncome: File;
  attachmentAddress: File;
}

interface Props {
  schema: any;
  onSubmit: (data: Schema) => void;
  editData?: GuarantorProps | Client;
  setAddressOccupationIds: (ids: AddressOccupationIds) => void;
  addressOccupationIds?: AddressOccupationIds;
}

export const ContainerEditData = ({ schema, onSubmit, editData, setAddressOccupationIds, addressOccupationIds }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <ReactHookFormProvider schema={schema} onSubmit={onSubmit}>
        <FormPersonalData personalData={editData} setInfoIds={setAddressOccupationIds} infoIds={addressOccupationIds} />
        <ContainerButtons>
          <ButtonComponent
            width="123px"
            height="47px"
            background="transparent"
            border="none"
            borderRadius="none"
            color="#80008C"
            text="global.button.cancel"
            weight="600"
            size="18px"
            margin="0 24px 0 0"
            onClick={() => history.goBack()}
          />
          <ButtonComponent
            width="101px"
            height="47px"
            background="#80008C"
            border="none"
            borderRadius="8px"
            color="#fff"
            text="global.button.save"
            weight="600"
            size="18px"
            type="submit"
          />
        </ContainerButtons>
      </ReactHookFormProvider>
    </>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 5px, 12px;
  gap: 8px;
  width: 326px;
  height: 51px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
`;
export const DataItem = styled.div`
  /* width: 54px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ::nth-child(2) {
    width: 81px;
  }
`;
export const Title = styled.span`
  /* width: 54px; */
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
`;
export const ValueContainer = styled.div`
  display: flex;
`;
export const Value = styled.span`
  margin-left: 5px;
  width: 23px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const Bar = styled.div`
  width: 1px;
  height: 41px;
  border-left: 1px solid #cccccc;
`;

import styled from 'styled-components';

export const FooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 37px;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  background-color: #fff;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
  }
`;

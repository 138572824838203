import styled from 'styled-components';

interface Props {
  diraction?: 'column' | 'row';
  size?: string;
  height?: string;
  width?: string;
  align?: 'flex-start' | 'center' | 'flex-end';
  margin?: string;
  color?: string;
  isDisabled?: boolean;
}

export const Container = styled.div`
  width: 376px;
  height: 510px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SimulatorTitle = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2b1a48;
  margin-top: 25px;
`;

export const ContainerInfos = styled.div<Props>`
  display: flex;
  flex-direction: ${({ diraction }) => diraction};
  justify-content: space-around;
  align-items: center;
  width: 336px;
  min-height: ${({ height }) => height};
  background: #fafafa;
  border-radius: 8px;
  margin: ${({ margin }) => margin};
`;

export const TextTitle = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #7f7f7f;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const TextInfo = styled.span<Props>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => size};
  line-height: 24px;
  color: ${({ color }) => (color ? color : '#2b1a48')};
  svg {
    margin-bottom: 7px;
    cursor: pointer;
  }
`;

export const ContainerTitleAndInfo = styled.div<Props>`
  max-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  margin: ${({ margin }) => margin};
`;

export const Icon = styled.img`
  margin-right: 3px;
`;

export const Line = styled.div<Props>`
  width: ${({ width }) => (width ? width : '282px')};
  min-height: 1px;
  background-color: #d8d8d8;
  margin: ${({ margin }) => (margin ? margin : '13px 0')};
`;

export const ContainerButton = styled.div`
  display: flex;
  margin-top: 22px;
`;

export const InputApprovedValue = styled.input<Props>`
  width: 140px;
  border: none;
  background-color: transparent;
  border-bottom: ${({ isDisabled }) => isDisabled && '1px solid #d8d8d8'};
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #2b1a48;
`;

export const ErrorMessage = styled.span`
  color: #e40b21;
  font-size: 12px;
`;

import ButtonComponent from 'components/Button';
import React, { useEffect, useState } from 'react';
import { Container, ContainerData, ContainerList, ContainerTitleAndButton, DataListText, TitleList } from './styles';
import Edit from '../../images/penEdit.svg';
import Delete from '../../images/deleteTrash.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { useFinancing } from 'provider/Financing';

import LinkedGuarantorModel from 'components/Modais/LinkedGuarantorModel';
import guarantorApi from 'api/guarantor-api';
import courseService from 'services/course.service';
import guarantorService from 'services/guarantor.service';

interface Props {
  allGuarantorsToshow: boolean;
}

const ListGuarantor = (props: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const history = useHistory();
  const courseId = parseInt(history.location.search.split('=')[1]);
  const { getLinkedGuarantors, getGuarantors, guarantors, linkedGuarantors, course } = useFinancing();
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.allGuarantorsToshow && !course?.guarantors) {
      getLinkedGuarantors(courseId);
    } else if (props.allGuarantorsToshow) {
      getGuarantors();
    }
  }, []);

  const removeLinkedGuarantor = async (courseId: number, id: number) => {
    await courseService.unlinkGuarantor(courseId, id);
    getLinkedGuarantors(courseId);
  };

  const removeGuarantor = async (id: number) => {
    await guarantorService.deleteGuarantor(id);
    getGuarantors();
  };

  const OpenModalVerification = () => {
    if (props.allGuarantorsToshow) {
      history.push('/client/register/guarantor');
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Container>
      <ContainerTitleAndButton>
        <span>{t('dashboardClient.guarantor.registerGuarantor')}</span>
        <ButtonComponent
          width="131px"
          height="30px"
          background="transparent"
          border="1px solid #80008C"
          borderRadius="8px"
          color="#80008C"
          text="dashboardClient.guarantor.button.newGuarantor"
          weight="600"
          size="14px"
          onClick={() => OpenModalVerification()}
        />
      </ContainerTitleAndButton>
      <ContainerList>
        <ContainerData>
          <TitleList>{t('dashboardClient.guarantor.guarantorData.name')}</TitleList>
          <TitleList>{t('dashboardClient.guarantor.guarantorData.cpf')}</TitleList>
          <TitleList>{t('dashboardClient.guarantor.guarantorData.phone')}</TitleList>
          <TitleList>{t('dashboardClient.guarantor.guarantorData.email')}</TitleList>
        </ContainerData>
        {linkedGuarantors.length > 0 &&
          linkedGuarantors.map((element, index) => {
            return (
              <ContainerData>
                <DataListText>{element.name}</DataListText>
                <DataListText>{StringUtils.cpfFormatter(element.cpf)}</DataListText>
                <DataListText>{StringUtils.phoneMask(element.phone)}</DataListText>
                <DataListText>{element.email}</DataListText>
                <img src={Edit} onClick={() => history.push(`/client/dashboard/guarantor/info?id=${element.id}`)} />
                <img src={Delete} onClick={() => removeLinkedGuarantor(courseId, element.id ? element?.id : 0)} />
              </ContainerData>
            );
          })}
        {guarantors.length > 0 &&
          guarantors.map((element, index) => {
            return (
              <ContainerData>
                <DataListText>{element.name}</DataListText>
                <DataListText>{StringUtils.cpfFormatter(element.cpf)}</DataListText>
                <DataListText>{StringUtils.phoneMask(element.phone)}</DataListText>
                <DataListText>{element.email}</DataListText>
                <img src={Edit} onClick={() => history.push(`/client/dashboard/guarantor/info?id=${element.id}`)} />
                <img src={Delete} onClick={() => removeGuarantor(element.id ? element?.id : 0)} />
              </ContainerData>
            );
          })}
      </ContainerList>
      <LinkedGuarantorModel isOpen={openModal} onCloseModal={() => setOpenModal(false)} />
    </Container>
  );
};

export default ListGuarantor;

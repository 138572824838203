import registerApi from 'api/register-api';
import ButtonComponent from 'components/Button';
import { ContainerAdminEditClient } from 'components/container-admin-edit-client';
import { ContainerAdminEditClientFinancing } from 'components/container-admin-edit-financing';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { parse } from 'date-fns';
import { id } from 'date-fns/locale';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/admin-edit-client-form';
import { useRegisterForm } from 'provider/RegisterForm';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clientProfileService from 'services/client-profile-service';
import courseService from 'services/course.service';
import { unMaskedCourseFieldsValues } from 'shared/util/course-utils';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer } from './styles';

interface IObjectives {
  label: string;
  value: 'WORK' | 'WORK_STUDY' | 'STUDY';
}

interface IDurantions {
  label: string;
  value: 'ONE_TO_THREE_MONTHS' | 'THREE_TO_SIX_MONTHS' | 'SIX_TO_TWELVE_MONTHS' | 'LONGER_THAN_TWELVE_MONTHS';
}
interface Schema {
  objective: string;
  language: string;
  courseValue: string;
  duration: string;
  startDate: string;
  approvedValue: string;
  downPaymentValue: string;
}

export const AdminEditClientFinancing = props => {
  const { t } = useTranslation();
  const { files, financingData, getCourseData } = useEditForm();
  const history = useHistory();

  const clientId = Number(props.match.params.id!);
  const courseId = Number(props.match.params.courseId!);

  const [addressOccupationIds, setAddressOccupationIds] = useState<AddressOccupationIds>();
  const [languageId, setLanguageId] = useState<number>(0);

  const objectives: IObjectives[] = [
    { label: t('clientFinancing.exchangeData.study'), value: 'STUDY' },
    { label: t('clientFinancing.exchangeData.workStudy'), value: 'WORK_STUDY' },
  ];

  const durations: IDurantions[] = [
    { label: t('clientFinancing.exchangeData.oneToThreeMonths'), value: 'ONE_TO_THREE_MONTHS' },
    { label: t('clientFinancing.exchangeData.threeToSixMonths'), value: 'THREE_TO_SIX_MONTHS' },
    { label: t('clientFinancing.exchangeData.sixToTwelveMonths'), value: 'SIX_TO_TWELVE_MONTHS' },
    { label: t('clientFinancing.exchangeData.longerThanTwelveMonths'), value: 'LONGER_THAN_TWELVE_MONTHS' },
  ];

  const schema = yup.object().shape({
    objective: yup.string().required(t('global.errorMessage.required')),
    language: yup.string().required(t('global.errorMessage.required')),
    courseValue: yup.string().required(t('global.errorMessage.required')),
    duration: yup.string().required(t('global.errorMessage.required')),
    startDate: yup.string().required(t('global.errorMessage.required')),
    approvedValue: yup.string(),
    downPaymentValue: yup.string(),
  });

  useEffect(() => {
    getCourseData(clientId, courseId);
  }, [clientId, courseId]);

  const sendNewCourseData = async (data: Schema) => {
    const guarantors = financingData.guarantors;

    const courseData: Course = {
      id: courseId,
      objective: objectives.find(objective => objective.label === data.objective)?.value ?? financingData.objective,
      language: { id: languageId } ?? financingData.language,
      courseValue: unMaskedCurrency(data.courseValue) ?? financingData.courseValue,
      duration: durations.find(duration => duration.label === data.duration)?.value ?? financingData.duration,
      startDate: StringUtils.returnDateFormatMask(data.startDate ?? financingData.duration ?? ''),
      financing: {
        id: Number(financingData.financing?.id),
        approvedValue: Number(financingData.financing?.approvedValue),
        downPaymentValue: unMaskedCurrency(data.downPaymentValue),
      },
      guarantors,
    };

    const unMaskedDatas = unMaskedCourseFieldsValues(courseData, true);
    unMaskedDatas.courseValue = Number(unMaskedDatas.courseValue) / 100;
    unMaskedDatas.financing = {
      ...unMaskedDatas.financing,
      downPaymentValue: Number(unMaskedDatas.financing?.downPaymentValue) / 100,
    };

    if (unMaskedDatas.language?.id === 0) {
      unMaskedDatas.language = financingData.language;
    }

    const res = await courseService.updateCourseData(unMaskedDatas);

    if (res) {
      history.replace(`/admin/dashboard/register/${clientId}/course/${courseId}`);
    }
  };

  const onHandleSubmit = res => {
    sendNewCourseData(res);
  };
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('clientRegisterEdit.financing')}</StyledEditHeader>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <Container>
            <ContainerAdminEditClientFinancing
              schema={schema}
              editData={financingData}
              setLanguageId={setLanguageId}
              onSubmit={onHandleSubmit}
              setAddressOccupationIds={setAddressOccupationIds}
              addressOccupationIds={addressOccupationIds}
            />
          </Container>
          <Footer>
            <ContainerButtons>
              <ButtonComponent
                width="117px"
                height="44px"
                background="transparent"
                border="none"
                borderRadius="none"
                color="#fff"
                text="global.button.cancel"
                weight="400"
                size="15px"
                onClick={() => history.goBack()}
              />
              <ButtonComponent
                width="150px"
                height="44px"
                background="#80008C"
                border="none"
                borderRadius="6px"
                color="#fff"
                text="global.button.save"
                weight="400"
                size="15px"
                type="submit"
              />
            </ContainerButtons>
          </Footer>
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import ActivateAccount from 'features/activate-account/activate-account';
import { AdminEditClientFinancing } from 'features/admin/dashboard/financing/edit/edit-financing';
import { AdminEditClient } from 'features/admin/dashboard/register/client/edit/edit-client';
import ChangePasswordAdmin from 'features/change-password/change-password';
import ChangedPasswordAdmin from 'features/change-password/changed-password/changed-password';
import ConfirmEmail from 'features/confirm-email/confirm-email';
import EmailSentAdmin from 'features/forgot-password/email-sent/email-sent';
import ForgotPasswordAdmin from 'features/forgot-password/forgot-password';
import Home from 'features/home';
import Login from 'features/login/login';
import Logout from 'features/logout/logout';
import { Register } from 'features/Register';
import { MinorRejectScreen } from 'features/Register/screens/MinorReject';
import search from 'features/search/search';
import { EditFormProvider } from 'provider/admin-edit-client-form';
import { ClientProfileIdsProvider } from 'provider/client-profile';
import { FinancingProvider } from 'provider/Financing';
import { RegisterFormProvider } from 'provider/RegisterForm';
import { SelectListsProvider } from 'provider/SelectLists';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/page-not-found';
import AdminRoutes from './admin';
import ClientRoutes from './client/index';
import PrivateRoute, { AccessLevel } from './private-routes';
import RegistrationRoutes from './Registration';

const AppRoutes = ({ match, isAuthenticated }) => {
  const appRoutesClassName = isAuthenticated ? 'has-header' : '';
  const adminAccessLevels: AccessLevel[] = [AccessLevel.ADMIN, AccessLevel.INTERNAL, AccessLevel.INTERNAL_ADMIN, AccessLevel.PARTNER];
  return (
    <div className={classNames([appRoutesClassName, 'App-routes-wrapper'])}>
      <div className={'App-routes-wrap'}>
        <Switch>
          <Route exact path={'/login'} component={Login} />
          <Route exact path={'/forgot-password'} component={ForgotPasswordAdmin} />
          <Route path={'/admin'} component={AdminRoutes} />
          <Route exact path={'/forgot-password/sent'} component={EmailSentAdmin} />
          <Route exact path={'/activate-account/:key'} component={ActivateAccount} />
          <Route exact path={'/confirm-email/:key'} component={ConfirmEmail} />
          <Route exact path={'/change-password/:key'} component={ChangePasswordAdmin} />
          <Route exact path={'/changed-password'} component={ChangedPasswordAdmin} />
          <Route exact path={match.url} component={Home} />
          <Route exact path={'/logout'} component={Logout} />
          <Route exact path={'/create-account'} component={Register} />
          <Route exact path={'/create-account/reject'} component={MinorRejectScreen} />
          <PrivateRoute exact path={'/search'} component={search} />
          <RegisterFormProvider>
            <SelectListsProvider>
              <FinancingProvider>
                <ClientProfileIdsProvider>
                  <PrivateRoute accessLevel={[AccessLevel.CLIENT]} path={'/client'} component={ClientRoutes} />
                  <PrivateRoute accessLevel={[AccessLevel.CLIENT]} path={'/client'} component={RegistrationRoutes} />
                </ClientProfileIdsProvider>
              </FinancingProvider>
            </SelectListsProvider>
          </RegisterFormProvider>
          <Route path={'*'} component={PageNotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default AppRoutes;

import React, { useState } from 'react';
import { ContainerButtons } from '../styles';
import people from '../../../images/threePeople.svg';
import ButtonComponent from 'components/Button';
import { useHistory } from 'react-router-dom';
import ListGuarantor from 'components/ListGuarantor';
import WarningContent from 'components/WarningContentFinancing';
import { SimpleModal } from 'components/SimpleModal';
import Check from '../../../images/modalCheckGreen.svg';
import LinkedGuarantorModel from 'components/Modais/LinkedGuarantorModel';
import courseApi from 'api/course-api';
import HttpStatus from 'model/enums/http-status';
import { GuarantorProps } from 'model/guarantor';
import { useTranslation } from 'react-i18next';

interface Props {
  guarantorRegistred: boolean;
  setStatusCourse: (status: 'NEW' | 'CREDIT_ANALYSIS' | 'ADJUST' | 'ADJUSTED' | 'APPROVED' | 'REJECTED') => void;
}

const WaitRegisterGuarantor = ({ guarantorRegistred, setStatusCourse }: Props) => {
  const [openModalRequest, setOpenModalRequest] = useState<boolean>(false);
  const [openModalLinkedGuarantor, setOpenModalLinkedGuarantor] = useState<boolean>(false);
  const history = useHistory();
  const courseId = parseInt(history.location.search.split('=')[1]);
  const { t } = useTranslation();

  const sendRequestToApproval = async () => {
    const res = await courseApi.courseAnalisys(courseId);
    if (res.status === HttpStatus.OK) {
      setOpenModalRequest(true);
    }
  };

  const nextStep = () => {
    setOpenModalRequest(false);
    setStatusCourse('CREDIT_ANALYSIS');
  };

  return (
    <>
      {guarantorRegistred && (
        <>
          <ListGuarantor allGuarantorsToshow={false} />
          <ButtonComponent
            width="257px"
            height="58px"
            background="#80008C"
            border="none"
            borderRadius="8px"
            color="white"
            text="dashboardClient.financing.buttons.sendRequest"
            margin="46px 8px 0"
            weight="600"
            size="24px"
            onClick={() => sendRequestToApproval()}
          />
        </>
      )}
      {!guarantorRegistred && (
        <>
          <WarningContent
            img={people}
            subtitle="dashboardClient.financing.subtextTitleGuarantor"
            description="dashboardClient.financing.descriptionGuarantor"
          />
          <ContainerButtons>
            <ButtonComponent
              width="257px"
              height="58px"
              background="transparent"
              border="1px solid #80008C"
              borderRadius="8px"
              color="#80008C"
              text="dashboardClient.financing.buttons.createGuarantor"
              margin="0 8px 0"
              weight="600"
              size="24px"
              onClick={() => setOpenModalLinkedGuarantor(true)}
            />
            <ButtonComponent
              width="257px"
              height="58px"
              background="#80008C"
              border="none"
              borderRadius="8px"
              color="white"
              text="dashboardClient.financing.buttons.sendRequest"
              weight="600"
              size="24px"
              onClick={() => sendRequestToApproval()}
            />
          </ContainerButtons>
        </>
      )}
      <SimpleModal
        isOpen={openModalRequest}
        onCloseModal={() => setOpenModalRequest(false)}
        imageSrc={Check}
        titleText={t('dashboardClient.financing.modal.sendTitle')}
        messageText=""
        confirmText={'OK'}
        handleConfirm={() => nextStep()}
      />
      <LinkedGuarantorModel isOpen={openModalLinkedGuarantor} onCloseModal={() => setOpenModalLinkedGuarantor(false)} />
    </>
  );
};

export default WaitRegisterGuarantor;

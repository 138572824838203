import styled from 'styled-components';

export const CircleContainer = styled.div`
  display: inline-block;
  border-radius: 100%;
  position: relative;
  width: 250px;
  height: 250px;
`;

export const PercentageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .percentage {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #333333;
  }
  .text {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
  }
`;

export const StyledCircle = styled.circle`
  transform-origin: 50% 50%;
`;

export const Svg = styled.svg`
  width: 250px;
  height: 250px;
  transform: rotate(-90deg);
`;

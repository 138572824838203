import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const ContainerTitleAndBar = styled.div`
  width: 977px;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitleButton = styled.div`
  display: flex;
  align-items: center;
  div {
    position: relative;
  }
`;

export const TitleText = styled.span`
  min-width: 364px;
  height: 40px;
  margin: 48px 0 24px 0;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const SubtitleText = styled.span`
  height: 40px;
  margin-top: 32px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #2b1a48;
  text-align: left;
  align-self: center;
`;

export const Description = styled.span`
  width: 420px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  margin-top: 8px;
  align-self: center;
`;

export const MainImage = styled.img`
  width: 352px;
  height: 231px;
  margin-top: 50px;
  align-self: center;
`;

export const ContainerButtons = styled.div`
  margin-top: 32px;
  display: flex;
`;

export const FooterStyled = styled.footer`
  width: 100%;
  height: 37px;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
  }
`;

import { ContainerItems } from 'components/styled-admin-header/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { APP_LOCAL_STORAGE_MENU_COLLAPSED } from '../../config/constants';
import ImgLogoWhite from '../../images/logo-white.svg';
import BurgerImg from '../../images/menu-hamburger.svg';
import { MinHeightScreeningContainer } from '../../themes/globalStyles';
import { ArrowMenu, MenuIcon, MenuItems, MenuLinkBackground, MenuSubLink } from './styles';

interface AdminHeaderProps {
  text?: string;
}
interface StyledProps {
  isCollapsed: boolean;
  isFlipped: boolean;
  hideSubroutes?: boolean;
  hideColor: boolean;
}

const Background = styled.div<Pick<StyledProps, 'isCollapsed'>>`
  width: ${props => (props.isCollapsed ? 55 : 200)}px;
  height: 100%;
  min-height: 100vh;
  background-color: #2b1a48;
  overflow: hidden;
  position: fixed;
  z-index: 11;
`;

const BurgerMenu = styled.div<Pick<StyledProps, 'isFlipped'>>`
  height: 68px;
  background-color: #80008c;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  ${props =>
    props.isFlipped
      ? css`
          padding: 0 14px;
        `
      : ''};

  .logo-img {
    width: 100px;
  }

  .burger-img {
    width: 32px;
    cursor: pointer;
  }

  ${props =>
    props.isFlipped
      ? css`
          .burger-img {
            transform: scaleX(-1);
          }
          .logo-img {
            display: none;
          }
        `
      : ''};
`;

const MenuLink = styled.div<Pick<StyledProps, 'hideColor' | 'hideSubroutes'>>`
  margin-bottom: 15px;

  a:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  ${props =>
    props.hideSubroutes
      ? css`
          ${MenuSubLink} {
            display: none !important;
          }
          ${ArrowMenu} {
            color: white;
          }
        `
      : ''}
`;

interface CollapsedMenuGlobalStyleProps {
  isCollapsed: boolean;
}
const CollapsedMenuGlobalStyle = createGlobalStyle<CollapsedMenuGlobalStyleProps>`
${MinHeightScreeningContainer} {
  padding-left: ${props => (props.isCollapsed ? 50 : 200)}px;
}
${ContainerItems} {
  padding-left: ${props => (props.isCollapsed ? 110 : 260)}px;
}
`;

const AdminHeader: React.FC<AdminHeaderProps> = props => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(window.localStorage.getItem(APP_LOCAL_STORAGE_MENU_COLLAPSED) === 'true');
  window.localStorage.setItem(APP_LOCAL_STORAGE_MENU_COLLAPSED, isCollapsed.toString());

  const dashboardMatch: boolean = useRouteMatch('/admin/dashboard') !== null;
  const generalMatch: boolean = useRouteMatch('/admin/dashboard/general') !== null;
  const registerMatch: boolean = useRouteMatch('/admin/dashboard/register') !== null;
  const financingMatch: boolean = useRouteMatch('/admin/dashboard/financing') !== null;
  const contractMatch: boolean = useRouteMatch('/admin/dashboard/contract') !== null;
  const paymentMatch: boolean = useRouteMatch('/admin/dashboard/payment') !== null;
  const teamMatch: boolean = useRouteMatch('/admin/team') !== null;
  const usersMatch: boolean = useRouteMatch('/admin/team/users') !== null;
  const consultantsMatch: boolean = useRouteMatch('/admin/team/consultants') !== null;

  return (
    <Background isCollapsed={isCollapsed}>
      <CollapsedMenuGlobalStyle isCollapsed={isCollapsed} />
      <BurgerMenu isFlipped={isCollapsed}>
        <img className="logo-img" src={ImgLogoWhite} />
        <img className="burger-img" src={BurgerImg} onClick={() => setIsCollapsed(!isCollapsed)} />
      </BurgerMenu>
      <MenuItems>
        <MenuLink className={dashboardMatch ? 'active' : ''} hideSubroutes={isCollapsed} hideColor={isCollapsed}>
          <MenuLinkBackground to="/admin/dashboard">
            <MenuIcon style={{ fontSize: '20px' }} className="icon-home" />
            {t('menu.dashboard.title')}
            <ArrowMenu className="icon-arrow-right" style={{ transform: `rotate(${dashboardMatch ? 90 : 0}deg)` }} />
          </MenuLinkBackground>
          <MenuSubLink className={generalMatch ? 'active' : ''} to="/admin/dashboard/general">
            {t('menu.dashboard.general')}
          </MenuSubLink>
          <MenuSubLink className={registerMatch ? 'active' : ''} to="/admin/dashboard/register">
            {t('menu.dashboard.register')}
          </MenuSubLink>
          <MenuSubLink className={financingMatch ? 'active' : ''} to="/admin/dashboard/financing">
            {t('menu.dashboard.financing')}
          </MenuSubLink>
          <MenuSubLink className={contractMatch ? 'active' : ''} to="/admin/dashboard/contract">
            {t('menu.dashboard.contract')}
          </MenuSubLink>
          <MenuSubLink className={paymentMatch ? 'active' : ''} to="/admin/dashboard/payment">
            {t('menu.dashboard.payment')}
          </MenuSubLink>
        </MenuLink>
        <MenuLink className={teamMatch ? 'active' : ''} hideColor={isCollapsed}>
          <MenuLinkBackground to="/admin/team">
            <MenuIcon style={{ fontSize: '20px' }} className="icon-people" />
            {t('menu.team.title')}
            <ArrowMenu className="icon-arrow-right" />
          </MenuLinkBackground>
          <MenuSubLink className={usersMatch ? 'active' : ''} to="/admin/team/users">
            {t('menu.team.users')}
          </MenuSubLink>
          <MenuSubLink className={consultantsMatch ? 'active' : ''} to="/admin/team/consultants">
            {t('menu.team.consultants')}
          </MenuSubLink>
        </MenuLink>
      </MenuItems>
    </Background>
  );
};

export default AdminHeader;

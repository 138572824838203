import styled, { css } from 'styled-components';

interface ButtonTabProps {
  active: boolean;
}
export const ButtonTab = styled.div<ButtonTabProps>`
  color: #505050;
  font-size: 14px;
  padding: 8px 15px 3px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  bottom: -1px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border: solid 1px #d8d8d8;
      background: white;
      color: #80008c;
      border-bottom: none;
    `}
`;

interface ButtonTitleProps {
  active: boolean;
}
export const ButtonTitle = styled.div<ButtonTitleProps>`
  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid transparent;
      height: 26px;
      margin-bottom: -3px;
    `}
`;
interface ContentItemsProps {
  borderTopLeftNone: boolean;
}
export const ContentItems = styled.div<ContentItemsProps>`
  background: white;
  padding: 4px 16px 16px 16px;
  border: solid 1px #d8d8d8;
  border-radius: 4px;

  &.remove-padding__content {
    padding: 0 !important;
  }

  ${props =>
    props.borderTopLeftNone &&
    css`
      border-top-left-radius: 0;
    `}
`;

interface ContentProps {
  disabled: boolean;
}
export const Content = styled.div<ContentProps>`
  ${props =>
    props.disabled &&
    css`
      display: none;
    `}
`;
export const ButtonEdit = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.541667px;
  color: #80008c;
  cursor: pointer;
`;

import { CircularProgress } from '@mui/material';
import ButtonComponent from 'components/Button';
import ContainerClientFirstSignUp from 'components/container-admin-create-client-first-signup.1';
import { Schema } from 'components/ContainerEditData';
import ProgressBar from 'components/radius-progress-bar';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { useQuery } from 'hook/route';
import { FirstSignUp } from 'model/first-signup';
import { useEditForm } from 'provider/admin-edit-client-form';
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import registerService from 'services/register-service';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer, Subtitle, Title } from './styles';
export const AdminCreateClientScore = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const clientId = props.match.params.id!;
  const query = useQuery();
  const score = Number(query.get('score'));

  const handleRedirectTo = () => {
    history.push({
      pathname: `/admin/new/client/${clientId}`,
      state: true,
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('clientRegisterEdit.title')}</StyledEditHeader>
        <Container>
          <ProgressBar progress={score} text={t('clientFinancing.creditData.clientScore')} score />
        </Container>
        <Footer>
          <ContainerButtons>
            <ButtonComponent
              width="150px"
              height="44px"
              background="#80008C"
              border="none"
              borderRadius="6px"
              color="#fff"
              text="clientRegister.saveButton"
              weight="400"
              size="15px"
              onClick={() => handleRedirectTo()}
            />
          </ContainerButtons>
        </Footer>
      </div>
    </div>
  );
};

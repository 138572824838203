import { AxiosResponse } from 'axios';
import { PageableResponse } from 'model/pageable';
import { Partner } from 'model/partner';
import { api } from './api';

interface beAPartner {
  companyName: string;
  email: string;
  phone: string;
  message: string;
}

export const partnerApi = () => {
  const beAPartner = (obj: beAPartner): Promise<AxiosResponse<void>> => {
    return api.post<void>('/internals/partner', obj);
  };
  const getPartners = (): Promise<AxiosResponse<PageableResponse<Partner>>> => {
    return api.get(`/internals/consultants`);
  };
  return {
    beAPartner,
    getPartners,
  };
};

export default partnerApi();

import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  span {
    font-size: 2rem;
    font-weight: 700;
    color: #2b1a48;
  }

  span + span {
    font-size: 1rem;
    color: #000;
    font-weight: 400;
  }
`;

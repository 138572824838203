import ButtonComponent from 'components/Button';
import { FormAttachmentsData } from 'components/form-attachments-data';
import { FormGeneralData } from 'components/form-general-data';
import { FormPersonalData } from 'components/formPersonalData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledTabs from 'components/styled-tabs/styled-tabs';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface Schema {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  income: string;
  office: string;
  birth: string;
  zipcode: string;
  address: string;
  district: string;
  number: string;
  city: string;
  state: string;
  complement: string;
  rg: string;
  password?: string;
  attachmentIdentification: File;
  attachmentIncome: File;
  attachmentAddress: File;
}

interface Props {
  schema: any;
  onSubmit: (data: Schema) => void;
  editData?: Client;
  setAddressOccupationIds: (ids: AddressOccupationIds) => void;
  addressOccupationIds?: AddressOccupationIds;
  newClient?: boolean;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
}

export const ContainerAdminEditClient = ({
  schema,
  onSubmit,
  editData,
  setAddressOccupationIds,
  addressOccupationIds,
  newClient = false,
  files,
  setFiles,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <StyledTabs
        editPage
        tabs={[
          {
            title: t('global.tabs.data'),
            content: (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
                <FormGeneralData
                  newClient={newClient}
                  personalData={editData}
                  setInfoIds={setAddressOccupationIds}
                  infoIds={addressOccupationIds}
                />
              </div>
            ),
          },
          {
            title: t('global.tabs.attachments'),
            content: (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
                <FormAttachmentsData files={files} setFiles={setFiles} personalData={editData} />
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

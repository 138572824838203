import registerApi from 'api/register-api';
import ButtonComponent from 'components/Button';
import ContainerClientFirstSignUp from 'components/container-admin-create-client-first-signup.1';
import { ContainerAdminEditClient } from 'components/container-admin-edit-client';
import { Schema } from 'components/ContainerEditData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { parse } from 'date-fns';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { FirstSignUp } from 'model/first-signup';
import { useEditForm } from 'provider/admin-edit-client-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clientProfileService from 'services/client-profile-service';
import registerService from 'services/register-service';
import { unMaskedFieldsValues } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer, Subtitle, Title } from './styles';

export const AdminCreateClientFirstSignUp = props => {
  const { t } = useTranslation();
  const { files, personalData } = useEditForm();
  const history = useHistory();

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')).email(t('global.errorMessage.email')),
    phone: yup.string().required(t('global.errorMessage.required')),
    cpf: yup.string().required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = res => {
    sendNewClientPersonalData(res);
  };

  const sendNewClientPersonalData = async (data: Schema) => {
    const clientData: FirstSignUp = {
      name: data.name,
      email: data.email,
      phone: data.phone.replace(/\W/g, ''),
      cpf: data.cpf.replace(/\W/g, ''),
    };

    registerService.initialRegistration(clientData).then(res => {
      history.replace(`/admin/new/client/${res.id}/created?score=${res.creditScore?.score}`);
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('clientRegisterEdit.title')}</StyledEditHeader>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <Container>
            <Title>{t('clientRegister.title')}</Title>
            <Subtitle>{t('clientRegister.subtitle')}</Subtitle>
            <ContainerClientFirstSignUp schema={schema} onSubmit={onHandleSubmit} />
          </Container>
          <Footer>
            <ContainerButtons>
              <ButtonComponent
                width="150px"
                height="44px"
                background="#80008C"
                border="none"
                borderRadius="6px"
                color="#fff"
                text="clientRegister.saveButton"
                weight="400"
                size="15px"
                type="submit"
              />
            </ContainerButtons>
          </Footer>
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

import styled from 'styled-components';

export const Caption = styled.div`
  padding: 8px 0;
  line-height: 13px;
  color: #404040;
  font-size: 13px;
  font-weight: lighter;
`;

export const Container = styled.div`
  width: 230px;
  min-height: 245px;
  padding: 16px 19px 14px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  z-index: 10;
  position: absolute;
  right: 12px;
  text-align: left;
  top: 30px;

  h1 {
    font-size: 13px;
  }

  ${Caption} + div {
    border-top: 1px solid #d8d8d8;
  }
`;

export const Content = styled.div``;

import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.9rem 0px;
`;

export const FormInputsContainer = styled.div`
  max-width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BackButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 1rem;
    color: #80008c;
    margin-left: 0.5rem;
  }
`;

export const ImageContainer = styled.div`
  margin-left: 30px;
`;

import styled from 'styled-components';

export const ContainerMenu = styled.aside`
  width: 200px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #2b1a48;
  z-index: 1;
`;

export const ContainerText = styled.div`
  width: 120px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const ContainerTextAndBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

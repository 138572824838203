import styled from 'styled-components';

export const ContainerFaq = styled.div`
  width: 100%;
  min-height: 700px;
  background: #fff;
  display: flex;
  justify-content: center;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 155px;
  text-align: left;
  position: relative;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-bottom: 18px;
    span {
      color: #80008c;
    }
  }
  span + span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    color: #4c4d4d;
    width: 376px;
    height: 116px;
  }
  img {
    position: absolute;
    top: 175px;
    left: 400px;
  }
  img + img {
    top: 45px;
    left: 222px;
  }
`;

export const ContainerQuestion = styled.div`
  width: 679px;
  height: 59px;
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f3ff;
  margin-bottom: 12px;
  cursor: pointer;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150.02%;
    color: #80008c;
    margin-bottom: 0;
  }
`;

export const ResponseQuestion = styled.p`
  width: 622px;
  margin-left: 24px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160.02%;
  color: #4c4d4d;
  @media (max-width: 1176px) {
    width: 500px;
  }
  @media (max-width: 550px) {
    width: 400px;
  }
  @media (max-width: 430px) {
    width: 280px;
  }
`;

export const ContainerContentFaq = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  @media (max-width: 1179px) {
    div {
      margin-right: 50px;
      div {
        width: 536px;
      }
    }
  }
  @media (max-width: 978px) {
    justify-content: center;
    div {
      margin-right: 0px;
      div {
        width: 536px;
        img {
          display: inline-block;
        }
      }
    }
    img {
      display: none;
    }
  }
  @media (max-width: 550px) {
    div {
      div {
        width: 416px;
      }
    }
  }
  @media (max-width: 430px) {
    div {
      span {
        font-size: 24px;
      }
      div {
        width: 320px;
        span {
          font-size: 16px;
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const TabContainer = styled.div`
  width: 490px;
  max-width: 490px;
`;
export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const TextTitle = styled.span`
  width: 64px;
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;
export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const ItemLink = styled.span`
  width: 58px;
  height: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.541667px;
  text-transform: uppercase;
  color: #404040;
`;

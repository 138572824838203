import { RegistrationStepsStatus } from 'model/enums/registration-status';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { StatusApprove } from './Approve';
import { StatusCorrections } from './Corrections';
import { StatusError } from './ErrorStatus';

export const DocumentsStatusScreen = () => {
  const account = useSelector((state: IRootState) => state.authentication.account);

  if (
    account?.client?.registrationStepsStatus === RegistrationStepsStatus.REGISTRATION_APPROVED ||
    account?.client?.registrationStepsStatus === RegistrationStepsStatus.FINANCING_STARTED
  ) {
    return <StatusApprove />;
  }

  if (account?.client?.registrationStepsStatus === RegistrationStepsStatus.REGISTRATION_ADJUST) {
    return <StatusCorrections admMessage={account.client.consultantComment} />;
  }

  if (account?.client?.registrationStepsStatus === RegistrationStepsStatus.REGISTRATION_REJECTED) {
    return <StatusError admMessage={account.client.consultantComment} />;
  }

  return <></>;
};

export enum StatusDashboard {
  NEW = 'NEW',
  PROGRESS = 'PROGRESS',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CREDIT_ANALYSIS = 'CREDIT_ANALYSIS',
  ADJUSTED = 'ADJUSTED',
  PRE_REJECTED = 'PRE_REJECTED',
  UPDATED = 'UPDATED',
  ADJUSTMENTS = 'ADJUSTMENTS',
  VALIDATION = 'VALIDATION',
  FINISHED = 'FINISHED',
}

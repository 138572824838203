import styled from 'styled-components';

interface Props {
  showCalendar?: boolean;
}

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 9.68rem;
    height: 9.81rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2b1a48;
  text-align: center;
  margin: 14px 0px 30px 0;
`;

export const MessageText = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const ConfirmContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const ContainerFormInput = styled.div`
  width: 376px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 110px;
`;

export const ContainerFinancing = styled.div`
  width: 377px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 22px;
  margin-left: 108px;
  span {
    margin-left: 16px;
  }
  img {
    width: 14px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const ContainerInputs = styled.div`
  width: 376px;
  height: 50px;
  background: #f6f6f6;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  img {
    cursor: pointer;
  }
`;

export const TitleDate = styled.span`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.666667px;
  color: #979797;
`;

export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.666667px;
  color: #505050;
  margin-right: 42px;
  margin-top: 25px;
`;

export const ContainerSubtitle = styled.div`
  margin-top: 25px;
`;

export const ContainerFinalization = styled.div`
  width: 177px;
  height: 66px;
  background: #fff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-right: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Circle = styled.div`
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  margin-right: 11px;
  margin-left: 16px;
`;

export const CircleSelected = styled.div`
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  background: #2b1a48;
  border: 4px solid #fff;
  margin-right: 11px;
  margin-left: 16px;
`;

export const TitleFinalization = styled.span`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5c5c5c;
  margin-right: 10px;
`;

export const ContainerCalendar = styled.div<Props>`
  display: ${({ showCalendar }) => (showCalendar ? 'flex' : 'none')};
`;

export const ContainerButton = styled.div`
  height: 75px;
  margin-top: 31px;
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '560px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

import { Client } from './client';
import { Course } from './courses';
import { CreditAnalysisStatus } from './enums/credit-analysis-status';

export interface Financing {
  client?: Client;
  objective?: string;
  creditAnalysisStatus?: CreditAnalysisStatus;
}

export enum DownPaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
}
export interface CourseFinancing {
  id?: number;
  acceptedDate?: string;
  course?: Course;
  approvedValue?: number;
  choosedValue?: number;
  financingValue?: number;
  financingTax?: number;
  installmentValue?: number;
  installmentsTotal?: number;
  receiptDeadline?: number;
  firstDueDate?: string;
  totalEffectiveCost?: number;
  monthlyTax?: number;
  annualTax?: number;
  monthlyTotalEffectiveCost?: number;
  annualTotalEffectiveCost?: number;
  downPaymentMonthlyTotalEffectiveCost?: number;
  downPaymentAnnualTotalEffectiveCost?: number;
  downPaymentValue?: number;
  downPaymentTotalValue?: number;
  downPaymentStatus?: DownPaymentStatus;
  installments?: InstallmentsSimulate[];
  bankSlip?: {
    id?: number;
    status?: string;
  };
  downPaymentInstallment?: DownPaymentInstallment;
  totalValue?: number;
  status?: string;
}

export interface FinancingResume {
  id: number;
  client: Client;
  approvedValue: number;
  choosedValue: number;
  installmentsTotal: number;
  receiptDeadline: number;
  course: Course;
  downPaymentValue: number;
}

export interface InstallmentsSimulate {
  id: number;
  installmentNumber: number;
  daysToCompensate: number;
  debit: number;
  amortizedCapital: number;
  taxValue: number;
  iofValue: number;
  installmentValue: number;
  dueDate: string;
  bankSlip?: {
    id?: number;
    status?: string;
  };
}

export interface SimulateFinancing {
  id: number;
  installmentsTotal: number;
  choosedValue?: number;
  downPaymentValue: number;
  course: {
    id: number;
  };
  totalValue?: number;
  financingTax?: number;
  installmentValue?: number;
  receiptDeadline?: number;
  totalEffectiveCost?: number;
  status?: 'NEW' | 'IN_PROGRESS' | 'FINISHED';
  iofValue?: number;
  iofPercentage?: number;
  iofTax?: number;
  firstDueDate?: string;
  acceptedDate?: string;
  downPaymentStatus?: 'PENDING' | 'PAID' | 'OVERDUE';
  installmentStatus?: 'PENDING' | 'PAID' | 'OVERDUE';
  installments?: InstallmentsSimulate[];
}

export interface Installments {
  numero_parcela: number;
  dias_compensar: number;
  saldo_devedor: number;
  capital_amortizado: number;
  valor_taxa: number;
  valor_iof: number;
  valor_parcela: number;
  data_vencimento: string;
}

export enum FinancingResponseType {
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  FINANCING = 'FINANCING',
}

export interface SimulateFinancingResponse {
  valor_solicitado: number;
  valor_financiado: number;
  taxa_mensal_emprestimo: number;
  taxa_anual_emprestimo: number;
  numero_parcelas: number;
  data_emprestimo: string;
  data_vencimento_primeira_parcela: string;
  valor_primeira_parcela: number;
  data_vencimento_ultima_parcela: string;
  iof_diario: number;
  taxa_iof: number;
  valor_total_iof: number;
  valor_total_emprestimo: number;
  valor_tarifas: number;
  custo_efetivo_total_mensal: number;
  custo_efetivo_total_anual: number;
  parcelas: Installments[];
  type: FinancingResponseType;
}

export interface DownPaymentInstallment {
  id: number;
  installmentNumber: number;
  daysToCompensate: number;
  debit: number;
  amortizedCapital: number;
  taxValue: number;
  iofValue: number;
  installmentValue: number;
  dueDate: number;
  bankSlip: {
    id: number;
    status: string;
  };
}

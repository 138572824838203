import AnalyseFinancingInputsContainer from 'components/analyse-financing-inputs/analyse-financing-inputs';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledButton from 'components/StyledButton';
import { Course } from 'model/courses';
import { CreditAnalysisStatus } from 'model/enums/credit-analysis-status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ClientProfileService from 'services/client-profile-service';
import { text } from 'stream/consumers';
import * as yup from 'yup';
import { AlertText, BreakLine, ButtonsContainer, CloseButton, CloseContainer, ContainerFormInput, Title } from './styles';

interface ModalProps {
  onCloseModal: () => void;
  clientId: number;
  courseId: number;
  hasCourseValue: boolean;
}

interface NewParams {
  id: number;
  course: Course;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '776px',
    height: '500px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

interface Schema {
  creditAnalysisStatus: CreditAnalysisStatus;
  consultantComment: string;
}

const AnalyseFinancingModal: React.FC<ModalProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    creditAnalysisStatus: yup.string().required(t('global.errorMessage.required')),
    consultantComment: yup.string(),
  });

  const handleSubmit = async (data: Schema) => {
    const newParams: NewParams = { id: props.clientId, course: { id: props.courseId, ...data } };
    const res = await ClientProfileService.analyseFinancing(newParams);
    if (res) {
      props.onCloseModal();
      history.go(0);
    }
  };

  return (
    <Modal isOpen style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={props.onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('global.modal.analyseFinancing')}</Title>
      <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
        <ContainerFormInput>
          {props.hasCourseValue ? (
            <>
              <AnalyseFinancingInputsContainer />
              <ButtonsContainer>
                <BreakLine />
                <div>
                  <StyledButton secondary onClick={props.onCloseModal} text={t('global.button.cancel')} />
                  <StyledButton primary type="submit" text={t('global.button.confirm')} />
                </div>
              </ButtonsContainer>
            </>
          ) : (
            <>
              <AlertText>{t('global.modal.noCourseValue')}</AlertText>
              <ButtonsContainer>
                <BreakLine />
                <div>
                  <StyledButton secondary onClick={props.onCloseModal} text={t('global.button.cancel')} />
                </div>
              </ButtonsContainer>
            </>
          )}
        </ContainerFormInput>
      </ReactHookFormProvider>
    </Modal>
  );
};

export default AnalyseFinancingModal;

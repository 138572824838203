import _ from 'lodash';
import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SearchType } from 'model/enums/search';
import { SearchResult } from 'model/search-result';
import './header/header.scss';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      <span id="dropdown-label">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);

const ResultItemTypeLabel = ({ type }: { type: SearchType }): JSX.Element => {
  switch (type) {
    case SearchType.GENERAL:
      return (
        <div className="result-item__type">
          <span className="result-item__type__text">{'GERAL'}</span>
        </div>
      );
    case SearchType.SMART_BULAS:
      return (
        <div className="result-item__type">
          <span className="result-item__type__text">{'SMART BULAS'}</span>
        </div>
      );
    case SearchType.CID:
      return (
        <div className="result-item__type">
          <span className="result-item__type__text">{'CID'}</span>
        </div>
      );
    case SearchType.SUS:
      return (
        <div className="result-item__type">
          <span className="result-item__type__text">{'SUS'}</span>
        </div>
      );
    case SearchType.SPECIALTY:
      return (
        <div className="result-item__type">
          <span className="result-item__type__text">{'ESPECIALIDADE'}</span>
        </div>
      );
    default:
      return <div className="result-item__type" />;
  }
};
export const ResultItem = ({
  result,
  index,
  isLastItem,
  onClick,
  hasSubscription,
}: {
  result: SearchResult;
  index: number;
  isLastItem?: boolean;
  onClick: (result) => void;
  hasSubscription?: boolean;
}): JSX.Element => {
  return (
    <a key={index} className="result-item" style={isLastItem ? { border: 'none' } : {}} onClick={() => onClick(result)}>
      <div className="result-item__left">
        <ResultItemTypeLabel type={result.type} />
        <span className="result-item__text">{_.upperFirst(_.truncate(result.title, { length: 100 }).toLowerCase())}</span>
      </div>
    </a>
  );
};

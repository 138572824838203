import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import HeaderLoginAdmin from 'components/header-login-admin/header-login-admin';
import InputText from 'components/InputText';
import StyledButton from 'components/StyledButton';
import LogoFdi from 'images/logo-fdi-escrita.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import ReactHookFormProvider from '../../components/ReactHookFormProvider';
import PasswordResetService from '../../services/forgot-password-service';
import { ButtonContainer, Container, FormContainer, InputContainer, LoginContainer, SubTextTitle, TextTitle } from './StyledComponents';

export interface IFinishRecoveryPasswordData {
  key: string;
  newPassword: string;
}

const ChangePasswordAdmin = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    password: yup.string().required(t('global.errorMessage.required')).min(4, t('global.errorMessage.password')),
    passwordConfirmation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .oneOf([yup.ref('password'), null], t('global.errorMessage.confirmPassword')),
  });

  const onHandleSubmit = data => {
    const newData = { key: match.params.key, newPassword: data.password };
    PasswordResetService.finishPasswordReset(newData).then(() => history.push('/changed-password'));
  };

  return (
    <>
      <HeaderLoginAdmin />
      <LoginContainer>
        <Container>
          <TextTitle>{t('changePassword.admin.title')}</TextTitle>
          <SubTextTitle>{t('changePassword.admin.subtitle')}</SubTextTitle>
          <FormContainer>
            <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
              <InputContainer>
                <InputText inputType="password" name="password" label="" placeholder={t('changePassword.form.insertPassword')} />
              </InputContainer>
              <InputContainer>
                <InputText
                  inputType="password"
                  name="passwordConfirmation"
                  label=""
                  placeholder={t('changePassword.form.confirmPassword')}
                />
              </InputContainer>
              <ButtonContainer>
                <StyledButton primary type="submit" text={t('global.button.saveNewPassword')} />
              </ButtonContainer>
            </ReactHookFormProvider>
          </FormContainer>
        </Container>
      </LoginContainer>
      <FooterLoginAdmin />
    </>
  );
};

export default ChangePasswordAdmin;

import AnalyseRegisterInputsContainer from 'components/analyse-registration-inputs-container/analyse-registration-inputs-container';
import LinkPartnerInputContainer from 'components/link-partner-input-container/link-partner-input-container';
import ProgressBar from 'components/radius-progress-bar';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { ContainerInputsCourse } from 'components/RegistrationForms/Course/InputsCourse';
import StyledButton from 'components/StyledButton';
import { Internal } from 'model/internal';
import { Partner, PartnersId } from 'model/partner';
import { Score } from 'model/score';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ClientProfileService from 'services/client-profile-service';
import { text } from 'stream/consumers';
import * as yup from 'yup';
import { BreakLine, ButtonsContainer, CloseButton, CloseContainer, ContainerFormInput, Title } from './styles';

interface ModalProps {
  onCloseModal: () => void;
  scoreData: Score;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '776px',
    height: '500px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const CalculateScoreModal: React.FC<ModalProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Modal isOpen style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={props.onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('global.modal.calculateScore')}</Title>
      <ContainerFormInput>
        <ProgressBar text={t('clientFinancing.creditData.valueCreditScore')} progress={props.scoreData.creditScore.score} score />
        <ButtonsContainer>
          <BreakLine />
          <div>
            <StyledButton secondary onClick={props.onCloseModal} text={t('global.button.cancel')} />
          </div>
        </ButtonsContainer>
      </ContainerFormInput>
    </Modal>
  );
};

export default CalculateScoreModal;

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 13px;
  margin-top: 30px;
  gap: 16px;
  width: 176px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
`;

export const CaptionTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
`;

export const CaptionContainer = styled.div``;

export const CaptionBox = styled.div`
  width: 150px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

export const InputDivisor = styled.div`
  width: 150px;
  height: 0px;
  border: 1px solid #d8d8d8;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    width: 150px;
    height: 44px;
    margin-bottom: 0px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
    }
  }
`;

export const StepContainer = styled.div`
  /* width: 125px;
  height: 148px; */
`;

export const ColorConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#80008C',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#80008C',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 0,
  },
}));

import { toast } from 'react-toastify';

const toastUtils = () => {
  const emitErrorToast = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
    });
  const emitSuccessToast = (message: string) => toast.success(message);

  return {
    emitErrorToast,
    emitSuccessToast,
  };
};

export default toastUtils();

import { BackButton, ButtonsContainer, FormInputsContainer } from 'components/RegistrationForms/styles';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { SelectInput } from 'components/SelectInput';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import { useRegisterForm } from 'provider/RegisterForm';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const OBJECTIVE_OPTIONS = [
  { label: 'Estudar', value: 'STUDY' },
  { label: 'Estudar e Trabalhar', value: 'WORK_STUDY' },
];

const DURATION_OPTIONS = [
  { label: '1 a 3 meses', value: 'ONE_TO_THREE_MONTHS' },
  { label: '3 a 6 meses', value: 'THREE_TO_SIX_MONTHS' },
  { label: '6 a 12 meses', value: 'SIX_TO_TWELVE_MONTHS' },
  { label: 'Mais de 12 meses', value: 'LONGER_THAN_TWELVE_MONTHS' },
];

interface Props {
  setLanguageId?: (id: number) => void;
}

export const ContainerInputsCourse = ({ setLanguageId }: Props) => {
  const [languagePage, setLanguagePage] = useState(0);
  const [languageName, setLanguageName] = useState('');
  const { t } = useTranslation();
  const { isFirstForm, onClickBack, isLastForm, initialClientData } = useRegisterForm();
  const { languages, languagesTotalPages, getLanguages } = useSelectLists();

  useEffect(() => {
    getLanguages({ name: languageName, page: languagePage, size: 7 }, true);
  }, [languagePage]);

  return (
    <FormInputsContainer>
      <TextInput name="objective" label={t('register.inputs.objective')} isRequired hasCustomInput>
        <SelectInput
          name="objective"
          placeholder={t('register.placeholder.objective')}
          options={OBJECTIVE_OPTIONS}
          defaultValue={OBJECTIVE_OPTIONS.find(obj => obj.value === initialClientData?.course?.objective)}
        />
      </TextInput>

      <TextInput name="language" label={t('register.inputs.language')} isRequired hasCustomInput>
        <SearchSelectInput
          name="language"
          placeholder={t('register.placeholder.language')}
          options={languages.map(lang => ({ label: lang.name ?? '', value: lang.id.toString() }))}
          defaultValue={initialClientData?.course?.language?.name}
          defaultOptions={languages.map(lang => ({ label: lang.name ?? '', value: lang.id.toString() }))}
          handleChange={values => {
            const language = languages.find(lg => lg.name === values.name);
            if (language != null) {
              setLanguageId && setLanguageId(language.id);
            }
            setLanguageName(values.name);
            setLanguagePage(0);
            getLanguages({ name: values.name, size: 7 });
          }}
          handleOnScroll={() => {
            if (languagePage < languagesTotalPages - 1) {
              setLanguagePage(languagePage + 1);
            }
          }}
        />
      </TextInput>

      <TextInput name="duration" label={t('register.inputs.duration')} isRequired hasCustomInput>
        <SelectInput
          name="duration"
          placeholder={t('register.placeholder.duration')}
          options={DURATION_OPTIONS}
          defaultValue={DURATION_OPTIONS.find(duration => duration.value === initialClientData?.course?.duration)}
        />
      </TextInput>

      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack} type="button">
            <span>Voltar</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? 'Cadastrar' : 'Avançar'} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};

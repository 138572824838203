import React, { useEffect, useState } from 'react';
import CorrectLp from '../../images/correctLp.svg';
import Dozero from '../../images/dozero.svg';
import Erin from '../../images/erin.svg';
import Leev from '../../images/leev.svg';
import Mooneeyy from '../../images/mooneeyy.svg';
import Ned from '../../images/ned.svg';
import Pathway from '../../images/pathway.svg';
import Seda from '../../images/seda.svg';
import Seda2 from '../../images/seda2.svg';
import Seda3 from '../../images/seda3.svg';
import ButtonComponent from 'components/Button';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import partnerService from 'services/partner.service';
import {
  ContainerBeApartner,
  ContainerContentBeApartner,
  ContainerPartnerImgs,
  ContainerRequestBePartner,
  ContainerTextBePartner,
  TextAreaStyled,
  ContainerForm,
} from './styles';
import InputText from 'components/InputText';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import TextareaText from 'components/textarea';

interface beAPartner {
  companyName: string;
  email: string;
  phone: string;
  message: string;
}

export const BePartner = () => {
  const [sendOk, setSendOk] = useState<boolean>(false);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    companyName: yup.string().required(t('LandingPage.form.errorMessage.name')),
    email: yup.string().required(t('LandingPage.form.errorMessage.email')).email(t('LandingPage.form.errorMessage.emailInvalid')),
    phone: yup.string().required(t('LandingPage.form.errorMessage.phone')),
    message: yup.string().required(t('LandingPage.form.errorMessage.message')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<beAPartner>({
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (data: beAPartner) => {
    const response = await partnerService.beAPartner(data);
    if (response?.data?.id) {
      setSendOk(true);
    }
  };

  return (
    <ContainerBeApartner id="bePartner">
      <ContainerContentBeApartner>
        <ContainerTextBePartner style={{ marginTop: '86px' }}>
          <span>
            {t('LandingPage.textBeApartner.firstText')} <span>{t('LandingPage.textBeApartner.secondText')}</span>{' '}
          </span>
          <span style={{ width: '456px' }}>{t('LandingPage.textBeApartner.textBeApartner')}</span>
          <img src={CorrectLp} style={{ top: '0', left: '331px' }} />
          <ContainerPartnerImgs style={{ width: '478px' }}>
            <img src={Seda} style={{ marginBottom: '30px' }} />
            <img src={Erin} style={{ marginBottom: '30px' }} />
            <img src={Pathway} style={{ marginBottom: '30px' }} />
            <img src={Seda2} style={{ marginRight: '8px', marginBottom: '30px' }} />
            <img src={Dozero} style={{ marginBottom: '30px' }} />
            <img src={Seda3} style={{ marginLeft: '25px', marginBottom: '30px' }} />
            <img src={Leev} style={{ marginLeft: '6px', marginRight: '35px', marginBottom: '30px' }} />
            <img src={Ned} style={{ marginBottom: '30px' }} />
            <img src={Mooneeyy} style={{ marginLeft: '10px', marginRight: '6px', marginBottom: '30px' }} />
          </ContainerPartnerImgs>
        </ContainerTextBePartner>
        <ContainerRequestBePartner>
          <ContainerForm>
            <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
              <span>
                {t('LandingPage.form.nameCompany')} <span>*</span>
              </span>
              <InputText name="companyName" label="" placeholder={t('LandingPage.form.placeholder.nameCompany')} />
              <span>
                {t('LandingPage.form.email')} <span>*</span>
              </span>
              <InputText name="email" label="" placeholder={t('LandingPage.form.placeholder.email')} />
              <span>
                {t('LandingPage.form.phone')} <span>*</span>
              </span>
              <InputText name="phone" label="" placeholder={t('LandingPage.form.placeholder.phone')} maxLength={15} mask="PHONE" />
              <span>
                {t('LandingPage.form.message')} <span>*</span>
              </span>
              <TextareaText name="message" label="" placeholder={t('LandingPage.form.placeholder.message')} />
              <ButtonComponent
                width="190px"
                height="56px"
                background="#80008C"
                border="none"
                borderRadius="35px"
                color="#fff"
                text="LandingPage.textBeApartner.buttonSend"
                type="submit"
                sendOk={sendOk}
              />
            </ReactHookFormProvider>
          </ContainerForm>
        </ContainerRequestBePartner>
      </ContainerContentBeApartner>
    </ContainerBeApartner>
  );
};

import React from 'react';
import {
  ContainerAllInfosToShow,
  ContainerExplanation,
  ContainerInfoExplanation,
  ContainerStepsWithPuppet,
  ContainerTextStap,
} from './styles';
import Arrow from '../../images/flecha.svg';
import Puppet1 from '../../images/puppet1.svg';
import Puppet2 from '../../images/puppet2.svg';
import Puppet3 from '../../images/puppet3.svg';
import { useTranslation } from 'react-i18next';

export default function ExplanationFinancing() {
  const { t } = useTranslation();
  return (
    <ContainerInfoExplanation id="howItWorks">
      <ContainerAllInfosToShow>
        <span>
          {t('LandingPage.textExplanation.firstText')} <span>{t('LandingPage.textExplanation.secondText')}</span>{' '}
          <img src={Arrow} alt={t('LandingPage.alt.arrow')} />
        </span>
        <ContainerExplanation>
          <ContainerStepsWithPuppet>
            <ContainerTextStap>
              <span>{t('LandingPage.textSteps.stepOne')}</span>
              <span>{t('LandingPage.textSteps.textStepOne')}</span>
            </ContainerTextStap>
            <img src={Puppet1} />
          </ContainerStepsWithPuppet>
          <ContainerStepsWithPuppet>
            <ContainerTextStap>
              <span>{t('LandingPage.textSteps.stepTwo')}</span>
              <span>{t('LandingPage.textSteps.textStepTwo')}</span>
            </ContainerTextStap>
            <img src={Puppet2} style={{ marginLeft: '150px' }} />
          </ContainerStepsWithPuppet>
          <ContainerStepsWithPuppet>
            <ContainerTextStap>
              <span>{t('LandingPage.textSteps.stepThree')}</span>
              <span>{t('LandingPage.textSteps.textStepThree')}</span>
            </ContainerTextStap>
            <img src={Puppet3} style={{ marginLeft: '150px' }} />
          </ContainerStepsWithPuppet>
        </ContainerExplanation>
      </ContainerAllInfosToShow>
    </ContainerInfoExplanation>
  );
}

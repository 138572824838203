import { Route, Switch } from 'react-router-dom';
import { RequestApproval } from 'features/RequestApproval';
import { AccessBlocked } from 'features/AccessBlocked';
import { PreRegisterDocuments } from 'features/PreRegisterDocuments';
import { RegisterDocuments } from 'features/RegisterDocuments';
import { RegisterDocumentsWait } from 'features/RegisterDocumentsWait';
import { DocumentsStatusScreen } from 'features/DocumentsStatusScreen';
import { RegistrationEditScreen } from 'features/ClientRegistrationEdit';
import ClientPrivateRoutes, { StepLevelStatus } from '../client-private-routes';

const RegistrationRoutes = ({ match }) => {
  console.log('match', match);
  return (
    <Switch>
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION}
        exact
        path={`${match.url}/request-approval`}
        component={RequestApproval}
      />
      <ClientPrivateRoutes stepLevel={StepLevelStatus.REGISTRATION} exact path={`${match.url}/blocked`} component={AccessBlocked} />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION}
        exact
        path={`${match.url}/register-documents`}
        component={PreRegisterDocuments}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION}
        exact
        path={`${match.url}/register-documents/attachments`}
        component={RegisterDocuments}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION}
        exact
        path={`${match.url}/register-documents/attachments/wait`}
        component={RegisterDocumentsWait}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION_STATUS}
        exact
        path={`${match.url}/register/status`}
        component={DocumentsStatusScreen}
      />
      <ClientPrivateRoutes
        stepLevel={StepLevelStatus.REGISTRATION}
        exact
        path={`${match.url}/registration/edit`}
        component={RegistrationEditScreen}
      />
    </Switch>
  );
};

export default RegistrationRoutes;

import { InputAndText } from 'components/InputAndTitle';
import { ContainerInputs } from './styles';

export interface Schema {
  name: string;
  email: string;
  phone: string;
  cpf: string;
}

interface Props {
  schema: any;
  onSubmit: (data: Schema) => void;
}

const ContainerClientFirstSignUp = ({ schema, onSubmit }: Props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.name"
          name="name"
          placeholder="dashboardClient.guarantor.form.placeholder.name"
        />
        <InputAndText
          title="dashboardClient.guarantor.form.cpf"
          name="cpf"
          placeholder="dashboardClient.guarantor.form.placeholder.cpf"
          mask="CPF"
          max={14}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.phone"
          name="phone"
          placeholder="dashboardClient.guarantor.form.placeholder.phone"
          mask="PHONE"
          max={15}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.email"
          name="email"
          placeholder="dashboardClient.guarantor.form.placeholder.email"
        />
      </ContainerInputs>
    </div>
  );
};

export default ContainerClientFirstSignUp;

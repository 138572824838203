import ButtonComponent from 'components/Button';
import { truncate } from 'lodash';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { useRegisterForm } from 'provider/RegisterForm';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Clip from '../../images/clip.svg';
import ClipBlack from '../../images/clipBlack.svg';
import ClipPurple from '../../images/clipPurple.svg';
import Delete from '../../images/closePurple.svg';
import { Container, ContainerDataDocument, ContainerTitleAndData, Label, LabelNotEdit } from './styles';
interface Props {
  title?: string;
  titleDocument: string;
  editDocument?: boolean;
  nameInput?: string;
  name?: string;
  type?: string;
  docUrl?: string;
  setFiles?: any;
  files?: Attachment[];
  guarantorsFiles?: Attachment[];
  setGuarantorsFiles?: any;
  selectedGuarantorId?: number;
}
export default function ContainerDocumentGeneric({
  title,
  titleDocument,
  editDocument,
  nameInput,
  name,
  type,
  docUrl,
  setFiles,
  files,
  selectedGuarantorId,
}: Props) {
  const { t } = useTranslation();
  const [filename, setFilename] = useState<string>();
  const [newFileName, setNewFileName] = useState<string>('');
  const [url, setUrl] = useState<string>();
  const [subtitle, setSubTitle] = useState(title);
  const { guarantorsFiles, setGuarantorsFiles } = useRegisterForm();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleRegister = register(name ?? '');

  const SliceFilename = (file: string) => {
    const splitFile = file.split('.');
    const len = splitFile.length;
    const extension = splitFile[len - 1];
    return `${splitFile[0].split('').slice(0, 6).join('')}...${extension}`;
  };

  const onHandleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]?.name) {
      return;
    }
    if (e.target.files) {
      let newFile = e.target.files[0].name;
      if (newFile.split('.')[0].length > 45) {
        newFile = SliceFilename(newFile);
      }
      setFilename(e.target.files[0].name);
      setSubTitle(newFile);
    }
  };

  const handleAddFile = (event: any) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile == null) return;
    const reader = new FileReader();
    onHandleChangeField(event);
    reader.onload = (ev: any) => {
      const existFilenameEquals = guarantorsFiles.find(file => file.fileName === uploadedFile.fileName);
      let renameFile = '';
      if (existFilenameEquals) {
        renameFile = `${uploadedFile.name} ${Math.random()}`;
        setNewFileName(renameFile);
      }
      setGuarantorsFiles([
        ...guarantorsFiles,
        {
          guarantorId: selectedGuarantorId,
          fileName: renameFile === '' ? uploadedFile.name : renameFile,
          contentType: uploadedFile.type,
          file: ev.target.result.split(',')[1],
          type: type ?? '',
        },
      ]);
      setUrl(`data:${uploadedFile.type};base64, ${ev.target.result.split(',')[1]}`);
    };
    reader.readAsDataURL(uploadedFile);
  };

  useEffect(() => {
    setSubTitle(title);
    setFilename(title);
  }, [title]);

  useEffect(() => {
    if (newFileName !== '') {
      setFilename(newFileName);
    }
  }, [newFileName]);

  useEffect(() => {
    if (docUrl) {
      setUrl(docUrl);
    }
  }, [docUrl]);

  const handleRemoveFile = (fileName: string) => {
    const filesFiltred = guarantorsFiles.filter(file => file.fileName !== fileName);
    setFiles(filesFiltred);
    setSubTitle(undefined);
  };

  return (
    <Container style={{ background: !subtitle ? '#F6F6F6' : 'transparent' }}>
      <ContainerTitleAndData style={{ justifyContent: !subtitle ? 'center' : 'flex-start' }}>
        <span
          style={{ height: !subtitle ? 'auto' : '30px', fontSize: !subtitle ? '16px' : '14px', color: !subtitle ? '#979797' : '#333333' }}
        >
          {t(titleDocument)}
        </span>
        {subtitle && (
          <ContainerDataDocument>
            <img src={ClipBlack} />
            <a download={filename} href={url} target="_blank">
              {subtitle && truncate(subtitle, { length: 45 })}
            </a>
            {editDocument && <img src={Delete} onClick={() => handleRemoveFile(filename ?? '')} />}
          </ContainerDataDocument>
        )}
      </ContainerTitleAndData>
      {editDocument && (
        <>
          <Label htmlFor={nameInput}>
            <img src={ClipPurple} />
            {t('global.button.attach')}
          </Label>
          <input
            type="file"
            id={nameInput}
            style={{ display: 'none' }}
            {...register(name ?? '')}
            accept="image/x-png, image/jpg, image/jpeg, image/svg, .pdf"
            onChange={e => {
              handleAddFile(e);
              handleRegister.onChange(e);
            }}
          />
        </>
      )}
    </Container>
  );
}

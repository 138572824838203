import React from 'react';
import {
  BallSignature,
  Border,
  Container,
  ContainerInfos,
  ContainerName,
  ContainerNameContract,
  ContainerSignature,
  ContainerSignatures,
  ContainerStatus,
  ContainerTitles,
  Signature,
  Status,
  TitleContract,
  TitleText,
} from './styles';
import Close from 'images/closePurple.svg';
import ClipPurple from '../../images/clipPurple.svg';
import ApprovedPurple from '../../images/approvedPurple.svg';
import { useTranslation } from 'react-i18next';
import { InfoContract } from 'model/contract';

interface Props {
  showDetail?: (contract: InfoContract) => void;
  admin?: boolean;
  contract?: InfoContract;
}

const ContainerContract = ({ showDetail, admin, contract }: Props) => {
  const { t } = useTranslation();

  const showContractViewer = () => {
    if (showDetail && contract) {
      showDetail(contract);
    }
  };

  return (
    <Container onClick={showContractViewer} admin={admin}>
      <ContainerTitles admin={admin}>
        <TitleText admin={admin} right={293}>
          {t('dashboardClient.contracts.title')}
        </TitleText>
        <Border admin={admin} />
        <TitleText admin={admin} right={160}>
          {t('dashboardClient.contracts.contractInfo.signature')}
        </TitleText>
        <Border admin={admin} />
        <TitleText admin={admin}>{t('dashboardClient.contracts.contractInfo.status')}</TitleText>
      </ContainerTitles>
      <ContainerInfos admin={admin}>
        <ContainerName admin={admin}>
          <ContainerNameContract admin={admin}>
            <img src={ClipPurple} />
            <TitleContract admin={admin}>{contract?.attachment.fileName}</TitleContract>
            <img src={Close} />
          </ContainerNameContract>
        </ContainerName>
        <Border admin={admin} />
        <ContainerSignatures admin={admin}>
          {contract?.contractSignatories?.map((element, index) => {
            return (
              <ContainerSignature key={index}>
                {element.signed ? <img src={ApprovedPurple} /> : <BallSignature />}
                <Signature>{element.signatory?.email}</Signature>
              </ContainerSignature>
            );
          })}
        </ContainerSignatures>
        <Border admin={admin} />
        <ContainerStatus>
          <Status>{t(`dashboardClient.contracts.contractInfo.${contract?.status.toLowerCase()}`)}</Status>
        </ContainerStatus>
      </ContainerInfos>
    </Container>
  );
};

export default ContainerContract;

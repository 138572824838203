import React from 'react';
import { ContainerMenu } from './styles';
import Close from '../../images/fechar-4.svg';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Button';

interface Props {
  display: string;
  closeMenu: () => void;
  onClickInButton: () => void;
}

export default function SideMenu({ display, closeMenu, onClickInButton }: Props) {
  const { t } = useTranslation();
  return (
    <ContainerMenu display={display}>
      <img src={Close} alt="fechar" onClick={() => closeMenu()} />
      <a href="#howItWorks">{t('LandingPage.link1')}</a>
      <a href="#finance">{t('LandingPage.link2')}</a>
      <a href="#app">{t('LandingPage.link3')}</a>
      <a href="#bePartner">{t('LandingPage.link4')}</a>
      <a href="#doubt">{t('LandingPage.link5')}</a>
      <ButtonComponent
        width="115px"
        height="40px"
        background="#80008C"
        border="none"
        borderRadius="8px"
        color="#fff"
        text="global.button.login"
        margin="0 0 0 20px"
        onClick={onClickInButton}
      />
    </ContainerMenu>
  );
}

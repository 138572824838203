import { Container, LogoImageContainer, LogoutButtonContainer } from './styles';
import Logo from '../../images/logoEscrita.svg';
import Logout from '../../images/logout.svg';
import AuthUtils from 'shared/util/auth-utils';
import { useHistory } from 'react-router-dom';
import { logoutRequest } from 'reducer/authentication/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const HeaderWithLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Container>
      <LogoImageContainer>
        <img src={Logo} />
      </LogoImageContainer>
      <LogoutButtonContainer>
        <button
          onClick={() => {
            AuthUtils.removeToken();
            dispatch(logoutRequest());
            history.replace('/');
          }}
        >
          <span>{t('global.button.exit')}</span>
          <img src={Logout} />
        </button>
      </LogoutButtonContainer>
    </Container>
  );
};

enum HttpStatus {
  OK = 200,
  CREATED = 201,
  DELETED = 204,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
}

export default HttpStatus;

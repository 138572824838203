import React, { useEffect, useState } from 'react';
import {
  ContainerContent,
  ContainerFirstSignUp,
  ContainerInfoApp,
  ContainerTextApresentation,
  ContainerRedirectToApp,
  ContainerContentApp,
  ContainerTextApp,
} from './styles';
import { useTranslation } from 'react-i18next';
import Bar from '../../images/barraLp.svg';
import Bar2 from '../../images/bar2.svg';
import Union from '../../images/Union.svg';
import CirclesLp from '../../images/circlesLp.svg';
import Arrow2 from '../../images/arrow2.svg';
import AppImage from '../../images/appImageLp.svg';
import AppAndroid from '../../images/buttonAndroid.svg';
import AppApple from '../../images/buttonApple.svg';
import LandingPageFooter from 'components/FooterLp';
import { Header } from 'components/Header';
import FirstSignUp from 'components/FirstSignUpLp';
import SideMenu from 'components/sideMenu';
import ExplanationFinancing from 'components/ExplanationFinancing';
import { InfoBenefits } from 'components/InfoBenefits';
import { BePartner } from 'components/BePartner';
import { Faq } from 'components/Faq';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header shadow />
      <ContainerFirstSignUp id="approval">
        <ContainerContent>
          <ContainerTextApresentation>
            <span>{t('LandingPage.textApresentation')}</span>
            <span>{t('LandingPage.subTextApresentation')}</span>
            <img src={Bar} alt={t('LandingPage.alt.details')} />
            <img src={Union} alt={t('LandingPage.alt.details')} />
            <img src={CirclesLp} alt={t('LandingPage.alt.details')} />
          </ContainerTextApresentation>
          <FirstSignUp />
        </ContainerContent>
      </ContainerFirstSignUp>
      <ExplanationFinancing />
      <ContainerInfoApp id="app">
        <ContainerContentApp>
          <ContainerTextApp>
            <span>
              {t('LandingPage.textApp.firstText')} <span>{t('LandingPage.textApp.secondText')}</span>{' '}
            </span>
            <span>{t('LandingPage.textApp.textInfoApp')}</span>
            <img src={Arrow2} alt={t('LandingPage.alt.arrow')} />
            <img src={Bar2} alt={t('LandingPage.alt.bar')} />
            <ContainerRedirectToApp>
              <a href="https://play.google.com/store/apps/details?id=br.com.financiamentodeintercambio&hl=pt_BR&gl=US" target="_blank">
                <img src={AppAndroid} alt={t('LandingPage.alt.appAndroid')} />
              </a>
              <a href="https://apps.apple.com/br/app/financiamento-de-interc%C3%A2mbio/id6443920018" target="_blank">
                <img src={AppApple} alt={t('LandingPage.alt.appIos')} />
              </a>
            </ContainerRedirectToApp>
          </ContainerTextApp>
          <img src={AppImage} style={{ width: '400px', height: '400px', marginLeft: '150px' }} />
        </ContainerContentApp>
      </ContainerInfoApp>
      <InfoBenefits />
      <BePartner />
      <Faq />
      <LandingPageFooter />
    </>
  );
};

export default Home;

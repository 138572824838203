import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';

import StyledButton from 'components/StyledButton';
import { CloseButton, CloseContainer, ButtonContainer, ImageContainer, MessageText, Title } from './styles';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Button';

interface SimpleModalProps {
  isOpen: boolean;
  imageSrc: string;
  titleText: string;
  messageText: string;
  onCloseModal: () => void;
  handleConfirm: () => void;
  resendEmail: () => void;
  isloadingResend: boolean;
  openWaitValidation: () => void;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '420px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const EmailConfirm = ({
  isOpen,
  onCloseModal,
  imageSrc,
  titleText,
  messageText,
  handleConfirm,
  resendEmail,
  isloadingResend,
  openWaitValidation,
}: SimpleModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <ImageContainer>
        <img src={imageSrc} />
      </ImageContainer>
      <Title>{t(titleText)}</Title>
      <MessageText>{t(messageText)}</MessageText>
      <ButtonContainer>
        <ButtonComponent
          text={t('global.button.cancel')}
          width="123px"
          height="47px"
          background="transparent"
          border="1px solid #80008C"
          borderRadius="8px"
          color="#80008C"
          size="18px"
          weight="600"
          margin="0 8px 0 0"
          onClick={onCloseModal}
        />
        <ButtonComponent
          text={t('global.button.resend')}
          width="123px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="#fff"
          size="18px"
          weight="600"
          margin="0 8px 0 0"
          onClick={resendEmail}
          load={isloadingResend}
        />
        <ButtonComponent
          text={t('global.button.confirm')}
          width="123px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="#fff"
          size="18px"
          weight="600"
          onClick={openWaitValidation}
        />
      </ButtonContainer>
    </Modal>
  );
};

export default EmailConfirm;

import AdminHeader from 'components/admin-header/admin-header';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledPagination from 'components/StyledPagination';
import TeamTableContent from 'components/team-table-content/team-table-content';
import { User } from 'model/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import teamService from 'services/team-service';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import { ItemLink, TableContainer, Title } from '../styles';

const TeamConsultants = () => {
  const { t } = useTranslation();

  const [tableContent, setTableContent] = useState<User[]>([]);
  const [filterBy, setFilterBy] = useState({ filterSelect: '', saveFilter: 'off' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const header = [
    { name: t('global.table.name'), propName: 'name' },
    { name: t('global.table.cpf'), propName: 'cpf' },
    { name: t('global.table.contact'), propName: 'contact' },
    { name: t('global.table.authority'), propName: 'authority' },
    { name: t('global.table.status'), propName: 'internalStatus' },
  ];

  const sortableColumns = ['Nome', 'CPF', 'Contato', 'Função', 'Status'];

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleOrderData = async () => {
    const today = StringUtils.dateMask(new Date());
    const filterOutput = filterBy.filterSelect === 'off' ? '' : filterBy.filterSelect;

    const register = await teamService.getConsultantsTableData({
      page: currentPage - 1,
      size: 10,
    });

    setTotalPages(register.totalPages);
    setTableContent(register.content ?? []);
  };

  // debounce
  const [timer, setTimer] = useState<any>();
  function debounce(func, timeout = 300) {
    clearTimeout(timer);
    setTimer(setTimeout(func, timeout));
  }

  useEffect(() => {
    debounce(() => {
      handleOrderData();
    }, 300);
  }, [currentPage]);

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AdminHeader />
      </div>
      <div style={{ width: '100%' }}>
        <StyledAdminHeader>{t('menu.team.title')}</StyledAdminHeader>
        <MinHeightScreeningContainer>
          <Title>{t('menu.team.consultants')}</Title>
          <TableContainer>
            {!AuthUtils.isPartner() && !AuthUtils.isInternal() && (
              <Link to={'/admin/new/consultant'}>
                <ItemLink>{t('global.button.addNew')}</ItemLink>
              </Link>
            )}
            <TeamTableContent
              consultants
              data={tableContent ?? []}
              sortableColumns={sortableColumns}
              header={header}
              tableName={'register'}
            />
            <StyledPagination
              page={{
                page: currentPage,
                totalPages,
              }}
              handleChangePage={handleChangePage}
            />
          </TableContainer>
          <FooterLoginAdmin />
        </MinHeightScreeningContainer>
      </div>
    </div>
  );
};

export default TeamConsultants;

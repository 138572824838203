import GridTable from 'components/GridTable';
import Status from 'components/status/status';
import TooltipModal from 'components/tooltip-modal/tooltip-modal';
import { User } from 'model/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { Container, Img } from './styles';
import Bullets from 'images/bullets.svg';
import OptionsModal from 'components/options-modal/options-modal';
import AuthUtils from 'shared/util/auth-utils';

interface TableContentProps {
  data: User[];
  sortableColumns: string[];
  header: {
    name: string;
    propName: string;
  }[];
  users?: boolean;
  tableName?: string;
  consultants?: boolean;
}
const AUTHORITY_OPTIONS = [
  { label: 'Admin', value: 'ROLE_ADMIN' },
  { label: 'Usuário Normal', value: 'ROLE_INTERNAL' },
  { label: 'Usuário Admin', value: 'ROLE_INTERNAL_ADMIN' },
  { label: 'Consultor', value: 'ROLE_PARTNER' },
];
const TeamTableContent: React.FC<TableContentProps> = ({
  data,
  sortableColumns,
  header,
  users = false,
  tableName,
  consultants = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const handleOnClickOptions = (id: number) => {
    setIsOptionsModalVisible(!isOptionsModalVisible);
    setSelectedId(id);
  };

  const handleOnMouseLeave = () => {
    setIsOptionsModalVisible(!isOptionsModalVisible);
  };

  const newData = data.map((item, i) => {
    let row = {};

    header.map(value => {
      if (value.name === 'Status') {
        row = {
          ...row,
          [value.propName]: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
                maxHeight: '50px',
              }}
            >
              <Status title={item[value.propName]} />
              {!AuthUtils.isPartner() && (
                <div style={{ position: 'relative' }}>
                  <Img src={Bullets} onClick={() => handleOnClickOptions(item.id!)} />
                  {isOptionsModalVisible && selectedId === item.id && (
                    <OptionsModal
                      handleOnMouseLeave={handleOnMouseLeave}
                      active={item[value.propName] === 'ACTIVE'}
                      users={users}
                      id={item.id}
                    />
                  )}
                </div>
              )}
            </div>
          ),
        };
      } else {
        if (value.propName === 'authority') {
          row = {
            ...row,
            [value.propName]: AUTHORITY_OPTIONS.find(option => option.value === item.user?.authorities?.[0].name)?.label ?? 'Não informado',
          };
        } else if (value.propName === 'name') {
          row = {
            ...row,
            [value.propName]: item[`${value.propName}`] ?? 'Não informado',
          };
        } else if (value.propName === 'cpf') {
          row = {
            ...row,
            [value.propName]: item.cpf ? StringUtils.cpfMask(item.cpf) : 'Não informado',
          };
        } else if (value.propName === 'contact') {
          row = {
            ...row,
            [value.propName]: (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '5px 0px' }}>
                <span>{item.phone ? StringUtils.phoneMask(item.phone) : 'Não informado'} </span>
                <span>{item.email ?? 'Não informado'}</span>
              </div>
            ),
          };
        }
      }
    });
    return row;
  });

  const handleOnClickRow = value => {
    const id = data.find(item => item.name === value.name)?.id;
    if (consultants) {
      return history.push(`/admin/team/consultants/${id}`);
    }
    return history.push(`/admin/team/users/${id}`);
  };

  return (
    <Container>
      <GridTable onRowClick={value => handleOnClickRow(value)} sortableColumns={sortableColumns} header={header} data={newData} />
    </Container>
  );
};

export default TeamTableContent;

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonStyled } from './styles';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Correct from '../../images/correct.svg';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  text: string;
  background?: string;
  border?: string;
  borderRadius?: string;
  margin?: string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
  load?: boolean;
  sendOk?: boolean;
  weight?: string;
  size?: string;
  icon?: string;
  iconPosition?: string;
  disabled?: boolean;
  children?: ReactNode;
}

const override = css`
  margin: 0 auto;
  border-color: #fff;
`;

const ButtonComponent = ({
  width = '150px',
  height = '44px',
  background = '#80008C',
  border = 'none',
  borderRadius = '8px',
  color = 'white',
  margin = '',
  type,
  weight = '600',
  size = '18px',
  onClick,
  text,
  load,
  sendOk,
  icon,
  iconPosition,
  disabled,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonStyled
      width={width}
      height={height}
      background={background}
      border={border}
      borderRadius={borderRadius}
      color={color}
      margin={margin}
      type={type}
      onClick={onClick}
      weight={weight}
      size={size}
      disabled={disabled}
    >
      {load && <BeatLoader css={override} size={10} color={'#fff'} loading={load} />}
      {sendOk && <img src={Correct} alt="Enviado" />}
      {icon && !iconPosition ? (
        <>
          <img src={icon} alt="Enviado" /> {t(text)}{' '}
        </>
      ) : (
        icon &&
        iconPosition === 'right' && (
          <>
            {t(text)} <img src={icon} alt="Enviado" />{' '}
          </>
        )
      )}
      {!sendOk && !load && !icon && t(text)}
      {children}
    </ButtonStyled>
  );
};

export default ButtonComponent;

import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import { Attachment } from 'model/files';
import { ContainerInputsDocuments } from 'components/RegistrationForms/FormDocuments/InputsDocument';
import { useRegisterForm } from 'provider/RegisterForm';
import { Client } from 'model/client';
interface Props {
  attachmentIdentification?: Attachment;
  attachmentIncome?: Attachment;
  attachmentAddress?: Attachment;
  isGuarantorRegister?: boolean;
}

export const FormDocuments = ({ attachmentIdentification, attachmentIncome, attachmentAddress, isGuarantorRegister }: Props) => {
  const schema = yup.object({
    attachmentIdentification: yup
      .mixed()
      .transform(value => attachmentIdentification ?? value[0])
      .required(),
    attachmentIncome: yup
      .mixed()
      .transform(value => attachmentIncome ?? value[0])
      .required(),
    attachmentAddress: yup
      .mixed()
      .transform(value => attachmentAddress ?? value[0])
      .required(),
  });
  const { files, onClickNext } = useRegisterForm();
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '31px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px' }}>
        <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ Attachments: files } as Client, isGuarantorRegister)}>
          <ContainerInputsDocuments />
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

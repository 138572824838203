import { Client } from 'model/client';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface ClientRegistrationDataProps {
  clientContent: Client;
}

const ClientRegistrationData: React.FC<ClientRegistrationDataProps> = props => {
  const { t } = useTranslation();
  const { clientContent } = props;

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.registrationData.name')}</ContentLabel>
        <ContentValue>{clientContent?.name ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <BreakLine />
      <ContentContainer>
        <ContentLabel>{t('clientData.registrationData.email')}</ContentLabel>
        <ContentValue>{clientContent?.email ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <BreakLine />

      <ContentContainer>
        <ContentLabel>{t('clientData.registrationData.password.label')}</ContentLabel>
        <ContentValue>{t('clientData.registrationData.password.placeholder')}</ContentValue>
      </ContentContainer>
    </DataContainer>
  );
};

export default ClientRegistrationData;

import { TextAreaStyled } from 'components/BePartner/styles';
import { FormInputsContainer } from 'components/RegistrationForms/styles';
import { SelectInput } from 'components/SelectInput';
import TextareaText from 'components/textarea';
import { TextInput } from 'components/TextInput';
import { AnalyzeStep } from 'model/enums/analyze-step';
import { useTranslation } from 'react-i18next';

interface IObjectiveOptions {
  label: string;
  value: string;
}

const AnalyseFinancingInputsContainer = () => {
  const { t } = useTranslation();

  const OBJECTIVE_OPTIONS: IObjectiveOptions[] = [
    { label: t('objectiveOptions.sendToAdjusts'), value: AnalyzeStep.ADJUST },
    { label: t('objectiveOptions.approveClient'), value: AnalyzeStep.APPROVED },
    { label: t('objectiveOptions.rejectClient'), value: AnalyzeStep.REJECTED },
  ];

  return (
    <FormInputsContainer>
      <TextInput name="creditAnalysisStatus" label={''} hasCustomInput>
        <SelectInput name="creditAnalysisStatus" placeholder={t('global.placeholder.selectAction')} options={OBJECTIVE_OPTIONS} />
      </TextInput>

      <TextInput name="consultantComment" label={''} placeholder={t('global.placeholder.annotateAdjustsObservations')} />
    </FormInputsContainer>
  );
};

export default AnalyseFinancingInputsContainer;

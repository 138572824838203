import i18next from '../../config/i18n';

export const consultantsAuthorities = () => {
  return [{ label: i18next.t('constants.partner'), value: 'ROLE_PARTNER' }];
};

export const userAuthorities = () => {
  return [
    { label: i18next.t('constants.internal'), value: 'ROLE_INTERNAL' },
    { label: i18next.t('constants.internalAdmin'), value: 'ROLE_INTERNAL_ADMIN' },
  ];
};

export const internalStatus = () => {
  return [
    { label: i18next.t('constants.active'), value: 'ACTIVE' },
    { label: i18next.t('constants.inactive'), value: 'INACTIVE' },
  ];
};

import styled from 'styled-components/macro';

export const FooterBody = styled.div`
  width: 100%;
  height: 50px;
  background-color: $white-three;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  position: relative;
  bottom: 0;
  margin-top: 50px;
`;

export const FooterText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
`;

import styled from 'styled-components';

export const Footer = styled.footer`
  width: 100%;
  min-height: 278px;
  background: #2b1a48;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const FooterContent = styled.div`
  width: 100%;
  height: 124px;
  margin-top: 40px;
  display: flex;
  div + div + div {
    a {
      width: 162px;
      height: 41px;
      background: #80008c;
      border: none;
      border-radius: 40px;
      color: #fff;
      text-align: center;
      padding-top: 9px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  div {
    div {
      display: none;
    }
  }
  @media (max-width: 1277px) {
    div + div {
      margin: 0 60px 0 150px !important;
    }
    div + div + div {
      margin: 22px 58px 0px 0px !important;
    }
  }
  @media (max-width: 1193px) {
    div + div {
      margin: 0 40px 0 60px !important;
    }
    div + div + div {
      margin: 22px 58px 0px 0px !important;
    }
  }
  @media (max-width: 1083px) {
    div {
      margin-left: 83px !important;
    }
    div + div {
      margin: 0 40px 0 60px !important;
      div {
        margin-left: 0 !important;
      }
    }
    div + div + div {
      margin: 22px 58px 0px 0px !important;
    }
  }
  @media (max-width: 1033px) {
    div {
      margin-left: 40px !important;
    }
    div + div {
      margin: 0 40px 0 60px !important;
      div {
        margin-left: 0 !important;
      }
    }
    div + div + div {
      margin: 22px 58px 0px 0px !important;
    }
  }
  @media (max-width: 989px) {
    div {
      margin-left: 5px !important;
    }
    div + div {
      margin: 0 40px 0 60px !important;
      div {
        margin-left: 0 !important;
      }
    }
    div + div + div {
      margin: 22px 58px 0px 0px !important;
    }
  }
  @media (max-width: 956px) {
    flex-direction: column;
    align-items: center;
    height: 362px;
    min-height: 124px !important;
    div {
      width: 360px;
      margin-right: 0;
      margin-left: 0 !important;
      img {
        width: 126px !important;
        height: 52px !important;
        margin-right: 40px !important;
      }
      flex-direction: row;
      align-items: center;
      div {
        display: flex;
        a {
          img {
            width: 17px !important;
            height: 17px !important;
            margin-right: 20px !important;
            margin-top: 0 !important;
          }
        }
      }
    }
    div + div {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px !important;
      margin-right: 0px !important;
      h2 {
        display: none;
      }
      div {
        display: none;
      }
    }
    div + div + div {
      margin: 20px 0 !important;
      h2 {
        display: block;
        font-size: 20px;
        line-height: 25px;
      }
      a {
        min-width: 282px !important;
        max-width: 282px !important;
        padding-top: 10px;
      }
    }
  }
  @media (max-width: 370px) {
    div {
      width: 320px;
      img {
        margin-right: 5px !important;
      }
    }
    div + div {
      width: 320px;
      a {
        font-size: 14px;
        line-height: 18px;
      }
    }
    div + div + div {
      width: 320px;
      h2 {
        margin-bottom: 6px;
      }
      span {
        width: 302px;
        height: 44px;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }
  @media (max-width: 320px) {
    div {
      img {
        margin-left: 20px;
      }
      div {
        margin-left: 5px !important;
        img {
          margin-left: 0;
        }
      }
    }
    div + div {
      a {
        margin-left: 20px;
      }
    }
    div + div + div {
      h2 {
        margin-left: 20px;
      }
      span {
        margin-left: 20px;
      }
    }
  }
`;

export const FooterLinkBox = styled.div`
  width: ${({ theme }) => theme.width ?? '125px'};
  margin: ${({ theme }) => theme.margin ?? '0px 70px 0px 0px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    text-align: left;
    width: 352px;
    height: 58px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.02%;
    color: #b3b2b3;
    margin-bottom: 22px;
  }
`;
export const FooterTitle = styled.h2`
  width: 288px;
  color: #ffffff;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
`;

export const FooterLinks = styled.a`
  height: 30px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  transition: 200ms;
  text-align: left;
  &:hover {
    color: #25b379;
    transition: 200ms;
  }
  @media (max-width: 956px) {
    color: #b3b2b3;
  }
`;

export const FooterSocialLinksBox = styled.div`
  display: flex;
`;

export const FooterSocialLinks = styled.img`
  width: 18px;
  height: 18px;
  margin: ${({ theme }) => theme.margin ?? '10px 15px 0px 0px'};
  cursor: pointer;
`;

export const FooterDownloadLinksBox = styled.div`
  width: 158px;
  height: 124px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
`;

export const FooterDownloadLink = styled.img`
  cursor: pointer;
  border-radius: 25px;
  margin: ${({ theme }) => theme.margin ?? '0px 0px 0px 0px'};
  :hover {
    background: linear-gradient(90deg, #24b379 0%, #9bda36 100%);
  }
`;

export const FooterBox = styled.div`
  width: ${({ theme }) => theme.width ?? '125px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FooterImageBox = styled.div`
  width: 196px;
  height: 124px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FooterImg = styled.img`
  width: 58px;
  height: 90px;
  margin-right: 20px;
`;

export const FooterPlantTitle = styled.h2`
  width: 118px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  user-select: none;
`;

export const ContainerCopyright = styled.div`
  width: 100%;
  height: 37px;
  border-top: 1px solid #80008c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 240px;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
  }
  @media (max-width: 956px) {
    top: 363px;
  }
`;

import { AxiosResponse } from 'axios';
import { ClientId } from 'model/client';
import { Contract } from 'model/contract';
import { Course } from 'model/courses';
import { CourseFinancing, FinancingResume } from 'model/financing';
import { Linkedguarantors } from 'model/guarantor';
import { PageableResponse } from 'model/pageable';
import { api } from './api';

export const course = () => {
  const LinkedGuarantor = (courseId: number, obj: ClientId[]): Promise<AxiosResponse<ClientId>> => {
    return api.patch<ClientId>(`/courses/${courseId}/guarantors`, obj);
  };

  const courseAnalisys = (courseId: number): Promise<AxiosResponse<Course>> => {
    return api.patch<Course>(`courses/${courseId}/analysis`);
  };

  const courseSummary = (courseId: number): Promise<AxiosResponse<Course>> => {
    return api.get<Course>(`/courses/${courseId}/summary`);
  };

  const courses = (): Promise<AxiosResponse<PageableResponse<Course>>> => {
    return api.get<PageableResponse<Course>>(`/courses?size=99`);
  };

  const coursesByClient = (id?: number): Promise<AxiosResponse<Course[]>> => {
    return api.get<Course[]>(`/courses/clients/${id}`);
  };

  const financingsById = (id?: number): Promise<AxiosResponse<CourseFinancing>> => {
    return api.get<CourseFinancing>(`/financing/${id}/installments`);
  };

  const financingsByClientId = (id?: number): Promise<AxiosResponse<CourseFinancing[]>> => {
    return api.get<CourseFinancing[]>(`/financing/clients/${id}`);
  };
  const coursesToLinkGuarantor = (id: number): Promise<AxiosResponse<Course[]>> => {
    return api.get<Course[]>(`/courses/unlinked?guarantorId=${id}`);
  };

  const newCourse = (obj: Course): Promise<AxiosResponse<Course>> => {
    return api.post<Course>(`/courses`, obj);
  };

  const getLinkedGuarantor = (courseId: number): Promise<AxiosResponse<Linkedguarantors>> => {
    return api.get<Linkedguarantors>(`/courses/${courseId}/guarantors`);
  };

  const getClientBankSlip = (financingId: number, installmentId: number) => {
    return api.get(`/financing/${financingId}/installments/${installmentId}/bank-slip`);
  };
  const getClientDownPaymentBankSlip = (financingId: number) => {
    return api.get(`/financing/${financingId}/down-payment/bank-slip`);
  };

  const unlinkGuarantor = (courseId: number, guarantorId: number): Promise<AxiosResponse<ClientId>> => {
    return api.patch<ClientId>(`/courses/${courseId}/guarantors/${guarantorId}`);
  };
  const updateCourseData = (data: Course): Promise<AxiosResponse<Course>> => {
    return api.patch<Course>('/courses/admin-update', data);
  };

  const courseContracts = (clientId: number): Promise<AxiosResponse<Contract>> => {
    return api.get<Contract>(`/courses/${clientId}/contracts`);
  };

  return {
    LinkedGuarantor,
    courseAnalisys,
    courseSummary,
    courses,
    coursesToLinkGuarantor,
    newCourse,
    getLinkedGuarantor,
    unlinkGuarantor,
    coursesByClient,
    updateCourseData,
    financingsById,
    courseContracts,
    financingsByClientId,
    getClientBankSlip,
    getClientDownPaymentBankSlip,
  };
};

export default course();

import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { FinancingResume, SimulateFinancing, SimulateFinancingResponse } from 'model/financing';
import financing from 'api/financing';
import { Contract, ContractOfClient } from 'model/contract';

export const FinancingService = () => {
  const getFinancing = async (courseId: number): Promise<FinancingResume> => {
    try {
      const result: AxiosResponse<FinancingResume> = await financing.finacingResume(courseId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const simulateFinancing = async (obj: SimulateFinancing): Promise<SimulateFinancingResponse[]> => {
    try {
      const result: AxiosResponse<SimulateFinancingResponse[]> = await financing.simulateFinacing(obj);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const confirmSimulateFinancing = async (financingId: number, obj: SimulateFinancingResponse[]): Promise<SimulateFinancing> => {
    try {
      const result: AxiosResponse<SimulateFinancing> = await financing.confirmSimulateFinacing(financingId, obj);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const ContractsClient = async (clientId: number): Promise<ContractOfClient[]> => {
    try {
      const result: AxiosResponse<ContractOfClient[]> = await financing.getClientContracts(clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getFinancing,
    simulateFinancing,
    confirmSimulateFinancing,
    ContractsClient,
  };
};

export default FinancingService();

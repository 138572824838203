import { BackButton, ButtonsContainer, FormInputsContainer } from 'components/RegistrationForms/styles';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import { useRegisterForm } from 'provider/RegisterForm';
import { useTranslation } from 'react-i18next';

export const ContainerInputsPassword = () => {
  const { isFirstForm, isLastForm, onClickBack } = useRegisterForm();
  const { t } = useTranslation();
  return (
    <FormInputsContainer>
      <TextInput
        name="password"
        label={t('register.inputs.password')}
        placeholder={t('register.placeholder.password')}
        isRequired
        inputType="password"
      />

      <TextInput
        name="confirmPassword"
        label={t('register.inputs.confirmPassword')}
        placeholder={t('register.placeholder.confirmPassword')}
        isRequired
        inputType="password"
      />

      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack} type="button">
            <span>{t('global.button.backStep')}</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};

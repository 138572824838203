import styled from 'styled-components';

interface Props {
  backgroundColor: string;
  primaryTextColor?: boolean;
  active?: boolean;
  inactive?: boolean;
}

export const StatusContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  gap: 10px;
  height: 16px;
  background: ${props => props.backgroundColor};
  border-radius: 10px;
  border: ${props => (props.active ? '1px solid #000' : props.inactive ? '1px solid #7B7B7B' : 'none')};
  width: fit-content;

  span {
    height: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${props => (props.primaryTextColor ? '#fff' : props.inactive ? '#7B7B7B' : '#000')};
  }
`;

import { style } from '@mui/system';
import styled from 'styled-components';

export const Container = styled.div`
  width: 773px;
  max-width: 773px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 50px;
  margin-right: 40px;

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .about {
      display: flex;
      align-items: center;
      .name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
      }
      .separator {
        width: 4px;
        height: 4px;
        background: #80008c;
        border: none;
        border-radius: 50%;
        margin: 10px;
      }
      .document-number {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.541667px;
        color: #000000;
      }
    }
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 20px;
  gap: 8px;
  background: #f8f8f8;
  border-radius: 4px;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
`;
export const ContentLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
`;
export const ContentValue = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3e3e3e;
`;

export const BreakLine = styled.div`
  width: 100%;
  border: 1px solid #e3e3e3;
`;

export const ContainerAttachment = styled.div`
  height: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #f9f3ff;
  border-radius: 6px;
  img {
    width: 12px;
    height: 12px;
  }
  a,
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline !important;
    color: #010101;
    cursor: pointer;
  }
`;

export const Select = styled.select`
  width: 240px;
  height: 32px;
  background: #f6f6f6;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.666667px;
  color: #979797;
  padding: 5px 10px;
`;

export const Option = styled.option``;

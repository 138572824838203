import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 30px;

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .about {
      display: flex;
      align-items: center;
      .name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
      }
      .separator {
        width: 4px;
        height: 4px;
        background: #80008c;
        border: none;
        border-radius: 50%;
        margin: 10px;
      }
      .document-number {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.541667px;
        color: #000000;
      }
    }
  }
`;

export const Title = styled.span`
  display: block;
  width: 100%;
  text-align: left;
  color: #2b1a48;
  font-size: 32px;
  font-weight: 700;

  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  margin-right: 150px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  background-color: #2b1a48;
  position: fixed;
  bottom: 0;
`;

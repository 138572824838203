import ButtonComponent from 'components/Button';
import React, { FormEvent, useEffect, useState } from 'react';
import {
  ContainerInputAndText,
  ContainerInputs,
  ContainerRequestApproval,
  InputStyled,
  ContainerImgAndText,
  ContainerAlltext,
  ContainerCheckBoxText,
} from './styles';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerService from 'services/register-service';
import Approved from '../../images/score-approve.svg';
import Denied from '../../images/score-not-approve.svg';
import Error from '../../images/score-error.svg';
import StringUtils from 'shared/util/string-utils';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';

export interface RequestApproval {
  name: string;
  cpf: string;
  phone: string;
  email: string;
}

const FirstSignUp = () => {
  const { t } = useTranslation();
  const title = 'LandingPage.textRequestAproval.firstText';
  const subTitle = 'LandingPage.textRequestAproval.secondText';
  const [titleAproval, setTitleAproval] = useState<string>(title);
  const [subTitleAproval, setSubTitleAproval] = useState<string>(subTitle);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [nextStep, setNextStep] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showApprovalCredit, setShowApprovalCredit] = useState<boolean>(false);
  const [showDeniedCredit, setShowDeniedCredit] = useState(false);
  const [showErrorCredit, setShowErrorCredit] = useState(false);
  const [cpfErrorMessage, setCpfErrorMessage] = useState<string>('');
  const [load, setLoad] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [key, setKey] = useState<string>('');
  const history = useHistory();

  const handleNextStepToApproval = () => {
    if (titleAproval === title && name?.length > 0) {
      setNextStep(true);
      setTitleAproval('LandingPage.textRequestAproval.thirdText');
      setSubTitleAproval('LandingPage.textRequestAproval.fourthText');
    } else {
      setNextStep(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleShowErrors = () => {
    if (cpf.length === 0 || phone.length === 0 || email.length === 0 || !checked) {
      setShowErrors(true);
    }
  };

  const schemaApproval = yup.object().shape({
    name: yup.string().required(t('LandingPage.form.errorMessage.namePerson')),
    email: yup.string().required(t('LandingPage.form.errorMessage.email')).email(t('LandingPage.form.errorMessage.emailInvalid')),
    phone: yup.string().required(t('LandingPage.form.errorMessage.phone')),
    cpf: yup.string().required(t('LandingPage.form.errorMessage.cpf')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestApproval>({
    resolver: yupResolver(schemaApproval),
  });

  const handleSubmitApproval = async (data: RequestApproval) => {
    try {
      if (checked) {
        setLoad(true);
        const result = await registerService.initialRegistration(data);
        if (result.id) {
          setKey(result?.registrationKey!);
          setLoad(false);
        }

        if (result.creditScore?.approved) {
          setShowApprovalCredit(true);
        } else {
          setShowDeniedCredit(true);
        }
      }
    } catch (error: any) {
      if (error.data.code.includes('cpf')) {
        setCpfErrorMessage(error.data.code);
      }
      setShowErrorCredit(true);
    }
  };

  useEffect(() => {
    if (phone.length >= 10) {
      setPhone(StringUtils.phoneMask(phone));
    }
  }, [phone]);

  useEffect(() => {
    if (cpf.length >= 11) {
      setCpf(StringUtils.cpfFormatter(cpf));
    }
  }, [cpf]);

  const formRequestApprovalCredit = () => {
    return (
      <>
        <span>{t(titleAproval)}</span>
        <span style={{ height: titleAproval !== title ? '50px' : '70px' }}>{t(subTitleAproval)}</span>
        {titleAproval === title ? (
          <>
            <span style={{ marginTop: '68px' }}>
              {t('LandingPage.form.name')} <span>*</span>
            </span>
            <InputStyled
              placeholder={t('LandingPage.form.placeholder.name')}
              style={{ width: '405px', marginLeft: '37px' }}
              {...register('name')}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
              value={name}
            />
            {!nextStep && <span style={{ fontWeight: '300', color: '#ff0045', marginTop: '5px', fontSize: '16px' }}>Nome inválido</span>}
          </>
        ) : (
          <>
            <ContainerInputs>
              <ContainerInputAndText>
                <span style={{ marginTop: '5px' }}>
                  {t('LandingPage.form.cpf')} <span>*</span>
                </span>
                <InputStyled
                  placeholder={t('LandingPage.form.placeholder.cpf')}
                  style={{ width: '194px' }}
                  {...register('cpf')}
                  value={cpf}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setCpf(e.currentTarget.value)}
                  maxLength={14}
                />
                {errors.cpf?.message && showErrors && (
                  <span style={{ fontWeight: '300', color: '#ff0045', marginTop: '5px' }}>{errors.cpf?.message}</span>
                )}
              </ContainerInputAndText>
              <ContainerInputAndText>
                <span style={{ marginTop: '5px', width: '100px' }}>
                  {t('LandingPage.form.phone')} <span>*</span>
                </span>
                <InputStyled
                  placeholder={t('LandingPage.form.placeholder.phone')}
                  style={{ width: '194px' }}
                  {...register('phone')}
                  value={phone}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setPhone(e.currentTarget.value)}
                  maxLength={15}
                />
                {errors.phone?.message && showErrors && (
                  <span style={{ fontWeight: '300', color: '#ff0045', marginTop: '5px' }}>{errors.phone?.message}</span>
                )}
              </ContainerInputAndText>
            </ContainerInputs>
            <span style={{ marginTop: '5px' }}>
              {t('LandingPage.form.email')} <span>*</span>
            </span>
            <InputStyled
              placeholder={t('LandingPage.form.placeholder.email')}
              style={{ width: '405px', marginLeft: '37px' }}
              {...register('email')}
              value={email}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
            />
            {errors.email?.message && showErrors && (
              <span style={{ fontWeight: '300', color: '#ff0045', marginTop: '5px', fontSize: '16px' }}>{errors.email?.message}</span>
            )}
            <ContainerCheckBoxText style={{ height: checked ? '42px' : '25px' }}>
              <Checkbox color="default" checked={checked} onChange={handleChange} />
              <p>
                {t('LandingPage.textRequestAproval.firstTextTerms')}{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://fdi-publico.s3.amazonaws.com/Termos+de+Uso+-+FDI.pdf">
                  {t('LandingPage.textRequestAproval.secondTextTerms')}
                </a>{' '}
                e a{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://fdi-publico.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+FDI.pdf"
                >
                  {t('LandingPage.textRequestAproval.thirdTextTerms')}
                </a>
              </p>
            </ContainerCheckBoxText>
            {!checked && showErrors && (
              <span
                style={{
                  fontWeight: '300',
                  color: '#ff0045',
                  marginTop: '0px',
                  marginLeft: '105px',
                  marginBottom: '10px',
                  fontSize: '16px',
                }}
              >
                Para continuar aceite os termos
              </span>
            )}
          </>
        )}
        {titleAproval === title ? (
          <ButtonComponent
            width="250px"
            height="56px"
            background="#80008C"
            border="none"
            borderRadius="40px"
            color="#fff"
            text="LandingPage.textRequestAproval.button"
            margin={errors.name?.message ? '0 0 20px 115px' : '31px 0 0 115px'}
            onClick={() => handleNextStepToApproval()}
          />
        ) : (
          <ButtonComponent
            width="250px"
            height="56px"
            background={checked ? '#80008C' : '#d2c6da'}
            border="none"
            borderRadius="40px"
            color={checked ? '#fff' : '#979797'}
            text="LandingPage.textRequestAproval.button"
            margin={errors.email?.message && showErrors ? '0 0 20px 115px' : '10px 0 0 115px'}
            onClick={() => handleShowErrors()}
            load={load}
          />
        )}
      </>
    );
  };

  const approvalCredit = () => {
    return (
      <>
        <ContainerImgAndText>
          <ContainerAlltext>
            <span>{t('LandingPage.textRequestAproval.approvalCredit')}</span>
            <span>{t('LandingPage.textRequestAproval.subTitleApprovalCredit')}</span>
          </ContainerAlltext>

          <img src={Approved} alt="woman" />
        </ContainerImgAndText>
        <ButtonComponent
          width="250px"
          height="56px"
          background="#80008C"
          border="none"
          borderRadius="40px"
          color="#fff"
          text="LandingPage.textRequestAproval.buttonCreateAccount"
          margin={errors.email?.message && showErrors ? '0 0 20px 115px' : '31px 0 0 115px'}
          onClick={() => history.push(`/create-account?registrationKey=${key}`)}
        />
      </>
    );
  };

  const deniedCredit = () => {
    return (
      <>
        <ContainerImgAndText>
          <ContainerAlltext>
            <span>{t('LandingPage.textRequestAproval.deniedCredit')}</span>
            <span>{t('LandingPage.textRequestAproval.subtitleDeniedCredit')}</span>
          </ContainerAlltext>

          <img src={Denied} alt="woman" />
        </ContainerImgAndText>
        <ButtonComponent
          width="250px"
          height="56px"
          background="#80008C"
          border="none"
          borderRadius="40px"
          color="#fff"
          text="LandingPage.textRequestAproval.buttonCreateAccount"
          margin={errors.email?.message && showErrors ? '0 0 20px 115px' : '31px 0 0 115px'}
          onClick={() => history.push(`/create-account?registrationKey=${key}`)}
        />
      </>
    );
  };

  const errorCredit = () => {
    return (
      <>
        <ContainerImgAndText>
          <ContainerAlltext>
            <span>{cpfErrorMessage ? t(cpfErrorMessage) : t('LandingPage.textRequestAproval.errorCredit')}</span>
            <span style={{ marginTop: '40px' }}>
              {cpfErrorMessage ? t('LandingPage.textRequestAproval.cpfRegistred') : t('LandingPage.textRequestAproval.subtitleErrorCredit')}
            </span>
          </ContainerAlltext>
          <img src={Error} alt="woman" />
        </ContainerImgAndText>
        {cpfErrorMessage && (
          <ButtonComponent
            width="250px"
            height="56px"
            background="#80008C"
            border="none"
            borderRadius="40px"
            color="#fff"
            text="global.button.goLogin"
            margin={errors.email?.message && showErrors ? '0 0 20px 115px' : '31px 0 0 115px'}
            onClick={() => history.push(`/login`)}
          />
        )}
      </>
    );
  };
  return (
    <ContainerRequestApproval onSubmit={handleSubmit(handleSubmitApproval)}>
      {!showApprovalCredit && !showDeniedCredit && !showErrorCredit && formRequestApprovalCredit()}
      {showApprovalCredit && approvalCredit()}
      {showDeniedCredit && deniedCredit()}
      {showErrorCredit && errorCredit()}
    </ContainerRequestApproval>
  );
};

export default FirstSignUp;

import styled from 'styled-components';

export const SubtitleText = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2b1a48;
  text-align: left;
`;

export const ContainerInputs = styled.div`
  max-width: 976px;
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  div {
    text-align: left;
    flex: 1;
  }

  > div + div {
    margin-left: 24px;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    > div + div {
      margin-left: 0px;
    }
  }
`;

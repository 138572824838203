import styled from 'styled-components';

interface Props {
  isOptionSelected?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const SelectContent = styled.button<Props>`
  width: 375px;
  height: 50px;
  background-color: #f6f6f6;
  border: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    color: ${({ isOptionSelected }) => (isOptionSelected ? '#333333' : '#b3b2b3')};
    margin: 0;
  }

  input {
    display: none;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  margin-top: 0.25rem;

  width: 100%;
  z-index: 10;

  position: absolute;

  button:last-child {
    border: none;
  }

  button {
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #cccccc;
  }
`;

export const Option = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  span {
    display: inline-block;
    padding: 15px;
  }

  :hover {
    background-color: #f6f6f6;
  }
`;

import styled, { css } from 'styled-components';

interface Props {
  isOptionSelected?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;

  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

export const SelectContent = styled.button<Props>`
  width: 100%;
  height: 50px;
  background-color: transparent;
  border-radius: 6px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    color: ${({ isOptionSelected }) => (isOptionSelected ? '#333333' : '#b3b2b3')};
  }

  input {
    background-color: transparent;
    border: none;
    height: 44px;
    flex: 1 1 100%;
  }
`;

export const OptionContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  margin-top: 0.25rem;
  max-height: 200px;

  overflow: auto;

  width: 100%;
  z-index: 10;

  position: absolute;

  button:last-child {
    border: none;
  }

  button {
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #cccccc;
  }
`;

export const Option = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  span {
    display: inline-block;
    padding: 15px;
  }

  :hover {
    background-color: #f6f6f6;
  }
`;

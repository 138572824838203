import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const ContainerButton = styled.div`
  width: 976px;
  display: flex;
  margin-top: 46px;
  img {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const ContainerContent = styled.div`
  width: 976px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const SubtitleText = styled.span`
  min-width: 100px;
  height: 26px;
  margin-bottom: 22px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #2b1a48;
  text-align: left;
`;

export const ContainerData = styled.div`
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7b7b7b;
  }
  span + span {
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
`;

export const TitleButtonBack = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #242424;
  cursor: pointer;
  img {
    margin-bottom: 4px;
    margin-right: 10px;
  }
`;

export const ContainerFinancing = styled.div`
  width: 475px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  img {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  width: 857px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 37px;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  background-color: #fff;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
  }
`;

import courseApi from 'api/course-api';
import HttpStatus from 'model/enums/http-status';
import Trash from '../../../images/deleteTrash.svg';
import { useFinancing } from 'provider/Financing';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import guarantorService from 'services/guarantor.service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { CloseButton, CloseContainer, ConfirmContainer, ContainerFinancing, ContainerFormInput, Title } from '../ModalNewFinancing/styles';
import { Course } from 'model/courses';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { TextInput } from 'components/TextInput';
import { SearchSelectInput } from 'components/SearchSelectInput';
import ButtonComponent from 'components/Button';

interface SimpleModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

interface Ids {
  id: number;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '420px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const ModalLinkFinancing = ({ isOpen, onCloseModal }: SimpleModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const guarantorId = parseInt(history.location.search.split('=')[1]);
  const [load, setLoad] = useState<boolean>(false);
  const [sendOk, setSendOk] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>([]);
  const [coursesSelected, setCoursesSelected] = useState<Course[]>([]);
  const { getGuarantor } = useFinancing();
  const schema = yup.object({
    financing: yup.string().required(t('global.errorMessage.required')),
  });

  useEffect(() => {
    const request = async () => {
      const res = await courseApi.coursesToLinkGuarantor(guarantorId);
      if (res.status === HttpStatus.OK) {
        setCourses(res.data);
      }
    };
    if (isOpen) {
      setSendOk(false);
      request();
    }
  }, [isOpen]);

  const removeCourseSelected = (index: number) => {
    let courses = coursesSelected;
    const newCourses = courses?.filter((element, indexGuarantor) => indexGuarantor !== index);
    setCoursesSelected(newCourses);
  };

  const generateParams = () => {
    let params: string = '';
    coursesSelected.forEach((element, index) => {
      if (element.id !== undefined) {
        params = params + `${index !== 0 ? '&' : ''}courseId=${element.id}`;
      }
    });
    return params;
  };

  const handleAddFunding = async () => {
    setLoad(true);
    if (coursesSelected) {
      const params = generateParams();
      await guarantorService.coursesToLinkGuarantor(guarantorId, params);
      setLoad(false);
      setSendOk(true);
      setCoursesSelected([]);
      getGuarantor(guarantorId);
    }
  };

  const handleOnSubmit = () => {};

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('dashboardClient.financing.modal.linkFinancing')}</Title>
      <ContainerFormInput>
        <div style={{ width: '375px' }}>
          <ReactHookFormProvider schema={schema} onSubmit={handleOnSubmit}>
            <TextInput name="financing" label={t('dashboardClient.financing.modal.linkFinancingLabel')} hasCustomInput isRequired>
              <SearchSelectInput
                name="financing"
                placeholder={t('dashboardClient.financing.modal.linkFinancingPlaceholder')}
                options={courses?.map(course => ({
                  label: `${t('dashboardClient.financing.modal.financingLabel')} ${StringUtils.dateFormatMask(course?.startDate ?? '')}`,
                  value: course?.id?.toString() ?? '',
                }))}
                defaultOptions={courses?.map(course => ({
                  label: `${t('dashboardClient.financing.modal.financingLabel')} ${StringUtils.dateFormatMask(course?.startDate ?? '')}`,
                  value: course?.id?.toString() ?? '',
                }))}
                handleChange={value => {
                  setSendOk(false);
                  let course = courses.find(course => course.id?.toString() === value.value);
                  if (coursesSelected && course) {
                    setCoursesSelected([...coursesSelected, course]);
                  } else if (course) {
                    setCoursesSelected([course]);
                  }
                }}
              />
            </TextInput>
            {coursesSelected &&
              coursesSelected.length > 0 &&
              coursesSelected.map((element, index) => {
                return (
                  <ContainerFinancing key={index}>
                    <span>
                      {t('dashboardClient.financing.modal.financingLabel')} {StringUtils.dateFormatMask(element.startDate ?? '')}
                    </span>
                    <img src={Trash} onClick={() => removeCourseSelected(index)} />
                  </ContainerFinancing>
                );
              })}
            <ConfirmContainer>
              <ButtonComponent
                width="123px"
                height="47px"
                background="transparent"
                border="1px solid #80008C"
                borderRadius="8px"
                color="#80008C"
                text="global.button.cancel"
                weight="600"
                size="18px"
                type="button"
                onClick={onCloseModal}
              />
              <ButtonComponent
                width="123px"
                height="47px"
                background="#80008C"
                border="none"
                borderRadius="8px"
                margin="0 0 0 8px"
                color="white"
                text="global.button.add"
                weight="600"
                size="18px"
                onClick={() => handleAddFunding()}
                load={load}
                sendOk={sendOk}
              />
            </ConfirmContainer>
          </ReactHookFormProvider>
        </div>
      </ContainerFormInput>
    </Modal>
  );
};

export default ModalLinkFinancing;

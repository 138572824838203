import AnalyseRegisterInputsContainer from 'components/analyse-registration-inputs-container/analyse-registration-inputs-container';
import LinkPartnerInputContainer from 'components/link-partner-input-container/link-partner-input-container';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { ContainerInputsCourse } from 'components/RegistrationForms/Course/InputsCourse';
import StyledButton from 'components/StyledButton';
import { Internal } from 'model/internal';
import { Partner, PartnersId } from 'model/partner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ClientProfileService from 'services/client-profile-service';
import * as yup from 'yup';
import { BreakLine, ButtonsContainer, CloseButton, CloseContainer, ContainerFormInput, Title } from './styles';

interface ModalProps {
  onCloseModal: () => void;
  clientId: number;
  partners?: Partner[];
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '776px',
    height: '500px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

interface Schema {
  partnerId: string;
}

const LinkPartnerModal: React.FC<ModalProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);

  useEffect(() => {
    if (props.partners !== undefined) {
      setSelectedPartners(props.partners);
    }
  }, []);

  const schema = yup.object({
    partnerId: yup.string().required(t('global.errorMessage.required')),
  });

  const handleSubmit = async (data: Schema) => {
    const partnersId: PartnersId[] = selectedPartners.map(item => {
      const id = { id: item.id };
      return id;
    });
    const res = await ClientProfileService.linkPartner(props.clientId, partnersId);

    if (res) {
      props.onCloseModal();
      history.go(0);
    }
  };

  return (
    <Modal isOpen style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={props.onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('global.modal.linkPartner')}</Title>
      <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
        <ContainerFormInput>
          <LinkPartnerInputContainer selectedPartners={selectedPartners} setSelectedPartners={setSelectedPartners} />
          <ButtonsContainer>
            <BreakLine />
            <div>
              <StyledButton secondary onClick={props.onCloseModal} text={t('global.button.cancel')} />
              <StyledButton primary type="submit" text={t('global.button.confirm')} />
            </div>
          </ButtonsContainer>
        </ContainerFormInput>
      </ReactHookFormProvider>
    </Modal>
  );
};

export default LinkPartnerModal;

import { FormInputsContainer } from 'components/RegistrationForms/styles';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { Options, SelectInput } from 'components/SelectInput';
import { TextInput } from 'components/TextInput';
import Trash from 'images/deleteTrash.svg';
import { Partner } from 'model/partner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientProfileService } from 'services/client-profile-service';
import partnerService from 'services/partner.service';
import { ContainerFinancing, SelectedPartnersContainer } from './styles';

interface LinkPartnerInputContainerProps {
  defaultValue?: Options;
  selectedPartners: Partner[];
  setSelectedPartners: (value) => void;
}

const LinkPartnerInputContainer: React.FC<LinkPartnerInputContainerProps> = props => {
  const { t } = useTranslation();

  const [partnersList, setPartnersList] = useState<Partner[]>([]);

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    const partners = await partnerService.getPartners();
    setPartnersList(partners.content ?? []);
  };

  const removePartnerSelected = (index: number) => {
    const partners = props.selectedPartners;
    const newPartner = partners.filter((element, indexPartner) => indexPartner !== index);
    props.setSelectedPartners(newPartner);
  };

  return (
    <FormInputsContainer>
      <TextInput name="partnerId" label={t('dashboardClient.financing.modal.label')} hasCustomInput>
        <SearchSelectInput
          name="partnerId"
          placeholder={t('global.placeholder.selectPartner')}
          options={partnersList?.map(guarantor => ({ label: guarantor.name ?? '', value: guarantor?.id?.toString() ?? '' }))}
          defaultOptions={partnersList?.map(guarantor => ({ label: guarantor.name ?? '', value: guarantor?.id?.toString() ?? '' }))}
          defaultValue={props.selectedPartners?.[0]?.name}
          handleChange={value => {
            if (partnersList && value.value && props.selectedPartners.length <= 3) {
              props.setSelectedPartners([
                ...props.selectedPartners,
                partnersList.find(guarantor => guarantor.id?.toString() === value.value)!,
              ]);
            }
          }}
        />
      </TextInput>
      <SelectedPartnersContainer>
        {props.selectedPartners &&
          props.selectedPartners.map((partner, index) => (
            <ContainerFinancing key={index}>
              <span>{partner.name}</span>
              <img src={Trash} onClick={() => removePartnerSelected(index)} />
            </ContainerFinancing>
          ))}
      </SelectedPartnersContainer>
    </FormInputsContainer>
  );
};

export default LinkPartnerInputContainer;

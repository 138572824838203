import StyledButton from 'components/StyledButton';
import Footer from 'features/footer/footer';
import { RegisterHeader } from 'components/HeaderRegister';
import { Container, Content, TextContainer } from './styles';
import Sitting from '../../../../images/sitting.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MinorRejectScreen = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Container>
      <RegisterHeader />
      <Content>
        <TextContainer>
          <span>{t('register.minorReject.title')}</span>
          <span>{t('register.minorReject.subtitle')}</span>
          <StyledButton primary text={t('register.minorReject.buttonText')} onClick={() => history.push('/create-account')} />
        </TextContainer>
        <img src={Sitting} />
      </Content>
      <Footer />
    </Container>
  );
};

import { color } from 'shared/util/styled-component-utils';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 9px;
`;

export const Document = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyDocument = styled.div`
  flex: 1 1;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${color`formSecond`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyMessage = styled.div`
  color: ${color`gray5`};
  font-size: 16px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  i {
    color: ${color`statusNew`};
    font-size: 36px;
    margin-bottom: 16px;
  }
`;

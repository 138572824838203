import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';

import {
  CloseButton,
  CloseContainer,
  ConfirmContainer,
  ContainerFinancing,
  ContainerFormInput,
  Title,
  ContainerInputs,
  TitleDate,
  Subtitle,
  ContainerFinalization,
  Circle,
  TitleFinalization,
  ContainerCalendar,
  CircleSelected,
  ContainerSubtitle,
  ContainerButton,
  modalStyles,
} from './styles';
import ButtonComponent from 'components/Button';
import CalendarIcon from 'images/calendarGrey.svg';
import { addDays, format } from 'date-fns';
import { SearchSelectInput } from 'components/SearchSelectInput';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import { SendToSignatory } from 'model/contract';
import clientProfileService from 'services/client-profile-service';

interface SimpleModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  idContract: number;
  setStatusContract: (status: string) => void;
}

const ModalSendToSignature = ({ isOpen, onCloseModal, idContract, setStatusContract }: SimpleModalProps) => {
  const REMINDER_SIGNATURE_PERIODS = [3, 6, 9, 12];
  const { t } = useTranslation();
  const [load, setLoad] = useState<boolean>(false);
  const [autoClose, setAutoClose] = useState<boolean>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [sendOk, setSendOk] = useState<boolean>(false);
  const [dateState, setDateState] = useState<Date>();
  const [periodSelected, setPeriodSelected] = useState<number>();

  const schema = yup.object({
    period: yup.number(),
  });

  const handleCalendarShow = () => {
    setShowCalendar(!showCalendar);
  };

  const changeDate = e => {
    setDateState(e);
    setShowCalendar(false);
  };

  const handleSendToSignature = async () => {
    console.log(dateState, autoClose);
    if (dateState && autoClose !== undefined) {
      setLoad(true);
      const sendToSignature: SendToSignatory = {
        document: {
          deadline_at: format(dateState, 'yyyy-MM-dd'),
          auto_close: autoClose,
          remind_interval: periodSelected ?? 0,
        },
      };
      const res = await clientProfileService.sendToSignature(sendToSignature, idContract);
      if (res) {
        setLoad(false);
        setSendOk(true);
        setStatusContract('IN_PROGRESS');
        onCloseModal();
      }
    }
  };

  const handleOnSubmit = () => {};

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('global.modal.sendToSignature')}</Title>
      <ContainerFormInput>
        <ReactHookFormProvider schema={schema} onSubmit={() => {}}>
          <ContainerInputs onClick={() => handleCalendarShow()}>
            <TitleDate>{dateState ? format(dateState, 'dd/MM/yyyy') : t('global.modal.reportLimitDate')}</TitleDate>
            <img src={CalendarIcon} />
          </ContainerInputs>
          <ContainerCalendar showCalendar={showCalendar}>
            <Calendar onChange={changeDate} value={dateState} maxDate={addDays(new Date(), 90)} minDate={addDays(new Date(), 1)} />
          </ContainerCalendar>
          <SearchSelectInput
            name="period"
            placeholder={t('global.modal.interval')}
            options={REMINDER_SIGNATURE_PERIODS?.map(periodElement => ({
              label: `${t('global.modal.remember')} ${periodElement} ${t('global.modal.day')}(s)` ?? '',
              value: periodElement.toString() ?? '',
            }))}
            defaultOptions={REMINDER_SIGNATURE_PERIODS?.map(periodElement => ({
              label: `${t('global.modal.remember')} ${periodElement} ${t('global.modal.day')}(s)` ?? '',
              value: periodElement.toString() ?? '',
            }))}
            handleChange={value => {
              if (value.value) {
                setPeriodSelected(Number(value.value));
              }
            }}
          />
          <ContainerSubtitle style={{ marginTop: '25px' }}>
            <Subtitle>{t('global.modal.finalizationDocument')}</Subtitle>
          </ContainerSubtitle>
          <ConfirmContainer style={{ marginTop: 0 }}>
            <ConfirmContainer style={{ marginLeft: '22px' }}>
              <ContainerFinalization onClick={() => setAutoClose(false)} style={autoClose === false ? { background: '#2B1A48' } : {}}>
                {autoClose === false ? <CircleSelected /> : <Circle />}
                <TitleFinalization style={autoClose === false ? { color: '#fff' } : {}}>{t('global.modal.deadline')}</TitleFinalization>
              </ContainerFinalization>
            </ConfirmContainer>
            <ConfirmContainer style={{ marginLeft: '22px' }}>
              <ContainerFinalization onClick={() => setAutoClose(true)} style={autoClose === true ? { background: '#2B1A48' } : {}}>
                {autoClose === true ? <CircleSelected /> : <Circle />}
                <TitleFinalization style={autoClose === true ? { color: '#fff' } : {}}>{t('global.modal.autoFinish')}</TitleFinalization>
              </ContainerFinalization>
            </ConfirmContainer>
          </ConfirmContainer>
          <ContainerButton>
            <ButtonComponent
              background="transparent"
              border="1px solid #D8D8D8"
              borderRadius="8px"
              color="#979797"
              text="global.button.backStep"
              type="button"
              onClick={onCloseModal}
            />
            <ButtonComponent
              margin="0 0 0 8px"
              text="global.button.send"
              onClick={() => handleSendToSignature()}
              load={load}
              sendOk={sendOk}
            />
          </ContainerButton>
        </ReactHookFormProvider>
      </ContainerFormInput>
    </Modal>
  );
};

export default ModalSendToSignature;

import React, { useEffect, useState } from 'react';
import { ContainerButton, ContainerInfos, ContainerTitleAndInfo, ErrorMessage, Line, SimulatorTitle, TextInfo, TextTitle } from '../styles';
import ButtonComponent from 'components/Button';
import { useFinancing } from 'provider/Financing';
import StringUtils from 'shared/util/string-utils';
import { useTranslation } from 'react-i18next';
import ModalConfirmSimulation from 'components/Modais/ModalConfirmSimulate';
import { SimpleModal } from 'components/SimpleModal';
import Check from '../../../images/modalCheckGreen.svg';
import EmailConfirm from 'components/Modais/EmailValidation';
import Error from '../../../images/error.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import customerApi from 'api/customer-api';
import WaitValidation from 'components/Modais/WaitValidation';
import { FinancingResponseType, SimulateFinancingResponse } from 'model/financing';
import { useHistory } from 'react-router-dom';
import { getEmailRequest } from 'reducer/authentication/actions';

interface Props {
  setShowResults: (show: boolean) => void;
  income: number;
}

const SimulatorResult = ({ setShowResults, income }: Props) => {
  const emailValidation = useSelector((state: IRootState) => state.authentication.emailValidation);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [financingSimulated, setFinancingSimulated] = useState<SimulateFinancingResponse>({} as SimulateFinancingResponse);
  const [isOpenModalEmailValidation, setIsOpenModalEmailValidation] = useState(false);
  const [isOpenModalWaitValidation, setIsOpenModalWaitValidation] = useState(false);
  const [isLoadingResendEmail, setIsLoadingResendEmail] = useState(false);
  const [confirmationOfSimulation, setConfirmationOfSimulation] = useState(false);
  const [errorValueInstallments, setErrorValueInstallments] = useState(false);
  const [incomeClient, setIncomeClient] = useState<number>(income);
  const { mockFinancing, financing } = useFinancing();
  const { t } = useTranslation();
  const choosedValueFormatted = StringUtils.moneyMaskPtbr(financingSimulated.valor_solicitado);
  const entryValueFormatted = StringUtils.moneyMaskPtbr(
    mockFinancing.find(element => element.type === FinancingResponseType.DOWN_PAYMENT)?.valor_total_emprestimo
  );
  const installmentsValueFormatted = StringUtils.moneyMaskPtbr(financingSimulated.valor_primeira_parcela);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    dispatch(getEmailRequest());
  }, []);

  const confirmSimulation = () => {
    if (!emailValidation) {
      setIsOpenModalEmailValidation(true);
    } else {
      setConfirmationOfSimulation(true);
    }
  };

  const closeConfirmSimulation = () => {
    setConfirmationOfSimulation(false);
  };

  const closeEmailValidation = () => {
    setIsOpenModalEmailValidation(false);
  };

  const closeWaitValidation = () => {
    setIsOpenModalWaitValidation(false);
  };

  const openWaitValidation = () => {
    setIsOpenModalWaitValidation(true);
  };

  const resendEmail = async () => {
    setIsLoadingResendEmail(true);
    try {
      await customerApi.resendEmail();
      setIsLoadingResendEmail(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const mockFinancingSimulated = mockFinancing.find(element => element.type === 'FINANCING');
    if (mockFinancingSimulated) {
      setFinancingSimulated(mockFinancingSimulated);
    }
  }, [mockFinancing]);

  useEffect(() => {
    if (financingSimulated.valor_primeira_parcela > incomeClient * 0.6) {
      setErrorValueInstallments(true);
    } else {
      setErrorValueInstallments(false);
    }
  }, [financingSimulated]);

  return (
    <>
      <SimulatorTitle>{t('dashboardClient.financing.simulator.result')}</SimulatorTitle>
      <ContainerInfos diraction="column" height="164px" margin="25px 0px 0px 0px" style={{ justifyContent: 'flex-start' }}>
        <ContainerTitleAndInfo align="center" margin="20px 0 0 0">
          <TextTitle>{t('dashboardClient.financing.simulator.entryValue')}</TextTitle>
          <TextInfo size="24px">{entryValueFormatted}</TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo align="center" margin="20px 0 0 0">
          <TextTitle>{t('dashboardClient.financing.simulator.requestedCredit')}</TextTitle>
          <TextInfo size="24px">{choosedValueFormatted}</TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ContainerInfos diraction="column" height="156px" margin="8px 0px 0px 0px" style={{ justifyContent: 'flex-start' }}>
        <ContainerInfos diraction="row" height="67px" margin="14px 0px 0px 0px" style={{ justifyContent: 'flex-start' }}>
          <ContainerTitleAndInfo align="flex-start" margin="0px 20px 0px 20px">
            <TextTitle>{t('dashboardClient.financing.simulator.fees')}</TextTitle>
            <TextInfo size="16px">{financingSimulated?.taxa_mensal_emprestimo}% a.m.</TextInfo>
            <Line width="132px" margin="5px 0 0 0" />
          </ContainerTitleAndInfo>
          <ContainerTitleAndInfo align="flex-start">
            <TextTitle>CET</TextTitle>
            <TextInfo size="16px">{financingSimulated?.taxa_anual_emprestimo?.toFixed(2)}% a.a.</TextInfo>
            <Line width="132px" margin="5px 0 0 0" />
          </ContainerTitleAndInfo>
        </ContainerInfos>
        <ContainerInfos diraction="row" height="67px" margin="0px 0px 0px 0px" style={{ justifyContent: 'flex-start' }}>
          <ContainerTitleAndInfo align="flex-start" margin="0px 35px 0px 20px">
            <TextTitle>{t('dashboardClient.financing.simulator.installment')}</TextTitle>
            <TextInfo size="16px">
              {financingSimulated?.numero_parcelas}x {installmentsValueFormatted}
            </TextInfo>
          </ContainerTitleAndInfo>
          <ContainerTitleAndInfo align="flex-start" style={{ marginLeft: '20px' }}>
            <TextTitle>{t('dashboardClient.financing.simulator.dueDate')}</TextTitle>
            <TextInfo size="16px">
              {financingSimulated?.parcelas && StringUtils.dateFormatMask(financingSimulated?.parcelas[0]?.data_vencimento)}
            </TextInfo>
          </ContainerTitleAndInfo>
        </ContainerInfos>
        {errorValueInstallments && <ErrorMessage>{t('dashboardClient.financing.simulator.errorInstallments')}</ErrorMessage>}
      </ContainerInfos>
      <ContainerButton>
        <ButtonComponent
          width="133px"
          height="47px"
          background="transparent"
          border="none"
          borderRadius="none"
          color="#80008C"
          text={t('global.button.cancel')}
          weight="600"
          size="18px"
          margin="0px 8px 0px 0px"
          onClick={() => setShowResults(false)}
        />
        <ButtonComponent
          width="133px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="white"
          text={t('global.button.confirm')}
          weight="600"
          size="18px"
          margin="0px 0px 0px 0px"
          disabled={errorValueInstallments}
          onClick={() => setIsOpenModal(true)}
        />
      </ContainerButton>
      <ModalConfirmSimulation isOpen={isOpenModal} onCloseModal={closeModal} confirmSimulation={confirmSimulation} />
      <EmailConfirm
        imageSrc={emailValidation ? Check : Error}
        isOpen={isOpenModalEmailValidation}
        handleConfirm={() => {}}
        titleText="global.button.errorMessage.emailValidation"
        messageText="global.button.errorMessage.decriptionEmailValidation"
        onCloseModal={closeEmailValidation}
        resendEmail={resendEmail}
        isloadingResend={isLoadingResendEmail}
        openWaitValidation={openWaitValidation}
      />
      <WaitValidation
        isOpen={isOpenModalWaitValidation}
        onCloseModal={closeWaitValidation}
        titleText="global.button.errorMessage.waitValidation"
        confirmSimulation={confirmSimulation}
      />
      <SimpleModal
        isOpen={confirmationOfSimulation}
        onCloseModal={closeConfirmSimulation}
        imageSrc={Check}
        titleText={t('dashboardClient.financing.modal.simulateCompleted')}
        messageText=""
        confirmText={'OK'}
        handleConfirm={() => window.location.reload()}
      />
    </>
  );
};

export default SimulatorResult;

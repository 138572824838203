import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { SendToSignatory } from 'model/contract';
import { Course } from 'model/courses';
import { Partner, PartnersId } from 'model/partner';
import { Score } from 'model/score';
import { AnalyseFinancingParams, AnalyseRegistrationParams } from 'services/client-profile-service';
import { api } from './api';

export interface PatchResponse {
  id: number;
}

export const ClientProfileApi = () => {
  const getClientSummary = (id: number, courseId: number | undefined): Promise<AxiosResponse<Client>> => {
    if (courseId !== undefined) {
      return api.get(`/clients/${id}/courses/${courseId}/summary`);
    }
    return api.get(`/clients/${id}/summary`);
  };
  const getClientSummaryWithoutCourse = (id: number): Promise<AxiosResponse<Client>> => {
    return api.get(`/clients/${id}/summary`);
  };
  const analyseRegistration = (params: AnalyseRegistrationParams): Promise<AxiosResponse<PatchResponse>> => {
    return api.patch(`/clients/${params.id}/analyze-registration`, params);
  };
  const analyseFinancing = (params: AnalyseFinancingParams): Promise<AxiosResponse<PatchResponse>> => {
    return api.patch(`/clients/${params.id}/analyze-course`, params);
  };
  const linkPartner = (clientId: number, partnersId: PartnersId[]): Promise<AxiosResponse<PatchResponse>> => {
    return api.patch(`/clients/${clientId}/partners`, {
      id: clientId,
      internals: partnersId,
    });
  };
  const updateClientData = (data: Client, id: number): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>(`/clients/${id}/admin-update`, data);
  };

  const sendToSignature = (data: SendToSignatory, idContract: number): Promise<AxiosResponse<SendToSignatory>> => {
    return api.patch<SendToSignatory>(`/contracts/${idContract}/send-to-sign`, data);
  };

  const clientRecalculateScore = (id: number): Promise<AxiosResponse<Score>> => {
    return api.post<Score>('/credit-score/client-analysis/check', { id });
  };

  return {
    getClientSummary,
    analyseRegistration,
    linkPartner,
    analyseFinancing,
    updateClientData,
    getClientSummaryWithoutCourse,
    sendToSignature,
    clientRecalculateScore,
  };
};

export default ClientProfileApi();

import styled, { css } from 'styled-components';

interface Props {
  width: string;
  height: string;
  color: string;
  background: string;
  border: string;
  borderRadius: string;
  margin?: string;
  weight: string;
  size?: string;
  disabled?: boolean;
}

export const ButtonStyled = styled.button<Props>`
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ margin }) => margin};
  font-family: 'Outfit';
  font-style: normal;
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  span {
    width: 10px !important;
    height: 10px !important;
    margin: 2px !important;
    line-height: 25px !important;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #d3acd6;
      cursor: inherit;
    `}
`;

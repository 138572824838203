import { Container } from 'reactstrap';
import { color, font } from 'shared/util/styled-component-utils';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';
import Footer from 'features/footer/footer';
import 'styles/constants/fonts.scss';

const GlobalStyle = createGlobalStyle`
  html body {
    font-family: ${font`default`};
    color: ${color`primary`};
  }
  h1 {
    font-size: 2rem;
    font-family: ${font`title`};
    color: ${color`title`};
    font-weight: 600;
  }
  button[type=submit]:not(:disabled), button:not(:disabled) {
    outline: 0 !important;
  }
  *:focus {
    outline: 0 !important;
  }
  button:focus {
    /* box-shadow: 0 0 2px 4px rgb(255 0 0 / 25%); */
  }
`;

export const TagItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FooterGray = styled(Footer)`
  border-color: ${color`lightGray`};
  background: white;
  position: absolute;

  div {
    color: ${color`gray`};
  }
`;

export const SubtitleGray = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: ${color`black2`};
`;

export const MinHeightScreeningContainer = styled(Container)`
  min-height: calc(100vh - 38px);
  padding-top: 68px;
  padding-left: 200px;
`;
export const MinHeightScreeningContainerPj = styled(Container)`
  min-height: calc(100vh - 106px);
  padding-left: 260px;
  padding-top: 92px;
`;

export const TitleText = styled.div`
  color: black;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  font-family: ${font`title`};
`;

export default GlobalStyle;

import courseApi from 'api/course-api';
import guarantorApi from 'api/guarantor-api';
import { Contract, ContractOfClient, InfoContract } from 'model/contract';
import { Course } from 'model/courses';
import { FinancingResume, SimulateFinancing, SimulateFinancingResponse } from 'model/financing';
import { GuarantorProps } from 'model/guarantor';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import CourseService from 'services/course.service';
import financingService from 'services/financing.service';
import guarantorService from 'services/guarantor.service';

interface FinancingData {
  courses: Course[];
  contract: ContractOfClient[] | undefined;
  financing: FinancingResume;
  EntryValue: number;
  mockFinancing: SimulateFinancingResponse[];
  guarantors: GuarantorProps[];
  guarantor: GuarantorProps | undefined;
  linkedGuarantors: GuarantorProps[];
  course: Course | undefined;
  getCourses: () => void;
  getGuarantors: () => void;
  getGuarantor: (guarantorId: number) => void;
  getLinkedGuarantors: (courseId: number) => void;
  getCourse: (courseId: number) => void;
  getFinancing: (courseId: number) => void;
  setEntryValue: (value: number) => void;
  simulateFinancing: (financing: SimulateFinancing) => void;
  confirmSimulateFinancing: (financingId, simulation: SimulateFinancingResponse[]) => void;
  getContracts: (clientId: number) => void;
}

interface FinancingProps {
  children: ReactNode;
}

const FinancingContext = createContext<FinancingData>({} as FinancingData);

export const FinancingProvider = ({ children }: FinancingProps) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [course, setCourse] = useState<Course>();
  const [contract, setContract] = useState<ContractOfClient[]>();
  const [financing, setFinancing] = useState<FinancingResume>({} as FinancingResume);
  const [EntryValue, setEntryValue] = useState<number>(0);
  const [mockFinancing, setMockFinancing] = useState<SimulateFinancingResponse[]>([]);
  const [guarantors, setGuarantors] = useState<GuarantorProps[]>([]);
  const [guarantor, setGuarantor] = useState<GuarantorProps>();
  const [linkedGuarantors, setLinkedGuarantors] = useState<GuarantorProps[]>([]);

  const getCourses = async () => {
    const res = await CourseService.getCourses();
    setCourses(res.content);
  };

  const getCourse = async (courseId: number) => {
    const res = await CourseService.getCourse(courseId);
    setCourse(res);
    setLinkedGuarantors(res.guarantors ?? []);
  };

  const getFinancing = async (courseId: number) => {
    const res = await financingService.getFinancing(courseId);
    setFinancing(res);
  };

  const simulateFinancing = async (financing: SimulateFinancing) => {
    const res = await financingService.simulateFinancing(financing);
    setMockFinancing(res);
  };

  const confirmSimulateFinancing = async (financingId: number, simulate: SimulateFinancingResponse[]) => {
    await financingService.confirmSimulateFinancing(financingId, simulate);
  };

  const getGuarantors = async () => {
    const res = await guarantorService.getGuarantors();
    setGuarantors(res);
  };

  const getGuarantor = async (guarantorId: number) => {
    const res = await guarantorService.getGuarantor(guarantorId);
    setGuarantor(res);
  };

  const getLinkedGuarantors = async (courseId: number) => {
    const res = await CourseService.getLinkedGuarantor(courseId);
    setLinkedGuarantors(res.guarantors);
  };

  const getContracts = async (clientId: number) => {
    const res = await financingService.ContractsClient(clientId);
    setContract(res);
  };

  return (
    <FinancingContext.Provider
      value={{
        course,
        courses,
        financing,
        EntryValue,
        mockFinancing,
        guarantors,
        guarantor,
        linkedGuarantors,
        getCourses,
        getCourse,
        getFinancing,
        setEntryValue,
        simulateFinancing,
        confirmSimulateFinancing,
        getGuarantors,
        getGuarantor,
        getLinkedGuarantors,
        getContracts,
        contract,
      }}
    >
      {children}
    </FinancingContext.Provider>
  );
};

export const useFinancing = () => useContext(FinancingContext);

import { Box, Tab, Tabs } from '@mui/material';
import SubStyledTabs from 'components/sub-styled-tabs/sub-styled-tabs';
import { GuarantorProps } from 'model/guarantor';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GuarantorAddressData from './guarantors-data/address-data';
import GuarantorAttachmentsData from './guarantors-data/attachments-data';
import GuarantorGeneralData from './guarantors-data/general-data';

interface GuarantorsTabsProps {
  guarantors?: GuarantorProps[];
  handleOpenScoreModal: (id) => void;
  setActiveGuarantor?: (value: number) => void;
  activeGuarantor?: number;
}

const GuarantorsTabs: React.FC<GuarantorsTabsProps> = props => {
  const { t } = useTranslation();
  const [selectedGuarantor, setSelectedGuarantor] = useState<number>(props.activeGuarantor ?? 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedGuarantor(newValue);
  };

  useEffect(() => {
    props.setActiveGuarantor && props.setActiveGuarantor(selectedGuarantor);
  }, [selectedGuarantor]);

  return props.guarantors && props.guarantors!.length > 0 ? (
    <>
      <Tabs
        value={props.guarantors.find(guarantor => guarantor.id === selectedGuarantor)?.id ?? selectedGuarantor}
        onChange={handleChange}
        scrollButtons="auto"
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ borderBottom: 1, borderColor: '#D8D8D8' }}
        centered
      >
        {props.guarantors && props.guarantors.map((item, i) => <Tab key={i} value={item.id} label={`Fiador ${i + 1}`} />)}
      </Tabs>

      <SubStyledTabs
        tabs={[
          {
            title: t('global.subTabs.guarantorGeneralData'),
            content: (
              <GuarantorGeneralData
                handleOpenScoreModal={props.handleOpenScoreModal}
                guarantorData={
                  selectedGuarantor === 0 ? props.guarantors?.[0] : props.guarantors?.find(guarantor => guarantor.id === selectedGuarantor)!
                }
              />
            ),
          },
          {
            title: t('global.subTabs.guarantorAddress'),
            content: (
              <GuarantorAddressData
                guarantorData={
                  selectedGuarantor === 0 ? props.guarantors?.[0] : props.guarantors?.find(guarantor => guarantor.id === selectedGuarantor)!
                }
              />
            ),
          },
          {
            title: t('global.subTabs.guarantorAttachments'),
            content: (
              <GuarantorAttachmentsData
                guarantorData={
                  selectedGuarantor === 0 ? props.guarantors?.[0] : props.guarantors?.find(guarantor => guarantor.id === selectedGuarantor)!
                }
              />
            ),
          },
        ]}
      />
    </>
  ) : (
    <> </>
  );
};

export default GuarantorsTabs;

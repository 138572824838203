import { yupResolver } from '@hookform/resolvers/yup';
import customerApi from 'api/customer-api';
import guarantorApi from 'api/guarantor-api';
import ButtonComponent from 'components/Button';
import { ContainerEditData, Schema } from 'components/ContainerEditData';
import { FormPersonalData } from 'components/formPersonalData';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { SimpleModal } from 'components/SimpleModal';
import { AddressOccupationIds } from 'model/address';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import HttpStatus from 'model/enums/http-status';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { useRegisterForm } from 'provider/RegisterForm';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';
import * as yup from 'yup';
import ArrowLeft from '../../images/arrowLeft.svg';
import Check from '../../images/modalCheckGreen.svg';
import { Container, ContainerBackStep, ContainerButtons, ContainerContent, ContainerInputs, SubtitleText, TitleText } from './styles';

const PersonalDataEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { files } = useRegisterForm();
  const [addressOccupationIds, setAddressOccupationIds] = useState<AddressOccupationIds>();
  const [openModal, setOpenModal] = useState(false);
  const [personalData, setPersonalData] = useState<Client>();

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required(),
    cpf: yup.string().required(),
    income: yup.string().required(),
    office: yup.string().required(),
    birth: yup.string().required(),
    zipcode: yup.string().required(),
    address: yup.string().required(),
    district: yup.string().required(),
    number: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    complement: yup.string().required(),
    rg: yup.string().required(),
    password: yup.string().optional().max(100, t('global.errorMessage.maxPassword')),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('global.errorMessage.confirmPassword')),
    attachmentIdentification: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.IDENTIFICATION_CARD)[0] ?? value[0])
      .required(),
    attachmentIncome: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.PROOF_INCOME)[0] ?? value[0])
      .required(),
    attachmentAddress: yup
      .mixed()
      .transform(value => personalData?.attachments?.filter(it => it.type === AttachmentType.PROOF_RESIDENCE)[0] ?? value[0])
      .required(),
  });

  useEffect(() => {
    const request = async () => {
      const res = await customerApi.getCustomerInfo();
      setPersonalData(res.data);
    };
    request();
  }, []);

  const removeAndAddFile = (attachments: Attachment[]) => {
    if (files.length > 0) {
      files.map(file => {
        const attIndex = attachments?.findIndex(att => att.type === file.type);

        if (attIndex !== undefined && attIndex >= 0) {
          attachments = attachments?.filter((att, index) => index !== attIndex);
          attachments?.push(file);
        }

        if (attIndex === -1) {
          attachments?.push(file);
        }
      });
    }
    return attachments;
  };

  const onSubmitPersonalDataEdit = async (data: Schema) => {
    const attachments = removeAndAddFile(personalData?.attachments ?? []);

    const { address, birth, zipcode, complement, email, district, income, name, number, office, phone, state, rg, password } = data;

    let personalDataEdit: Client = {
      id: personalData?.id,
      attachments,
      address: {
        id: personalData?.address?.id,
        city: {
          id: addressOccupationIds?.cityId ?? personalData?.address?.city?.id ?? 0,
        },
        complement,
        district,
        number,
        street: address,
        zipcode,
      },
      birth: new Date(birth.split('/').reverse().join('-')),
      email,
      income: unMaskedCurrency(data.income) ?? personalData?.income,
      name,
      occupation: {
        id: addressOccupationIds?.occupationId ?? personalData?.occupation?.id ?? 0,
      },
      phone,
      rg,
    };
    if (password) {
      personalDataEdit.user = {
        password,
      };
    }
    const unMaskedDatas = unMaskedFieldsValues(personalDataEdit, true);
    unMaskedDatas.income = Number(unMaskedDatas.income) / 100;

    const res = await customerApi.editCustomer(unMaskedDatas);
    if (res.status === HttpStatus.OK) {
      setOpenModal(true);
    }
  };

  return (
    <Container>
      <ContainerContent>
        <ContainerEditData
          schema={schema}
          editData={personalData}
          onSubmit={onSubmitPersonalDataEdit}
          setAddressOccupationIds={setAddressOccupationIds}
          addressOccupationIds={addressOccupationIds}
        />
      </ContainerContent>
      <SimpleModal
        isOpen={openModal}
        onCloseModal={() => setOpenModal(false)}
        imageSrc={Check}
        titleText={'dashboardClient.personalData.modal.clientEdit'}
        messageText=""
        confirmText={'OK'}
        handleConfirm={() => history.goBack()}
      />
    </Container>
  );
};

export default PersonalDataEdit;

import StyledInputWrapper from 'components/StyledInputWrapper';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Container, Option, OptionContainer, SelectContent } from './styles';

interface Options {
  label: string;
  value: string;
}

export interface Values {
  name: string;
  value?: string;
}

interface SelectInputProps {
  name: string;
  placeholder: string;
  options?: Options[];
  defaultOptions?: Options[];
  defaultValue?: string;
  handleChange?: (values: Values) => void;
  handleOnScroll?: () => void;
  errorMessage?: string;
  showErrorIcon?: boolean;
  disabled?: boolean;
  resetValue?: boolean;
}

export const SearchSelectInput = ({
  name,
  options,
  placeholder,
  defaultOptions,
  defaultValue,
  handleChange,
  handleOnScroll,
  errorMessage,
  showErrorIcon,
  disabled = false,
  resetValue,
}: SelectInputProps) => {
  const [optionSelected, setOptionSelected] = useState<Options | null>(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [timeOutSearch, setTimeOutSearch] = useState<NodeJS.Timeout | null>(null);

  const {
    register,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (defaultValue) setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setValue(name, inputValue);

    if (timeOutSearch != null) {
      clearTimeout(timeOutSearch);
    }

    setTimeOutSearch(
      setTimeout(() => {
        if (handleChange) {
          handleChange({ name: inputValue, value: optionSelected?.value });
        }
      }, 500)
    );
  }, [inputValue]);

  useEffect(() => {
    if (resetValue) {
      setValue(name, '');
      setInputValue('');
      setOptionSelected(null);
      setOpenOptions(false);
      setIsFocused(false);
    }
  }, [resetValue]);

  const handleGetValue = () => {
    const values = getValues();
    return Boolean(values[name]);
  };

  const hasDefaultValue = () => {
    if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
      return true;
    }
    return dirtyFields[name];
  };

  const hasOptionValue = () => {
    if (optionSelected != null) {
      return true;
    }

    return false;
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleRegister = register(name);

  const styledComponentsProps = {
    isTouched: isFocused,
    hasError: !disabled ? ((!!errors[name] && inputValue === '') || errorMessage != null) && !isFocused : false,
    hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
    isFocused,
    isValid: !disabled
      ? (!errors[name] && !isFocused && (handleGetValue() || hasDefaultValue())) || handleGetValue() || defaultValue != null
      : false,
  };
  return (
    <Container>
      <StyledInputWrapper {...styledComponentsProps} style={{ padding: 0 }}>
        <SelectContent type="button" onClick={() => setOpenOptions(!openOptions)} isOptionSelected={optionSelected?.label != null}>
          <input
            id={`#select-${name}`}
            {...register(name)}
            placeholder={placeholder}
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
              setOptionSelected(null);
              setOpenOptions(true);
              handleRegister.onChange(e);
            }}
            autoComplete={'password'}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={disabled}
          />

          {!styledComponentsProps.hasError && !styledComponentsProps.isValid && (openOptions ? <FiChevronUp /> : <FiChevronDown />)}

          {errorMessage && !styledComponentsProps.hasError && showErrorIcon !== true && (
            <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
          )}

          {styledComponentsProps.isValid && !styledComponentsProps.hasError && showErrorIcon !== true && !errorMessage && (
            <i className="icon-check" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
          )}

          {showErrorIcon === true && (
            <i className="icon-status-icon" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
          )}

          {styledComponentsProps.hasError && showErrorIcon !== true && (
            <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
          )}
        </SelectContent>
      </StyledInputWrapper>
      {openOptions && (
        <OptionContainer
          disabled={!isFocused}
          onScroll={e => {
            const listBox = e.currentTarget;
            if (listBox.scrollTop + listBox.clientHeight === listBox.scrollHeight) {
              if (handleOnScroll) {
                handleOnScroll();
              }
            }
          }}
        >
          {inputValue !== '' ? (
            options?.map(option => (
              <Option
                key={option.value}
                type="button"
                onClick={() => {
                  setInputValue(option.label);
                  setOptionSelected(option);
                  setOpenOptions(false);
                }}
              >
                <span>{option.label}</span>
              </Option>
            ))
          ) : defaultOptions ? (
            defaultOptions?.map(option => (
              <Option
                key={option.label}
                type="button"
                onClick={() => {
                  setInputValue(option.label);
                  setOptionSelected(option);
                  setOpenOptions(false);
                }}
              >
                <span>{option.label}</span>
              </Option>
            ))
          ) : (
            <></>
          )}
        </OptionContainer>
      )}
    </Container>
  );
};

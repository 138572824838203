import { FormInputsContainer } from 'components/RegistrationForms/styles';
import { SelectInput } from 'components/SelectInput';
import { TextInput } from 'components/TextInput';
import { useTranslation } from 'react-i18next';
import { TextArea } from './styles';

const OBJECTIVE_OPTIONS = [
  { label: 'Enviar para ajustes do cliente', value: 'ADJUST' },
  { label: 'Aprovar cliente', value: 'APPROVED' },
  { label: 'Rejeitar cliente', value: 'REJECTED' },
];

const AnalyseRegisterInputsContainer = () => {
  const { t } = useTranslation();

  return (
    <FormInputsContainer>
      <TextInput name="registrationStatus" label={''} hasCustomInput>
        <SelectInput name="registrationStatus" placeholder={t('global.placeholder.selectAction')} options={OBJECTIVE_OPTIONS} />
      </TextInput>

      <TextInput name="consultantComment" label={''} placeholder={t('global.placeholder.annotateAdjustsObservations')} />
    </FormInputsContainer>
  );
};

export default AnalyseRegisterInputsContainer;

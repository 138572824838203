import { InputAndText } from 'components/InputAndTitle';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { TextInput } from 'components/TextInput';
import { User } from 'model/user';
import { useTranslation } from 'react-i18next';
import { consultantsAuthorities, internalStatus } from 'shared/util/team-utils';
import { ContainerInputs } from './styles';
interface Props {
  personalData?: User;
}

export const FormConsultantData = ({ personalData }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerInputs>
        <InputAndText
          title="register.inputs.name"
          name="name"
          placeholder="register.placeholder.name"
          value={personalData && personalData.name}
        />
        <InputAndText
          title="register.inputs.cpf"
          name="cpf"
          placeholder="register.placeholder.cpf"
          value={personalData && personalData.cpf}
          mask="CPF"
          max={14}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="register.inputs.companyName"
          name="companyName"
          placeholder="register.placeholder.companyName"
          value={personalData && personalData.companyName}
        />
        <InputAndText
          title="register.inputs.cnpj"
          name="cnpj"
          placeholder="register.placeholder.cnpj"
          value={personalData && personalData.cnpj}
          mask="CNPJ"
          max={19}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="register.inputs.phone"
          name="phone"
          placeholder="register.placeholder.alternativePhone"
          value={personalData && personalData.phone}
          mask="PHONE"
          max={16}
        />
        <InputAndText
          title="register.inputs.email"
          name="email"
          placeholder="register.placeholder.email"
          value={personalData && personalData.email}
        />
      </ContainerInputs>
      <ContainerInputs>
        <TextInput name="internalStatus" label={t('register.inputs.internalStatus')} isRequired hasCustomInput>
          <SearchSelectInput
            name="internalStatus"
            placeholder={t('register.placeholder.internalStatus')}
            options={internalStatus()}
            defaultOptions={internalStatus()}
            defaultValue={internalStatus().find(status => status.value === personalData?.internalStatus)?.label}
          />
        </TextInput>
        <TextInput name="authority" label={t('register.inputs.authority')} isRequired hasCustomInput>
          <SearchSelectInput
            name="authority"
            placeholder={t('register.placeholder.authority')}
            options={consultantsAuthorities()}
            defaultOptions={consultantsAuthorities()}
            defaultValue={consultantsAuthorities().find(item => item.value === personalData?.user?.authorities?.[0].name)?.label}
          />
        </TextInput>
      </ContainerInputs>
    </div>
  );
};

import styled, { css } from 'styled-components';

interface Props {
  isActualStep?: boolean;
  isPreviewStep?: boolean;
  isInitial?: boolean;
  isFinal?: boolean;
}

export const Container = styled.div`
  margin: 3.3rem auto 0px auto;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StepContainer = styled.div`
  flex: 1;
`;

export const StepBar = styled.div<Props>`
  height: 0.813rem;
  background-color: #f1f1f1;
  margin-top: 0.938rem;

  ${({ isInitial }) =>
    isInitial &&
    css`
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    `}

  ${({ isFinal }) =>
    isFinal &&
    css`
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    `}
`;

export const ProgressActiveBar = styled.div<Props>`
  height: 0.813rem;
  background-color: ${({ isActualStep, isPreviewStep }) => (isActualStep || isPreviewStep ? '#80008c' : '#f1f1f1')};

  ${({ isActualStep, isFinal }) =>
    (isActualStep || isFinal) &&
    css`
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    `}

  ${({ isInitial }) =>
    isInitial &&
    css`
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    `}
`;

import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerContract,
  ContainerNameDocument,
  Header,
  Separator,
  TitleDocumentNumber,
  TitleHeader,
  TitleName,
} from './styles';
import ArrowLeft from 'images/circuleWIthArrowLeft.svg';
import ContractInfo from 'components/contractInfo';
import Footer from 'features/footer/footer';
import { useHistory } from 'react-router-dom';
import { ContractAndClient } from 'model/contract';
import { useTranslation } from 'react-i18next';

const ViewContract = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [contractInfo, setContractInfo] = useState({} as ContractAndClient);

  useEffect(() => {
    setContractInfo(history.location.state as ContractAndClient);
  }, []);

  return (
    <Container>
      <Header>
        <img src={ArrowLeft} onClick={() => history.goBack()} />
        <TitleHeader>{t('menu.dashboard.viewDocument')}</TitleHeader>
      </Header>
      <ContainerNameDocument>
        <TitleName>{contractInfo.nameClient}</TitleName>
        <Separator />
        <TitleDocumentNumber>{contractInfo.cpf}</TitleDocumentNumber>
      </ContainerNameDocument>
      <ContainerContract>
        <ContractInfo admin contractInfo={contractInfo} />
      </ContainerContract>
      <Footer />
    </Container>
  );
};

export default ViewContract;

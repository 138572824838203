import { GuarantorProps } from 'model/guarantor';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, Button, ButtonContainer, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface GuarantorGeneralDataProps {
  guarantorData: GuarantorProps;
  handleOpenScoreModal: (id) => void;
}

const GuarantorGeneralData: React.FC<GuarantorGeneralDataProps> = props => {
  const { t } = useTranslation();
  const { guarantorData, handleOpenScoreModal } = props;

  return (
    <>
      <DataContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.name')}</ContentLabel>
          <ContentValue>{guarantorData?.name ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.email')}</ContentLabel>
          <ContentValue>{guarantorData?.email ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.phone')}</ContentLabel>
          <ContentValue>{StringUtils.phoneMask(guarantorData?.phone) ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <BreakLine />
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.cpf')}</ContentLabel>
          <ContentValue>{StringUtils.cpfMask(guarantorData?.cpf) ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.occupation')}</ContentLabel>
          <ContentValue>{guarantorData?.occupation?.name ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.income')}</ContentLabel>
          <ContentValue>
            {guarantorData?.income ? StringUtils.currencyMask(guarantorData?.income?.toFixed(2)) : 'Não informado'}
          </ContentValue>
        </ContentContainer>
        <BreakLine />
        <ContentContainer>
          <ContentLabel>{t('clientFinancing.creditData.clientScore')}</ContentLabel>
          <ContentValue>{guarantorData?.creditScore?.score ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.rg')}</ContentLabel>
          <ContentValue>{guarantorData?.rg ?? 'Não informado'}</ContentValue>
        </ContentContainer>
        <ContentContainer>
          <ContentLabel>{t('clientData.generalData.birth')}</ContentLabel>
          <ContentValue>
            {guarantorData?.birth ? StringUtils.dateFormatMask(guarantorData?.birth?.toString()) : 'Não informado'}
          </ContentValue>
        </ContentContainer>
      </DataContainer>
      {!guarantorData?.creditScore?.score && (
        <ButtonContainer>
          <Button onClick={() => handleOpenScoreModal(guarantorData.id)}>
            <span>{t('global.button.calculateScore')}</span>
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default GuarantorGeneralData;

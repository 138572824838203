import { Container, ImageContainer, MessageText, TextsContainer, Title } from './styles';
import ErrorWoman from '../../images/ErrorWoman.svg';
import Footer from 'features/footer/footer';
import { useTranslation } from 'react-i18next';
import { HeaderWithLogout } from 'components/HeaderWithLogout';

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextsContainer>
          <Title>{t('accessDenied.title')}</Title>
          <MessageText>{t('accessDenied.message')}</MessageText>
        </TextsContainer>
        <ImageContainer>
          <img src={ErrorWoman} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

export default AccessDenied;

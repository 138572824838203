import { isEmpty } from 'lodash';
import React from 'react';
import * as yup from 'yup';
import Modal from 'react-modal';
import { Container, FormContainer, modalStyles } from './styles';
import StyledButton from 'components/StyledButton';
import { User } from 'model/user';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { TextInput } from 'components/TextInput';
import { useHistory } from 'react-router-dom';
import adminService from 'services/admin-service';
import { useTranslation } from 'react-i18next';

interface StyledModalEditLoginProps {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleLogout: () => void;
  userData: User;
  setUserData: (value) => void;
}

const StyledModalEditLogin: React.FC<StyledModalEditLoginProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    firstName: yup.string().required(t('global.errorMessage.required')),
    login: yup.string().email(t('global.errorMessage.type')).required(t('global.errorMessage.required')),
    password: yup.string(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('global.errorMessage.confirmPassword')),
  });

  const onSubmit = async data => {
    let adminData: User = {
      login: data.login,
      firstName: data.firstName,
    };

    if (!isEmpty(data.password)) {
      adminData = { ...adminData, password: data.password };
    }

    const res = await adminService.updateLogin(adminData);

    if (res) {
      history.replace(`/admin/dashboard/general`);
    }
  };

  return (
    <Modal isOpen={props.isOpenModal} style={modalStyles}>
      <Container>
        <h2>{t('global.modal.editLogin')}</h2>
        <FormContainer>
          <h4>{t('global.modal.loginData')}</h4>
          <ReactHookFormProvider schema={schema} onSubmit={onSubmit}>
            <TextInput name="firstName" label={t('global.fields.responsibleName')} defaultValue={props.userData?.firstName ?? ''} />
            <TextInput name="login" label={t('global.fields.email')} defaultValue={props?.userData.email ?? ''} />
            <TextInput name="password" label={t('global.fields.insertPassword')} inputType="password" />
            <TextInput name="confirmPassword" label={t('global.fields.confirmPassword')} inputType="password" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton secondary onClick={props.handleOpenModal} text={t('global.button.cancel2')} />
              <StyledButton primary type="submit" text={t('global.button.save')} />
            </div>
          </ReactHookFormProvider>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default StyledModalEditLogin;

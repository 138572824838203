import { HttpRequestStatus } from 'model/enums/http-request-status';
import { User } from 'model/user';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
  LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
  LOGIN_ADMIN_REQUEST = '@@authentication/LOGIN_ADMIN_REQUEST',
  GET_SESSION_REQUEST = '@@authentication/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@authentication/GET_SESSION_SUCCESS',
  GET_SESSION_ERROR = '@@authentication/GET_SESSION_ERROR',
  GET_EMAIL_REQUEST = '@@authentication/GET_EMAIL_REQUEST',
  GET_EMAIL_SUCCESS = '@@authentication/GET_EMAIL_SUCCESS',
  GET_EMAIL_ERROR = '@@authentication/GET_EMAIL_ERROR',
  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',
}

export interface AuthenticationState {
  readonly loginStatus: HttpRequestStatus;
  readonly getSessionStatus: HttpRequestStatus;
  readonly isAuthenticated: boolean;
  readonly account?: User;
  readonly getEmailStatus: HttpRequestStatus;
  readonly emailValidation?: boolean;
  readonly error?: string;
}

import { HeaderWithLogout } from 'components/HeaderWithLogout';
import StyledButton from 'components/StyledButton';
import { AdminMessageContainer, ButtonContainer, Container, ImageContainer, Message, TextContainer, Title } from './styles';
import WomanSitting from '../../../images/woman-sitting2.svg';
import Footer from 'features/footer/footer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
  admMessage?: string;
}

export const StatusCorrections = ({ admMessage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    history.replace('/client/registration/edit');
  };

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextContainer>
          <Title>{t('documentStatus.corrections.title')}</Title>
          <Message>{t('documentStatus.corrections.message')}</Message>
          {admMessage && (
            <AdminMessageContainer>
              <span>{admMessage}</span>
            </AdminMessageContainer>
          )}
          <ButtonContainer>
            <StyledButton primary text={t('documentStatus.corrections.buttonText')} onClick={handleOnClick} />
          </ButtonContainer>
        </TextContainer>
        <ImageContainer>
          <img src={WomanSitting} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

import { useRegisterForm } from 'provider/RegisterForm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StepContainer, Container, StepBar, ProgressActiveBar } from './styles';

interface ProgressBarProps {
  steps: string[];
}

export const ProgressBar = ({ steps }: ProgressBarProps) => {
  const { t } = useTranslation();
  const { actualStep, registerSteps } = useRegisterForm();

  useEffect(() => {
    registerSteps(steps);
  }, []);

  return (
    <Container>
      {steps.map((it, index) => (
        <StepContainer key={it}>
          <span>{t(it)}</span>
          <StepBar isInitial={index === 0} isFinal={index === steps.length - 1}>
            <ProgressActiveBar
              isInitial={index === 0}
              isFinal={index === steps.length - 1}
              isActualStep={actualStep === index}
              isPreviewStep={index < actualStep}
            />
          </StepBar>
        </StepContainer>
      ))}
    </Container>
  );
};

import guarantor from 'api/guarantor-api';
import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import HttpStatus from 'model/enums/http-status';
import { GuarantorProps, Linkedguarantors } from 'model/guarantor';
import { Score } from 'model/score';

export const GuarantorService = () => {
  const GuarantorRegistration = async (obj: Client): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await guarantor.createGuarantor(obj);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const GuarantorCheckScore = async (id: number): Promise<Score> => {
    try {
      const result: AxiosResponse<Score> = await guarantor.checkGuarantorScore(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getGuarantors = async (): Promise<GuarantorProps[]> => {
    try {
      const result: AxiosResponse<GuarantorProps[]> = await guarantor.getGuarantor();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getGuarantor = async (guarantorId: number): Promise<GuarantorProps> => {
    try {
      const result: AxiosResponse<GuarantorProps> = await guarantor.getGuarantorId(guarantorId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const deleteGuarantor = async (guarantorId: number): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await guarantor.deleteGuarantorId(guarantorId);
      if (result.status === HttpStatus.DELETED && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const coursesToLinkGuarantor = async (guarantorId: number, params: string): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await guarantor.coursesToLinkGuarantor(guarantorId, params);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    GuarantorRegistration,
    getGuarantors,
    getGuarantor,
    deleteGuarantor,
    coursesToLinkGuarantor,
    GuarantorCheckScore,
  };
};

export default GuarantorService();

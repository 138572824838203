import React from 'react';
import { GuarantorRegister } from 'components/GuarantorRegister';
import GuarantorEdit from 'components/GuarantorEdit';
import InfosToShow from 'components/InfosToShow';
import { GuarantorList } from 'components/guarantorList';
import { SideMenu } from 'components/sideMenuClient';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ContainerContent, ContainerMenuAndPrincipalContent } from './styles';
import SeeFunding from 'components/SeeFunding';
import Financing from 'components/Financing';
import PersonalDataEdit from 'components/PersonalDataEdit';
import { FinancingProvider } from 'provider/Financing';
import { HeaderWithLogout } from 'components/HeaderWithLogout';
import Contracts from 'components/contracts';
import Payment from 'components/Payment';

export const DashboardClient = () => {
  const dashboardFinancing = useRouteMatch('/client/dashboard/financing')?.isExact;
  const dashboardFinancingProcess = useRouteMatch('/client/dashboard/financing/process')?.isExact;
  const dashboardPersonalData = useRouteMatch('/client/dashboard/personalData')?.isExact;
  const dashboardPersonalDataEdit = useRouteMatch('/client/dashboard/personalData/edit')?.isExact;
  const dashboardContracts = useRouteMatch('/client/dashboard/contracts')?.isExact;
  const dashboardPayment = useRouteMatch('/client/dashboard/payment')?.isExact;
  const dashboardGuarantor = useRouteMatch('/client/dashboard/guarantor')?.isExact;
  const dashboardGuarantorList = useRouteMatch('/client/dashboard/guarantor/list')?.isExact;
  const dashboardGuarantorInfo = useRouteMatch('/client/dashboard/guarantor/info')?.isExact;
  const dashboardGuarantorEdit = useRouteMatch('/client/dashboard/guarantor/edit')?.isExact;

  return (
    <>
      <HeaderWithLogout />
      <ContainerMenuAndPrincipalContent>
        <SideMenu />
        <ContainerContent>
          <FinancingProvider>
            {dashboardFinancing && <SeeFunding />}
            {dashboardFinancingProcess && <Financing />}
            {dashboardPersonalData && <InfosToShow title="dashboardClient.personalData.textTitle" client />}
            {dashboardPersonalDataEdit && <PersonalDataEdit />}
            {dashboardGuarantor && <GuarantorRegister />}
            {dashboardGuarantorList && <GuarantorList />}
            {dashboardGuarantorInfo && <InfosToShow title="dashboardClient.guarantor.guarantorData.title" buttonBack />}
            {dashboardGuarantorEdit && <GuarantorEdit />}
            {dashboardContracts && <Contracts />}
            {dashboardPayment && <Payment />}
          </FinancingProvider>
        </ContainerContent>
      </ContainerMenuAndPrincipalContent>
    </>
  );
};

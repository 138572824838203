import styled from 'styled-components';

export const Container = styled.div`
  min-height: 80vh;
  max-width: 54vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  display: block;
  width: 100%;
  text-align: left;
  color: #2b1a48;
  font-size: 32px;
  font-weight: 700;

  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerInfos,
  ContainerTitleAndInfo,
  ErrorMessage,
  Icon,
  InputApprovedValue,
  Line,
  TextInfo,
  TextTitle,
} from '../styles';
import Money from 'images/money.svg';
import CalendarCash from 'images/calendar-cash.svg';
import CalendarError from 'images/calendar-error.svg';
import ButtonComponent from 'components/Button';
import SimulatorResult from '../SimulatorResult';
import { useFinancing } from 'provider/Financing';
import StringUtils from 'shared/util/string-utils';
import { SimulateFinancing } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { SliderStyled } from './styles';
import { FaPen } from 'react-icons/fa';
import { addDays, format } from 'date-fns';

const SimulatorForm = () => {
  const { financing, simulateFinancing, mockFinancing, setEntryValue, course } = useFinancing();
  const [installments, setInstallments] = useState<number>(36);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [errorValue, setErrorValue] = useState<boolean>(false);
  const [slideErrorValue, setSlideErrorValue] = useState<string>();
  const [approvedValueFormatted, setApprovedValueFormatted] = useState<string>('');
  const [valueToApprove, setValueToApprove] = useState<string>('');
  const FirstDue = format(addDays(new Date(), 30), 'dd/MM/yyyy');
  const [load, setLoad] = useState<boolean>(false);

  const [showResults, setShowResults] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setValueToApprove(String(financing.downPaymentValue));
    setApprovedValueFormatted(StringUtils.moneyMaskPtbr(financing.downPaymentValue, true));
  }, [financing]);

  const handleChangeInstallments = (event: Event, newValue: number | number[]) => {
    setInstallments(newValue as number);
  };

  const handleSimulateFinancing = async () => {
    setLoad(true);
    if (financing.course.id) {
      const financingTosimulate: SimulateFinancing = {
        id: financing.id,
        downPaymentValue: Number(valueToApprove),
        course: {
          id: financing.course.id,
        },
        installmentsTotal: installments,
      };
      simulateFinancing(financingTosimulate);
    }
  };

  useEffect(() => {
    if (mockFinancing.length > 1) {
      setLoad(false);
      setShowResults(true);
    }
  }, [mockFinancing]);

  const handleChangeAprovedValue = (e: React.FormEvent<HTMLInputElement>) => {
    setValueToApprove(e.currentTarget.value.replace(',', '.'));
    if (Number(e.currentTarget.value.replace(',', '.')) > financing.approvedValue) {
      setErrorValue(true);
      setIsDisabled(false);
    } else {
      setErrorValue(false);
    }
  };

  const FormattedValue = () => {
    if (valueToApprove.length > 2 && Number(valueToApprove) && Number(valueToApprove) < financing.approvedValue) {
      setIsDisabled(true);
      setErrorValue(false);
      setApprovedValueFormatted(StringUtils.moneyMaskPtbr(Number(valueToApprove), true));
    } else if (Number(valueToApprove) > financing.approvedValue) {
      setErrorValue(true);
      setIsDisabled(false);
    }
  };

  const handleEdit = () => {
    setIsDisabled(!isDisabled);
  };

  return (
    <Container>
      {showResults ? (
        <SimulatorResult setShowResults={setShowResults} income={course?.client?.income ?? 0} />
      ) : (
        <>
          <TextTitle style={{ marginTop: '43px' }}>{t('dashboardClient.financing.simulator.entryValue')}</TextTitle>
          <TextInfo size="24px">
            <TextInfo size="18px" color="#999999">
              R$
            </TextInfo>
            <InputApprovedValue
              value={isDisabled ? approvedValueFormatted : valueToApprove}
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeAprovedValue(e)}
              onBlur={() => FormattedValue()}
              disabled={isDisabled}
              isDisabled={!isDisabled}
            />
            <FaPen color="#80008C" size={16} onClick={() => handleEdit()} />
          </TextInfo>
          {errorValue && <ErrorMessage>{t('dashboardClient.financing.simulator.errorEntry')}</ErrorMessage>}
          <Line />
          <TextTitle style={{ marginTop: '25px' }}>{t('dashboardClient.financing.simulator.valueCredit')}</TextTitle>
          <TextInfo size="24px">
            <TextInfo size="18px" color="#999999">
              R$
            </TextInfo>
            {StringUtils.moneyMaskPtbr(financing.approvedValue - Number(valueToApprove), true)}
          </TextInfo>
          <Line />
          <TextTitle style={{ marginTop: '31px' }}>{t('dashboardClient.financing.simulator.installmentNumber')}</TextTitle>
          <TextInfo size="24px">{installments} Parcelas</TextInfo>
          <SliderStyled
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={installments}
            min={1}
            max={36}
            onChange={handleChangeInstallments}
          />
          {slideErrorValue && <ErrorMessage>{t(slideErrorValue)}</ErrorMessage>}
          <ButtonComponent
            width="140px"
            height="58px"
            background={slideErrorValue ? '#cccccc' : '#80008C'}
            border="none"
            borderRadius="8px"
            color="white"
            text={t('global.button.simulate')}
            weight="600"
            size="24px"
            margin="38px 0px 0px 0px"
            load={load}
            disabled={errorValue}
            onClick={() => handleSimulateFinancing()}
          />
          <ContainerInfos diraction="row" height="67px" margin="8px 0px 0px 0px">
            <ContainerTitleAndInfo align="center">
              <TextTitle>
                <Icon src={CalendarError} />
                {t('dashboardClient.financing.simulator.firstDue')}
              </TextTitle>
              <TextInfo size="18px">{FirstDue}</TextInfo>
            </ContainerTitleAndInfo>
          </ContainerInfos>
        </>
      )}
    </Container>
  );
};

export default SimulatorForm;

import { AxiosResponse } from 'axios';
import { Contract, ContractOfClient } from 'model/contract';
import { FinancingResume, SimulateFinancing, SimulateFinancingResponse } from 'model/financing';
import { api } from './api';

export const Financing = () => {
  const finacingResume = (courseId: number): Promise<AxiosResponse<FinancingResume>> => {
    return api.get<FinancingResume>(`/financing/courses/${courseId}`);
  };

  const simulateFinacing = (obj: SimulateFinancing): Promise<AxiosResponse<SimulateFinancingResponse[]>> => {
    return api.post<SimulateFinancingResponse[]>(`/financing/simulate`, obj);
  };

  const confirmSimulateFinacing = (financingId: number, obj: SimulateFinancingResponse[]): Promise<AxiosResponse<SimulateFinancing>> => {
    return api.patch<SimulateFinancing>(`/financing/${financingId}/confirm-simulate`, obj);
  };

  const getClientContracts = (clientId: number): Promise<AxiosResponse<ContractOfClient[]>> => {
    return api.get<ContractOfClient[]>(`/contracts/clients/${clientId}`);
  };

  return {
    finacingResume,
    simulateFinacing,
    confirmSimulateFinacing,
    getClientContracts,
  };
};

export default Financing();

import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { useRegisterForm } from 'provider/RegisterForm';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import PersonalData from '../../../images/personal-data.svg';
import { FormContainer, ImageContainer } from '../styles';
import { ContainerInputsPersonalData } from './InputsPersonalData';
import { isDate, parse } from 'date-fns';
import { validateCpf } from 'shared/util/utils';
import { useTranslation } from 'react-i18next';

export const FormPersonalData = () => {
  const { validateMajority, onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const history = useHistory();
  const generateMinDate = () => {
    const actualDate = new Date();
    actualDate.setFullYear(actualDate.getFullYear() - 18);
    return actualDate;
  };
  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          if (validateMajority(res.birth)) {
            onClickNext(res);
          }
        }}
      >
        <ContainerInputsPersonalData />
      </ReactHookFormProvider>
      <ImageContainer>
        <img src={PersonalData} />
      </ImageContainer>
    </FormContainer>
  );
};

import ContainerContract from 'components/containerContract';
import ContractInfo from 'components/contractInfo';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerBackStep, ContainerTitle, TitleText } from './styles';
import ArrowLeft from 'images/circuleWIthArrowLeft.svg';
import { useHistory } from 'react-router-dom';
import courseService from 'services/course.service';
import { Course } from 'model/courses';
import { Contract, InfoContract } from 'model/contract';
import { useFinancing } from 'provider/Financing';
import { Client } from 'model/client';

interface Props {
  course?: Course;
  client: Client;
}

const ClientContract = ({ course, client }: Props) => {
  const [contracts, setContracts] = useState<Contract>();
  const { t } = useTranslation();
  const history = useHistory();

  const handleContract = (contract?: InfoContract) => {
    if (contract) {
      const contractAndClient = {
        contract,
        nameClient: client.name ?? '',
        cpf: client.cpf ?? '',
      };
      history.push({
        pathname: '/admin/dashboard/viewContract',
        state: contractAndClient,
      });
    }
  };

  const getContracts = async () => {
    if (course?.id) {
      const res = await courseService.getContracts(course?.id);
      setContracts(res);
    }
  };

  useEffect(() => {
    getContracts();
  }, []);

  return (
    <Container>
      {contracts &&
        contracts.contracts?.map((element, key) => {
          return <ContainerContract key={key} admin showDetail={handleContract} contract={element} />;
        })}
    </Container>
  );
};

export default ClientContract;

import { useTranslation } from 'react-i18next';

export const RegisterTooltipCaptions = () => {
  const { t } = useTranslation();

  return [
    { title: t('preRejected'), propName: 'PRE_REJECTED' },
    { title: t('new'), propName: 'NEW' },
    { title: t('adjust'), propName: 'ADJUST' },
    { title: t('adjusted'), propName: 'ADJUSTED' },
    { title: t('approved'), propName: 'APPROVED' },
  ];
};

export const ContractTooltipCaptions = () => {
  const { t } = useTranslation();

  return [
    { title: t('new'), propName: 'NEW' },
    { title: t('progress'), propName: 'PROGRESS' },
    { title: t('approved'), propName: 'APPROVED' },
  ];
};

export const FinancingTooltipCaptions = () => {
  const { t } = useTranslation();

  return [
    { title: t('new'), propName: 'NEW' },
    { title: t('creditAnalysis'), propName: 'CREDIT_ANALYSIS' },
    { title: t('approved'), propName: 'APPROVED' },
    { title: t('rejected'), propName: 'REJECTED' },
    { title: t('adjust'), propName: 'ADJUST' },
    { title: t('adjusted'), propName: 'ADJUSTED' },
    { title: t('accepted'), propName: 'ACCEPTED' },
  ];
};

export const PaymentTooltipCaptions = () => {
  const { t } = useTranslation();

  return [
    { title: t('new'), propName: 'NEW' },
    { title: t('progress'), propName: 'PROGRESS' },
    { title: t('approved'), propName: 'APPROVED' },
  ];
};

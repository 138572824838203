import InputText from 'components/InputText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerInputAndText, TitleTextInput } from './styles';

interface Props {
  title: string;
  placeholder: string;
  name: string;
  value?: string;
  mask?: string;
  type?: string;
  disabled?: boolean;
  max?: number;
  required?: boolean;
}

export const InputAndText = ({ title, placeholder, name, value, mask, type, disabled = false, max, required = true }: Props) => {
  const { t } = useTranslation();
  return (
    <ContainerInputAndText>
      <TitleTextInput>
        {t(title)}
        {required && <span>*</span>}
      </TitleTextInput>
      <InputText
        name={name}
        label=""
        placeholder={t(placeholder)}
        defaultValue={value}
        mask={mask}
        inputType={type}
        readOnly={disabled}
        maxLength={max}
      />
    </ContainerInputAndText>
  );
};

import React, { useEffect, useState } from 'react';
import StyledTabs from 'components/styled-tabs/styled-tabs';
import { useTranslation } from 'react-i18next';
import {
  BallSignature,
  Container,
  ContainerDetails,
  ContainerDownload,
  ContainerInfos,
  ContainerNameStatus,
  ContainerRowInformationData,
  ContainerSignatoryTitle,
  ContainerStatus,
  ContainerTitleDetail,
  ContractDate,
  DownloadTitle,
  NameContract,
  RowInformationSignatory,
  Status,
  TitleDataSignatory,
  TitleDetailSignatory,
  TitleSignatory,
} from './styles';
import ContractSvg from 'images/contractRepresentation.svg';
import DownloadSvg from 'images/download.svg';
import ButtonComponent from 'components/Button';
import DocumentViewer from 'components/documentViewer';
import { ContractAndClient } from 'model/contract';
import ModalSendToSignature from 'components/Modais/ModalSendToSignature';
import ApprovedPurple from '../../images/approvedPurple.svg';
import { format } from 'date-fns';
import StringUtils from 'shared/util/string-utils';

interface Props {
  admin?: boolean;
  contractInfo?: ContractAndClient;
}

const ContractInfo = ({ admin, contractInfo }: Props) => {
  const [url, setUrl] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [allSigned, setAllSigned] = useState<boolean>(false);
  const [statusContract, setStatusContract] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setUrl(contractInfo?.contract?.attachment?.presignedUrl);
    setStatusContract(contractInfo?.contract?.status ?? '');
    if (contractInfo) {
      const res = contractInfo?.contract?.contractSignatories?.find(element => element.signed === false);
      setAllSigned(res ? false : true);
    }
  }, [contractInfo]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Container>
      <StyledTabs
        editPage
        tabs={[
          {
            title: t('dashboardClient.contracts.contractInfo.signature'),
            content: (
              <div
                style={{
                  width: admin ? '95%' : '936px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <ContainerInfos admin={admin}>
                  <ContainerDetails>
                    <ContainerNameStatus>
                      <img src={ContractSvg} />
                      <NameContract>{contractInfo?.contract?.attachment?.fileName}</NameContract>
                      <ContainerStatus>
                        <Status>{t(`dashboardClient.contracts.contractInfo.${statusContract.toLowerCase()}`)}</Status>
                      </ContainerStatus>
                    </ContainerNameStatus>
                    {contractInfo?.contract?.createdDate && (
                      <ContractDate>{`${t('dashboardClient.contracts.contractInfo.addDate')} ${format(
                        new Date(contractInfo.contract.createdDate),
                        'dd/MM/yyyy'
                      )}`}</ContractDate>
                    )}
                    <ContainerDownload
                      download={contractInfo?.contract?.attachment?.fileName}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={DownloadSvg} />
                      <DownloadTitle>{t('dashboardClient.contracts.contractInfo.download')}</DownloadTitle>
                    </ContainerDownload>
                  </ContainerDetails>
                  {statusContract === 'NEW' && admin && (
                    <ButtonComponent
                      width={'200px'}
                      height={'44px'}
                      background="#80008C"
                      color="#fff"
                      size={'18px'}
                      weight={'500'}
                      borderRadius="8px"
                      border="none"
                      onClick={() => setOpenModal(true)}
                      text={'dashboardClient.contracts.contractInfo.sendDocument'}
                    />
                  )}
                </ContainerInfos>
                <TitleSignatory>{t('dashboardClient.contracts.contractInfo.Signatory')}</TitleSignatory>
                <ContainerSignatoryTitle admin={admin}>
                  <ContainerTitleDetail>
                    <TitleDetailSignatory>{t('dashboardClient.contracts.contractInfo.Signatory')}</TitleDetailSignatory>
                  </ContainerTitleDetail>
                  <ContainerTitleDetail>
                    <TitleDetailSignatory>{t('dashboardClient.contracts.contractInfo.birth')}</TitleDetailSignatory>
                  </ContainerTitleDetail>
                  {admin && (
                    <ContainerTitleDetail>
                      <TitleDetailSignatory>CPF</TitleDetailSignatory>
                    </ContainerTitleDetail>
                  )}
                  <ContainerTitleDetail>
                    <TitleDetailSignatory>{t('dashboardClient.contracts.contractInfo.email')}</TitleDetailSignatory>
                  </ContainerTitleDetail>
                  <ContainerTitleDetail>
                    <TitleDetailSignatory>{t('dashboardClient.contracts.contractInfo.howSignature')}</TitleDetailSignatory>
                  </ContainerTitleDetail>
                </ContainerSignatoryTitle>
                {contractInfo?.contract &&
                  contractInfo?.contract?.contractSignatories?.map((element, index) => {
                    return (
                      <RowInformationSignatory admin={admin} key={index}>
                        <ContainerRowInformationData>
                          <TitleDataSignatory>{element.signatory?.name}</TitleDataSignatory>
                        </ContainerRowInformationData>
                        <ContainerRowInformationData>
                          <TitleDataSignatory>{StringUtils.dateFormatMask(element.signatory?.birthDate ?? '')}</TitleDataSignatory>
                        </ContainerRowInformationData>
                        {admin && (
                          <ContainerRowInformationData>
                            <TitleDataSignatory>{element.signatory?.documentNumber}</TitleDataSignatory>
                          </ContainerRowInformationData>
                        )}
                        <ContainerRowInformationData>
                          <TitleDataSignatory>{element.signatory?.email}</TitleDataSignatory>
                        </ContainerRowInformationData>
                        <ContainerRowInformationData>
                          <TitleDataSignatory>
                            {element.signed ? <img src={ApprovedPurple} /> : <BallSignature />}
                            {!element.signed && t('dashboardClient.contracts.contractInfo.waitToSignature')}
                            {t(`dashboardClient.contracts.contractInfo.${element.signatoryType?.toLowerCase()}`)}
                          </TitleDataSignatory>
                        </ContainerRowInformationData>
                      </RowInformationSignatory>
                    );
                  })}
                <ModalSendToSignature
                  isOpen={openModal}
                  onCloseModal={handleCloseModal}
                  idContract={contractInfo?.contract?.id ?? 0}
                  setStatusContract={setStatusContract}
                />
              </div>
            ),
          },
          {
            title: t('dashboardClient.contracts.contractInfo.document'),
            content: (
              <div style={{ width: admin ? '95%' : '936px', display: 'flex', justifyContent: 'center', margin: '30px' }}>
                <DocumentViewer attachment={contractInfo?.contract?.attachment} />
              </div>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default ContractInfo;

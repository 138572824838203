import styled from 'styled-components';

export const Container = styled.div`
  width: 1000px;
  max-width: 1000px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
`;
export const TablesContainer = styled.div`
  width: 1000px;
  max-width: 1000px;
`;
export const TableContainer = styled.div`
  width: 1000px;
  max-width: 1000px;
  text-align: right;
`;
export const ItemLink = styled.span`
  width: 148px;
  height: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.541667px;
  text-transform: uppercase;
  color: #80008c;
`;
export const Title = styled.div`
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 10px;
`;

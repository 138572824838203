import LogoFdi from 'images/logo-fdi-escrita.svg';
import { Container, ImgContainer } from './StyledComponents';

const HeaderLoginAdmin = () => {
  return (
    <Container>
      <ImgContainer>
        <img src={LogoFdi} />
      </ImgContainer>
    </Container>
  );
};

export default HeaderLoginAdmin;

import StyledTabs from 'components/styled-tabs/styled-tabs';
import { ReactNode } from 'react';
import { Container, ContentContainer, Title } from './StyledComponents';

interface SubStyledTabsProps {
  tabs: { title: string | ReactNode; content: ReactNode }[];
}

const SubStyledTabs: React.FC<SubStyledTabsProps> = props => {
  return (
    <>
      {props.tabs.map((tab, i) => (
        <Container key={i}>
          <Title>{tab.title}</Title>
          {tab.content}
        </Container>
      ))}
    </>
  );
};

export default SubStyledTabs;

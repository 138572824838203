import ContainerContract from 'components/containerContract';
import ContractInfo from 'components/contractInfo';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerBackStep, ContainerTitle, TitleText } from './styles';
import ArrowLeft from 'images/circuleWIthArrowLeft.svg';
import { useHistory } from 'react-router-dom';
import { useFinancing } from 'provider/Financing';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Contract, ContractAndClient, ContractOfClient, InfoContract } from 'model/contract';
import { useQuery } from 'hook/route';
import { FooterStyled } from 'components/FooterStyled';
import NoDataPage from 'features/admin/dashboard/register/client/client-data/no-data-page';

const Contracts = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const accountId = useSelector((state: IRootState) => state.authentication.account?.client?.id);
  const history = useHistory();
  const { getContracts, contract } = useFinancing();
  const [showContractDetail, setshowContractDetail] = useState(false);
  const [contractSelected, setContractSelected] = useState<ContractAndClient>();

  const showDetail = (contract: InfoContract) => {
    setContractSelected({ contract });
    setshowContractDetail(true);
  };

  useEffect(() => {
    if (accountId) {
      getContracts(accountId);
    }
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (contract && id) {
      const res = contract.find(element => element?.course?.id === Number(id));
      res && setContractSelected({ contract: res });
    }
  }, [contract]);

  useEffect(() => {
    if (contractSelected) {
      setshowContractDetail(true);
    }
  }, [contractSelected]);
  console.log({ contract });
  return (
    <Container>
      <ContainerTitle>
        {showContractDetail && (
          <ContainerBackStep onClick={() => setshowContractDetail(false)}>
            <span>
              <img src={ArrowLeft} alt={t('global.button.backStep')} />
            </span>
          </ContainerBackStep>
        )}
        <TitleText>{t('dashboardClient.contracts.title')}</TitleText>
      </ContainerTitle>
      {contract?.length === 0 ? (
        <NoDataPage tab="Contract" />
      ) : (
        <>
          {showContractDetail ? (
            <ContractInfo contractInfo={contractSelected} />
          ) : (
            contract?.map((element, index) => {
              return <ContainerContract key={index} showDetail={showDetail} contract={element} />;
            })
          )}
        </>
      )}

      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

export default Contracts;

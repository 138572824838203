import ContainerDocument from 'features/RegisterDocuments/TempContainerDocument';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { Attachment } from 'model/files';
import { GuarantorProps } from 'model/guarantor';
import { useEditForm } from 'provider/admin-edit-client-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { SubtitleText } from './styles';

interface Props {
  personalData?: GuarantorProps | Client;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
}

export const FormAttachmentsData = ({ personalData, files, setFiles }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <SubtitleText style={{ marginBottom: '24px' }}>{t('dashboardClient.guarantor.documents')}</SubtitleText>
      {personalData?.attachments && (
        <>
          <div style={{ marginTop: '24px' }}>
            <ContainerDocument
              files={files}
              setFiles={setFiles}
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={
                personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName
              }
              docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
          </div>
          <ContainerDocument
            files={files}
            setFiles={setFiles}
            titleDocument="documents.ProofOfIncome"
            editDocument
            nameInput="doc2"
            name="attachmentIncome"
            type={AttachmentType.PROOF_INCOME}
            title={personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
            docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.PROOF_INCOME))}
          />
          <ContainerDocument
            files={files}
            setFiles={setFiles}
            titleDocument="documents.proofOfAddress"
            editDocument
            nameInput="doc3"
            name="attachmentAddress"
            type={AttachmentType.PROOF_RESIDENCE}
            title={personalData?.attachments && personalData.attachments.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
            docUrl={StringUtils.getFileOrUrl(personalData.attachments.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
          />
        </>
      )}
    </div>
  );
};

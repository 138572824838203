import React, { useState } from 'react';
import drop from '../../images/tiny-expand.svg';
import { Container, FormContainer, InputContainer, OpenButton, Option } from './styles';

interface ICustomDropdownProps {
  options: { label: string; value: string }[];
  handleClickCourse: (id: string) => void;
  selectedCourse: string;
}

const CustomDrodown: React.FC<ICustomDropdownProps> = props => {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <Container>
      <InputContainer>
        <OpenButton onClick={() => setOpenDropdown(!openDropdown)}>
          <span>{props.options.find(course => course.value === props.selectedCourse)?.label}</span>
          <div>
            <img src={drop} alt="dropdown icon" />
          </div>
        </OpenButton>
      </InputContainer>
      {openDropdown && (
        <FormContainer>
          {props.options.map((item, key) => (
            <Option
              key={key}
              onClick={() => {
                setOpenDropdown(!openDropdown);
                props.handleClickCourse(item.value);
              }}
            >
              {item.label}
            </Option>
          ))}
        </FormContainer>
      )}
    </Container>
  );
};

export default CustomDrodown;

import styled from 'styled-components';

interface Props {
  size?: string;
  color?: string;
  weight?: number;
}

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 9.68rem;
    height: 9.81rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2b1a48;
  text-align: center;
  margin: 14px 0px 30px 0;
`;

export const ContainerTitles = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

export const Subtitle = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #cccccc;
  text-align: center;
  margin: 14px 0px 48px 0;
`;

export const MessageText = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const ConfirmContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const ContainerFormInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  form {
    div {
      margin-left: 0px;
      div + div + div + div {
        display: none;
      }
    }
  }
`;

export const ContainerInfos = styled.div`
  display: flex;
  width: 274px;
  margin-left: 165px;
  margin-top: 20px;
  justify-content: space-evenly;
  align-items: center;
`;

export const ContainerTitleAndInfo = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextTitle = styled.span<Props>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size }) => (size ? size : '16px')};
  line-height: 100%;
  color: #7f7f7f;
  margin-bottom: 8px;
  display: block;
  text-align: center;
`;

export const TextInfo = styled.span<Props>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : 600)};
  font-size: ${({ size }) => size};
  line-height: 24px;
  color: ${({ color }) => (color ? color : '#2b1a48')};
  text-align: center;
  display: block;
`;

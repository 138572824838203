import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerBackStep = styled.div`
  width: 976px;
  display: flex;
  margin-top: 46px;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #242424;
    cursor: pointer;
    img {
      margin-bottom: 4px;
      margin-right: 10px;
    }
  }
`;

export const ContainerContent = styled.div`
  width: 976px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  margin-bottom: 32px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const SubtitleText = styled.span`
  min-width: 168px;
  height: 30px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2b1a48;
  text-align: left;
  margin-right: 900px;
`;

export const ContainerInputs = styled.div`
  width: 976px;
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 62px;
  margin-bottom: 50px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px dashed #bcbcbc;
  border-radius: 6px;
  margin-bottom: 8px;
`;

export const ContainerDataDocument = styled.div`
  height: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #f9f3ff;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  img {
    width: 12px;
    height: 12px;
  }
  a,
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline !important;
    color: #010101;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ContainerTitleAndData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: #333333;
  }
`;

export const Label = styled.label`
  width: 85px;
  height: 30px;
  background: transparent;
  border: 1px solid #999999;
  border-radius: 4px;
  color: #80008c;
  margin: 14px 15px 0;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    margin-right: 6px;
  }
`;

export const LabelNotEdit = styled.label`
  width: 85px;
  height: 30px;
  background: transparent;
  border: 1px solid #999999;
  border-radius: 4px;
  color: #999999;
  margin: 14px 15px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    margin-right: 6px;
  }
`;

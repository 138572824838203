import AdminHeader from 'components/admin-header/admin-header';
import DashboardTables from 'components/dashboard-table/dashboard-table';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import GeneralGraphInfos from 'components/general-graph-infos/general-graph-infos';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import TableContent from 'components/table-content/table-content';
import { use } from 'i18next';
import { isEmpty } from 'lodash';
import { Client } from 'model/client';
import { Contract } from 'model/contract';
import { Financing } from 'model/financing';
import { Payment } from 'model/payment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import dashboardService from 'services/dashboard-service';
import AuthUtils from 'shared/util/auth-utils';
import {
  ContractTooltipCaptions,
  FinancingTooltipCaptions,
  PaymentTooltipCaptions,
  RegisterTooltipCaptions,
} from 'shared/util/tooltip-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import { Container, ItemLink, TablesContainer } from './styles';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [registerTableContent, setRegisterTableContent] = useState<Client[]>([]);
  const [financingTableContent, setFinancingTableContent] = useState<Financing[]>([]);
  const [contractTableContent, setContractTableContent] = useState<Contract[]>([]);
  const [paymentTableContent, setPaymentTableContent] = useState<Payment[]>([]);

  const header = [
    { name: t('global.table.name'), propName: 'name' },
    { name: t('global.table.email'), propName: 'email' },
  ];
  const headerFinancing = [...header, { name: t('global.table.status'), propName: 'creditAnalysisStatus' }];
  const headerRegistration = [...header, { name: t('global.table.status'), propName: 'registrationStatus' }];
  const headerStatus = [...header, { name: t('global.table.status'), propName: 'status' }];

  const sortableColumns = ['Nome', 'Email', 'Status'];

  useEffect(() => {
    const callDashboardService = async () => {
      const register = await dashboardService.getRegisterTableData({ page: 0, size: 4, sort: 'registrationStatusOrdinal,asc' });
      const financing = await dashboardService.getFinancingTableData({ page: 0, size: 4, sort: 'creditAnalysisStatusOrdinal,asc' });
      const contract = await dashboardService.getContractTableData({ page: 0, size: 4, sort: 'statusOrdinal,asc' });
      const payment = await dashboardService.getPaymentTableData({ page: 0, size: 4, sort: 'statusOrdinal,asc' });

      setRegisterTableContent(register.content ?? []);
      setFinancingTableContent(financing.content ?? []);
      setContractTableContent(contract.content ?? []);
      setPaymentTableContent(payment.content ?? []);
    };

    callDashboardService();
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AdminHeader />
      </div>
      <div style={{ width: '100%' }}>
        <StyledAdminHeader>{t('menu.dashboard.general')}</StyledAdminHeader>
        <MinHeightScreeningContainer>
          <Container>
            <GeneralGraphInfos />
            {!AuthUtils.isPartner() && !AuthUtils.isInternal() && (
              <Link to={'/admin/new/client'}>
                <ItemLink>{t('global.button.addNewClient')}</ItemLink>
              </Link>
            )}
          </Container>
          <TablesContainer>
            <DashboardTables
              tables={[
                {
                  title: t('menu.dashboard.register'),
                  content: (
                    <TableContent
                      data={registerTableContent}
                      sortableColumns={sortableColumns}
                      header={headerRegistration}
                      tooltipCaptions={RegisterTooltipCaptions()}
                      tableName={'register'}
                    />
                  ),
                  seeAll: '/admin/dashboard/register',
                },
                {
                  title: t('menu.dashboard.financing'),
                  content: (
                    <TableContent
                      data={financingTableContent}
                      sortableColumns={sortableColumns}
                      header={headerFinancing}
                      client
                      tooltipCaptions={FinancingTooltipCaptions()}
                      tableName={'financing'}
                    />
                  ),
                  seeAll: '/admin/dashboard/financing',
                },
                {
                  title: t('menu.dashboard.contract'),
                  content: (
                    <TableContent
                      data={contractTableContent}
                      sortableColumns={sortableColumns}
                      header={headerStatus}
                      tooltipCaptions={ContractTooltipCaptions()}
                      tableName={'contract'}
                      client
                    />
                  ),
                  seeAll: '/admin/dashboard/contract',
                },
                {
                  title: t('menu.dashboard.payment'),
                  content: (
                    <TableContent
                      data={paymentTableContent}
                      sortableColumns={sortableColumns}
                      header={headerStatus}
                      tooltipCaptions={PaymentTooltipCaptions()}
                      tableName={'payment'}
                      client
                    />
                  ),
                  seeAll: '/admin/dashboard/payment',
                },
              ]}
            />
          </TablesContainer>
          <FooterLoginAdmin />
        </MinHeightScreeningContainer>
      </div>
    </div>
  );
};

export default Dashboard;

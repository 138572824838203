import { AUTHORITIES } from 'config/constants';
import jwt_decode from 'jwt-decode';

class AuthUtils {
  static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

  static isAuthenticated = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
  };

  static isUser = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    const decode = jwt_decode(AuthUtils.getToken()!).auth;
    if (decode.includes(AUTHORITIES.USER)) {
      if (decode.includes(AUTHORITIES.INTERNAL_ADMIN) || decode.includes(AUTHORITIES.PARTNER) || decode.includes(AUTHORITIES.INTERNAL)) {
        return false;
      }
    }
    return true;
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    const decode = jwt_decode(AuthUtils.getToken()!).auth;
    if (
      decode.includes(AUTHORITIES.ADMIN) ||
      decode.includes(AUTHORITIES.INTERNAL_ADMIN) ||
      decode.includes(AUTHORITIES.PARTNER) ||
      decode.includes(AUTHORITIES.INTERNAL)
    ) {
      return true;
    }

    return false;
  };

  static isPartner = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    const decode = jwt_decode(AuthUtils.getToken()!).auth;
    if (decode.includes(AUTHORITIES.PARTNER)) {
      return true;
    }
    return false;
  };

  static isInternal = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    const decode = jwt_decode(AuthUtils.getToken()!).auth;
    if (decode.includes(AUTHORITIES.INTERNAL)) {
      return true;
    }
    return false;
  };

  static getToken = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static setToken = (token: string) => {
    localStorage.setItem(AuthUtils.AUTH_TOKEN_KEY, token);
  };

  static removeToken = () => {
    localStorage.removeItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static getMainPath = () => {
    return AuthUtils.isAdmin() ? '/admin/dashboard' : AuthUtils.isUser() ? '/home' : '/';
  };
}

export default AuthUtils;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const ContainerButton = styled.div`
  width: 976px;
  display: flex;
  margin-top: 46px;
  img {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const ContainerAllCourse = styled.div`
  width: 976px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
  img {
    margin-left: 36px;
    cursor: pointer;
  }
`;

import GoBack from 'images/go-back.svg';
import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContainerItems, TitleHeader } from './styles';

interface StyledEditHeaderProps {
  text?: string;
}

const StyledEditHeader: React.FC<StyledEditHeaderProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ContainerItems>
      <img src={GoBack} onClick={() => history.goBack()} />
      <TitleHeader>{children}</TitleHeader>
    </ContainerItems>
  );
};

export default StyledEditHeader;

import registerApi from 'api/register-api';
import { City, State } from 'model/address';
import { Language, Occupation } from 'model/client';
import { createContext, ReactNode, useContext, useState } from 'react';

interface SelectListsData {
  states: State[];
  cities: City[];
  languages: Language[];
  occupations: Occupation[];
  stateID: number | null;
  statesTotalPages: number;
  citiesTotalPages: number;
  occupationsTotalPages: number;
  languagesTotalPages: number;
  handleSetStateID: (stateID: number) => void;
  getStates: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getCities: (stateID: number, param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getLanguages: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
  getOccupations: (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => void;
}

interface SelectListsProps {
  children: ReactNode;
}

const SelectListsContext = createContext<SelectListsData>({} as SelectListsData);

export const SelectListsProvider = ({ children }: SelectListsProps) => {
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);
  const [stateID, setStateID] = useState<number | null>(null);
  const [statesTotalPages, setStatesTotalPages] = useState(0);
  const [citiesTotalPages, setCitiesTotalPages] = useState(0);
  const [languagesTotalPages, setLanguagesTotalPages] = useState(0);
  const [occupationsTotalPages, setOccupationsTotalPages] = useState(0);

  const handleSetStateID = (id: number) => {
    if (id != null) {
      setStateID(id);
    }
  };

  const getStates = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getStates(param?.size, param?.page, param?.name);
      setStatesTotalPages(res.data.totalPages);
      if (scroll) {
        setStates(states.concat(res.data.content));
      } else {
        setStates(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async (id: number, param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getCities(id, param?.size, param?.page, param?.name);
      setCitiesTotalPages(res.data.totalPages);
      if (scroll) {
        setCities(cities.concat(res.data.content));
      } else {
        setCities(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguages = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getLanguages(param?.size, param?.page, param?.name);
      setLanguagesTotalPages(res.data.totalPages);
      if (scroll) {
        setLanguages(languages.concat(res.data.content));
      } else {
        setLanguages(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOccupations = async (param?: { size?: number; page?: number; name?: string }, scroll?: boolean) => {
    try {
      const res = await registerApi.getOccupations(param?.size, param?.page, param?.name);
      setOccupationsTotalPages(res.data.totalPages);
      if (scroll) {
        setOccupations(occupations.concat(res.data.content));
      } else {
        setOccupations(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SelectListsContext.Provider
      value={{
        states,
        cities,
        languages,
        occupations,
        stateID,
        statesTotalPages,
        citiesTotalPages,
        languagesTotalPages,
        occupationsTotalPages,
        handleSetStateID,
        getStates,
        getCities,
        getLanguages,
        getOccupations,
      }}
    >
      {children}
    </SelectListsContext.Provider>
  );
};

export const useSelectLists = () => useContext(SelectListsContext);

import GridTable from 'components/GridTable';
import Status from 'components/status/status';
import TooltipModal from 'components/tooltip-modal/tooltip-modal';
import { truncate } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { Container } from './StyledComponents';
import { format, parse } from 'date-fns';

interface TableContentProps {
  data: any[];
  sortableColumns: string[];
  header: {
    name: string;
    propName: string;
  }[];
  client?: boolean;
  tooltipCaptions?: {
    title: string;
    propName: string;
  }[];
  tableName?: string;
  columnMaxWidth?: number;
}
const OBJECTIVE_OPTIONS = [
  { label: 'Estudar', value: 'STUDY' },
  { label: 'Estudar e Trabalhar', value: 'WORK_STUDY' },
];
const TableContent: React.FC<TableContentProps> = ({
  data,
  sortableColumns,
  columnMaxWidth,
  header,
  client = false,
  tooltipCaptions,
  tableName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState<boolean>(false);

  const newData = data.map((item, i) => {
    let row = {};
    header.map(value => {
      if (value.name === 'Status') {
        row = { ...row, [value.propName]: <Status title={item[value.propName]} /> };
      } else {
        if (client) {
          if (value.propName === 'name') {
            row = {
              ...row,
              [value.propName]: item.client[`${value.propName}`] ?? 'Não informado',
            };
          } else if (value.propName === 'email') {
            row = {
              ...row,
              [value.propName]: item.client[`${value.propName}`] ?? 'Não informado',
            };
          } else if (value.propName === 'language') {
            row = { ...row, [value.propName]: item.language.name ?? 'Não informado' };
          } else if (value.propName === 'objective') {
            row = {
              ...row,
              [value.propName]: OBJECTIVE_OPTIONS.find(obj => obj.value === item.objective)?.label ?? 'Não informado',
            };
          } else if (value.propName === 'requestedCredit') {
            row = {
              ...row,
              [value.propName]: item.approvedValue ? StringUtils.currencyMask(item.approvedValue.toFixed(2)) : 'Não informado',
            };
          } else if (value.propName === 'installments') {
            row = { ...row, [value.propName]: item.installmentsTotal ?? 'Não informado' };
          } else if (value.propName === 'dueDate') {
            row = { ...row, [value.propName]: item.firstDueDate ? StringUtils.dateFormatMask(item.firstDueDate) : 'Não informado' };
          } else {
            row = { ...row, [value.propName]: item[value.propName] ?? 'Não informado' };
          }
        } else {
          if (value.propName === 'phone') {
            row = { ...row, [value.propName]: StringUtils.phoneMask(item[value.propName]) ?? 'Não informado' };
          } else if (value.propName === 'createdDate') {
            row = {
              ...row,
              [value.propName]: item.createdDate ? format(new Date(item.createdDate), 'dd/MM/yyyy') : 'Não informado',
            };
          } else {
            row = { ...row, [value.propName]: item[value.propName] ?? 'Não informado' };
          }
        }
      }
    });
    return { ...row, id: tableName !== 'register' && tableName !== 'financing' ? item.course.id : tableName === 'financing' && item.id };
  });

  const handleOnClickRow = value => {
    const id = data.find(item => item.email === value.email)?.id ?? data.find(item => item.client.email === value.email)?.client.id;
    const courseId = value.id;

    switch (tableName) {
      case 'register':
        localStorage.setItem('activeTab', `0`);
        history.push(`/admin/dashboard/register/${id}`);
        return;
      case 'payment':
        localStorage.setItem('activeTab', '4');
        history.push(`/admin/dashboard/register/${id}${courseId && `/course/${courseId}`}`);
        return;
      case 'financing':
        localStorage.setItem('activeTab', '2');
        history.push(`/admin/dashboard/register/${id}${courseId && `/course/${courseId}`}`);
        return;
      case 'contract':
        localStorage.setItem('activeTab', '3');
        history.push(`/admin/dashboard/register/${id}${courseId && `/course/${courseId}`}`);
        return;
      default:
        return;
    }
  };

  return (
    <Container>
      {isTooltipModalOpen && <TooltipModal captions={tooltipCaptions} tableName={tableName} />}

      <GridTable
        onRowClick={value => handleOnClickRow(value)}
        sortableColumns={sortableColumns}
        header={header}
        data={newData}
        isTooltipModalOpen={isTooltipModalOpen}
        setIsTooltipModalOpen={setIsTooltipModalOpen}
        columnMaxWidth={columnMaxWidth}
      />
    </Container>
  );
};

export default TableContent;

import styled from 'styled-components';

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 9.68rem;
    height: 9.81rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2b1a48;
  text-align: center;
  margin: 14px 0px 30px 0;
`;

export const Subtitle = styled.span`
  display: block;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #cccccc;
  text-align: center;
  margin: 14px 0px 48px 0;
`;

export const MessageText = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const ConfirmContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const ContainerFormInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  form {
    div {
      margin-left: 0px;
      div + div + div + div {
        display: none;
      }
    }
  }
`;

export const ContainerFinancing = styled.div`
  width: 377px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 22px;
  margin-left: 108px;
  span {
    margin-left: 16px;
  }
  img {
    width: 14px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

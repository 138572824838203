import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { Contract } from 'model/contract';
import { Financing } from 'model/financing';
import { Graph } from 'model/graph';
import { PageableResponse } from 'model/pageable';
import { Payment } from 'model/payment';
import { api } from './api';

export const dashboardApi = () => {
  const getRegisterTableData = (params): Promise<AxiosResponse<PageableResponse<Client>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/dashboard/clients?${urlParams}`);
  };
  const getFinancingTableData = (params): Promise<AxiosResponse<PageableResponse<Financing>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/dashboard/courses?${urlParams}`);
  };
  const getContractTableData = (params): Promise<AxiosResponse<PageableResponse<Contract>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/dashboard/contracts?${urlParams}`);
  };
  const getPaymentTableData = (params): Promise<AxiosResponse<PageableResponse<Payment>>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get(`/dashboard/financings?${urlParams}&status=contains(NEW)&status=contains(IN_PROGRESS)    `);
    return api.get(`/dashboard/financings?${urlParams}   `);
  };
  const getGraphData = (page): Promise<AxiosResponse<Graph>> => {
    if (page === 'general') {
      return api.get(`/dashboard/count-status`);
    }
    return api.get(`/dashboard/count-status-${page}`);
  };

  return {
    getRegisterTableData,
    getFinancingTableData,
    getContractTableData,
    getPaymentTableData,
    getGraphData,
  };
};

export default dashboardApi();

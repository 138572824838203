import { useEffect } from 'react';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import Income from '../../../images/income.svg';
import { ContainerInputsIncome } from './InputsIncome';
import { FormContainer } from '../styles';
import { useRegisterForm } from 'provider/RegisterForm';
import { Client } from 'model/client';
import { useSelectLists } from 'provider/SelectLists';
import { useTranslation } from 'react-i18next';

export const FormIncome = () => {
  const { onClickNext } = useRegisterForm();
  const { occupations, getOccupations } = useSelectLists();
  const { t } = useTranslation();
  const schema = yup.object({
    occupation: yup.string().required(t('global.errorMessage.required')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
  });

  useEffect(() => {
    getOccupations({ size: 7 });
  }, []);

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const occupation = occupations.find(occ => occ.name === res.occupation);
          onClickNext({ income: res.income, occupation } as Client);
        }}
      >
        <ContainerInputsIncome />
      </ReactHookFormProvider>

      <div style={{ marginLeft: '160px' }}>
        <img src={Income} />
      </div>
    </FormContainer>
  );
};

export enum RegistrationStatus {
  PRE_REJECTED = 'PRE_REJECTED',
  NEW = 'NEW',
  VALIDATION = 'VALIDATION',
  ADJUSTED = 'ADJUSTED',
  ADJUST = 'ADJUST',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum RegistrationStepsStatus {
  REGISTRATION = 'REGISTRATION',
  DOCUMENT_REGISTRATION = 'DOCUMENT_REGISTRATION',
  DOCUMENT_SENT = 'DOCUMENT_SENT',
  REGISTRATION_ADJUST = 'REGISTRATION_ADJUST',
  REGISTRATION_ADJUSTED = 'REGISTRATION_ADJUSTED',
  REGISTRATION_REJECTED = 'REGISTRATION_REJECTED',
  REGISTRATION_APPROVED = 'REGISTRATION_APPROVED',
  FINANCING_STARTED = 'FINANCING_STARTED',
  REGISTRATION_UPDATED = 'REGISTRATION_UPDATED',
  REGISTRATIONSTATUS_UPDATED = 'REGISTRATIONSTATUS_UPDATED',
}

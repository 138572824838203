import { Tab, Tabs } from '@mui/material';
import ContainerDocumentGeneric from 'components/container-documents-generic';
import ContainerDocument from 'components/ContainerDocument';
import { InputAndText } from 'components/InputAndTitle';
import { SearchSelectInput } from 'components/SearchSelectInput';
import { TextInput } from 'components/TextInput';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { GuarantorProps } from 'model/guarantor';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { ContainerInputs, SubtitleText } from './styles';

export interface InfoIds {
  cityId: number;
  occupationId: number;
}
interface Props {
  guarantorsData?: GuarantorProps[];
  setInfoIds?: (ids: InfoIds) => void;
  infoIds?: InfoIds;
  isClient?: boolean;
  edit?: boolean;
}

export const FormGuarantorData = ({ guarantorsData, setInfoIds, infoIds, isClient = false, edit = false }: Props) => {
  const { t } = useTranslation();

  const [selectedGuarantor, setSelectedGuarantor] = useState<number>(0);
  const [selectedGuarantorData, setSelectedGuarantorData] = useState<GuarantorProps | undefined>(
    guarantorsData && guarantorsData.length > 0 ? guarantorsData![0] : undefined
  );
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [resetCities, setResetCities] = useState(false);

  const {
    states,
    cities,
    stateID,
    handleSetStateID,
    getStates,
    getCities,
    statesTotalPages,
    citiesTotalPages,
    occupations,
    occupationsTotalPages,
    getOccupations,
  } = useSelectLists();

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7 }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage]);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  useEffect(() => {
    setResetCities(true);
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 });
    }
  }, [stateID]);

  useEffect(() => {
    if (selectedGuarantor === 0) {
      setSelectedGuarantorData(guarantorsData?.[0] ?? {});
    } else {
      setSelectedGuarantorData(guarantorsData?.find(item => item.id === selectedGuarantor) ?? {});
    }
  }, [selectedGuarantor]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedGuarantor(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedGuarantor}
        onChange={handleChange}
        scrollButtons="auto"
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ borderBottom: 1, borderColor: '#D8D8D8' }}
        centered
      >
        {guarantorsData && guarantorsData.map((item, i) => <Tab key={i} value={item.id} label={`Fiador ${i + 1}`} />)}
      </Tabs>
      <SubtitleText>{t('global.subTabs.guarantorGeneralData')}</SubtitleText>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.name"
          name="name"
          placeholder="dashboardClient.guarantor.form.placeholder.name"
          value={selectedGuarantorData && selectedGuarantorData?.name}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.email"
          name="email"
          placeholder="dashboardClient.guarantor.form.placeholder.email"
          value={selectedGuarantorData && selectedGuarantorData?.email}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.phone"
          name="phone"
          placeholder="dashboardClient.guarantor.form.placeholder.phone"
          value={selectedGuarantorData && selectedGuarantorData?.phone}
          mask="PHONE"
          max={15}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.cpf"
          name="cpf"
          placeholder="dashboardClient.guarantor.form.placeholder.cpf"
          value={selectedGuarantorData && selectedGuarantorData?.cpf}
          mask="CPF"
          disabled={!edit}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.income"
          name="income"
          placeholder="dashboardClient.guarantor.form.placeholder.income"
          value={selectedGuarantorData && selectedGuarantorData?.income?.toFixed(2)}
          mask="CURRENCY"
        />
        <TextInput name="office" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="office"
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
            defaultValue={selectedGuarantorData?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              const occupation = occupations.find(op => op.name === values.name);
              if (occupation != null) {
                setInfoIds && setInfoIds({ ...infoIds!, occupationId: occupation.id });
              }
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs style={{ marginBottom: '48px' }}>
        <InputAndText
          title="dashboardClient.guarantor.form.rg"
          name="rg"
          placeholder="dashboardClient.guarantor.form.placeholder.rg"
          value={selectedGuarantorData && selectedGuarantorData?.rg}
          disabled={!edit}
          max={9}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.birthDate"
          name="birth"
          placeholder="dashboardClient.guarantor.form.placeholder.birthDate"
          value={selectedGuarantorData && selectedGuarantorData?.birth?.toString().split('-').reverse().join('/')}
          mask="BIRTHDAY"
          max={10}
        />
      </ContainerInputs>
      <SubtitleText>{t('global.subTabs.guarantorAddress')}</SubtitleText>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.zipCode"
          name="zipcode"
          placeholder="dashboardClient.guarantor.form.placeholder.zipCode"
          value={selectedGuarantorData && selectedGuarantorData?.address?.zipcode}
          mask="CEP"
          max={9}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.address"
          name="address"
          placeholder="dashboardClient.guarantor.form.placeholder.address"
          value={selectedGuarantorData && selectedGuarantorData?.address?.street}
        />
      </ContainerInputs>
      <ContainerInputs>
        <InputAndText
          title="dashboardClient.guarantor.form.district"
          name="district"
          placeholder="dashboardClient.guarantor.form.placeholder.district"
          value={selectedGuarantorData && selectedGuarantorData?.address?.district}
        />
        <InputAndText
          title="dashboardClient.guarantor.form.number"
          name="number"
          placeholder="dashboardClient.guarantor.form.placeholder.number"
          value={selectedGuarantorData && selectedGuarantorData?.address?.number}
        />
      </ContainerInputs>
      <ContainerInputs>
        <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
          <SearchSelectInput
            name="state"
            placeholder={t('register.placeholder.state')}
            options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultValue={selectedGuarantorData?.address?.city?.state?.name}
            handleChange={values => {
              const state = states.find(st => st.name === values.name);
              if (state != null) {
                handleSetStateID(state.id);
              }
              setStateName(values.name);
              setStatePage(0);
              getStates({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (statePage < statesTotalPages - 1) {
                setStatePage(statePage + 1);
              }
            }}
          />
        </TextInput>
        <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
          <SearchSelectInput
            name="city"
            placeholder={t('register.placeholder.city')}
            defaultValue={selectedGuarantorData?.address?.city?.name}
            options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            handleChange={values => {
              setResetCities(false);
              setCityName(values.name);
              const city = cities.find(ct => ct.name === values.name);
              if (city != null) {
                setInfoIds && setInfoIds({ ...infoIds!, cityId: city.id });
              }
              setCityPage(0);
              if (stateID) {
                getCities(stateID, { name: values.name, size: 7 });
              }
            }}
            handleOnScroll={() => {
              if (cityPage < citiesTotalPages - 1) {
                setCityPage(cityPage + 1);
              }
            }}
            resetValue={resetCities}
          />
        </TextInput>
      </ContainerInputs>
      <ContainerInputs style={{ marginBottom: '48px' }}>
        <InputAndText
          title="dashboardClient.guarantor.form.complement"
          name="complement"
          placeholder="dashboardClient.guarantor.form.placeholder.complement"
          value={selectedGuarantorData && selectedGuarantorData?.address?.complement}
        />
        <div />
      </ContainerInputs>
      <SubtitleText style={{ marginBottom: '24px' }}>{t('global.subTabs.guarantorAttachments')}</SubtitleText>
      <div style={{ marginTop: '24px' }}>
        <ContainerDocumentGeneric
          titleDocument="documents.identification"
          editDocument
          nameInput="doc1"
          name="attachmentIdentification"
          type={AttachmentType.IDENTIFICATION_CARD}
          title={
            selectedGuarantorData?.attachments &&
            selectedGuarantorData?.attachments.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName
          }
          docUrl={StringUtils.getFileOrUrl(
            selectedGuarantorData?.attachments?.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)
          )}
          selectedGuarantorId={selectedGuarantorData?.id}
        />
      </div>
      <ContainerDocumentGeneric
        titleDocument="documents.ProofOfIncome"
        editDocument
        nameInput="doc2"
        name="attachmentIncome"
        type={AttachmentType.PROOF_INCOME}
        title={
          selectedGuarantorData?.attachments &&
          selectedGuarantorData?.attachments.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName
        }
        docUrl={StringUtils.getFileOrUrl(selectedGuarantorData?.attachments?.find(att => att.type === AttachmentType.PROOF_INCOME))}
        selectedGuarantorId={selectedGuarantorData?.id}
      />
      <ContainerDocumentGeneric
        titleDocument="documents.proofOfAddress"
        editDocument
        nameInput="doc3"
        name="attachmentAddress"
        type={AttachmentType.PROOF_RESIDENCE}
        title={
          selectedGuarantorData?.attachments &&
          selectedGuarantorData?.attachments.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName
        }
        docUrl={StringUtils.getFileOrUrl(selectedGuarantorData?.attachments?.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
        selectedGuarantorId={selectedGuarantorData?.id}
      />
    </div>
  );
};

import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import HeaderLoginAdmin from 'components/header-login-admin/header-login-admin';
import Loading from 'components/loading/loading';
import StyledButton from 'components/StyledButton';
import EmailSentIcon from 'images/email-sent.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import userService from 'services/user-service';
import { ButtonContainer, Container, FormContainer, LoginContainer, SubTextTitle, TextTitle } from './styles';

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ key: string }>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (params.key) {
      userService
        .confirmEmail(params.key)
        .then(it => {
          setError(false);
          setLoading(false);
        })
        .catch(e => {
          setError(true);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderLoginAdmin />
      <LoginContainer>
        {!error && (
          <Container>
            <TextTitle>{t('confirmEmail.successMessage')}</TextTitle>
            <SubTextTitle>{t('confirmEmail.successEnterMessage')}</SubTextTitle>
            <FormContainer>
              <ButtonContainer>
                <StyledButton primary onClick={() => history.push('/login')} text={t('global.button.enter')} />
              </ButtonContainer>
            </FormContainer>
          </Container>
        )}
        {error && (
          <Container>
            <TextTitle>{t('confirmEmail.errorMessage')}</TextTitle>
            <SubTextTitle>{t('confirmEmail.tryAgain')}</SubTextTitle>
            <FormContainer>
              <ButtonContainer>
                <StyledButton primary onClick={() => window.location.reload()} text={t('global.button.tryAgain')} />
              </ButtonContainer>
            </FormContainer>
          </Container>
        )}
      </LoginContainer>
      <FooterLoginAdmin />
    </>
  );
};

export default ConfirmEmail;

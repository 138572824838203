import styled from 'styled-components';

export const Container = styled.div`
  height: 80vh;
  width: 70vw;
  margin: 0 auto;

  display: flex;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  text-align: left;
  color: #2b1a48;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 35px;
  margin-top: 48px;
`;

export const Message = styled.span`
  display: inline-block;
  max-width: 483px;
  text-align: start;

  margin-bottom: 16px;
`;

export const ImageContainer = styled.div`
  height: max-content;

  margin-top: 164px;
  margin-left: 300px;
`;

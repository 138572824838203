const arrayQuestions = [
  { question: 'LandingPage.textFaq.firstQuestion', Response: 'LandingPage.textFaq.firstQuestionResponse' },
  { question: 'LandingPage.textFaq.secondQuestion', Response: 'LandingPage.textFaq.secondQuestionResponse' },
  { question: 'LandingPage.textFaq.thirdQuestion', Response: 'LandingPage.textFaq.thirdQuestionResponse' },
  { question: 'LandingPage.textFaq.fourthQuestion', Response: 'LandingPage.textFaq.fourthQuestionResponse' },
  { question: 'LandingPage.textFaq.fifthQuestion', Response: 'LandingPage.textFaq.fifthQuestionResponse' },
  { question: 'LandingPage.textFaq.sixthQuestion', Response: 'LandingPage.textFaq.sixthQuestionResponse' },
  { question: 'LandingPage.textFaq.seventhQuestion', Response: 'LandingPage.textFaq.seventhQuestionResponse' },
];

export default arrayQuestions;

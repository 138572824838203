import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContainerCard, ContainerInfoCourse, ContainerInfos, ContainerStatus, Info } from './styles';

interface Data {
  id: number;
  objective: string;
  duration: string;
  language: string;
  startDate: string;
  creditAnalysisStatus?: string;
}

interface Props {
  data: Data;
}

export default function CardFinancing({ data }: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ContainerCard
      onClick={() => history.push(`/client/dashboard/financing/process?id=${data.id}`)}
      style={{ position: !data.creditAnalysisStatus ? 'absolute' : 'inherit', zIndex: !data.creditAnalysisStatus ? 1 : 'auto' }}
    >
      <ContainerInfoCourse style={{ borderRadius: data.creditAnalysisStatus ? '8px 8px 0px 0px' : '8px' }}>
        <ContainerInfos>
          <Info>
            <span>{t('dashboardClient.guarantor.cardFinancing.objective')}</span>
            <span>{t(`enums.${data.objective.toLowerCase()}`)}</span>
          </Info>
          <Info>
            <span>{t('dashboardClient.guarantor.cardFinancing.duration')}</span>
            <span>{t(`enums.${data.duration.toLowerCase()}`)}</span>
          </Info>
        </ContainerInfos>
        <ContainerInfos>
          <Info>
            <span>{t('dashboardClient.guarantor.cardFinancing.language')}</span>
            <span>{data.language}</span>
          </Info>
          <Info>
            <span>{t('dashboardClient.guarantor.cardFinancing.date')}</span>
            <span>{data.startDate}</span>
          </Info>
        </ContainerInfos>
      </ContainerInfoCourse>
      {data.creditAnalysisStatus && (
        <ContainerStatus>
          <span>{t(`enums.${data.creditAnalysisStatus.toLowerCase()}`)}</span>
        </ContainerStatus>
      )}
    </ContainerCard>
  );
}

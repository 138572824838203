import courseApi from 'api/course-api';
import ButtonComponent from 'components/Button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { ContainerInputsCourse } from 'components/RegistrationForms/Course/InputsCourse';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { Duration } from 'model/enums/duration';
import HttpStatus from 'model/enums/http-status';
import { Objective } from 'model/enums/objective';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import courseService from 'services/course.service';
import * as yup from 'yup';
import { CloseButton, CloseContainer, ConfirmContainer, ContainerFormInput, Title } from '../ModalLinkFinancing/styles';

interface SimpleModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  setModalRequestOk: () => void;
}

interface Ids {
  id: number;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    minHeight: '620px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

interface Schema {
  objective: Objective;
  language: string;
  duration: Duration;
}

const ModalNewFinancing = ({ isOpen, onCloseModal, setModalRequestOk }: SimpleModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [languageId, setLanguageId] = useState<number>();
  const [load, setLoad] = useState<boolean>(false);
  const [sendOk, setSendOk] = useState<boolean>(false);
  const schema = yup.object({
    objective: yup.string().required(t('global.errorMessage.required')),
    language: yup.string().required(t('global.errorMessage.required')),
    duration: yup.string().required(t('global.errorMessage.required')),
  });

  const handleSubmit = async (data: Schema) => {
    setLoad(true);
    const NewFinancing: Course = {
      language: {
        id: languageId ?? 0,
      },
      objective: data.objective,
      duration: data.duration,
      startDate: new Date().toString(),
    };
    const res = await courseService.newCourse(NewFinancing);
    if (res) {
      setLoad(false);
      setSendOk(true);
      setModalRequestOk();
      onCloseModal();
    }
  };

  useEffect(() => {
    if (!isOpen) setSendOk(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('dashboardClient.financing.modal.title')}</Title>
      <ContainerFormInput>
        <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
          <div style={{ width: '375px' }}>
            <ContainerInputsCourse setLanguageId={setLanguageId} />
          </div>
          <ConfirmContainer>
            <ButtonComponent
              width="123px"
              height="47px"
              background="transparent"
              border="1px solid #80008C"
              borderRadius="8px"
              color="#80008C"
              text="global.button.cancel"
              weight="600"
              size="18px"
              type="button"
              onClick={onCloseModal}
            />
            <ButtonComponent
              width="123px"
              height="47px"
              background="#80008C"
              border="none"
              borderRadius="8px"
              margin="0 0 0 8px"
              color="white"
              text="global.button.add"
              weight="600"
              size="18px"
              type="submit"
              load={load}
              sendOk={sendOk}
            />
          </ConfirmContainer>
        </ReactHookFormProvider>
      </ContainerFormInput>
    </Modal>
  );
};

export default ModalNewFinancing;

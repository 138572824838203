import styled, { css } from 'styled-components';
import { color } from '../shared/util/styled-components-utils';

interface StyledInputWrapperProps {
  isFocused: boolean;
  isTouched: boolean;
  isValid?: boolean;
  hasError?: boolean;
  hasValue?: boolean;
  isWhite?: boolean;
}
const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  ${props =>
    props.isFocused || props.isValid || props.hasValue || props.isWhite
      ? css`
          background: white;
        `
      : css`
          background: ${color`formThird`};
        `}
  position: relative;
  display: flex;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  overflow: hidden;
  padding-left: 13px;
  padding-top: 2px;
  ${props =>
    props.hasError
      ? css`
          padding-top: 2px;
          border-top: 2px solid ${color`red`};
        `
      : ''}
  ${props =>
    props.isValid && !props.hasError
      ? css`
          padding-top: 2px;
          border-top: 2px solid ${color`green`};
        `
      : ''}

    input {
    background: transparent;
    border: none;
    height: 46px;
    flex: 1 1 100%;
    font-weight: 400;
    color: #333333;
    margin-right: 30px;
  }

  input:focus {
    margin-right: 38px;
  }

  ${props => (props.hasValue ? 'input ~ span.input__text--label,' : '')}
  input:focus ~ span.input__text--label {
    top: 12px;
    font-size: 0.75rem;
    color: #979797;
  }
`;

export default StyledInputWrapper;

import styled from 'styled-components';

interface Props {
  admin?: boolean;
  right?: number;
}

export const Container = styled.div<Props>`
  width: ${({ admin }) => (admin ? '100%' : '976px')};
  height: 121px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  border-radius: 4px 4px 0px 0px;
  margin-top: ${({ admin }) => (admin ? '0' : '20px')};
  margin-bottom: ${({ admin }) => (admin ? '20px' : '0')};
  cursor: pointer;
`;

export const ContainerTitles = styled.div<Props>`
  width: ${({ admin }) => (admin ? '100%' : '976px')};
  height: 29px;
  display: flex;
  justify-content: ${({ admin }) => (admin ? 'flex-start' : 'space-between')};
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px 4px 0px 0px;
  padding: ${({ admin }) => (admin ? '0 60px 0 15px' : '0 95px 0 15px')};
`;

export const TitleText = styled.span<Props>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: ${({ admin }) => (admin ? '13px' : '18px')};
  line-height: 22px;
  margin-right: ${({ admin, right }) => (admin ? `${right}px` : '0')};

  color: #000000;
`;

export const Border = styled.div<Props>`
  width: 1px;
  height: 100%;
  background-color: #d8d8d8;
  margin-right: 15px;
  display: ${({ admin }) => (admin ? 'inline-block' : 'none')};
`;

export const ContainerInfos = styled.div<Props>`
  width: 976px;
  height: 92px;
  display: flex;
  justify-content: ${({ admin }) => (admin ? 'flex-start' : 'space-between')};
  align-items: center;
  padding: 0 53px 0 15px;
`;

export const ContainerNameContract = styled.div<Props>`
  min-width: ${({ admin }) => (admin ? '100px' : '277px')};
  min-height: ${({ admin }) => (admin ? '18px' : '30px')};
  display: flex;
  align-items: center;
  background: #d8bdfd;
  border-radius: 6px;
  justify-content: flex-start;
  margin-right: ${({ admin }) => (admin ? '106px' : '0')};
  img {
    margin-left: 10px;
  }
  img + span + img {
    display: none;
  }
  width: fit-content;
`;

export const ContainerName = styled.div<Props>`
  width: ${({ admin }) => (admin ? '345px' : '277px')};
`;

export const TitleContract = styled.span<Props>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ admin }) => (admin ? '12px' : '16px')};
  margin-left: 10px;
  margin-right: 10px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

export const ContainerSignatures = styled.div<Props>`
  max-width: ${({ admin }) => (admin ? '223px' : '149px')};
  min-width: ${({ admin }) => (admin ? '223px' : '149px')};
  display: flex;
  flex-direction: column;
  margin-right: ${({ admin }) => (admin ? '0' : '150px')};
  img {
    margin-right: 8px;
  }
`;

export const ContainerSignature = styled.div`
  display: flex;
  align-items: center;
`;

export const BallSignature = styled.div`
  width: 6px;
  height: 6px;
  background: #979797;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 3px;
`;

export const Signature = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerStatus = styled.div`
  width: 93px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d90980;
  border-radius: 10px;
`;

export const Status = styled.span`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

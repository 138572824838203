import React from 'react';
import ButtonComponent from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ContainerDescription, Description, MainImage, SubtitleText } from './styles';

interface WarningContent {
  img?: string;
  subtitle: string;
  description: string;
  containerDescription?: boolean;
  message?: string;
  titleButton?: string;
  onClick?: () => void;
}

export default function WarningContent({
  img,
  subtitle,
  description,
  containerDescription = false,
  message,
  titleButton,
  onClick,
}: WarningContent) {
  const { t } = useTranslation();

  return (
    <>
      {img && <MainImage src={img} />}
      <SubtitleText>{t(subtitle)}</SubtitleText>
      {containerDescription ? (
        <ContainerDescription>
          <span>{message}</span>
        </ContainerDescription>
      ) : (
        <Description>{t(description)}</Description>
      )}
      {titleButton && (
        <ButtonComponent
          width="263px"
          height="58px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          color="white"
          text={titleButton}
          margin="32px 0px 0px 0px"
          weight="600"
          size="24px"
          onClick={onClick}
        />
      )}
    </>
  );
}

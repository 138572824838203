import AdminHeader from 'components/admin-header/admin-header';
import DropdownFilter from 'components/DropdownFilter';
import DynamicGraphInfos from 'components/dynamic-graph-infos/dynamic-graph-infos';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import GeneralGraphInfos from 'components/general-graph-infos/general-graph-infos';
import { InputContainers, InputDivisor } from 'components/InputContainers';
import { SearchInput } from 'components/search-input/search-input';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledPagination from 'components/StyledPagination';
import TableContent from 'components/table-content/table-content';
import { financingSteps } from 'helpers/helpersDropdownLabels';
import { StatusDashboard } from 'model/enums/status';
import { Financing } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dashboardService from 'services/dashboard-service';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import { FinancingTooltipCaptions } from 'shared/util/tooltip-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import { Container, ItemLink, TableContainer } from '../styles';

const DashboardFinancing = () => {
  const { t } = useTranslation();

  const [tableContent, setTableContent] = useState<Financing[]>([]);
  const [filterBy, setFilterBy] = useState({ filterSelect: '', saveFilter: 'off' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const header = [
    { name: t('global.table.name'), propName: 'name' },
    { name: t('global.table.email'), propName: 'email' },
    { name: t('global.table.goal'), propName: 'objective' },
    { name: t('global.table.language'), propName: 'language' },
    { name: t('global.table.score'), propName: 'score' },
    { name: t('global.table.status'), propName: 'creditAnalysisStatus' },
  ];

  const sortableColumns = ['Nome', 'Email', 'Objetivo', 'Linguagem', 'Score', 'Status'];

  const filterSaved = localStorage.getItem(financingSteps.toString());

  useEffect(() => {
    filterSaved &&
      handleFilter({
        filterSelect: filterSaved,
        saveFilter: 'on',
      });
  }, []);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilter = e => {
    if (!e.filterSelect) {
      return;
    }

    const statusOptions = [StatusDashboard.NEW, StatusDashboard.CREDIT_ANALYSIS, StatusDashboard.APPROVED, StatusDashboard.REJECTED];
    const status = statusOptions[Number(e.filterSelect)] ?? 'off';

    setFilterBy({
      filterSelect: status,
      saveFilter: e.saveFilter,
    });
  };

  const handleOrderData = async () => {
    const today = StringUtils.dateMask(new Date());
    const filterOutput = filterBy.filterSelect === 'off' ? '' : filterBy.filterSelect;

    const financing = await dashboardService.getFinancingTableData({
      page: currentPage - 1,
      size: 10,
      creditAnalysisStatus: `contains(${[filterOutput]})`,
      'client.name': `contains(${searchInputValue})`,
      sort: 'creditAnalysisStatusOrdinal,asc',
    });

    setTotalPages(financing.totalPages);
    setTableContent(financing.content ?? []);
  };

  // debounce
  const [timer, setTimer] = useState<any>();
  function debounce(func, timeout = 300) {
    clearTimeout(timer);
    setTimer(setTimeout(func, timeout));
  }

  useEffect(() => {
    debounce(() => {
      handleOrderData();
    }, 300);
  }, [filterBy, currentPage, searchInputValue]);

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AdminHeader />
      </div>
      <div style={{ width: '100%' }}>
        <StyledAdminHeader>{t('menu.dashboard.financing')}</StyledAdminHeader>
        <MinHeightScreeningContainer>
          <Container>
            <DynamicGraphInfos page="courses" />
            <InputContainers>
              <SearchInput
                placeholder={t('fields.searchByName')}
                onChange={e => setSearchInputValue(e.target.value)}
                value={searchInputValue}
              />
              <InputDivisor />
              <DropdownFilter placeholder={t('global.fields.filterBy')} labels={financingSteps} onApplyFiltration={e => handleFilter(e)} />
            </InputContainers>
          </Container>
          <TableContainer>
            {!AuthUtils.isPartner() && !AuthUtils.isInternal() && (
              <Link to={'/admin/new/client'}>
                <ItemLink>{t('global.button.addNewClient')}</ItemLink>
              </Link>
            )}
            <TableContent
              data={tableContent ?? []}
              sortableColumns={sortableColumns}
              header={header}
              tooltipCaptions={FinancingTooltipCaptions()}
              tableName={'financing'}
              client
            />
            <StyledPagination
              page={{
                page: currentPage,
                totalPages,
              }}
              handleChangePage={handleChangePage}
            />
          </TableContainer>
          <FooterLoginAdmin />
        </MinHeightScreeningContainer>
      </div>
    </div>
  );
};

export default DashboardFinancing;

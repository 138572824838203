import { Checkbox } from '@mui/material';
import authenticationApi from 'api/authentication-api';
import { RegisterHeader } from 'components/HeaderRegister';
import { SimpleModal } from 'components/SimpleModal';
import StyledButton from 'components/StyledButton';
import Footer from 'features/footer/footer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bike from '../../images/bike.svg';
import { ButtonContainer, CheckBoxContainer, Container, ImageContainer, Message, TextContainer, Title } from './styles';
import ScoreApprove from '../../images/score-approve.svg';
import ScoreNotApprove from '../../images/score-not-approve.svg';
import ScoreError from '../../images/score-error.svg';
import { useHistory } from 'react-router-dom';

export const RequestApproval = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isApproved, setIsAppoved] = useState<boolean | null>(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isApproved !== null) {
      setIsOpen(true);
    }
  }, [isApproved]);

  const onHandleRequestApproval = async () => {
    try {
      const res = await authenticationApi.checkCreditScoreClient();
      if (res.data.creditScore?.approved) {
        setIsAppoved(true);
      }

      if (!res.data.creditScore?.approved) {
        setIsAppoved(false);
      }
    } catch (error) {
      setIsAppoved(null);
      setIsOpen(true);
    }
  };

  const onHandleConfirm = () => {
    switch (isApproved) {
      case true:
        history.replace('/client/register-documents');
        break;

      case false:
        history.replace('/client/blocked');
        break;

      default:
        history.replace('/');
        break;
    }
  };

  const onHandleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <RegisterHeader />
      <Container>
        <TextContainer>
          <Title>{t('requestApproval.title')}</Title>
          <Message>{t('requestApproval.message')}</Message>

          <CheckBoxContainer>
            <Checkbox color="default" onClick={() => setAcceptTerms(!acceptTerms)} />
            <span>
              {t('requestApproval.termsText')}
              <a href="">{t('requestApproval.linkText')}</a>
            </span>
          </CheckBoxContainer>
          <ButtonContainer>
            <StyledButton primary text={t('requestApproval.buttonText')} onClick={onHandleRequestApproval} disabled={!acceptTerms} />
          </ButtonContainer>
        </TextContainer>
        <ImageContainer>
          <img src={Bike} />
        </ImageContainer>
      </Container>
      <Footer />
      <SimpleModal
        isOpen={isOpen}
        onCloseModal={onHandleCloseModal}
        confirmText={t(`requestApproval.modal.${isApproved ? 'consfirmTextApproved' : 'confirmText'}`)}
        handleConfirm={onHandleConfirm}
        titleText={
          isApproved !== null && isApproved
            ? t('requestApproval.modal.titleTextApproved')
            : isApproved !== null && !isApproved
            ? t('requestApproval.modal.titleTextNotApproved')
            : t('requestApproval.modal.titleTextError')
        }
        messageText={
          isApproved !== null && isApproved
            ? t('requestApproval.modal.messageTextApproved')
            : isApproved !== null && !isApproved
            ? t('requestApproval.modal.messageTextNotApproved')
            : t('requestApproval.modal.messageTextError')
        }
        imageSrc={isApproved !== null && isApproved ? ScoreApprove : isApproved !== null && !isApproved ? ScoreNotApprove : ScoreError}
      />
    </>
  );
};

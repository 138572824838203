import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
  height: 80vh;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.span`
  color: #2b1a48;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const Message = styled.span`
  color: #4c4d4d;
  font-size: 1rem;
  font-weight: 400;
  max-width: 437px;
  text-align: start;
  margin-top: 1rem;
`;

export const ImageContainer = styled.div``;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  > button {
    margin: 0;
  }
`;

export const AdminMessageContainer = styled.div`
  padding: 20px;
  text-align: start;
  background-color: #f9f3ff;
  border-radius: 8px;
  margin-top: 24px;
  max-width: 487px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  width: 976px;
  display: flex;
  align-items: center;
  margin-top: 46px;
`;

export const TitleText = styled.span`
  min-width: 244px;
  height: 40px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2b1a48;
  text-align: left;
`;

export const ContainerBackStep = styled.div`
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #242424;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  min-width: 977px;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
`;

export const ContainerText = styled.div`
  width: 100%;
  height: 17px;
  display: flex;
  justify-content: space-between;
`;

export const TextBar = styled.span`
  width: max-content;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  text-align: left;
  position: absolute;
  top: -40px;
`;

export const ActualCircule = styled.div`
  width: 24px;
  height: 24px;
  border: 4px solid #80008c;
  border-radius: 75px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const CompletedCircule = styled.div`
  width: 24px;
  height: 24px;
  background-color: #80008c;
  border-radius: 75px;
  position: relative;
  display: flex;
  justify-content: center;
  img {
    width: 10px;
  }
`;

export const Circule = styled.div`
  min-width: 24px;
  height: 24px;
  border-radius: 75px;
  background: #cccccc;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const CompletedBar = styled.div`
  width: 208px;
  height: 8px;
  background: #80008c;
`;

export const Bar = styled.div`
  width: 208px;
  height: 8px;
  background: #cccccc;
`;
export const ContainerProgressBar = styled.div`
  margin-left: 39px;
  margin-right: 26px;
  display: flex;
  align-items: center;
`;

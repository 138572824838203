import ContainerDocument from 'components/ContainerDocument';
import { HeaderWithLogout } from 'components/HeaderWithLogout';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { ButtonContainer, Container, ContainerTextAndArrow, ImageContainer, List } from './styles';
import Footer from 'features/footer/footer';
import { AttachmentType } from 'model/enums/attachment-types';
import { useTranslation } from 'react-i18next';
import Arrow from '../../images/arrowRightPurple.svg';
import Document from '../../images/document.svg';
import * as yup from 'yup';
import StyledButton from 'components/StyledButton';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Attachment } from 'model/files';
import TempContainerDocument from './TempContainerDocument';
import registerApi from 'api/register-api';
import HttpStatus from 'model/enums/http-status';

export const RegisterDocuments = () => {
  const [files, setFiles] = useState<Attachment[]>([]);
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    attachmentIdentification: yup
      .mixed()
      .transform(value => value[0])
      .required(t('global.errorMessage.required')),
    attachmentIncome: yup.mixed().transform(value => value[0]),
    attachmentAddress: yup
      .mixed()
      .transform(value => value[0])
      .required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = res => {
    if (files.length === 3) {
      sendDocuments();
    }
  };

  const sendDocuments = async () => {
    try {
      const res = await registerApi.sendDocumentsClient(files);
      if (res.status === HttpStatus.OK) {
        history.replace(`${history.location.pathname}/wait`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <span>{t('attachments.title')}</span>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <ContainerTextAndArrow>
            <img src={Arrow} />
            <span>{t('documents.identification')}</span>
          </ContainerTextAndArrow>
          <ImageContainer>
            <img src={Document} />
          </ImageContainer>
          <List>
            <li>{t('documents.list.picture')}</li>
            <li>{t('documents.list.reflection')}</li>
            <li>{t('documents.list.verification')}</li>
          </List>
          <ContainerTextAndArrow>
            <img src={Arrow} />
            <span>{t('documents.ProofOfIncome')}</span>
          </ContainerTextAndArrow>
          <ContainerTextAndArrow>
            <img src={Arrow} />
            <span>{t('documents.proofOfAddress')}</span>
          </ContainerTextAndArrow>
          <TempContainerDocument
            titleDocument="documents.identification"
            editDocument
            nameInput="doc1"
            name="attachmentIdentification"
            type={AttachmentType.IDENTIFICATION_CARD}
            files={files}
            setFiles={setFiles}
          />
          <TempContainerDocument
            titleDocument="documents.ProofOfIncome"
            editDocument
            nameInput="doc2"
            name="attachmentIncome"
            type={AttachmentType.PROOF_INCOME}
            files={files}
            setFiles={setFiles}
          />
          <TempContainerDocument
            titleDocument="documents.proofOfAddress"
            editDocument
            nameInput="doc3"
            name="attachmentAddress"
            type={AttachmentType.PROOF_RESIDENCE}
            files={files}
            setFiles={setFiles}
          />
          <ButtonContainer>
            <StyledButton text={t('attachments.buttonText')} primary />
          </ButtonContainer>
        </ReactHookFormProvider>
      </Container>
      <Footer />
    </>
  );
};

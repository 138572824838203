import { isEmpty, isNil } from 'lodash';
import { Attachment } from 'model/files';
import moment, { Moment } from 'moment';
import { EmptyLine } from '../../model/enums/empty-line';
// import { Attachment } from '../../model/legal-person/attachment';

class StringUtils {
  static isStringEmpty = (event?: string) => {
    return isNil(event) || event === '';
  };

  static translateBoolean = (value?: boolean) => {
    return value ? 'Sim' : 'Não';
  };

  static ifNullGetDash = (value?: string): string => {
    return (!isEmpty(value) ? value : EmptyLine.DASH)!;
  };

  static isPasswordInvalid = (event: string) => {
    return StringUtils.isStringEmpty(event) || event.length < 5 || event.length > 250;
  };

  static isSyntaxEmpty = (event?: string) => {
    return event === '${}' || event === '#{}';
  };

  static cpfMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');

    return documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  static dateFormatMask(value: string) {
    return value.split('-').reverse().join('/');
  }

  static returnDateFormatMask(value: string) {
    return value.split('/').reverse().join('-');
  }

  static moneyMask = (money?: string) => {
    if (money == null) {
      return '';
    }

    money = money.replace(/\D/g, '');
    return `R$ ${money.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
  };

  static moneyMaskPtbr = (money?: number, onlyValue?: boolean) => {
    if (money == null) {
      return '';
    }

    const moneyFormatted = money.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    if (onlyValue) {
      return moneyFormatted.replace('R$', '');
    } else {
      return moneyFormatted;
    }
  };

  static rgMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');
    return documentNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4');
  }

  static birthdayMask(date?: string) {
    if (date == null) {
      return '';
    }

    date = date.replace(/\D/g, '');

    return date.replace(/(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3');
  }

  static cnpjMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');

    return documentNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  static phoneMask(phone?: string) {
    if (phone == null) {
      return '';
    }

    if (phone.length === 11 || phone.length === 15) {
      phone = phone.replace(/\D/g, '');
      return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2$3-$4');
    } else if (phone.length === 12 || phone.length === 16) {
      phone = phone.replace(/\D/g, '');
      return phone.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/g, '+$1 $2 $3 $4');
    } else {
      phone = phone.replace(/\D/g, '');
      return phone;
    }
  }
  static AlternativePhoneMask(phone?: string) {
    if (phone === null) {
      return '';
    }
  }

  static landlineMask(phone?: string) {
    if (phone == null) {
      return '';
    }

    phone = StringUtils.removeNonNumbersFromMaskedValue(phone);
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
    return phone.replace(/(\d)(\d{4})$/, '$1-$2');
  }

  static isSizeInvalid = (size: number, event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    return event?.length !== size;
  };

  static generateFileUrl = (file: string, type: string) => {
    const blobObject = StringUtils.b64toBlob(file, type, null);
    return URL.createObjectURL(blobObject);
  };

  static b64toBlob = (b64Data, contentType, size) => {
    const sliceSize = size ?? 512;

    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType ?? '' });
  };

  static isEmailInvalid = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    const emailRegex = /\S+@\S+\.\S+/;
    return !emailRegex.test(event!);
  };

  static toObject = (key: string, event: any) => {
    const result = {};
    result[key] = event;
    return result;
  };

  static randomColor = () => {
    const colors: string[] = ['#14b071', '#4f35a5', '#1e98d6'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  static randomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  static b64EncodeUnicode = (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(Number(`0x${p1}`));
      })
    );
  };

  static removeAccentToLowerCase = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return '';

    let withoutAccent;

    withoutAccent = event!.replace(new RegExp('[Ç]', 'gi'), 'c');
    withoutAccent = withoutAccent.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    withoutAccent = withoutAccent.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    withoutAccent = withoutAccent.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    withoutAccent = withoutAccent.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    withoutAccent = withoutAccent.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');

    return withoutAccent.toLowerCase();
  };

  static removeNonNumbersFromMaskedValue = (value: string) => {
    return value.replace(/[^\d]/g, '');
  };

  static currencyMask = (event?: string) => {
    let value = String(event);

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return `R$ ${value}`;
  };

  static zipCodeMask = (value?: string) => {
    if (value == null) {
      return '';
    }

    value = StringUtils.removeNonNumbersFromMaskedValue(value);
    return value.replace(/(.{5})(\d)/, '$1-$2');
  };

  static dateMask = (event?: string | Moment | Date) => moment(event).format('YYYY-MM-DD') ?? '';

  static addDashToString = (value?: string) => {
    if (isEmpty(value)) {
      return '';
    }

    return `${value} - `;
  };

  static percentageMask(percentage?: string) {
    if (percentage == null) {
      return '';
    }
    percentage = percentage.replace(/\D/g, '');

    if (percentage === '100') {
      return percentage;
    }

    if (percentage.length <= 4) {
      return (percentage = percentage.replace(/(\d{2})(\d{1})/g, '$1.$2'));
    } else {
      percentage = percentage.replace(/(\d{2})(\d{1})/g, '$1$2.');
      if (percentage === '100.00') {
        return percentage;
      }

      if (parseFloat(percentage) > 100.0) {
        return (percentage = '100.00');
      }
    }
  }

  static getFileOrUrl(attachment?: Attachment) {
    return attachment?.presignedUrl ? attachment.presignedUrl : `data:${attachment?.contentType};base64, ${attachment?.file}`;
  }

  static validateCNPJ = (value?: string) => {
    value = value?.replace(/[^\d]+/g, '');

    if (isEmpty(value)) {
      return false;
    }

    if (
      value === '00000000000000' ||
      value === '11111111111111' ||
      value === '22222222222222' ||
      value === '33333333333333' ||
      value === '44444444444444' ||
      value === '55555555555555' ||
      value === '66666666666666' ||
      value === '77777777777777' ||
      value === '88888888888888' ||
      value === '99999999999999'
    ) {
      return false;
    }

    // Save an array with all the digits of the value
    const match = value!.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) {
      return false;
    }

    // Validator calculation
    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separate the last 2 digits of checkers
    const digits = numbers.slice(12);

    const digit0 = calc(12);

    if (digit0 !== digits[0]) {
      return false;
    }

    const digit1 = calc(13);

    return digit1 === digits[1];
  };

  static validateCPF = (value?: string) => {
    let sum = 0;
    let rest;

    value = value?.replace(/[^\d]+/g, '');

    if (isEmpty(value)) {
      return false;
    }

    if (
      value === '00000000000' ||
      value === '11111111111' ||
      value === '22222222222' ||
      value === '33333333333' ||
      value === '44444444444' ||
      value === '55555555555' ||
      value === '66666666666' ||
      value === '77777777777' ||
      value === '88888888888' ||
      value === '99999999999'
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + Number(value!.substring(i - 1, i)) * (11 - i);
    }

    rest = sum % 11;

    if (rest === 10 || rest === 11 || rest < 2) {
      rest = 0;
    } else {
      rest = 11 - rest;
    }

    if (rest !== Number(value!.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + Number(value!.substring(i - 1, i)) * (12 - i);
    }

    rest = sum % 11;

    if (rest === 10 || rest === 11 || rest < 2) {
      rest = 0;
    } else {
      rest = 11 - rest;
    }

    if (rest !== Number(value!.substring(10, 11))) {
      return false;
    }

    return true;
  };

  static phoneFormatter = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
  };

  static cpfFormatter = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  static base64ToArrayBuffer(_base64Str) {
    const binaryString = window.atob(_base64Str);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  static showDocument(_base64Str, _contentType) {
    const byte = this.base64ToArrayBuffer(_base64Str);
    const blob = new Blob([byte], { type: _contentType });
    window.open(URL.createObjectURL(blob), '_blank');
  }
}

export default StringUtils;

import { AxiosError, AxiosResponse } from 'axios';
import { IFinishRecoveryPasswordData } from 'features/change-password/change-password';
import { IRecoveryEmail } from 'features/forgot-password/forgot-password';
import { User } from 'model/user';
import userApi from '../api/user-api';
import HttpStatus from '../model/enums/http-status';

const AdminService = () => {
  const updateLogin = async (userData: User): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await userApi.updateLogin(userData);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    updateLogin,
  };
};

export default AdminService();

import partnerApi from 'api/partner-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PageableResponse } from 'model/pageable';
import { Partner } from 'model/partner';

interface beAPartner {
  companyName: string;
  email: string;
  phone: string;
  message: string;
}

export const partnerService = () => {
  const beAPartner = async (obj: beAPartner): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await partnerApi.beAPartner(obj);
      if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getPartners = async (): Promise<PageableResponse<Partner>> => {
    try {
      const result: AxiosResponse<PageableResponse<Partner>> = await partnerApi.getPartners();
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    beAPartner,
    getPartners,
  };
};

export default partnerService();

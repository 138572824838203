import React from 'react';
import { Attachment } from '../../model/files';
import { WithTranslation, withTranslation } from 'react-i18next';
import StringUtils from '../../shared/util/string-utils';
import { Container, Document, EmptyDocument, EmptyMessage } from './styles';

interface DocumentViewerProps extends WithTranslation {
  attachment?: Attachment;
}

const DocumentViewer = ({ attachment, t }: DocumentViewerProps) => {
  const attachmentSource = StringUtils.getFileOrUrl(attachment);
  return (
    <>
      {!attachment ? (
        <EmptyDocument>
          <EmptyMessage>
            <i className="icon-document" />
            <div style={{ width: '230px' }}>{t('dashboardClient.contracts.contractInfo.emptyContract')}</div>
          </EmptyMessage>
        </EmptyDocument>
      ) : (
        <Container>
          <Document>
            <embed type={attachment.contentType} src={attachmentSource} width="20000" style={{ minHeight: '700px' }} height="100%" />
          </Document>
        </Container>
      )}
    </>
  );
};

export default withTranslation()(DocumentViewer);

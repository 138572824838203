import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import Pass from '../../../images/pass.svg';
import { ContainerInputsPassword } from './InputsPassword';
import { useRegisterForm } from 'provider/RegisterForm';
import { Client } from 'model/client';
import { useTranslation } from 'react-i18next';

export const FormPassword = () => {
  const { onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const schema = yup.object({
    password: yup
      .string()
      .required(t('global.errorMessage.required'))
      .min(4, t('global.errorMessage.minPassword'))
      .max(100, t('global.errorMessage.maxPassword')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('global.errorMessage.confirmPassword'))
      .required(t('global.errorMessage.required')),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          onClickNext({ user: { password: res.password } } as Client);
        }}
      >
        <ContainerInputsPassword />
      </ReactHookFormProvider>
      <img src={Pass} />
    </FormContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { FormAddress } from 'components/RegistrationForms/Address';
import { FormIncome } from 'components/RegistrationForms/Income';
import { FormPersonalData } from 'components/RegistrationForms/PersonalData';
import { RegisterHeader } from 'components/HeaderRegister';
import { ProgressBar } from 'components/ProgressBar';
import { RegisterForm, StepTitles } from 'components/RegisterForm';
import Footer from 'features/footer/footer';
import { FormDocuments } from 'components/RegistrationForms/FormDocuments';
import Check from '../../../images/modalCheckGreen.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const RegisterGuarantor = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const arrayForms: JSX.Element[] = [<FormPersonalData />, <FormAddress />, <FormIncome />, <FormDocuments isGuarantorRegister />];

  const modalInfos = {
    img: Check,
    title: t('register.modal.sucessTitleGuarantor'),
    buttonTitle: t('register.modal.buttonText'),
    goBack: history.goBack,
  };

  const stepTitles: StepTitles[] = [
    { title: t('register.titles.personalDataGuarantor') },
    { title: t('register.titles.addressGuarantor') },
    { title: t('register.titles.incomeGuarantor'), subtitle: t('register.subtitles.income') },
    { title: t('register.titles.documentsGuarantor') },
  ];

  const stepBarTitles: string[] = [
    t('register.progressBar.personalData'),
    t('register.progressBar.address'),
    t('register.progressBar.income'),
    t('register.progressBar.documents'),
  ];

  return (
    <>
      <RegisterHeader />
      <ProgressBar steps={stepBarTitles} />
      <RegisterForm formsToRender={arrayForms} stepTitles={stepTitles} modalInfos={modalInfos} />
      <Footer />
    </>
  );
};

export default RegisterGuarantor;

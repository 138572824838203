import Status from 'components/status/status';
import { InstallmentsSimulate } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { BreakLine, ContentContainer, ContentLabel, DataContainer } from '../styles';

interface ClientDataProps {
  paymentContent: InstallmentsSimulate[];
  showContent: boolean;
}

const InstallmentsPaymentData: React.FC<ClientDataProps> = props => {
  const { t } = useTranslation();
  const { paymentContent, showContent } = props;

  return (
    <DataContainer>
      {showContent &&
        paymentContent &&
        paymentContent
          .sort((a, b) => a.id - b.id)
          .map((item, key) => {
            return (
              <>
                <ContentContainer key={key}>
                  <ContentLabel>{`${item.installmentNumber}ª parcela`}</ContentLabel>
                  <Status title={item.bankSlip?.status} />
                </ContentContainer>
                {key !== paymentContent.length - 1 && <BreakLine />}
              </>
            );
          })}
    </DataContainer>
  );
};

export default InstallmentsPaymentData;

import clientProfileApi, { PatchResponse } from 'api/client-profile-api';
import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import { SendToSignatory } from 'model/contract';
import { Course } from 'model/courses';
import HttpStatus from 'model/enums/http-status';
import { PartnersId } from 'model/partner';
import { Score } from 'model/score';

export interface AnalyseRegistrationParams {
  id: number;
  consultantComment: string;
  registrationStatus: string;
}
export interface AnalyseFinancingParams {
  id: number;
  course: Course;
}

export const ClientProfileService = () => {
  const getClientSummary = async (id: number, courseId: number | undefined): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientProfileApi.getClientSummary(id, courseId);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getClientSummaryWithoutCourse = async (id: number): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientProfileApi.getClientSummaryWithoutCourse(id);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const analyseRegistration = async (params: AnalyseRegistrationParams): Promise<PatchResponse> => {
    try {
      const result: AxiosResponse<PatchResponse> = await clientProfileApi.analyseRegistration(params);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const analyseFinancing = async (params: AnalyseFinancingParams): Promise<PatchResponse> => {
    try {
      const result: AxiosResponse<PatchResponse> = await clientProfileApi.analyseFinancing(params);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const linkPartner = async (clientId: number, partnersId: PartnersId[]): Promise<PatchResponse> => {
    try {
      const result: AxiosResponse<PatchResponse> = await clientProfileApi.linkPartner(clientId, partnersId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const updateClientData = async (data: Client, id: number): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientProfileApi.updateClientData(data, id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const sendToSignature = async (data: SendToSignatory, idContract: number): Promise<SendToSignatory> => {
    try {
      const result: AxiosResponse<SendToSignatory> = await clientProfileApi.sendToSignature(data, idContract);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const clientRecalculateScore = async (id: number): Promise<Score> => {
    try {
      const result: AxiosResponse<Score> = await clientProfileApi.clientRecalculateScore(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getClientSummary,
    analyseRegistration,
    linkPartner,
    analyseFinancing,
    updateClientData,
    getClientSummaryWithoutCourse,
    sendToSignature,
    clientRecalculateScore,
  };
};

export default ClientProfileService();

import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 64px;

  box-shadow: 0px 10px 10px #f1f1f1;
`;

export const LogoImageContainer = styled.div`
  height: 3.35rem;
  margin: 0.75rem 0px;

  > img {
    height: inherit;
    width: inherit;
  }
`;

export const LogoutButtonContainer = styled.div`
  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #80008c;
      font-size: 1.125rem;
      font-weight: 700;
    }

    img {
      margin-left: 6px;
    }
  }
`;

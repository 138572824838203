import StyledButton from 'components/StyledButton';
import { useRegisterForm } from 'provider/RegisterForm';
import { ContainerTextAndArrow, List } from './styles';
import Arrow from '../../../../images/arrowRightPurple.svg';
import Document from '../../../../images/document.svg';
import ContainerDocument from 'components/ContainerDocument';
import { useTranslation } from 'react-i18next';
import { BackButton, ButtonsContainer } from 'components/RegistrationForms/styles';
import { AttachmentType } from 'model/enums/attachment-types';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsDocuments = () => {
  const { t } = useTranslation();
  const { isFirstForm, isLastForm, onClickBack, files } = useRegisterForm();

  return (
    <>
      <ContainerTextAndArrow>
        <img src={Arrow} />
        <span>{t('documents.identification')}</span>
      </ContainerTextAndArrow>
      <img src={Document} />
      <List>
        <li>{t('documents.list.picture')}</li>
        <li>{t('documents.list.reflection')}</li>
        <li>{t('documents.list.verification')}</li>
      </List>
      <ContainerTextAndArrow>
        <img src={Arrow} />
        <span>{t('documents.ProofOfIncome')}</span>
      </ContainerTextAndArrow>
      <ContainerDocument
        titleDocument="documents.identification"
        editDocument
        nameInput="doc1"
        name="attachmentIdentification"
        type={AttachmentType.IDENTIFICATION_CARD}
        title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
      />
      <ContainerDocument
        titleDocument="documents.ProofOfIncome"
        editDocument
        nameInput="doc2"
        name="attachmentIncome"
        type={AttachmentType.PROOF_INCOME}
        title={files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
      />
      <ContainerDocument
        titleDocument="documents.proofOfAddress"
        editDocument
        nameInput="doc3"
        name="attachmentAddress"
        type={AttachmentType.PROOF_RESIDENCE}
        title={files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
      />
      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? t('global.button.registerGuarantor') : t('global.button.nextStep')} />
      </ButtonsContainer>
    </>
  );
};

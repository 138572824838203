import PartnerContainer from 'components/partner-container/partner-container';
import Status from 'components/status/status';
import StyledButton from 'components/StyledButton';
import { RegistrationStatus } from 'model/enums/registration-status';
import { Internal } from 'model/internal';
import { useTranslation } from 'react-i18next';
import AuthUtils from 'shared/util/auth-utils';
import { ButtonsContainer, CaptionBox, CaptionContainer, CaptionTitle, Container, InputDivisor, Title } from './styles';

interface RegisterStatusProps {
  registrationStatus: RegistrationStatus;
  setShowAnalyseRegistrationModal: (value: boolean) => void;
  setShowLinkPartnerModal: (value: boolean) => void;
  internals?: Internal[];
  isPartner: boolean;
}

const RegisterStatus: React.FC<RegisterStatusProps> = props => {
  // tslint:disable-next-line:no-shadowed-variable
  const { t } = useTranslation();

  const handleAnalyseRegistration = () => {
    props.setShowAnalyseRegistrationModal(true);
  };

  const handleLinkPartner = () => {
    props.setShowLinkPartnerModal(true);
  };

  return (
    <Container>
      <Title>{t('registrationStatus.title')}</Title>
      <CaptionContainer>
        <CaptionTitle>{t('registrationStatus.status')}</CaptionTitle>
        <CaptionBox>
          <Status title={props.registrationStatus} />
        </CaptionBox>
      </CaptionContainer>
      <ButtonsContainer>
        {!props.isPartner && props.registrationStatus !== 'APPROVED' && !AuthUtils.isInternal() && (
          <>
            <StyledButton primary onClick={handleAnalyseRegistration} text={t('registrationStatus.analyseRegistration')} />
          </>
        )}

        {props.internals?.length! > 0 ? (
          <>
            <InputDivisor />
            <PartnerContainer
              setShowLinkPartnerModal={props.setShowLinkPartnerModal}
              partnersList={props.internals!}
              handleLinkPartner={handleLinkPartner}
              isPartner={props.isPartner}
            />
          </>
        ) : (
          !props.isPartner &&
          !AuthUtils.isInternal() && (
            <>
              <InputDivisor />
              <StyledButton secondary onClick={handleLinkPartner} text={t('registrationStatus.linkPartner')} />
            </>
          )
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default RegisterStatus;

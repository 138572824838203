import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import config from '../../images/config.svg';
import logout from '../../images/logout.svg';

type DialogMenuProfileProps = WithTranslation & {
  handleClick?: () => void;
  handleLogout?: () => void;
};

const Container = styled.div`
  position: absolute;
  top: 52px;
  right: -50px;
  transform: translateX(-30%);
  width: 176px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Content = styled.div`
  color: #000;

  div {
    display: flex;
    align-items: center;

    img {
      margin-right: 7px;
    }
  }
`;

const Config = styled.div`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background: #f6f6f6;
  }
`;
const BorderMenu = styled.div`
  width: 88%;
  height: 1px;
  background: #fff;
  margin: 0 12px;
`;

const Logout = styled.div`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background: #f6f6f6;
  }
`;

const DialogMenuProfile: React.FC<DialogMenuProfileProps> = props => {
  return (
    <Container>
      <Content>
        <Config onClick={props.handleClick}>
          <img src={config} alt="configurações" />
          <span>Configurações</span>
        </Config>
        <BorderMenu />
        <Logout onClick={props.handleLogout}>
          <img src={logout} alt="Sair" />
          <span>Sair</span>
        </Logout>
      </Content>
    </Container>
  );
};

export default withTranslation()(DialogMenuProfile);

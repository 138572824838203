import { AxiosResponse } from 'axios';
import { City, State } from 'model/address';
import { Client, Language, Occupation } from 'model/client';
import { Course } from 'model/courses';
import { CustomerRegister } from 'model/customer-register';
import { Attachment, AttachmentResponse } from 'model/files';
import { FirstSignUp } from 'model/first-signup';
import { PageableResponse } from 'model/pageable';
import { api } from './api';

export const register = () => {
  const initialRegistration = (obj: FirstSignUp): Promise<AxiosResponse<FirstSignUp>> => {
    return api.post<FirstSignUp>('/clients', obj);
  };

  const registerFree = (customer: CustomerRegister): Promise<AxiosResponse<CustomerRegister>> => {
    return api.post<CustomerRegister>('/account/register', customer);
  };

  const checkIfEmailAlreadyRegistered = (email: string): Promise<AxiosResponse<boolean>> => {
    return api.get<boolean>(`/account/check-email-availability?email=${email}`);
  };

  const getClientRegistration = (registrationKey: string): Promise<AxiosResponse<FirstSignUp>> => {
    return api.get<FirstSignUp>(`/clients/registration/${registrationKey}`);
  };

  const getStates = (size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<State>>> => {
    return api.get<PageableResponse<State>>(`/states?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const getCities = (stateID: number, size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<City>>> => {
    return api.get<PageableResponse<City>>(`cities/states/${stateID}?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const getLanguages = (size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<Language>>> => {
    return api.get<PageableResponse<Language>>(`/languages?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const getOccupations = (size?: number, page?: number, name?: string): Promise<AxiosResponse<PageableResponse<Occupation>>> => {
    return api.get<PageableResponse<Occupation>>(`/occupations?size=${size ?? ''}&page=${page ?? ''}&name=${name ?? ''}`);
  };

  const registerNewUser = (client: Client): Promise<AxiosResponse<Client>> => {
    return api.post<Client>('/clients/registration', client);
  };

  const sendDocumentsClient = (documents: Attachment[]) => {
    return api.post('/clients/attachments', documents);
  };

  const getDocuments = (): Promise<AxiosResponse<AttachmentResponse>> => {
    return api.get<AttachmentResponse>('/clients/attachments');
  };

  const toFinancingStarted = (): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>('/clients/to-financing-started');
  };

  const getClientPersonalDatas = (): Promise<AxiosResponse<Client>> => {
    return api.get<Client>('/clients');
  };

  const sendNewClientPersonalDatas = (personalDatas: Client): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>('/clients', personalDatas);
  };

  return {
    initialRegistration,
    registerFree,
    checkIfEmailAlreadyRegistered,
    getClientRegistration,
    getStates,
    getCities,
    getLanguages,
    getOccupations,
    registerNewUser,
    sendDocumentsClient,
    getDocuments,
    toFinancingStarted,
    getClientPersonalDatas,
    sendNewClientPersonalDatas,
  };
};

export default register();

import styled from 'styled-components';

export const InputContainers = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 380px;

  display: flex;
  align-items: center;

  div {
    :first-child {
      border: none;
    }
  }
`;

export const InputDivisor = styled.div`
  width: 3px;
  height: 24px;

  background: #ccc;
`;

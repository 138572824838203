import styled from 'styled-components';

export const Container = styled.div`
  width: 150px;
  min-height: 44px;
  padding: 5px 10px;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #000000;
`;
export const ChangeButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.54px;
  color: #80008c;
  cursor: pointer;
`;
export const Name = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
`;

import InputText from 'components/InputText';
import StyledButton from 'components/StyledButton';
import GeoLocation from 'images/geo-location.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { loginRequest } from 'reducer/authentication/actions';
import * as yup from 'yup';
import ReactHookFormProvider, { FieldsError } from '../../components/ReactHookFormProvider';
import { Header } from 'components/Header';
import {
  ButtonContainer,
  Container,
  ContentContainer,
  FormContainer,
  ImgContainer,
  InputContainer,
  ItemLink,
  LoginContainer,
  TextTitle,
} from './StyledComponents';
import { RegistrationStepsStatus } from 'model/enums/registration-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FooterStyled } from 'components/FooterStyled';

interface ILogin {
  username: string;
  password: string;
}

const DOCUMENTS_STATUS = [
  RegistrationStepsStatus.REGISTRATION_APPROVED,
  RegistrationStepsStatus.REGISTRATION_ADJUST,
  RegistrationStepsStatus.REGISTRATION_REJECTED,
];

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const isAuthenticated = useSelector((state: IRootState) => state.authentication.isAuthenticated);
  const loginStatus = useSelector((state: IRootState) => state.authentication.loginStatus);
  const [hasError, setHasError] = useState(false);

  const schema = yup.object({
    username: yup.string().email().required(t('global.errorMessage.required')),
    password: yup.string().required(t('global.errorMessage.required')),
  });

  const fieldsError: FieldsError[] = [
    { name: 'username', errorMessage: t('global.errorMessage.login') },
    { name: 'password', errorMessage: t('global.errorMessage.login') },
  ];

  useEffect(() => {
    if (isAuthenticated && account?.id != null) {
      redirectToPath();
    }

    handleSetError();
  }, [account, loginStatus]);

  const redirectToPath = () => {
    if (account?.client?.creditScore == null) {
      return history.replace('client/request-approval');
    } else {
      if (account.client.registrationStepsStatus === RegistrationStepsStatus.FINANCING_STARTED) {
        return history.replace('client/dashboard/financing');
      }

      if (
        account.client.registrationStepsStatus === RegistrationStepsStatus.DOCUMENT_SENT ||
        account.client.registrationStepsStatus === RegistrationStepsStatus.REGISTRATION_ADJUSTED
      ) {
        return history.replace('client/register-documents/attachments/wait');
      }

      if (DOCUMENTS_STATUS.includes(account.client.registrationStepsStatus!)) {
        return history.replace('client/register/status');
      }

      if (account.client.creditScore.approved) {
        return history.replace('client/register-documents');
      } else {
        return history.replace('client/blocked');
      }
    }
  };

  const handleSetError = () => {
    if (loginStatus === HttpRequestStatus.ERROR) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const onHandleSubmit = (data: ILogin) => {
    handleSetError();
    dispatch(loginRequest(data));
  };

  return (
    <>
      <Header shadow />
      <LoginContainer>
        <ContentContainer>
          <ImgContainer>
            <img src={GeoLocation} />
          </ImgContainer>
          <Container>
            <TextTitle>{t('login.title')}</TextTitle>
            <FormContainer>
              <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} hasError={hasError} fieldsWithError={fieldsError}>
                <InputContainer>
                  <span>{t('login.form.username')}</span>
                  <InputText name="username" label="" placeholder={t('login.form.usernamePlaceholder')} />
                </InputContainer>
                <InputContainer>
                  <span>{t('login.form.password')}</span>
                  <InputText inputType="password" name="password" label="" placeholder={t('login.form.passwordPlaceholder')} />
                </InputContainer>
                <ButtonContainer>
                  <Link to={'/forgot-password'}>
                    <ItemLink>{t('login.form.forgotPassword')}</ItemLink>
                  </Link>
                  <StyledButton primary type="submit" text={t('global.button.login')} />
                  <Link to={'/create-account'}>
                    <ItemLink>{t('login.createAccount')}</ItemLink>
                  </Link>
                </ButtonContainer>
              </ReactHookFormProvider>
            </FormContainer>
          </Container>
        </ContentContainer>
      </LoginContainer>
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </>
  );
};

export default Login;

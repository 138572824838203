import React from 'react';

interface InputErrorMessageProps {
  isFocused: boolean;
  errorMessage: string;
}

const InputErrorMessage: React.FC<InputErrorMessageProps> = props => {
  return (
    <div style={{ color: '#E40B21', textAlign: 'left', fontSize: '12px', minHeight: '1rem', maxWidth: '300px' }}>{props.errorMessage}</div>
  );
};

export default InputErrorMessage;

import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';
import StatusTooltip from '../images/info.svg';
import { color } from '../shared/util/styled-components-utils';
import TooltipModal from './tooltip-modal/tooltip-modal';
interface GridTableProps extends WithTranslation {
  header: {
    name: any;
    propName: string;
  }[];
  data?: any[];
  sortableColumns: string[];
  onChangeFilters?: (x: string[]) => void;
  onRowClick?: (data: any) => void;
  disableRow?: boolean;
  isTooltipModalOpen?: boolean;
  setIsTooltipModalOpen?: (value: boolean) => void;
  columnMaxWidth?: number;
}
interface GridContainerProps {
  gridTemplateColumns: number;
}

interface GridCellProps {
  borderColor?: string;
}

export interface ColumnSort {
  name?: string;
  sortCode?: string;
  type?: 'asc' | 'desc';
  sortDisabled?: boolean;
  sortActivated?: boolean;
}

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.gridTemplateColumns}, auto);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid ${color`lightGray`};
  overflow: auto;
`;
export const GridHeader = styled.div`
  background: white;
  border-left: 1px solid ${color`lightGray`};
  display: flex;
  align-items: center;
  line-height: 30px;
  padding: 0 12px;
  font-size: 0.8125rem;
  color: black;
  font-weight: 500;
  justify-content: space-between;
  white-space: nowrap;
  max-width: 180px;
`;
export const GridCell = styled.div<GridCellProps>`
  background: ${color`white`};
  border-left: 1px solid ${color`lightGray`};
  border-top: 1px solid ${color`lightGray`};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  padding: 0 12px;
  font-size: 0.8125rem;
  color: #505050;
  font-weight: 400;

  &:first-child {
    border-left: none;
  }

  ${props =>
    props.borderColor
      ? css`
          &:first-child {
            border-left: 4px solid ${props.theme.tags[props.borderColor].background};
          }
        `
      : ''}

  &.disabled-row {
    pointer-events: none;
    opacity: 0.5;
    border-left: 1px solid #b6b5b5;
    border-top: 1px solid #b6b5b5;

    &:first-child {
      border-left: none;
    }
  }
`;

export const FilterButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  display: none;
`;

export const GridRow = styled.div`
  display: contents;

  &:first-child ${GridHeader} {
    border-left: none;
  }

  &:hover > ${GridCell} {
    background: ${color`gray8`};
  }
`;

export const Truncate = styled.span<{ maxWidth?: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '140px')};
`;

function getIcon(name, columns: string[]) {
  if (columns.includes(`+${name}`)) return <div className="icon-caret-up" style={{ color: '#0687E1' }} />;
  if (columns.includes(`-${name}`)) return <div className="icon-caret-down" style={{ color: '#0687E1' }} />;
  return <div className="icon-select" />;
}

const GridTable: React.FC<GridTableProps> = props => {
  const [sortColumns, setSortColumns] = useState<string[]>([]);

  useEffect(() => {
    setSortColumns(props.data ?? []);
  }, [props.data]);

  function handleSort(name) {
    if (sortColumns.includes(`+${name}`)) {
      setSortColumns([...sortColumns.filter(e => e !== `+${name}`), `-${name}`]);
    } else if (sortColumns.includes(`-${name}`)) {
      setSortColumns(sortColumns.filter(e => e !== `-${name}`));
    } else {
      setSortColumns([...sortColumns, `+${name}`]);
    }
  }

  useEffect(() => {
    if (typeof props.onChangeFilters === 'function') {
      props.onChangeFilters(sortColumns);
    }
  }, [sortColumns]);

  const handleTooltipClick = () => {
    props.setIsTooltipModalOpen && props.setIsTooltipModalOpen(!props.isTooltipModalOpen);
  };

  return (
    <GridContainer gridTemplateColumns={props.header.length}>
      {props.header.map((header, i, a) => (
        <GridRow key={header.name}>
          <GridHeader>
            {header.name}
            {header.name === 'Status' && props.setIsTooltipModalOpen && <img onClick={handleTooltipClick} src={StatusTooltip} />}
            {props.sortableColumns.includes(header.propName) ? (
              <FilterButton onClick={() => handleSort(header.propName)}>{getIcon(header.propName, sortColumns)}</FilterButton>
            ) : null}
          </GridHeader>
        </GridRow>
      ))}
      {/* ROW */}
      {sortColumns.map((data, rowIndex) => (
        <GridRow key={rowIndex}>
          {props.header.map((header, i, a) => (
            <GridCell
              onClick={() => props.onRowClick && i < props.header.length - 1 && props.onRowClick(data)}
              key={`${rowIndex}-${header.name}`}
              className={`${data['disableRow'] ? 'disabled-row' : ''}`}
            >
              <Truncate maxWidth={props.columnMaxWidth}>{data[header.propName]}</Truncate>
            </GridCell>
          ))}
        </GridRow>
      ))}
    </GridContainer>
  );
};

export default withTranslation()(GridTable);

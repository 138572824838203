import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const ContainerTitles = styled.div`
  width: 975px;
  display: flex;
  flex-direction: column;
  h1 {
    width: 975px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #2b1a48;
    margin: 48px 0 24px 0px;
    text-align: left;
  }
  span {
    width: 621px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.666667px;
    color: #333333;
    text-align: left;
  }
`;

export const FooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 37px;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  background-color: #fff;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
  }
`;

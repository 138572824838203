import styled from 'styled-components';

const PrimaryButton = styled.button<IStyledButton>`
  display: flex;
  flex-direction: center;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '1rem')};
  width: ${({ largeSize }) => (largeSize ? '375' : '177.82')}px;
  height: ${({ largeSize }) => (largeSize ? '58' : '44')}px;
  background: #80008c;
  color: #fff;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  :hover {
    background-color: #b463bc;
  }

  :active {
    background-color: #2b1a48;
  }

  :disabled {
    background-color: #d2c6da;
  }

  span {
    width: 100%;
    font-style: normal;
    font-weight: ${({ largeSize }) => (largeSize ? '600' : '400')};
    font-size: ${({ largeSize }) => (largeSize ? '24' : '16')}px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
`;
const SecondaryButton = styled.button<IStyledButton>`
  display: flex;
  flex-direction: center;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '1rem')};
  width: ${({ largeSize }) => (largeSize ? '375' : '177.82')}px;
  height: ${({ largeSize }) => (largeSize ? '58' : '44')}px;
  background: #fff;
  color: #979797;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  span {
    width: 100%;
    font-style: normal;
    font-weight: ${({ largeSize }) => (largeSize ? '600' : '400')};
    font-size: ${({ largeSize }) => (largeSize ? '24' : '16')}px;
    line-height: 19px;
    text-align: center;
    color: #979797;
  }
`;

const GhostButton = styled.button<IStyledButton>`
  display: flex;
  flex-direction: center;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '1rem')};
  width: ${({ largeSize }) => (largeSize ? '375' : '101')}px;
  height: ${({ largeSize }) => (largeSize ? '58' : '44')}px;
  background: transparent;
  border-radius: 8px;
  border: none;

  :hover {
    span {
      color: #b463bc;
    }
  }

  :active {
    span {
      color: #2b1a48;
    }
  }

  :disabled {
    span {
      color: #d2c6da;
    }
  }

  span {
    width: 100%;
    font-style: normal;
    font-weight: ${({ largeSize }) => (largeSize ? '600' : '400')};
    font-size: ${({ largeSize }) => (largeSize ? '24' : '16')}px;
    line-height: 19px;
    text-align: center;
    color: #80008c;
  }
`;

interface IStyledButton {
  text?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  primary?: boolean;
  secondary?: boolean;
  largeSize?: boolean;
  disabled?: boolean;
  margin?: string;
  ghost?: boolean;
}

const StyledButton = (props: IStyledButton) => {
  const { text, onClick, type, primary, ghost, margin } = props;

  if (primary) {
    return (
      <PrimaryButton type={type} onClick={onClick} largeSize={props.largeSize} disabled={props.disabled} margin={props.margin}>
        <span>{text}</span>
      </PrimaryButton>
    );
  }

  if (ghost) {
    return (
      <GhostButton type={type} onClick={onClick} largeSize={props.largeSize} disabled={props.disabled} margin={props.margin}>
        <span>{text}</span>
      </GhostButton>
    );
  }

  return (
    <SecondaryButton type={type} onClick={onClick} largeSize={props.largeSize} disabled={props.disabled} margin={props.margin}>
      <span>{text}</span>
    </SecondaryButton>
  );
};

export default StyledButton;

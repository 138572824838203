import { t } from 'i18next';
import { title } from 'process';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import { ButtonEdit, ButtonTab, ButtonTitle, Content, ContentItems } from './styles';

interface StyledTabsProps {
  tabs: { title: string | ReactNode; content: JSX.Element }[];
  className?: string;
  style?: any;
  setShowAboutFinancing?: (value: boolean) => void;
  setActiveTabQuery?: (value: number) => void;
  editPage?: boolean;
  editClientRoute?: string;
  editCourseRoute?: string;
  isPartner?: boolean;
  activeTabQuery?: number;
}

const StyledTabs: React.FC<StyledTabsProps> = ({
  className,
  tabs,
  style,
  setShowAboutFinancing,
  editPage = false,
  editClientRoute = '/',
  editCourseRoute = '/',
  isPartner,
  activeTabQuery = 0,
  setActiveTabQuery,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabQuery);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (setShowAboutFinancing) {
      if (activeTab > 1) {
        setShowAboutFinancing(true);
      } else {
        setShowAboutFinancing(false);
      }
    }
    setActiveTabQuery && setActiveTabQuery(activeTab);
  }, [activeTab]);

  const generateEditButton = () => {
    if (!isPartner && !AuthUtils.isInternal()) {
      if (activeTab <= 1) {
        return <ButtonEdit onClick={() => history.push(editClientRoute)}>{t('global.button.editClient')}</ButtonEdit>;
      }
      return <ButtonEdit onClick={() => history.push(editCourseRoute)}>{t('global.button.editCourse')}</ButtonEdit>;
    }
  };

  return (
    <div style={style} className={className}>
      <div className="styled-tabs__tabs-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          {tabs.map((tab, index) => (
            <ButtonTab
              key={index}
              active={index === activeTab}
              className={index === activeTab ? 'styled-tabs__active-tab' : 'styled-tabs__tab'}
              onClick={() => setActiveTab(index)}
            >
              <ButtonTitle active={index === activeTab} className={index === activeTab ? 'styled-tabs__active-tab-title' : ''}>
                {tab.title}
              </ButtonTitle>
            </ButtonTab>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{!editPage && generateEditButton()}</div>
      </div>
      <ContentItems className={`styled-tabs__content ${className}`} borderTopLeftNone={activeTab === 0} key={activeTab}>
        {tabs.map((tab, index) => (
          <Content key={index} disabled={index !== activeTab}>
            {tab.content}
          </Content>
        ))}
      </ContentItems>
    </div>
  );
};

export default StyledTabs;

import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;
  position: relative;
`;

export const InputContainer = styled.div`
  width: 100%;

  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;

  padding: 0px 18px;
`;

export const FormContainer = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 2;
  border-radius: 0 0 4px 4px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export const OpenButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;

  span {
    color: #ccc;
    font-weight: lighter;
    font-size: 0.8125rem;
  }
`;

const lightBlack = '#3E3E3E';
const tagLightGreen = '#90EE90';
const gray = '#979797';
const lightGray = '#D8D8D8';
const lightGray2 = '#DCDCDC';
const red = '#E40B21';
const redHover = '#CA0F22';
const redActive = '#B0091A';
const grayButton = '#F3F3F3';
const grayButtonColor = '#4A4A4A';
const grayButtonActive = '#414141';
const boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
const borderActive = '1px solid transparent';
const buttonBorderRadius = '6px';
const gray10 = '#7b7b7b';

export default {
  color: {
    primary: '#80008C',
    secondary: '#ffffff',
    red,
    redHover,
    redActive,
    grayButton,
    red2: '#FCE7E9',
    red3: '#FFCED1',
    red4: '#FFF1F3',
    lightGray,
    lightGray2,
    green: '#2D9187',
    green2: '#F2F6EC',
    green3: '#46853A',
    title: '#000000',
    form: gray,
    lightBlack,
    black2: '#5C5C5C',
    colorGray: grayButtonColor,
    textGray: '#404040',
    formSecond: '#D8D8D8',
    formThird: '#F6F6F6',
    progress: '#22A3E3',
    yellow: '#FFF9EA',
    yellow2: '#FFE1AA',
    gray: '#787878',
    gray2: '#414141',
    gray3: '#393939',
    gray4: '#979797',
    gray5: '#c4c4c4',
    gray6: '#333333',
    gray7: gray,
    gray8: '#F8F8F8',
    gray9: '#4A4A4A',
    gray10,
    gray11: '#494A4A',
    gray12: '#A4A4A4',
    gray13: '#5c5c5c',
    gray14: '#FAF9F9',
    gray15: '#f4f4f4',
    gray16: '#E7E5E5',
    gray17: '#F9F9F9',
    black1: '#010101',
    pink: '#E40B53',
    orange: '#E42F0B',
  },
  size: {
    small: '1px',
  },
  font: {
    default: 'Helvetica Neue',
    title: 'SourceSans',
  },
  buttons: {
    white: {
      background: 'white',
      color: gray,
      border: `1px solid ${lightGray}`,
      borderRadius: buttonBorderRadius,
      boxShadow,
      lineHeight: `${42 / 16}rem`,
      padding: '0 20px',
      minWidth: '150px',
      fontSize: '1rem',
      // HOVER
      backgroundHover: lightGray,
      colorHover: lightBlack,
      boxShadowHover: boxShadow,
      // ACTIVE
      borderActive,
      boxShadowActive: 'none',
    },
  },
};

import styled from 'styled-components';

interface Props {
  shadow: string;
}

export const HeaderStyled = styled.header<Props>`
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ shadow }) => shadow};
  position: fixed;
  z-index: 1;
  img {
    height: 55px;
  }
`;

export const ContainerInfos = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  button + img {
    display: none;
  }
  @media (max-width: 1091px) {
    button {
      display: none;
    }
    button + img {
      display: inline-block;
      position: relative;
    }
    justify-content: space-between;
  }
`;

export const ContainerLink = styled.nav`
  width: 80%;
  max-width: 711px;
  display: flex;
  justify-content: space-between;
  margin-left: 102px;
  margin-right: 102px;
  a {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #80008c !important;
    cursor: pointer;
  }
  @media (max-width: 1158px) {
    span {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #80008c;
      cursor: pointer;
    }
  }
  @media (max-width: 1091px) {
    display: none;
  }
`;

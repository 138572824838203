import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import teamService from 'services/team-service';
import { resolve } from 'url';
import { Modal } from './styles';

interface IOptionsModalProps {
  id?: number;
  users?: boolean;
  consultant?: boolean;
  active?: boolean;
  handleOnMouseLeave?: () => void;
}

const OptionsModal: React.FC<IOptionsModalProps> = ({ id, users = false, consultant = false, active, handleOnMouseLeave }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/admin/team/${users ? 'users' : 'consultants'}/${id}`);
  };

  const handleDelete = async () => {
    const res = await teamService.deleteInternal(id!);
    if (res) {
      window.location.reload();
    }
  };

  return (
    <Modal onMouseLeave={handleOnMouseLeave}>
      <div className="option" onClick={handleEdit}>
        {t('global.button.edit')}
      </div>
      {active && (
        <div className="option" onClick={handleDelete}>
          {t('global.button.inactivate')}
        </div>
      )}
    </Modal>
  );
};

export default OptionsModal;

import styled from 'styled-components';

export const Modal = styled.div`
  width: 100px;
  position: fixed;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 1;

  .option {
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 10px;

    :hover {
      background: #f7f7f7;
    }
  }
`;

import registerApi from 'api/register-api';
import { City, State } from 'model/address';
import { Language, Occupation } from 'model/client';
import { createContext, ReactNode, useContext, useState } from 'react';

interface IClientProfileIds {
  selectedCourseId: number;
  activeTab: number;
  setSelectedCourseId: (value: number) => void;
  setActiveTab: (value: number) => void;
}
interface ClientProfileIdsProps {
  children: ReactNode;
}

const ClientProfileIdsContext = createContext<IClientProfileIds>({} as IClientProfileIds);

export const ClientProfileIdsProvider = ({ children }: ClientProfileIdsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCourseId, setSelectedCourseId] = useState(0);

  return (
    <ClientProfileIdsContext.Provider
      value={{
        selectedCourseId,
        activeTab,
        setSelectedCourseId,
        setActiveTab,
      }}
    >
      {children}
    </ClientProfileIdsContext.Provider>
  );
};

export const useClientProfileIds = () => useContext(ClientProfileIdsContext);

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';
import {
  CloseButton,
  CloseContainer,
  ConfirmContainer,
  ContainerFormInput,
  ContainerInfos,
  ContainerText,
  ContainerTitleAndInfo,
  ContainerTitles,
  TextInfo,
  TextTitle,
  Title,
} from './styles';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Button';
import StringUtils from 'shared/util/string-utils';
import { useFinancing } from 'provider/Financing';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { FinancingResponseType, SimulateFinancingResponse } from 'model/financing';

interface SimpleModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  confirmSimulation: () => void;
}

interface Ids {
  id: number;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '630px',
    height: '420px',
    border: 'none',
    borderRadius: '16px',
    margin: 'auto auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

const ModalConfirmSimulation = ({ isOpen, onCloseModal, confirmSimulation }: SimpleModalProps) => {
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [load, setLoad] = useState<boolean>(false);
  const [sendOk, setSendOk] = useState<boolean>(false);
  const { mockFinancing, confirmSimulateFinancing, financing } = useFinancing();
  const [financingSimulated, setFinancingSimulated] = useState<SimulateFinancingResponse>({} as SimulateFinancingResponse);
  const choosedValueFormatted = StringUtils.moneyMaskPtbr(financingSimulated.valor_solicitado);
  const entryValueFormatted = StringUtils.moneyMaskPtbr(
    mockFinancing.find(element => element.type === FinancingResponseType.DOWN_PAYMENT)?.valor_total_emprestimo
  );
  const installmentsValueFormatted = StringUtils.moneyMaskPtbr(financingSimulated.valor_primeira_parcela);

  useEffect(() => {
    if (!isOpen) setSendOk(false);
  }, [isOpen]);

  useEffect(() => {
    const mockFinancingSimulated = mockFinancing.find(element => element.type === 'FINANCING');
    if (mockFinancingSimulated) {
      setFinancingSimulated(mockFinancingSimulated);
    }
  }, [mockFinancing]);

  const handleConfirmSimulation = async () => {
    if (account?.emailVerified) {
      setLoad(true);
      await confirmSimulateFinancing(financing.id, mockFinancing);
      confirmSimulation();
      setLoad(false);
      onCloseModal();
    } else {
      confirmSimulation();
    }
  };

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('dashboardClient.financing.modal.confirmSimulation')}</Title>
      <ContainerTitles>
        <ContainerText>
          <TextTitle>{t('dashboardClient.financing.simulator.entryValue')}</TextTitle>
          <TextInfo size="24px">{entryValueFormatted}</TextInfo>
        </ContainerText>
        <ContainerText>
          <TextTitle>{t('dashboardClient.financing.simulator.valueCredit')}</TextTitle>
          <TextInfo size="24px">{choosedValueFormatted}</TextInfo>
        </ContainerText>
      </ContainerTitles>
      <ContainerInfos>
        <ContainerTitleAndInfo>
          <TextTitle size="14px">{t('dashboardClient.financing.simulator.fees')}</TextTitle>
          <TextInfo size="16px" weight={400}>
            {financingSimulated.taxa_mensal_emprestimo}% a.m.
          </TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo>
          <TextTitle size="14px">CET</TextTitle>
          <TextInfo size="16px" weight={400}>
            {financingSimulated.taxa_anual_emprestimo?.toFixed(2)}% a.a.
          </TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ContainerInfos>
        <ContainerTitleAndInfo>
          <TextTitle size="14px">{t('dashboardClient.financing.simulator.installment')}</TextTitle>
          <TextInfo size="16px" weight={400}>
            {financingSimulated.numero_parcelas}x {installmentsValueFormatted}
          </TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo>
          <TextTitle size="14px">{t('dashboardClient.financing.simulator.dueDate')}</TextTitle>
          <TextInfo size="16px" weight={400}>
            {financingSimulated.parcelas && StringUtils.dateFormatMask(financingSimulated.parcelas[0].data_vencimento)}
          </TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ConfirmContainer>
        <ButtonComponent
          width="123px"
          height="47px"
          background="transparent"
          border="none"
          borderRadius="8px"
          color="#80008C"
          text="global.button.cancel"
          weight="600"
          size="18px"
          type="button"
          onClick={onCloseModal}
        />
        <ButtonComponent
          width="123px"
          height="47px"
          background="#80008C"
          border="none"
          borderRadius="8px"
          margin="0 0 0 8px"
          color="white"
          text="global.button.confirm"
          weight="600"
          size="18px"
          load={load}
          onClick={() => handleConfirmSimulation()}
        />
      </ConfirmContainer>
    </Modal>
  );
};

export default ModalConfirmSimulation;

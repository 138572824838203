import styled from 'styled-components';

export const ContainerInfoExplanation = styled.div`
  width: 100%;
  min-height: 590px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerAllInfosToShow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-bottom: 18px;
    img {
      margin-top: 15px;
    }
    span {
      color: #80008c;
    }
  }
  @media (max-width: 1179px) {
    span {
      font-size: 30px;
    }
  }
  @media (max-width: 960px) {
    align-items: center;
    span {
      width: 206px;
      height: 60px;
      margin-top: 48px;
      font-size: 24px;
      line-height: 30px;
      align-self: flex-start;
      img {
        margin-top: -75px;
        margin-left: 205px;
        width: 42px;
      }
    }
  }
`;

export const ContainerExplanation = styled.div`
  width: 1175px;
  height: 310px;
  background: #80008c;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  span {
    margin-top: 0 !important;
    height: 20px;
  }
  @media (max-width: 1179px) {
    width: 960px;
    img {
      width: 150px;
    }
  }
  @media (max-width: 960px) {
    width: 320px;
    height: 625px;
    flex-direction: column;
    img {
      width: 144px;
      height: 167px;
    }
    div + div {
      div {
        left: 123px;
        text-align: right;
        top: 60px;
        span {
          width: 178px;
        }
      }
      img {
        margin-left: -126px !important;
        transform: scaleX(-1);
      }
    }
    div + div + div {
      div {
        left: 25px;
        text-align: left;
        top: 24px;
        span {
          width: 168px;
          margin-top: 0;
        }
      }
      img {
        margin-left: 156px !important;
        transform: scaleX(1);
      }
    }
  }
`;

export const ContainerStepsWithPuppet = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  img {
    margin-left: 120px;
  }
  @media (max-width: 960px) {
    justify-content: center;
  }
`;

export const ContainerTextStap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 115px;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 160.02%;
    color: #fff;
    margin-bottom: 5px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    width: 204px;
  }
  @media (max-width: 960px) {
    top: 22px;
    left: 20px;
  }
`;

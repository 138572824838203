import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, ItemLink, LinkContainer, TabContainer, TextTitle, TitleContainer } from './StyledComponents';

const DashboardTables = ({ tables }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {tables.map((item, i) => {
        return (
          <TabContainer key={i}>
            <TitleContainer>
              <TextTitle>{item.title}</TextTitle>
            </TitleContainer>
            {item.content}
            <LinkContainer>
              <Link to={item.seeAll}>
                <ItemLink>{t('global.button.seeAll')}</ItemLink>
              </Link>
            </LinkContainer>
          </TabContainer>
        );
      })}
    </Container>
  );
};

export default DashboardTables;

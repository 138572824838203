import { HeaderWithLogout } from 'components/HeaderWithLogout';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import Footer from 'features/footer/footer';
import { Container, ImageContainer, Message, TextsContainer, Title } from './styles';
import * as yup from 'yup';
import ContainerDocument from 'components/ContainerDocument';
import { AttachmentType } from 'model/enums/attachment-types';
import WomanWithDocument from '../../images/woman-with-document.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegisterService from '../../services/register-service';
import { Attachment } from '../../model/files';
import { getSessionRequest } from 'reducer/authentication/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useHistory } from 'react-router-dom';

interface Files {
  identification?: Attachment;
  income?: Attachment;
  addres?: Attachment;
}

export const RegisterDocumentsWait = () => {
  const { t } = useTranslation();
  const accountStatus = useSelector((state: IRootState) => state.authentication.account?.client?.registrationStatus);
  const [files, setFiles] = useState<Files>({});
  const schema = yup.object({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const res = RegisterService.getDocuments();
    if (accountStatus === RegistrationStatus.APPROVED) {
      history.replace(`/client/register/status`);
    }
    res.then(data => {
      const files: Files = {
        identification: data.attachments?.find(att => att.type === AttachmentType.IDENTIFICATION_CARD),
        income: data.attachments?.find(att => att.type === AttachmentType.PROOF_INCOME),
        addres: data.attachments?.find(att => att.type === AttachmentType.PROOF_RESIDENCE),
      };

      setFiles(files);
    });
  }, []);

  const onHandleSubmit = () => {
    return;
  };

  return (
    <>
      <HeaderWithLogout />
      <Container>
        <TextsContainer>
          <Title>{t('regiterDocumentWait.title')}</Title>
          <Message>{t('regiterDocumentWait.message')}</Message>
          <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
            <ContainerDocument
              titleDocument="documents.identification"
              title={files.identification?.fileName}
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              docUrl={files.identification?.url}
            />
            <ContainerDocument
              titleDocument="documents.ProofOfIncome"
              title={files.income?.fileName}
              nameInput="doc2"
              name="attachmentIncome"
              type={AttachmentType.PROOF_INCOME}
              docUrl={files.income?.url}
            />
            <ContainerDocument
              titleDocument="documents.proofOfAddress"
              title={files.addres?.fileName}
              nameInput="doc3"
              name="attachmentAddress"
              type={AttachmentType.PROOF_RESIDENCE}
              docUrl={files.addres?.url}
            />
          </ReactHookFormProvider>
        </TextsContainer>
        <ImageContainer>
          <img src={WomanWithDocument} />
        </ImageContainer>
      </Container>
      <Footer />
    </>
  );
};

import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import HeaderLoginAdmin from 'components/header-login-admin/header-login-admin';
import StyledButton from 'components/StyledButton';
import ChangedPasswordIcon from 'images/changed-password.svg';
import LogoFdi from 'images/logo-fdi-escrita.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonContainer, Container, FormContainer, ImgContainer, LoginContainer, SubTextTitle, TextTitle } from './StyledComponents';

const ChangedPasswordAdmin = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderLoginAdmin />
      <LoginContainer>
        <Container>
          <TextTitle>{t('changePassword.sucessMessage.title')}</TextTitle>
          <ImgContainer>
            <img src={ChangedPasswordIcon} />
          </ImgContainer>
          <SubTextTitle>{t('changePassword.sucessMessage.subtitle')}</SubTextTitle>
          <FormContainer>
            <ButtonContainer>
              <StyledButton primary onClick={() => history.push('/login')} text={t('global.button.goLogin')} />
            </ButtonContainer>
          </FormContainer>
        </Container>
      </LoginContainer>
      <FooterLoginAdmin />
    </>
  );
};

export default ChangedPasswordAdmin;

import courseApi from 'api/course-api';
import { AxiosResponse } from 'axios';
import { ClientId } from 'model/client';
import { Contract } from 'model/contract';
import { Course } from 'model/courses';
import HttpStatus from 'model/enums/http-status';
import { CourseFinancing, FinancingResume } from 'model/financing';
import { Linkedguarantors } from 'model/guarantor';
import { PageableResponse } from 'model/pageable';

export const CourseService = () => {
  const getCourses = async (): Promise<PageableResponse<Course>> => {
    try {
      const result: AxiosResponse<PageableResponse<Course>> = await courseApi.courses();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getCoursesByClientId = async (id?: number): Promise<Course[]> => {
    try {
      const result: AxiosResponse<Course[]> = await courseApi.coursesByClient(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getFinancingsById = async (id?: number): Promise<CourseFinancing> => {
    try {
      const result: AxiosResponse<CourseFinancing> = await courseApi.financingsById(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getFinancingsByClientId = async (id?: number): Promise<CourseFinancing[]> => {
    try {
      const result: AxiosResponse<CourseFinancing[]> = await courseApi.financingsByClientId(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getCourse = async (courseId: number): Promise<Course> => {
    try {
      const result: AxiosResponse<Course> = await courseApi.courseSummary(courseId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const unlinkGuarantor = async (courseId: number, id: number): Promise<ClientId> => {
    try {
      const result: AxiosResponse<ClientId> = await courseApi.unlinkGuarantor(courseId, id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getLinkedGuarantor = async (courseId: number): Promise<Linkedguarantors> => {
    try {
      const result: AxiosResponse<Linkedguarantors> = await courseApi.getLinkedGuarantor(courseId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const linkedGuarantor = async (courseId: number, arrayIds: ClientId[]): Promise<ClientId> => {
    try {
      const result: AxiosResponse<ClientId> = await courseApi.LinkedGuarantor(courseId, arrayIds);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const newCourse = async (newFinancing: Course): Promise<Course> => {
    try {
      const result: AxiosResponse<Course> = await courseApi.newCourse(newFinancing);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const updateCourseData = async (data: Course): Promise<Course> => {
    try {
      const result: AxiosResponse<Course> = await courseApi.updateCourseData(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getContracts = async (clientId: number): Promise<Contract> => {
    try {
      const result: AxiosResponse<Contract> = await courseApi.courseContracts(clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getClientBankSlip = async (financingId: number, installmentId: number) => {
    try {
      const result: AxiosResponse = await courseApi.getClientBankSlip(financingId, installmentId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getClientDownPaymentBankSlip = async (financingId: number) => {
    try {
      const result: AxiosResponse = await courseApi.getClientDownPaymentBankSlip(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    getCourses,
    getCourse,
    unlinkGuarantor,
    getLinkedGuarantor,
    linkedGuarantor,
    newCourse,
    getCoursesByClientId,
    updateCourseData,
    getFinancingsById,
    getContracts,
    getFinancingsByClientId,
    getClientBankSlip,
    getClientDownPaymentBankSlip,
  };
};

export default CourseService();

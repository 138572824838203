import Logo from '../../images/logoEscrita.svg';
import { BackButton, Container, GhostSeparator, LogoImage, LogoImageContainer } from './styles';
import { FaChevronLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const RegisterHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Container>
      <BackButton onClick={() => history.goBack()}>
        <FaChevronLeft color="#80008C" size={'0.8rem'} />
        <span>{t('global.button.backStep')}</span>
      </BackButton>
      <LogoImageContainer>
        <LogoImage src={Logo} />
      </LogoImageContainer>
      <GhostSeparator />
    </Container>
  );
};

import StyledTabs from 'components/styled-tabs/styled-tabs';
import StyledButton from 'components/StyledButton';
import { User } from 'model/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { LabelAnswer, LabelContainer, LabelLineSecond, LabelTitle, modalStyles, ModalTitleSecond } from './styles';

interface StyledModalMyProfileProps {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleLogout: () => void;
  handleOpenModalEditLogin: () => void;
  userData: User;
}

const StyledModalAdminProfile: React.FC<StyledModalMyProfileProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={props.isOpenModal} style={modalStyles}>
      <div style={{ margin: '40px 100px' }}>
        <ModalTitleSecond>{t('profile.title')}</ModalTitleSecond>
        <StyledTabs
          editPage
          style={{ width: '576px', height: '346px' }}
          tabs={[
            {
              title: t('global.tabs.login'),
              content: (
                <div style={{ padding: '32px 28px 14px 28px' }}>
                  <LabelContainer>
                    <LabelLineSecond>
                      <LabelTitle className="label-title-my-profile">{t('profile.name')}</LabelTitle>
                      <LabelAnswer>{props.userData?.firstName ?? ''}</LabelAnswer>
                    </LabelLineSecond>
                    <LabelLineSecond>
                      <LabelTitle className="label-title-my-profile">{t('profile.email')}</LabelTitle>
                      <LabelAnswer>{props.userData?.email ?? ''}</LabelAnswer>
                    </LabelLineSecond>
                    <LabelLineSecond>
                      <LabelTitle className="label-title-my-profile">{t('profile.password')}</LabelTitle>
                      <LabelAnswer>********</LabelAnswer>
                    </LabelLineSecond>
                  </LabelContainer>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '43px' }}>
                    <StyledButton secondary onClick={props.handleOpenModal} text={t('global.button.cancel2')} />
                    <div style={{ width: '25px' }} />
                    <StyledButton
                      primary
                      onClick={() => {
                        props.handleOpenModalEditLogin();
                        props.handleOpenModal();
                      }}
                      text={t('global.button.editData')}
                    />
                  </div>
                </div>
              ),
            },
            {
              title: t('global.tabs.logout'),
              content: (
                <div>
                  <div style={{ padding: '36px 0' }}>
                    <div style={{ fontSize: '18px', textAlign: 'center' }}>{t('profile.logout')}</div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '38px' }}>
                      <StyledButton secondary onClick={props.handleOpenModal} text={t('global.button.cancel2')} />
                      <div style={{ width: '25px' }} />
                      <StyledButton primary onClick={props.handleLogout} text={t('global.button.leave')} />
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default StyledModalAdminProfile;

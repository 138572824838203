import { Client } from 'model/client';
import { Course } from 'model/courses';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { BreakLine, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';

interface ClientDataProps {
  clientContent: Client;
  selectedCourse: Course;
}

const ClientCreditData: React.FC<ClientDataProps> = props => {
  const { t } = useTranslation();
  const { clientContent, selectedCourse } = props;

  console.log(clientContent, selectedCourse);

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.creditData.clientScore')}</ContentLabel>
        <ContentValue>{clientContent.creditScore?.score ?? 'Não informado'}</ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.creditData.approvedCredit')}</ContentLabel>
        <ContentValue>
          {selectedCourse?.financing?.approvedValue
            ? StringUtils.currencyMask(selectedCourse?.financing?.approvedValue?.toFixed(2))
            : 'Não informado'}
        </ContentValue>
      </ContentContainer>
      <ContentContainer>
        <ContentLabel>{t('clientFinancing.creditData.downPaymentValue')}</ContentLabel>
        <ContentValue>
          {selectedCourse?.financing?.approvedValue
            ? StringUtils.currencyMask(selectedCourse?.financing?.downPaymentValue?.toFixed(2))
            : 'Não informado'}
        </ContentValue>
      </ContentContainer>
    </DataContainer>
  );
};

export default ClientCreditData;

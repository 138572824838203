import { BackButton, ButtonsContainer, FormInputsContainer } from 'components/RegistrationForms/styles';
import { SearchSelectInput } from 'components/SearchSelectInput';
import StyledButton from 'components/StyledButton';
import { TextInput } from 'components/TextInput';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/RegisterForm';
import { useSelectLists } from 'provider/SelectLists';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsAddress = () => {
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [resetCities, setResetCities] = useState(false);
  const { t } = useTranslation();
  const { isFirstForm, isLastForm, onClickBack, initialClientData } = useRegisterForm();
  const { states, cities, stateID, handleSetStateID, getStates, getCities, statesTotalPages, citiesTotalPages } = useSelectLists();

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7 }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage]);

  useEffect(() => {
    setResetCities(true);
  }, [stateID]);

  return (
    <FormInputsContainer>
      <TextInput
        name="zipcode"
        label={t('register.inputs.zipcode')}
        isRequired
        placeholder={t('register.placeholder.zipcode')}
        maxLength={9}
        mask={Mask.CEP}
        defaultValue={initialClientData?.address?.zipcode}
      />

      <TextInput
        name="street"
        label={t('register.inputs.address')}
        isRequired
        placeholder={t('register.placeholder.address')}
        defaultValue={initialClientData?.address?.street}
      />

      <TextInput
        name="number"
        label={t('register.inputs.number')}
        isRequired
        placeholder={t('register.placeholder.number')}
        mask={Mask.NUMBERS}
        defaultValue={initialClientData?.address?.number}
      />

      <TextInput
        name="complement"
        label={t('register.inputs.complement')}
        isRequired
        placeholder={t('register.placeholder.complement')}
        defaultValue={initialClientData?.address?.complement}
      />

      <TextInput
        name="district"
        label={t('register.inputs.district')}
        placeholder={t('register.placeholder.district')}
        isRequired
        defaultValue={initialClientData?.address?.district}
      />

      <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
        <SearchSelectInput
          name="state"
          placeholder={t('register.placeholder.state')}
          options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
          defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
          defaultValue={initialClientData?.address?.state?.name}
          handleChange={values => {
            const state = states.find(st => st.name === values.name);
            if (state != null) {
              handleSetStateID(state.id);
            }
            setStateName(values.name);
            setStatePage(0);
            getStates({ name: values.name, size: 7 });
          }}
          handleOnScroll={() => {
            if (statePage < statesTotalPages - 1) {
              setStatePage(statePage + 1);
            }
          }}
        />
      </TextInput>

      <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
        <SearchSelectInput
          name="city"
          placeholder={t('register.placeholder.city')}
          defaultValue={initialClientData?.address?.city?.name}
          options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
          defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
          handleChange={values => {
            setResetCities(false);
            setCityName(values.name);
            setCityPage(0);
            if (stateID) {
              getCities(stateID, { name: values.name, size: 7 });
            }
          }}
          handleOnScroll={() => {
            if (cityPage < citiesTotalPages - 1) {
              setCityPage(cityPage + 1);
            }
          }}
          resetValue={resetCities}
        />
      </TextInput>

      <ButtonsContainer>
        {!isFirstForm() && (
          <BackButton onClick={onClickBack} type="button">
            <span>Voltar</span>
          </BackButton>
        )}
        <StyledButton primary type="submit" text={isLastForm() ? 'Cadastrar' : 'Avançar'} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};

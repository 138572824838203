export enum OperatingSystem {
  WINDOWS_PHONE = 'Windows Phone',
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'Unknown',
}
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = (): OperatingSystem => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OperatingSystem.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return OperatingSystem.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OperatingSystem.IOS;
  }

  return OperatingSystem.UNKNOWN;
};

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email != null && email.length > 0 && re.test(String(email).toLowerCase());
};

export const validateCreditCard = (cardNumber: string): boolean => {
  // const cards = [
  //     /^4[0-9]{12}(?:[0-9]{3})/, // visa
  //     /^5[1-5][0-9]{14}/, // Mastercard
  //     /^3[47][0-9]{13}/, // Amex
  //     /^3(?:0[0-5]|[68][0-9])[0-9]{11}/, // DinersClub
  //     /^6(?:011|5[0-9]{2})[0-9]{12}/, // Discover
  //     /^(?:2131|1800|35\d{3})\d{11}/ // JCB
  // ];
  const cards = [
    /^4[0-9]{12}(?:[0-9]{3})/,
    /^5[1-5][0-9]{14}/,
    /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    /^3[47][0-9]{13}/,
    /^6(?:011|5[0-9]{2})[0-9]{12}/,
    /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    /^(?:2131|1800|35\d{3})\d{11}/,
    /^(5078\d{2})(\d{2})(\d{11})$/,
  ];

  console.log('card filter', { card: cards.filter(it => cardNumber.match(it))[0], teste: cardNumber.match(cards[0]) });
  if (cards.filter(it => (cardNumber.match(it) ? cardNumber.match(it)![0].length === cardNumber.length : false)).length > 0) {
    return true;
  }
  return false;
};

export function validateCpf(value: string) {
  if (value == null || value === '') {
    return false;
  }
  const cpf = value.replace(/[^\d]+/g, '');
  let add = 0;
  let rev = 0;
  if (cpf === '') {
    return false;
  }
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
}

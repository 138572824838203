import React, { useState } from 'react';
import PuppetFaq from '../../images/puppetFaq.svg';
import ArrowDown from '../../images/arrowDown.svg';
import { ContainerContentFaq, ContainerFaq, ContainerQuestion, ContainerText, ResponseQuestion } from './styles';
import { useTranslation } from 'react-i18next';
import arrayQuestions from 'shared/util/arrayQuestionsFaq';

export const Faq = () => {
  const { t } = useTranslation();
  const [numberOfQuestion, setNumberOfQuestion] = useState<number>();

  const selectedQuestionFaq = (number: number) => {
    if (number !== numberOfQuestion) {
      setNumberOfQuestion(number);
    } else {
      setNumberOfQuestion(undefined);
    }
  };

  return (
    <ContainerFaq id="doubt">
      <ContainerContentFaq>
        <ContainerText>
          <span>
            {t('LandingPage.textFaq.firstText')} <span>{t('LandingPage.textFaq.secondText')}</span>{' '}
          </span>
          {arrayQuestions.map((element, index) => {
            return (
              <>
                <ContainerQuestion onClick={() => selectedQuestionFaq(index)}>
                  <span>{t(element.question)}</span>
                  <img src={ArrowDown} style={{ position: 'static' }} />
                </ContainerQuestion>
                {numberOfQuestion === index && <ResponseQuestion>{t(arrayQuestions[numberOfQuestion].Response)}</ResponseQuestion>}
              </>
            );
          })}
        </ContainerText>
        <img src={PuppetFaq} style={{ width: '337px', height: '400px', marginTop: '150px' }} />
      </ContainerContentFaq>
    </ContainerFaq>
  );
};

import styled from 'styled-components';

export const ContainerCard = styled.div`
  width: 316px;
  height: 174px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  cursor: pointer;
  left: 35px;
  z-index: 1;
  margin-bottom: 20px;
`;

export const ContainerInfoCourse = styled.div`
  width: 100%;
  height: 134px;
  background: #eeeeee;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ContainerStatus = styled.div`
  width: 100%;
  height: 40px;
  background: #2b1a48;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: white;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #7f7f7f;
    margin-bottom: 8px;
  }
  span + span {
    font-weight: 600;
    font-size: 16px;
    color: #2b1a48;
  }
`;

export const ContainerInfos = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

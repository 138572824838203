import React from 'react';
import { CircleContainer, PercentageContainer, StyledCircle, Svg } from './styles';

interface IProgressBarProps {
  progress: number;
  text: string;
  score?: boolean;
}
const ProgressBar: React.FC<IProgressBarProps> = props => {
  const width = 250;

  const PI = 3.14;
  const R = width / 2 - (width / 10) * 2;
  const circumference = 2 * PI * R;
  const offset = circumference - (props.progress / 100) * circumference;
  const offsetScore = circumference - (props.progress / 1000) * circumference;

  return (
    <CircleContainer>
      <PercentageContainer>
        {props.score ? <span className="percentage">{props.progress}</span> : <span className="percentage">{props.progress}%</span>}
        <span className="text">{props.text}</span>
      </PercentageContainer>
      <Svg>
        <circle
          strokeWidth={15}
          fill="transparent"
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={'#E5E7E7'}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <StyledCircle
          strokeWidth={15}
          fill={'transparent'}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={`#80008C`}
          strokeLinecap={'round'}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={props.score ? offsetScore : offset}
        />
      </Svg>
    </CircleContainer>
  );
};

export default ProgressBar;

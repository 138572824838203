import DashboardContract from 'features/admin/dashboard/contract/contract';
import Dashboard from 'features/admin/dashboard/dashboard';
import { AdminEditClientFinancing } from 'features/admin/dashboard/financing/edit/edit-financing';
import DashboardFinancing from 'features/admin/dashboard/financing/financing';
import DashboardPayment from 'features/admin/dashboard/payment/payment';
import ClientProfile from 'features/admin/dashboard/register/client/client-profile';
import { AdminCreateClientScore } from 'features/admin/dashboard/register/client/client-score/client-score';
import { AdminCreateClientFirstSignUp } from 'features/admin/dashboard/register/client/create/create-client';
import { AdminEditClient } from 'features/admin/dashboard/register/client/edit/edit-client';
import DashboardRegister from 'features/admin/dashboard/register/register';
import ViewContract from 'features/admin/dashboard/viewContract';
import LoginAdmin from 'features/admin/login/login';
import TeamConsultants from 'features/admin/my-team/consultants/consultants';
import { AdminCreateConsultant } from 'features/admin/my-team/consultants/create/create-consultant';
import { AdminEditConsultant } from 'features/admin/my-team/consultants/edit/edit-consultant';
import { AdminCreateUser } from 'features/admin/my-team/users/create/create-user';
import { AdminEditUser } from 'features/admin/my-team/users/edit/edit-user';
import TeamUsers from 'features/admin/my-team/users/users';
import { DashboardClient } from 'features/client/dashboard';
import RegisterGuarantor from 'features/client/registerGuarantor';
import ForgotPasswordAdmin from 'features/forgot-password/forgot-password';
import { EditFormProvider } from 'provider/admin-edit-client-form';
import { SelectListsProvider } from 'provider/SelectLists';
import { Redirect, Route, Switch } from 'react-router-dom';
import ClientPrivateRoutes, { StepLevelStatus } from '../client-private-routes';
import PrivateRoute, { AccessLevel } from '../private-routes';

const adminAccessLevels: AccessLevel[] = [AccessLevel.ADMIN, AccessLevel.INTERNAL, AccessLevel.INTERNAL_ADMIN, AccessLevel.PARTNER];

const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/login`} component={LoginAdmin} />
      <Route exact path={`${match.url}/dashboard`}>
        <Redirect to={`${match.url}/dashboard/general`} />
      </Route>
      <Route exact path={`${match.url}/team`}>
        <Redirect to={`${match.url}/team/users`} />
      </Route>
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/general`} component={Dashboard} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/register`} component={DashboardRegister} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/register/:id`} component={ClientProfile} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/viewContract`} component={ViewContract} />
      <PrivateRoute
        accessLevel={adminAccessLevels}
        exact
        path={`${match.url}/dashboard/register/:id/course/:courseId`}
        component={ClientProfile}
      />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/financing`} component={DashboardFinancing} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/contract`} component={DashboardContract} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/dashboard/payment`} component={DashboardPayment} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/team/users`} component={TeamUsers} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/team/users/:id`} component={AdminEditUser} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/team/consultants/:id`} component={AdminEditConsultant} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/new/user`} component={AdminCreateUser} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/team/consultants`} component={TeamConsultants} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/new/consultant`} component={AdminCreateConsultant} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/new/client`} component={AdminCreateClientFirstSignUp} />
      <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/new/client/:id/created`} component={AdminCreateClientScore} />
      <EditFormProvider>
        <SelectListsProvider>
          <PrivateRoute accessLevel={adminAccessLevels} exact path={`${match.url}/new/client/:id`} component={AdminEditClient} />
          <PrivateRoute
            accessLevel={adminAccessLevels}
            exact
            path={`${match.url}/dashboard/register/edit/:id`}
            component={AdminEditClient}
          />
          <PrivateRoute
            accessLevel={adminAccessLevels}
            exact
            path={`${match.url}/dashboard/financing/edit/:id/course/:courseId`}
            component={AdminEditClientFinancing}
          />
        </SelectListsProvider>
      </EditFormProvider>
    </Switch>
  );
};

export default AdminRoutes;

import AnalyseRegisterInputsContainer from 'components/analyse-registration-inputs-container/analyse-registration-inputs-container';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { ContainerInputsCourse } from 'components/RegistrationForms/Course/InputsCourse';
import StyledButton from 'components/StyledButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ClientProfileService from 'services/client-profile-service';
import * as yup from 'yup';
import { BreakLine, ButtonsContainer, CloseButton, CloseContainer, ContainerFormInput, Title } from './styles';

interface ModalProps {
  onCloseModal: () => void;
  clientId: number;
}

const modalStyles: ReactModal.Styles = {
  content: {
    width: '776px',
    height: '500px',
    border: 'none',
    borderRadius: '4px',
    margin: 'auto',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: '#0000001f',
  },
};

interface Schema {
  registrationStatus: 'ADJUST' | 'REJECTED' | 'APPROVED';
  consultantComment: string;
}

const AnalyseRegistrationModal: React.FC<ModalProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    registrationStatus: yup.string().required(t('global.errorMessage.required')),
    consultantComment: yup.string(),
  });

  const handleSubmit = async (data: Schema) => {
    const newParams = { id: props.clientId, ...data };
    const res = await ClientProfileService.analyseRegistration(newParams);
    if (res) {
      props.onCloseModal();
      history.go(0);
    }
  };

  return (
    <Modal isOpen style={modalStyles}>
      <CloseContainer>
        <CloseButton onClick={props.onCloseModal}>
          <CgClose size={24} />
        </CloseButton>
      </CloseContainer>
      <Title>{t('global.modal.analyseRegistration')}</Title>
      <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
        <ContainerFormInput>
          <AnalyseRegisterInputsContainer />
          <ButtonsContainer>
            <BreakLine />
            <div>
              <StyledButton secondary onClick={props.onCloseModal} text={t('global.button.cancel')} />
              <StyledButton primary type="submit" text={t('global.button.confirm')} />
            </div>
          </ButtonsContainer>
        </ContainerFormInput>
      </ReactHookFormProvider>
    </Modal>
  );
};

export default AnalyseRegistrationModal;

import DialogMenuProfile from 'components/dialog-menu-profile/dialog-menu-profile';
import StyledModalAdminProfile from 'components/styled-modal-admin-profile/styled-modal-admin-profile';
import StyledModalEditAdminProfile from 'components/styled-modal-edit-admin-profile.tsx/styled-modal-edit-admin-profile.tsx';
import { APP_LOCAL_STORAGE_MENU_COLLAPSED } from 'config/constants';
import React, { useEffect, useState } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import AuthUtils from 'shared/util/auth-utils';
import { createGlobalStyle } from 'styled-components';
import drop from '../../images/tiny-expand.svg';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';
import SearchHeader from '../search-header/search-header';
import StyleCircle from '../StyledCircleRed';
import { ContainerItems, TitleHeader, UserHeader, UserName } from './styles';

interface StyledAdminHeaderProps extends WithTranslation, StateProps, DispatchProps {
  text?: string;
}

interface AccountData {
  id: number;
  firstName: string;
  email: string;
}

const StyledAdminHeader: React.FC<StyledAdminHeaderProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenModalEditLogin, setIsOpenModalEditLogin] = useState(false);
  const [userData, setUserData] = useState<AccountData>({
    id: props.authentication?.account?.id!,
    firstName: props.authentication.account?.firstName!,
    email: props.authentication.account?.email!,
  });

  const handleLogout = () => {
    props.logout();
    history.replace('/admin/login');
  };

  return (
    <ContainerItems>
      <TitleHeader>{children}</TitleHeader>
      <UserHeader>
        {/* <SearchHeader /> */}

        <UserName onClick={() => setOpenDialog(!openDialog)}>
          <i className="icon-perfil" />
          <span>{t('header.hello')}&ensp;</span>
          <div style={{ color: '#80008C', cursor: 'pointer' }} className="name">
            {`${userData.firstName}!`}
          </div>
          <div className="imgBox">
            <img src={drop} alt="" />
          </div>
          {openDialog && <DialogMenuProfile handleClick={() => setOpenModal(!openModal)} handleLogout={handleLogout} />}
        </UserName>
      </UserHeader>
      <StyledModalAdminProfile
        isOpenModal={openModal}
        handleOpenModal={() => {
          setOpenModal(!openModal);
        }}
        handleLogout={handleLogout}
        handleOpenModalEditLogin={() => setIsOpenModalEditLogin(!isOpenModalEditLogin)}
        userData={userData}
      />
      <StyledModalEditAdminProfile
        isOpenModal={isOpenModalEditLogin}
        handleOpenModal={() => setIsOpenModalEditLogin(!isOpenModalEditLogin)}
        handleLogout={handleLogout}
        userData={userData}
        setUserData={setUserData}
      />
    </ContainerItems>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  authentication,
});

const mapDispatchToProps = {
  logout: logoutRequest,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(StyledAdminHeader) as React.ComponentType<any>;

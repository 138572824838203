import ButtonComponent from 'components/Button';
import { FormUserData } from 'components/form-user-data';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StyledEditHeader from 'components/styled-edit-header/styled-edit-header';
import { User } from 'model/user';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import teamService from 'services/team-service';
import { internalStatus, userAuthorities } from 'shared/util/team-utils';
import * as yup from 'yup';
import { Container, ContainerButtons, Footer } from './styles';

export const AdminCreateUser = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')).email(t('global.errorMessage.email')),
    phone: yup.string().required(t('global.errorMessage.required')),
    cpf: yup.string().required(t('global.errorMessage.required')),
  });

  const onHandleSubmit = async data => {
    const userData: User = {
      name: data.name,
      email: data.email,
      phone: data.phone.replace(/\W/g, ''),
      cpf: data.cpf.replace(/\W/g, ''),
      internalStatus: internalStatus().find(status => status.label === data.internalStatus)?.value,
      authority: userAuthorities().find(authority => authority.label === data.authority)?.value,
    };

    const res = await await teamService.createUser(userData);

    if (res) {
      history.replace(`/admin/team/users`);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <StyledEditHeader>{t('team.createUser.header')}</StyledEditHeader>
        <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
          <Container>
            <div className="container-data">
              <span className="title">{t('team.createUser.title')}</span>
              <span className="sub-title">{t('team.createUser.subTitle')}</span>
              <FormUserData />
            </div>
          </Container>
          <Footer>
            <ContainerButtons>
              <ButtonComponent
                width="117px"
                height="44px"
                background="transparent"
                border="none"
                borderRadius="none"
                color="#fff"
                text="global.button.cancel"
                weight="400"
                size="15px"
                onClick={() => history.goBack()}
              />
              <ButtonComponent
                width="150px"
                height="44px"
                background="#80008C"
                border="none"
                borderRadius="6px"
                color="#fff"
                text="global.button.save"
                weight="400"
                size="15px"
                type="submit"
              />
            </ContainerButtons>
          </Footer>
        </ReactHookFormProvider>
      </div>
    </div>
  );
};

import backgroundImage from 'images/background.png';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  border-top: 1px solid #d8d8d8;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #787878;
  }
`;

import teamApi from 'api/team-api';
import { AxiosResponse } from 'axios';
import { Pageable, PageableResponse, PageableSort, Params } from 'model/pageable';
import { User } from 'model/user';
import HttpStatus from '../model/enums/http-status';

const TeamService = () => {
  const createUser = async (obj: User): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.createUser(obj);
      if (result.status === HttpStatus.OK && result.data != null) {
        // mudar de OK pra CREATED (falar com pessoal da API)
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const createConsultant = async (obj: User): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.createConsultant(obj);
      if (result.status === HttpStatus.OK && result.data != null) {
        // mudar de OK pra CREATED (falar com pessoal da API)
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const deleteInternal = async (id: number): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.deleteInternal(id);
      if (result.status === HttpStatus.DELETED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getUsersTableData = async (params: Params): Promise<PageableResponse<User>> => {
    try {
      const result: AxiosResponse<PageableResponse<User>> = await teamApi.getUsersTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getConsultantsTableData = async (params: Params): Promise<PageableResponse<User>> => {
    try {
      const result: AxiosResponse<PageableResponse<User>> = await teamApi.getConsultantsTableData(params);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getUserById = async (id: number): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.getUserById(id);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const updateUserData = async (data: User, id: number): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.updateUserData(data, id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const updateConsultantData = async (data: User, id: number): Promise<User> => {
    try {
      const result: AxiosResponse<User> = await teamApi.updateConsultantData(data, id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    createUser,
    getUsersTableData,
    getConsultantsTableData,
    deleteInternal,
    getUserById,
    updateUserData,
    createConsultant,
    updateConsultantData,
  };
};

export default TeamService();

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Mask } from '../model/enums/mask-types';
import StringUtils from '../shared/util/string-utils';
import { color } from '../shared/util/styled-components-utils';
import InputErrorMessage from './InputErrorMessage';
import StyledInputWrapper from './StyledInputWrapper';

interface InputTextProps {
  name: string;
  label: string;
  inputType?: string;
  style?: any;
  disabled?: boolean;
  readOnly?: boolean;
  isWhite?: boolean;
  showErrorIcon?: boolean;
  onChange?: (e: any) => void;
  mask?: string;
  defaultValue?: string;
  labelClassName?: string;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  step?: any;
  placeholder?: string;
  errorMessage?: string;
}

export const InputLabelWrapper = styled.label`
  width: 100%;
  overflow-wrap: break-word;
  span.input__text--label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    pointer-events: none;
    padding-left: 15px;
    transition: 250ms;
    color: ${color`form`};
  }

  input:disabled {
    color: transparent;
  }
`;

const InputText: React.FC<InputTextProps> = ({
  name,
  label,
  inputType,
  style,
  disabled = false,
  isWhite = false,
  onChange,
  mask,
  defaultValue,
  readOnly,
  labelClassName,
  maxLength,
  minLength,
  max,
  showErrorIcon,
  min,
  step,
  placeholder,
  errorMessage,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    register,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleGetValue = () => {
    const values = getValues();
    return Boolean(values[name]);
  };

  const hasDefaultValue = () => {
    if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
      return true;
    }
    return dirtyFields[name];
  };
  const styledComponentsProps = {
    isTouched: isFocused,
    hasError: !disabled ? (!!errors[name] || errorMessage != null) && !isFocused : false,
    hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
    isFocused,
    isValid: !disabled ? !errors[name] && !isFocused && hasDefaultValue() : false,
  };

  const isWhiteIfNotDisabled = !disabled ? isWhite : false;

  const handleRegister = register(name);

  const handleSetMask = (value: string | number) => {
    const text = value.toString();
    const MaskFormats = {
      [Mask.CURRENCY]: StringUtils.currencyMask(text),
      [Mask.PHONE]: StringUtils.phoneMask(text),
      [Mask.CNPJ]: StringUtils.cnpjMask(text),
      [Mask.CPF]: StringUtils.cpfMask(text),
      [Mask.CEP]: StringUtils.zipCodeMask(text),
      [Mask.LANDLINE]: StringUtils.landlineMask(text),
      [Mask.PERCENTAGE]: StringUtils.percentageMask(text),
      [Mask.NUMBERS]: StringUtils.removeNonNumbersFromMaskedValue(text),
      [Mask.RG]: StringUtils.rgMask(text),
      [Mask.BIRTHDAY]: StringUtils.birthdayMask(text),
      [Mask.MONEY]: StringUtils.moneyMask(text),
      [Mask.DEFAULT]: text,
    };

    setValue(name, MaskFormats[mask ?? Mask.DEFAULT]);
  };

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    handleSetMask(e.target.value);
  };
  useEffect(() => {
    if (defaultValue) {
      handleSetMask(defaultValue);
    }
    return;
  }, [defaultValue]);

  return (
    <InputLabelWrapper htmlFor={`#input-${name}`} {...styledComponentsProps} className={labelClassName}>
      <StyledInputWrapper isWhite={isWhiteIfNotDisabled} {...styledComponentsProps}>
        <input
          id={`#input-${name}`}
          onFocus={handleFocus}
          {...register(name)}
          onBlur={handleBlur}
          type={inputType ? inputType : disabled ? 'reset' : 'text'}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          minLength={minLength}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          {...props}
          onChange={e => {
            handleChange(e);
            handleRegister.onChange(e);
          }}
        />

        {errorMessage && !styledComponentsProps.hasError && showErrorIcon !== true && (
          <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
        )}

        {styledComponentsProps.isValid && !styledComponentsProps.hasError && showErrorIcon !== true && !errorMessage && (
          <i className="icon-check" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
        )}

        {showErrorIcon === true && (
          <i className="icon-status-icon" style={{ position: 'absolute', bottom: '35%', right: '5%', color: '#2D9187' }} />
        )}

        {styledComponentsProps.hasError && showErrorIcon !== true && (
          <i className="icon-error" style={{ position: 'absolute', bottom: '38%', right: '5%', fontSize: '13px', color: '#E40B21' }} />
        )}

        <span className="input__text--label">{label}</span>
      </StyledInputWrapper>
      {(styledComponentsProps.hasError || errorMessage) && (
        <InputErrorMessage isFocused={isFocused} errorMessage={errors[name]?.message ?? errorMessage} />
      )}
    </InputLabelWrapper>
  );
};

export default InputText;

import AdminHeader from 'components/admin-header/admin-header';
import DashboardTables from 'components/dashboard-table/dashboard-table';
import DropdownFilter from 'components/DropdownFilter';
import DynamicGraphInfos from 'components/dynamic-graph-infos/dynamic-graph-infos';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import GeneralGraphInfos from 'components/general-graph-infos/general-graph-infos';
import { InputContainers, InputDivisor } from 'components/InputContainers';
import SearchHeader from 'components/search-header/search-header';
import { SearchInput } from 'components/search-input/search-input';
import StyledAdminHeader from 'components/styled-admin-header/styled-admin-header';
import StyledPagination from 'components/StyledPagination';
import TableContent from 'components/table-content/table-content';
import { table } from 'console';
import { registerSteps } from 'helpers/helpersDropdownLabels';
import { t } from 'i18next';
import { sortedLastIndexBy } from 'lodash';
import { Client } from 'model/client';
import { StatusDashboard } from 'model/enums/status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dashboardService from 'services/dashboard-service';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import { RegisterTooltipCaptions } from 'shared/util/tooltip-utils';
import { MinHeightScreeningContainer } from 'themes/globalStyles';
import { Container, ItemLink, TableContainer } from '../styles';

const DashboardRegister = () => {
  const { t } = useTranslation();

  const [tableContent, setTableContent] = useState<Client[]>([]);
  const [filterBy, setFilterBy] = useState({ filterSelect: '', saveFilter: 'off' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const header = [
    { name: t('global.table.name'), propName: 'name' },
    { name: t('global.table.email'), propName: 'email' },
    { name: t('global.table.adviser'), propName: 'adviser' },
    { name: t('global.table.phone'), propName: 'phone' },
    { name: t('global.table.createdDate'), propName: 'createdDate' },
    { name: t('global.table.status'), propName: 'registrationStatus' },
  ];

  const sortableColumns = ['Nome', 'Email', 'Consultor', 'Telefone', 'Status'];
  const filterSaved = localStorage.getItem(registerSteps.toString());

  useEffect(() => {
    filterSaved &&
      handleFilter({
        filterSelect: filterSaved,
        saveFilter: 'on',
      });
  }, []);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilter = e => {
    if (!e.filterSelect) {
      return;
    }

    const statusOptions = [
      StatusDashboard.PRE_REJECTED,
      StatusDashboard.NEW,
      StatusDashboard.ADJUSTMENTS,
      StatusDashboard.UPDATED,
      StatusDashboard.APPROVED,
    ];
    const status = statusOptions[Number(e.filterSelect)] ?? 'off';

    setFilterBy({
      filterSelect: status,
      saveFilter: e.saveFilter,
    });
  };

  const handleOrderData = async () => {
    const today = StringUtils.dateMask(new Date());
    const filterOutput = filterBy.filterSelect === 'off' ? '' : filterBy.filterSelect;

    const register = await dashboardService.getRegisterTableData({
      page: currentPage - 1,
      size: 10,
      registrationStatus: `contains(${[filterOutput]})`,
      name: `contains(${searchInputValue})`,
      sort: 'registrationStatusOrdinal,asc',
    });

    setTotalPages(register.totalPages);
    setTableContent(register.content ?? []);
  };

  // debounce
  const [timer, setTimer] = useState<any>();
  function debounce(func, timeout = 300) {
    clearTimeout(timer);
    setTimer(setTimeout(func, timeout));
  }

  useEffect(() => {
    debounce(() => {
      handleOrderData();
    }, 300);
  }, [filterBy, currentPage, searchInputValue]);

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AdminHeader />
      </div>
      <div style={{ width: '100%' }}>
        <StyledAdminHeader>{t('menu.dashboard.register')}</StyledAdminHeader>
        <MinHeightScreeningContainer>
          <Container>
            <DynamicGraphInfos page="clients" />
            <InputContainers>
              <SearchInput
                placeholder={t('fields.searchByName')}
                onChange={e => setSearchInputValue(e.target.value)}
                value={searchInputValue}
              />
              <InputDivisor />
              <DropdownFilter placeholder={t('global.fields.filterBy')} labels={registerSteps} onApplyFiltration={e => handleFilter(e)} />
            </InputContainers>
          </Container>
          <TableContainer>
            {!AuthUtils.isPartner() && !AuthUtils.isInternal() && (
              <Link to={'/admin/new/client'}>
                <ItemLink>{t('global.button.addNewClient')}</ItemLink>
              </Link>
            )}
            <TableContent
              data={tableContent ?? []}
              sortableColumns={sortableColumns}
              header={header}
              tooltipCaptions={RegisterTooltipCaptions()}
              tableName={'register'}
            />
            <StyledPagination
              page={{
                page: currentPage,
                totalPages,
              }}
              handleChangePage={handleChangePage}
            />
          </TableContainer>
          <FooterLoginAdmin />
        </MinHeightScreeningContainer>
      </div>
    </div>
  );
};

export default DashboardRegister;

import { AxiosError, AxiosResponse } from 'axios';
import { IFinishRecoveryPasswordData } from 'features/change-password/change-password';
import { IRecoveryEmail } from 'features/forgot-password/forgot-password';
import authenticationApi from '../api/authentication-api';
import HttpStatus from '../model/enums/http-status';

const PasswordResetService = () => {
  const requestPasswordReset = async (email: IRecoveryEmail): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await authenticationApi.requestPasswordReset(email);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: result.status } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const finishPasswordReset = async (data: IFinishRecoveryPasswordData): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await authenticationApi.finishPasswordReset(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: result.status } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    requestPasswordReset,
    finishPasswordReset,
  };
};

export default PasswordResetService();

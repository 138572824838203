import { FooterStyled } from 'components/FooterStyled';
import PaymentSteps from 'components/paymentsSteps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerTitle, TitleText } from './styles';

const Payment = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ContainerTitle>
        <TitleText>{t('dashboardClient.payments.title')}</TitleText>
      </ContainerTitle>
      <PaymentSteps />
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

export default Payment;

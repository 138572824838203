import React, { useEffect, useState } from 'react';
import { Container, ContainerAllCourse, ContainerButton, TitleText } from './styles';
import { useTranslation } from 'react-i18next';
import Plus from '../../images/plusPurple.svg';
import CardFinancing from 'components/CardFinancing';
import ModalNewFinancing from 'components/Modais/ModalNewFinancing';
import Check from '../../images/modalCheckGreen.svg';
import { SimpleModal } from 'components/SimpleModal';
import { useFinancing } from 'provider/Financing';
import StringUtils from 'shared/util/string-utils';
import { FooterStyled } from 'components/FooterStyled';

const SeeFunding = () => {
  const { t } = useTranslation();
  const { courses, getCourses } = useFinancing();
  const [showModalCreateFinancing, setShowModalCreateFinancing] = useState<boolean>(false);
  const [showModalRequestOk, setShowModalRequestOk] = useState<boolean>(false);

  useEffect(() => {
    if (courses.length === 0) {
      getCourses();
    }
  }, []);

  const handleCloseModal = () => {
    setShowModalCreateFinancing(false);
  };

  const handleOpenModalRequest = () => {
    setShowModalRequestOk(true);
  };

  const handleCloseModalRequest = () => {
    getCourses();
    setShowModalRequestOk(false);
  };

  return (
    <Container>
      <ContainerButton>
        <TitleText>{t('dashboardClient.financing.myFinancing')}</TitleText>
      </ContainerButton>
      <ContainerAllCourse>
        {courses.length > 0 &&
          courses.map(element => {
            return (
              <CardFinancing
                key={element.id}
                data={{
                  id: element.id ?? 0,
                  creditAnalysisStatus: element.creditAnalysisStatus,
                  duration: element?.duration ?? '',
                  language: element.language?.name ?? '',
                  objective: element?.objective ?? '',
                  startDate: StringUtils.dateFormatMask(element.startDate ?? ''),
                }}
              />
            );
          })}
        <img src={Plus} onClick={() => setShowModalCreateFinancing(true)} />
      </ContainerAllCourse>
      <ModalNewFinancing isOpen={showModalCreateFinancing} onCloseModal={handleCloseModal} setModalRequestOk={handleOpenModalRequest} />
      <SimpleModal
        isOpen={showModalRequestOk}
        onCloseModal={() => setShowModalRequestOk(false)}
        imageSrc={Check}
        titleText={'dashboardClient.financing.modal.registerFinancing'}
        messageText=""
        confirmText={'OK'}
        handleConfirm={() => handleCloseModalRequest()}
      />
      <FooterStyled>
        <span>{t('footer.texts.copyright')}</span>
      </FooterStyled>
    </Container>
  );
};

export default SeeFunding;

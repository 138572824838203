import Status from 'components/status/status';
import { truncate } from 'lodash';
import { Client } from 'model/client';
import { CourseFinancing, DownPaymentInstallment } from 'model/financing';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import ClipBlack from '../../../../../../images/clipBlack.svg';
import { BreakLine, ContainerAttachment, ContentContainer, ContentLabel, ContentValue, DataContainer } from '../styles';
import NoDataPage from './no-data-page';

interface ClientDataProps {
  paymentContent: DownPaymentInstallment;
}

const DownPaymentData: React.FC<ClientDataProps> = props => {
  const { t } = useTranslation();
  const { paymentContent } = props;

  return (
    <DataContainer>
      <ContentContainer>
        <ContentLabel>{t('clientData.details.income')}</ContentLabel>
        <Status title={paymentContent?.bankSlip?.status} />
      </ContentContainer>
    </DataContainer>
  );
};

export default DownPaymentData;

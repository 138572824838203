import { addDays, format } from 'date-fns';
import { useFinancing } from 'provider/Financing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { Container, ContainerInfos, ContainerTitleAndInfo, Icon, SimulatorTitle, TextInfo, TextTitle } from '../styles';
import CalendarCash from 'images/calendar-cash.svg';

const SimulatorInfo = () => {
  const { financing, EntryValue } = useFinancing();
  const approvedValue = StringUtils.moneyMaskPtbr(Number(financing.approvedValue?.toFixed(2)), true);
  const { t } = useTranslation();
  const DueDate = format(addDays(new Date(), 3), 'dd/MM/yyyy');

  return (
    <Container>
      <SimulatorTitle>{t('dashboardClient.financing.simulator.myFinancing')}</SimulatorTitle>
      <ContainerInfos diraction="column" height="144px" margin="25px 0px 0px 0px">
        <ContainerTitleAndInfo align="center">
          <TextTitle>{t('dashboardClient.financing.simulator.value')}</TextTitle>
          <TextInfo size="24px">
            <TextInfo size="18px" color="#999999">
              R$
            </TextInfo>
            {approvedValue}
          </TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo align="center">
          <TextTitle>{t('dashboardClient.financing.simulator.initialDate')}</TextTitle>
          <TextInfo size="24px">{StringUtils.dateFormatMask(financing?.course?.startDate ?? '')}</TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ContainerInfos diraction="row" height="100px" margin="21px 0px 0px 0px">
        <ContainerTitleAndInfo align="flex-start" width="80px" height="68px">
          <TextTitle>{t('dashboardClient.guarantor.cardFinancing.objective')}</TextTitle>
          <TextInfo size="16px">{t(`enums.${financing?.course?.objective?.toLowerCase()!}`)}</TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo align="flex-start" width="80px" height="68px">
          <TextTitle>{t('dashboardClient.guarantor.cardFinancing.language')}</TextTitle>
          <TextInfo size="16px">{financing?.course?.language?.name}</TextInfo>
        </ContainerTitleAndInfo>
        <ContainerTitleAndInfo align="flex-start" width="80px" height="68px">
          <TextTitle>{t('dashboardClient.guarantor.cardFinancing.duration')}</TextTitle>
          <TextInfo size="16px">{t(`enums.${financing?.course?.duration?.toLowerCase()!}`)}</TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ContainerInfos diraction="row" height="67px" margin="8px 0px 0px 0px">
        <ContainerTitleAndInfo align="center">
          <TextTitle>
            <Icon src={CalendarCash} />
            {t('dashboardClient.financing.simulator.deadline')}
          </TextTitle>
          <TextInfo size="18px">{t('dashboardClient.financing.simulator.days')}</TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
      <ContainerInfos diraction="row" height="67px" margin="8px 0px 0px 0px">
        <ContainerTitleAndInfo align="center">
          <TextTitle>
            <Icon src={CalendarCash} />
            {t('dashboardClient.financing.simulator.deadlineEntry')}
          </TextTitle>
          <TextInfo size="18px">{DueDate}</TextInfo>
        </ContainerTitleAndInfo>
      </ContainerInfos>
    </Container>
  );
};

export default SimulatorInfo;

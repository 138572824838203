import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { Course } from 'model/courses';
import { useRegisterForm } from 'provider/RegisterForm';
import { useSelectLists } from 'provider/SelectLists';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Airplane from '../../../images/airplane.svg';
import { FormContainer } from '../styles';
import { ContainerInputsCourse } from './InputsCourse';

export const FormCourse = () => {
  const { onClickNext } = useRegisterForm();
  const { languages, getLanguages } = useSelectLists();
  const { t } = useTranslation();
  const schema = yup.object({
    objective: yup.string().required(t('global.errorMessage.required')),
    language: yup.string().required(t('global.errorMessage.required')),
    duration: yup.string().required(t('global.errorMessage.required')),
  });

  useEffect(() => {
    getLanguages({ size: 7 });
  }, []);

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const language = languages.find(lang => lang.name === res.language);
          const course: Course = {
            objective: res.objective,
            language,
            duration: res.duration,
          };

          onClickNext({ course } as Client);
        }}
      >
        <ContainerInputsCourse />
      </ReactHookFormProvider>
      <div style={{ marginLeft: '90px' }}>
        <img src={Airplane} />
      </div>
    </FormContainer>
  );
};

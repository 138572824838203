import { yupResolver } from '@hookform/resolvers/yup';
import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import HeaderLoginAdmin from 'components/header-login-admin/header-login-admin';
import InputText from 'components/InputText';
import StyledButton from 'components/StyledButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import ReactHookFormProvider from '../../components/ReactHookFormProvider';
import PasswordResetService from '../../services/forgot-password-service';
import { ButtonContainer, Container, FormContainer, InputContainer, LoginContainer, SubTextTitle, TextTitle } from './StyledComponents';

export interface IRecoveryEmail {
  email: string;
}

const ForgotPasswordAdmin = (props: any) => {
  const { t } = useTranslation();

  const history = useHistory();

  const schema = yup.object({
    email: yup.string().email(t('global.errorMessage.type')).required(t('global.errorMessage.required')),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IRecoveryEmail) => {
    PasswordResetService.requestPasswordReset(data).then(() => history.push('/forgot-password/sent'));
  };

  return (
    <>
      <HeaderLoginAdmin />
      <LoginContainer>
        <Container>
          <TextTitle>{t('forgotPassword.admin.title')}</TextTitle>
          <SubTextTitle>{t('forgotPassword.admin.subtitle')}</SubTextTitle>
          <FormContainer>
            <ReactHookFormProvider schema={schema} onSubmit={onSubmit}>
              <InputContainer>
                <InputText name="email" label="" placeholder={t('login.admin.form.usernamePlaceholder')} />
              </InputContainer>
              <ButtonContainer>
                <StyledButton primary type="submit" text={t('global.button.send')} />
              </ButtonContainer>
            </ReactHookFormProvider>
          </FormContainer>
        </Container>
      </LoginContainer>
      <FooterLoginAdmin />
    </>
  );
};

export default ForgotPasswordAdmin;

import FooterLoginAdmin from 'components/footer-login-admin/footer-login-admin';
import HeaderLoginAdmin from 'components/header-login-admin/header-login-admin';
import StyledButton from 'components/StyledButton';
import EmailSentIcon from 'images/email-sent.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ButtonContainer, Container, FormContainer, ImgContainer, LoginContainer, SubTextTitle, TextTitle } from './StyledComponents';

const EmailSentAdmin = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderLoginAdmin />
      <LoginContainer>
        <Container>
          <TextTitle>{t('forgotPassword.sucessMessage.title')}</TextTitle>
          <ImgContainer>
            <img src={EmailSentIcon} />
          </ImgContainer>
          <SubTextTitle>{t('forgotPassword.sucessMessage.subtitle')}</SubTextTitle>
          <SubTextTitle>{t('forgotPassword.sucessMessage.subtitle2')}</SubTextTitle>
          <FormContainer>
            <ButtonContainer>
              <StyledButton margin="5px" primary onClick={() => history.push('/forgot-password')} text={t('global.button.sendAgain')} />
            </ButtonContainer>
            <ButtonContainer>
              <StyledButton margin="5px" secondary onClick={() => history.push('/login')} text={t('global.button.goLogin')} />
            </ButtonContainer>
          </FormContainer>
        </Container>
      </LoginContainer>
      <FooterLoginAdmin />
    </>
  );
};

export default EmailSentAdmin;
